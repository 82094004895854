var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect, Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon, Input, Text, Parent, Link, Checkbox } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import DataNotFound from '../DataNotFound/DataNotFound';
import { FilterIcon, DotsVerticalIcon, ShoppingCartIcon, SearchIcon } from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import SlideOverlay from '../../Component/SlideOverlay/SlideOverlay';
import { SERVERURL } from '../../Utilities/Constants';
import { classNames } from '../../Utilities/Helpers';
import ItemsTable from './ItemsTable';
import ItemPopUp from '../../Component/ItemPopUp/ItemPopUp';
import SuccessPopUp from '../../Component/SuccessPopUp/SuccessPopUp';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { APILINK } from '../../Utilities/ApiLink';
import { DATALIMIT, PAGELIMIT, STARTPAGE } from '../../Utilities/Constants';
import { AppContext } from '../../../App';
import useRestApiHooks from '../hooks/RestApiHooks';
const Items = ({}) => {
    const { t } = useTranslation();
    const { isLoading: getLoad, error, sendRequest: fetchTasks } = useRestApiHooks();
    const options = [
        {
            label: t('tax_on_off'),
            value: '1'
        },
        {
            label: t('item_on_off'),
            value: '2'
        },
        {
            label: t('available_qty'),
            value: '3'
        },
        {
            label: t('apply_discount'),
            value: '4'
        }
    ];
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState(t('filter_category'));
    const [form, setForm] = useState('');
    const [filtered, setFiltered] = useState([]);
    const [singleObj, setSingleObj] = useState({});
    const [showItemPopUp, setshowItemPopUp] = useState(false);
    const [popTitle, setpopTitle] = useState('');
    const [filterData, setFilterData] = useState([]);
    const [checkItem, setcheckItem] = useState([]);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [apiResultArray, setApiResultArray] = useState([]);
    const [showpop, setShowpop] = useState(true);
    const businessId = localStorage.getItem('businessId');
    const outletId = localStorage.getItem('outletId');
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    let showSuccess = localStorage.getItem('showSuccess');
    !showpop && localStorage.setItem('showSuccess', 'false');
    const [checkTF, setCheckTF] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categoryLength, setCategoryLength] = useState();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [items, setItems] = useState(filterData);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [strt, setStrt] = useState(0);
    const [end, setEnd] = useState(5);
    const [selectedFilterCategoryIds, setSelectedFilterCategoryIds] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const { itemsFilterCategory, setItemsFilterCategory } = useContext(AppContext);
    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };
    const delayedSearch = debounce((value) => {
        setDebouncedSearch(value);
        setOffset(0);
        setCurrentPage(1);
        setCurrentPageNumber(1);
        setStartIndex(currentPageNumber * dataLimit - dataLimit + 1);
        setEndIndex(currentPage * dataLimit);
    }, 800);
    useEffect(() => {
        if (offset === 0) {
            setCurrentPage(1);
            setCurrentPageNumber(1);
            getItem();
        }
        else {
            getItem();
        }
    }, [offset, dataLimit, debouncedSearch, selectedFilterCategoryIds]);
    useEffect(() => {
        setItems(filterData);
        getPaginatedData(1);
    }, [dataLimit, filterData]);
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = currentPage * dataLimit;
        const list = filterData.slice(startIndex, endIndex);
        setEndIndex(endIndex);
        setStartIndex(startIndex + 1);
        setOffset(startIndex);
        // setOffset(currentPage === 1 ? 0 : (currentPage - 1) * dataLimit);
    };
    const columns = [
        {
            id: 1,
            title: (_jsxs(Parent, Object.assign({ className: "flex  justify-between w-3/12" }, { children: [_jsx(Checkbox, { type: "checkbox", name: 'item.cat_name', className: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'focus:ring-transparent h-4 ml-2 mt-6 w-4 hidden items-center justfy-center border-gray-300 rounded'), id: '', onChange: (e) => changeCheckTF(e) }, void 0), _jsx(Text, { className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", as: "p", label: t('image') }, void 0)] }), void 0)),
            className: ''
        },
        {
            id: 2,
            title: t('name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: `${t(`type`)}`,
            className: 'px-6 py-6 text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: `${t(`price`)} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        businessType == 'Retail' && {
            id: 6,
            title: t('available_quantity'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        // data type is any if really want to pass html code change it react.reactNode
        {
            id: 6,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const changeCheckTF = (e) => {
        if (e.target.checked) {
            setCheckTF(true);
        }
        else {
            setCheckTF(false);
        }
    };
    const ShowItemPopUpAl = (title) => {
        setpopTitle(title);
        setshowItemPopUp(true);
    };
    // const filCatData = (filcat: any, filData: any) => {
    //     let filterCAtData = filData ? filData : apiResultArray;
    //     setFiltered(filcat);
    //     setItemsFilterCategory(filcat);
    //     setOpen(false);
    //     const oldData: any = [];
    //     let filteredCategoryIds: any = [];
    //     filcat.map((item: any) => {
    //         filteredCategoryIds.push(item.id);
    //         const fulFill = filterCAtData.filter((data: any) => {
    //             return data.cat_id == item.id;
    //         });
    //         fulFill.map((fulFil: any) => {
    //             oldData.push(fulFil);
    //         });
    //     });
    //     setSelectedFilterCategoryIds(filteredCategoryIds.toString());
    //     if (filcat.length > 0) {
    //         setFilterData(filterCAtData);
    //     } else {
    //         setFilterData(apiResultArray);
    //     }
    // };
    const filCatData = (filcat, filData) => {
        const filterCAtData = filData ? filData : apiResultArray;
        setFiltered(filcat);
        setItemsFilterCategory(filcat);
        setOpen(false);
        const oldData = [];
        const filteredCategoryIds = [];
        filcat.forEach((item) => {
            filteredCategoryIds.push(item.id);
            const fulFill = filterCAtData.filter((data) => data.cat_id == item.id);
            oldData.push(...fulFill); // Use spread operator to push all filtered items
        });
        setSelectedFilterCategoryIds(filteredCategoryIds.toString());
        // Set filter data to oldData or fallback to apiResultArray
        if (oldData.length > 0) {
            setFilterData(oldData);
        }
        else {
            setFilterData(apiResultArray);
        }
    };
    const filterDataList = (e) => {
        const filterText = e.target.value;
        delayedSearch(filterText);
    };
    const sliderOpen = (value, title) => {
        setOpen(value);
        setSliderTitle(title);
        setForm(title);
    };
    const [productTotalLength, setproductTotalLength] = useState(0);
    const datass = (response) => __awaiter(void 0, void 0, void 0, function* () {
        yield setApiResultArray(response.data.data);
        setproductTotalLength(Number(response.data.product_count));
        if (filtered.length > 0) {
            filCatData(filtered, response.data.data);
        }
        else {
            if (debouncedSearch !== '') {
                setproductTotalLength(response.data.product_count);
                setStartIndex(1);
            }
            yield setFilterData(response.data.data);
            setItems(filterData);
            getPaginatedData(currentPageNumber);
        }
        if (itemsFilterCategory.length > 0) {
            setFiltered(itemsFilterCategory);
            filCatData(itemsFilterCategory, response.data.data);
        }
    });
    const [prevCatId, setPrevCatId] = useState('');
    const getItem = () => __awaiter(void 0, void 0, void 0, function* () {
        const currentCatId = selectedFilterCategoryIds;
        let newOffset = offset;
        if (prevCatId !== currentCatId) {
            newOffset = 0;
            setCurrentPageNumber(1);
        }
        // Update prevCatId to the current one for future comparison
        setPrevCatId(currentCatId);
        const param = {
            business_id: businessId,
            outlet_id: outletId,
            offset: newOffset,
            limit: dataLimit,
            search: debouncedSearch,
            cat_id: currentCatId || ''
        };
        try {
            yield fetchTasks({
                url: APILINK.product_get,
                method: 'GET',
                token,
                param
            }, datass);
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    });
    const resetFilter = () => {
        setFiltered([]);
        setItemsFilterCategory([]);
        setOpen(false);
        setFilterData(apiResultArray);
        setSelectedFilterCategoryIds('');
        setCurrentPageNumber(1);
    };
    useEffect(() => {
        return () => {
            resetFilter();
        };
    }, []);
    useEffect(() => {
        getItem();
    }, [selectedFilterCategoryIds, offset, debouncedSearch]);
    useEffect(() => {
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, filterData]);
    const addItems = () => {
        localStorage.setItem('prodectId', '');
        localStorage.setItem('apiSiArray', JSON.stringify({
            addon: '{}',
            available_qty: '',
            brand_name: '',
            business_id: 0,
            c_ts: '',
            cat_id: '',
            description: '',
            disc_amount: '',
            disc_type_id: 0,
            from_scale: '',
            id: 0,
            is_active: '',
            is_age_restricted: '',
            is_favorite: '',
            is_taxable: '',
            min_qty: '',
            outlet_id: 0,
            sku: '',
            barcode: '',
            prod_item: '',
            prod_name: '',
            price: '',
            spl_notes: '',
            type: '',
            sq_id: '',
            su_id: 0,
            u_ts: '',
            uom_id: '',
            variation: '{"color":[],"size":[]}'
        }));
        history.push('/add-item');
    };
    const loadData = (values, from) => {
        // refetch();
    };
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((user) => user.id === id);
        setSingleObj(Obj);
        setOpen(value);
        setSliderTitle(title);
        setForm(title);
    };
    const checks = (e, indexss) => {
        // setCurrentPageNumber(1);
        const oldData = [...checkItem];
        if (e.target.checked) {
            let date = {
                id: e.target.id
            };
            oldData.push(date);
            setcheckItem(oldData);
            let oldItem = [];
            apiResultArray.map((item) => {
                if (item.id == e.target.id) {
                    let cust = Object.assign(Object.assign({}, item), { checked: true });
                    oldItem.push(cust);
                }
                else {
                    let cust = Object.assign({}, item);
                    oldItem.push(cust);
                }
                setFilterData(oldItem);
                setApiResultArray(oldItem);
            });
        }
        else {
            let oldItem = [];
            apiResultArray.map((item) => {
                if (item.id == e.target.id) {
                    let cust = Object.assign(Object.assign({}, item), { checked: false });
                    oldItem.push(cust);
                }
                else {
                    let cust = Object.assign({}, item);
                    oldItem.push(cust);
                }
                setFilterData(oldItem);
                setApiResultArray(oldItem);
            });
            const filDate = oldData.filter((data) => {
                return data.id != e.target.id;
            });
            setcheckItem(filDate);
        }
    };
    const convertArrayOfObjectsToCSV = (array) => {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        if (array.length > 0) {
            const keys = Object.keys(array[0]);
            result = '';
            result += 'Product Information';
            result += lineDelimiter;
            array.forEach((item) => {
                let ctr = 0;
                keys.forEach((key) => {
                    if (ctr > 0)
                        result += columnDelimiter;
                    result += item[key];
                    ctr++;
                });
                result += lineDelimiter;
            });
        }
        return result;
    };
    const downloadCSV = () => __awaiter(void 0, void 0, void 0, function* () {
        const link = document.createElement('a');
        const headers = [
            [
                'Name',
                'SKU',
                `Price  (${currencySymbol})`,
                'Available Quantity',
                'Minimum Quantity',
                'Barcode',
                'Description',
                'Brand Name',
                'Category Name',
                'Discount Amount',
                'Discount Name',
                'UOM',
                'Is Favorite',
                'Is Taxable',
                'Is Enable',
                'Is Age Restricted'
            ]
        ];
        const costData = filterData.map((cost) => [
            cost.prod_name,
            cost.sku,
            cost.price,
            cost.available_qty,
            cost.min_qty,
            cost.barcode,
            cost.description,
            cost.brand_name,
            cost.cat_name,
            cost.disc_amount,
            cost.disc_name,
            cost.uom,
            cost.is_favorite,
            cost.is_taxable,
            cost.is_enable,
            cost.is_age_restricted
        ]);
        const cust = headers.concat(costData);
        let csv = yield convertArrayOfObjectsToCSV(cust);
        if (csv == null)
            return;
        const filename = 'Products Summary.csv';
        csv = '\ufeff' + csv;
        var csvFile = new Blob([csv], {
            type: 'text/csv'
        });
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', URL.createObjectURL(csvFile));
        link.setAttribute('download', filename);
        link.click();
    });
    useEffect(() => {
        const currency = localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£';
        setCurrencySymbol(currency);
    }, []);
    useEffect(() => {
        getItem();
    }, []);
    useEffect(() => {
        !showpop && localStorage.setItem('showSuccess', 'false');
        setShowpop(true);
    }, [showpop]);
    return (_jsxs(Parent, Object.assign({ className: " max-w-8xl w-full lg:px-12 md:px-6 pb-9 align-middle inline-block px-4" }, { children: [_jsx(Parent, Object.assign({ className: " right-10 top-14 absolute flex justify-end " }, { children: showSuccess == 'true' && (_jsx(SuccessPopUp, { setShowSuccess: setShowpop, title: t('successfully_created'), description: t('items_successfully_created') }, void 0)) }), void 0), _jsxs(ContainerSection, Object.assign({ className: "bg-white max-w-8xl rounded-lg shadow-custom  px-5 py-5 mt-3" }, { children: [_jsxs(Parent, Object.assign({ className: "flex sm:flex-row flex-col sm:justify-between sm:items-center " }, { children: [_jsx(Parent, Object.assign({ className: "" }, { children: _jsx(Parent, { children: _jsx(Input, { name: "search", className: classNames(businessType == 'Retail'
                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                            : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm focus:outline-none block sm:w-auto w-full px-3 pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "search", placeHolder: t('search_itemname'), type: "text", labelText: t('search'), labelClass: "block text-sm font-poppins font-medium text-gray-300 pb-1", onChange: (e) => filterDataList(e), iconRequired: true, labelRequired: false, iconTag: SearchIcon }, void 0) }, void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "flex-1 mt-5 sm:mt-0 flex items-center justify-between sm:justify-end" }, { children: [_jsx(Parent, Object.assign({ className: "" }, { children: _jsx(Button, Object.assign({ text: t('filter'), className: classNames(businessType == 'Retail'
                                                ? 'text-blue-600 bg-blue-100  hover:bg-blue-600'
                                                : 'text-blue-600 bg-blue-100  hover:bg-blue-600', 'group inline-flex items-center mr-4 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md  focus:outline-none hover:text-white'), onClick: () => setOpen(true) }, { children: _jsx(Icon, { tag: FilterIcon, className: classNames(businessType == 'Retail'
                                                    ? 'text-blue-600'
                                                    : 'text-blue-600', 'w-5 h-5 group-hover:text-white rounded-md mr-2') }, void 0) }), void 0) }), void 0), _jsx(Parent, Object.assign({ className: "" }, { children: _jsx(Button, { text: t('add_item'), className: classNames(businessType == 'Retail'
                                                ? 'text-blue-600 bg-blue-100  hover:bg-blue-600'
                                                : 'text-blue-600 bg-blue-100  hover:bg-blue-600', 'group inline-flex items-center mr-4 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md  hover:text-white focus:outline-none'), onClick: () => addItems() }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "" }, { children: _jsxs(Menu, Object.assign({ as: "div", className: "relative inline-block text-left" }, { children: [_jsx(Parent, { children: _jsxs(Menu.Button, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'text-blue-600 bg-blue-100  hover:bg-blue-600'
                                                            : 'text-blue-600 bg-blue-100  hover:bg-blue-600', 'group inline-flex items-center px-2.5 py-2.5 border border-transparent shadow-custom text-sm font-poppins font-medium rounded-md hover:text-white focus:outline-none') }, { children: [_jsx(Text, { as: "span", className: "sr-only", label: t('open_options') }, void 0), _jsx(Icon, { tag: DotsVerticalIcon, className: classNames(businessType == 'Retail'
                                                                    ? 'text-blue-600'
                                                                    : 'text-blue-600', 'w-5 h-5 group-hover:text-white rounded-md') }, void 0)] }), void 0) }, void 0), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, Object.assign({ className: "origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" }, { children: _jsxs(Parent, Object.assign({ className: "py-1" }, { children: [_jsx(Menu.Item, { children: ({ active }) => (_jsx(Link
                                                                    // href="#"
                                                                    , Object.assign({ 
                                                                        // href="#"
                                                                        onClick: () => ShowItemPopUpAl(t('apply_tax')), className: classNames(active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-600', 'hidden  px-4 py-2 text-sm font-poppins font-medium cursor-pointer') }, { children: t('tax_on_off') }), void 0)) }, void 0), _jsx(Menu.Item, { children: ({ active }) => (_jsx(Link
                                                                    // href="#"
                                                                    , Object.assign({ 
                                                                        // href="#"
                                                                        onClick: () => ShowItemPopUpAl(t('item')), className: classNames(active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-600', 'block px-4 py-2 text-sm font-poppins font-medium cursor-pointer') }, { children: t('item_on_off') }), void 0)) }, void 0), businessType == 'Retail' && (_jsx(Menu.Item, { children: ({ active }) => (_jsx(Link
                                                                    // href="#"
                                                                    , Object.assign({ 
                                                                        // href="#"
                                                                        onClick: () => ShowItemPopUpAl(t('apply_qty')), className: classNames(active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-600', 'block px-4 py-2 text-sm font-poppins font-medium cursor-pointer') }, { children: t('available_qty') }), void 0)) }, void 0)), _jsx(Menu.Item, { children: ({ active }) => (_jsx(Link
                                                                    // href="#"
                                                                    , Object.assign({ 
                                                                        // href="#"
                                                                        onClick: () => ShowItemPopUpAl(t('discount_per')), className: classNames(active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-600', 'block px-4 py-2 text-sm font-poppins font-medium cursor-pointer') }, { children: t('apply_discount') }), void 0)) }, void 0), _jsx(Menu.Item, { children: ({ active }) => (_jsx(Link, Object.assign({ href: `${SERVERURL}${APILINK.download_excel_get}?business_id=${parseInt(businessId)}&outlet_id=${parseInt(outletId)}`, title: t('export_csv'), 
                                                                        // onClick={() => downloadCSV()}
                                                                        className: classNames(active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-600', ' block px-4 py-2 text-sm font-poppins font-medium cursor-pointer') }, { children: t('export_csv') }), void 0)) }, void 0), _jsx(Menu.Item, { children: ({ active }) => (_jsx(Link
                                                                    // href="#"
                                                                    , Object.assign({ 
                                                                        // href="#"
                                                                        title: t('upload_csv'), onClick: () => ShowItemPopUpAl(t('upload_csv')), className: classNames(active
                                                                            ? 'bg-gray-100 text-gray-900'
                                                                            : 'text-gray-600', ' block px-4 py-2 text-sm font-poppins font-medium cursor-pointer') }, { children: t('upload_csv') }), void 0)) }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0)] }), void 0), getLoad ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames(businessType == 'Retail'
                                        ? 'border-blue-900'
                                        : 'border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames(businessType == 'Retail' ? 'text-blue-900' : 'text-blue-900', ' font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsxs(Parent, { children: [filtered.length != 0 && (_jsxs(Parent, Object.assign({ className: "flex justify-between items-center px-3 py-3" }, { children: [_jsx(Parent, Object.assign({ className: "" }, { children: _jsxs(Text, Object.assign({ className: "block text-base  font-medium  font-poppins text-gray-700  ", as: "p" }, { children: [`${t('categories_selected')} : `, filtered.length > 0 &&
                                                    (categoryLength === filtered.length ? (_jsx(Text, { className: " inline-block text-base  font-normal  font-poppins text-gray-700  ", as: "p", label: t('all') }, void 0)) : (filtered.map((item, index) => {
                                                        return (_jsx(Text, { className: " inline-block text-sm pl-1  font-normal  font-poppins text-gray-700  ", as: "p", label: filtered[index] ==
                                                                filtered[(filtered === null || filtered === void 0 ? void 0 : filtered.length) - 1]
                                                                ? `${item.name}.`
                                                                : `${item.name},` }, item.id));
                                                    })))] }), void 0) }), void 0), _jsx(Parent, { className: "flex-1 flex justify-end" }, void 0)] }), void 0)), filterData && filterData.length > 0 ? (_jsx(ItemsTable, { onClick: sliderOpen, resultData: filterData, getSingleUser: getSingleObj, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, columns: columns, total: productTotalLength, reloadData: loadData, getItem: getItem, checks: checks, checkTF: checkTF, offset: offset, setOffset: setOffset, items: items, pageLimit: pageLimit, dataLimit: dataLimit, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, currentPage: currentPage, setItems: setItems, setCurrentPage: setCurrentPage, strt: strt, setStrt: setStrt, end: end, setEnd: setEnd }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('items_are_not_available_click_add_item_to_create_new_item'), iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: ShoppingCartIcon }, void 0)), _jsx(SlideOverlay, { resetFilter: resetFilter, open: open, setOpen: setOpen, title: sliderTitle, singleObj: singleObj, filCatData: filCatData, setCategoryLength: setCategoryLength, preSelectedCat: filtered, from: "Filter", overLayWidth: "max-w-md" }, void 0), _jsx(ItemPopUp, { id: checkItem, open: showItemPopUp, setOpen: setshowItemPopUp, reloadData: getItem, 
                                // deleteRecord={deleteUser}
                                title: popTitle }, void 0)] }, void 0))] }), void 0)] }), void 0));
};
export default Items;
