/** @format */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useCallback } from "react";
import apiCall from "../../../API/apiCall";
const useRestApiHooks = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const sendRequest = useCallback((requestConfig, applyData) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        setError(null);
        yield apiCall
            .GetServerCall(requestConfig.url, requestConfig.method, requestConfig.token, requestConfig.param)
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            applyData(response);
            setIsLoading(false);
        }))
            .catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
        setIsLoading(false);
    }), []);
    return {
        isLoading,
        error,
        sendRequest,
    };
};
export default useRestApiHooks;
