import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '../../Utilities/Helpers';
import { Parent, Icon, Text } from '../../Component';
import { XIcon } from '@heroicons/react/solid';
const TableCard = ({ card, showAddTable, showConfirmation }) => {
    return (_jsxs(Parent, Object.assign({ className: classNames(card.status == '1'
            ? 'flex mt-2 ml-2 items-center justify-between rounded-md shadow-custom sm:w-52 lg:w-auto px-4 py-2 relative bg-blue-400  text-white h-20'
            : 'flex mt-2 ml-2 items-center justify-between rounded-md shadow-custom sm:w-52 lg:w-auto px-4 py-2 relative hover:bg-blue-400 text-gray-500 hover:text-white h-20') }, { children: [_jsx(Parent, Object.assign({ className: classNames(' h-5 w-5  rounded-full absolute -top-1 -right-1 bg-gray-100'), onClick: () => showConfirmation(true, card.id, card.table_name, 'table') }, { children: _jsx(Parent, Object.assign({ className: "flex items-center justify-center" }, { children: _jsx(Icon, { tag: XIcon, className: "w-3 absolute top-1 h-3 mb-4 text-red-500" }, void 0) }), void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "grid grid-cols-4 w-full items-center", onClick: () => showAddTable(card) }, { children: [_jsxs(Parent, Object.assign({ className: " col-span-3 flex flex-col" }, { children: [_jsx(Text, { as: "h1", className: " font-poppins text-base font-semibold truncate", title: `${card.table_name}`, label: `${card.table_name}` }, void 0), _jsx(Text, { as: "p", className: " font-poppins text-xs pt-1 font-medium  hover:text-white", label: card.waiter_name }, void 0)] }), void 0), _jsx(Parent, Object.assign({ className: " col-span-1 flex items-center justify-end" }, { children: _jsx(Parent, Object.assign({ className: " h-8 w-8 p-3 bg-blue-100 rounded-full flex items-center justify-center" }, { children: _jsx(Text, { as: "p", className: " font-poppins text-xs font-medium text-blue-600", label: card.seating_capacity }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0));
};
export default TableCard;
