import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import { Parent, Text, Image, Input } from '../../Component/index';
import { SearchIcon } from '@heroicons/react/solid';
import '../../../translations/i18n';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../Utilities/Helpers';
import DotIcon from '../../Component/DotIcon/DotIcon';
import DataNotFound from '../DataNotFound/DataNotFound';
import { ProductsIcon } from '../../assets/Icons';
import { imageOnErrorHandler } from '../../Utilities/Helpers';
let defalutImg = require('../../assets/media/no-image.png');
const MenuCat = ({ navItems, changeShow, showConfirmation, filterData, sliderOpen, CatId }) => {
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    return (_jsxs(Parent, Object.assign({ className: classNames('flex-grow flex flex-col  w-full bg-white   transform transition-colors ease-in-out duration-15 overflow-y-auto') }, { children: [_jsx(Parent, Object.assign({ className: "flex justify-end py-5 px-2" }, { children: _jsx(Parent, Object.assign({ className: "flex-1 min-w-0" }, { children: _jsx(Input, { name: "search", className: classNames(businessType == 'Retail'
                            ? 'focus:ring-blue-600 focus:border-blue-600'
                            : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm focus:outline-none block px-4 w-full pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "search", placeHolder: t('category_name'), type: "text", labelText: t('search'), labelClass: "block text-sm font-poppins font-medium text-gray-300 pb-1", onChange: (e) => filterData(e, 'category'), iconRequired: true, labelRequired: false, iconTag: SearchIcon }, void 0) }), void 0) }), void 0), navItems && navItems.length > 0 ? (_jsx(Parent, Object.assign({ className: "h-category overflow-y-auto" }, { children: navItems.map((cat) => {
                    return (_jsx(Parent, Object.assign({ className: classNames(CatId == cat.id ? 'bg-blue-100' : '', ' py-2.5 px-2') }, { children: _jsxs(Parent, Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx(Parent, Object.assign({ onClick: () => changeShow(cat.id), className: " cursor-pointer" }, { children: _jsx(Parent, Object.assign({ className: " text-left text-sm font-poppins font-normal text-gray-400 " }, { children: _jsx(Parent, Object.assign({ className: "flex items-center flex-col" }, { children: _jsxs(Parent, Object.assign({ className: "flex" }, { children: [_jsx(Parent, Object.assign({ className: "text-sm w-10 h-10  font-poppins font-normal text-gray-800" }, { children: _jsx(Image, { className: " w-full h-full object-cover rounded-lg ", src: cat.cat_image
                                                                ? cat.cat_image
                                                                : defalutImg, onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "Home img" }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsxs(Parent, Object.assign({ className: "flex  pl-3 flex-col " }, { children: [_jsxs(Parent, Object.assign({ className: "truncate flex items-center font-poppins font-normal text-gray-800" }, { children: [_jsx(Text, { as: "p", className: "text-gray-800 text-sm font-poppins font-normal mb-1.25", label: cat.cat_name }, void 0), _jsx(Text, { as: "p", className: classNames(cat.is_pos_on === '1'
                                                                                ? 'text-green-500'
                                                                                : 'text-red-400', ' text-xs font-poppins ml-2 font-normal'), label: `${cat.is_pos_on === '1'
                                                                                ? `(${t('on')})`
                                                                                : `(${t('off')})`}` }, void 0)] }), void 0), _jsx(Parent, Object.assign({ className: "text-xs  truncate  font-poppins font-normal text-gray-400" }, { children: cat.id }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(DotIcon, { sliderOpen: sliderOpen, showConfirmation: showConfirmation, catId: cat.id, catName: cat.cat_name }, void 0)] }), cat.id) }), cat.id));
                }) }), void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('to_creat_new_category_click_here'), titles: t('add_category'), iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-8 w-8  m-auto mb-2'), iconTag: ProductsIcon }, void 0))] }), void 0));
};
export default MenuCat;
