import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import { useState, Fragment } from 'react';
import { Parent, Icon, Link, Text } from '../../Component';
import { ChevronDownIcon, ChevronRightIcon, DotsHorizontalIcon, PlusIcon } from '@heroicons/react/solid';
import { classNames } from '../../Utilities/Helpers';
import DataNotFound from '../DataNotFound/DataNotFound';
import '../../../translations/i18n';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { FolderAddIcon } from '@heroicons/react/outline';
const MenuTable = ({ navItems, expand, setAreas, setTable_Floor, setTable_Area, getTableData, props, ShowItemPopUp, showConfirmation, setExpandID, expandID }) => {
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const setArea = (i_id, s_id, area, floor) => {
        expand(i_id, s_id);
        getTableData({
            variables: {
                area_id: s_id,
                floor_id: i_id,
                business_id: businessId,
                outlet_id: outletId
            }
        });
        setTable_Area({
            area: area,
            area_id: s_id
        });
        setTable_Floor({
            floor: floor,
            floor_id: i_id
        });
    };
    const expandCall = (id) => {
        id == expandID ? setExpandID('') : setExpandID(id);
    };
    return (_jsx(Parent, Object.assign({ className: classNames('flex-grow flex flex-col pt-2 w-full bg-white   transform transition-colors ease-in-out duration-15 overflow-y-auto') }, { children: _jsx(_Fragment, { children: navItems && navItems.length > 0 ? (_jsx(Parent, Object.assign({ className: "h-area overflow-y-auto" }, { children: navItems &&
                    navItems.map((item) => (_jsxs(Parent, { children: [_jsxs(Parent, Object.assign({ className: " py-1 grid grid-cols-3 justify-between items-center" }, { children: [_jsxs(Parent, Object.assign({ className: " col-span-2 flex" }, { children: [_jsx(Parent, { children: _jsx(Link, Object.assign({ className: "cursor-pointer sm:mt-1 flex items-center justify-center     mr-3   ", title: "", onClick: () => expandCall(item.id) }, { children: _jsx(Icon, { tag: expandID == item.id
                                                            ? ChevronDownIcon
                                                            : ChevronRightIcon, className: classNames(props.table_Daet.floor_id == item.id
                                                            ? 'text-blue-600'
                                                            : 'text-gray-400', 'h-4 w-4  ') }, void 0) }), void 0) }, void 0), _jsx(Text, { as: "label", label: item.floor_name, className: classNames(props.table_Daet.floor_id == item.id
                                                    ? 'text-blue-600'
                                                    : 'text-gray-400', 'text-base font-medium  text-gray-400 font-poppins '), title: item.floor_name }, void 0)] }), void 0), _jsx(Link, Object.assign({ className: classNames('bg-blue-600 group inline-flex items-center p-2 cursor-pointer justify-self-end  shadow-custom  rounded-md   text-white  focus:outline-none'), title: t('add_new_category'), onClick: () => ShowItemPopUp(item.id, item.floor_name, '', '') }, { children: _jsx(Icon, { tag: PlusIcon, className: "h-3.5 w-3.5  text-white " }, void 0) }), void 0)] }), void 0), expandID == item.id && (_jsx(Parent, { children: item.area_array
                                    ? item.area_array.map((subItem) => (_jsxs(Parent, Object.assign({ className: "py-1 flex justify-between items-center" }, { children: [_jsx("a", Object.assign({ onClick: () => setArea(item.id, subItem.id, subItem.area_name, item.floor_name), className: classNames(props.table_Area.area_id ==
                                                    subItem.id
                                                    ? 'text-blue-600'
                                                    : 'text-gray-400', ' cursor-pointer group w-full flex items-center   pl-10 text-sm text-gray-400 font-poppins  font-medium rounded-md') }, { children: subItem.area_name }), subItem.area_name), _jsx(Parent, Object.assign({ className: "" }, { children: _jsxs(Menu, Object.assign({ as: "div", className: "relative inline-block text-left" }, { children: [_jsx(Parent, { children: _jsxs(Menu.Button, Object.assign({ className: " bg-blue-50 h-8 w-8 rounded-full flex items-center text-blue-600 focus:outline-none focus:ring-transparent" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "sr-only" }, { children: t('open_options') }), void 0), _jsx(DotsHorizontalIcon, { className: "h-4 w-5 mx-auto", "aria-hidden": "true" }, void 0)] }), void 0) }, void 0), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, Object.assign({ className: "origin-top-right absolute z-10 right-1 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" }, { children: _jsxs(Parent, Object.assign({ className: "py-1" }, { children: [_jsx(Menu.Item, { children: ({ active }) => (_jsx(Parent, Object.assign({ onClick: () => ShowItemPopUp(item.id, item.floor_name, subItem.id, subItem.area_name), className: classNames(active
                                                                                    ? 'bg-gray-100 text-gray-900'
                                                                                    : 'text-gray-600', 'block px-4 py-2.5 text-sm font-poppins font-medium cursor-pointer hover:text-blue-600') }, { children: t('edit') }), void 0)) }, void 0), _jsx(Menu.Item, { children: ({ active }) => (_jsx(Parent, Object.assign({ onClick: () => showConfirmation(true, subItem.id, subItem.area_name, 'area'), className: classNames(active
                                                                                    ? 'bg-gray-100 text-gray-900'
                                                                                    : 'text-gray-600', 'block px-4 py-2.5 text-sm font-poppins font-medium cursor-pointer hover:text-blue-600') }, { children: t('delete') }), void 0)) }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0)))
                                    : '' }, void 0))] }, void 0))) }), void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('to_create_area'), titles: t('no_floor_no_area'), iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-gray-400', 'h-8 w-8  m-auto mb-2'), iconTag: FolderAddIcon }, void 0)) }, void 0) }), void 0));
};
export default MenuTable;
