/** @format */
import { gql } from '@apollo/client';
/* PLOP_INJECT_IMPORT */
export const GETALLTABLECONFIGAREA = gql `
    query getAllTableConfigArea($business_id: ID, $outlet_id: ID) {
        getAllTableConfigArea(business_id: $business_id, outlet_id: $outlet_id) {
            id
            area_name
            floor
            is_active
            c_ts
            u_ts
        }
    }
`;
export const GETALLMAINCATEGORY = gql `
    query getAllMainCategory($business_id: ID, $outlet_id: ID) {
        getAllMainCategory(business_id: $business_id, outlet_id: $outlet_id) {
            id
            name
            is_active
        }
    }
`;
export const GETTABLEFLOORQUERY = gql `
    query getAllTableConfigFloor {
        getAllTableConfigFloor {
            id
            floor_name
            is_active
            c_ts
            u_ts
        }
    }
`;
export const GETALLMENUSCHEDULES = gql `
    query getAllMenuSchedules($business_id: ID, $outlet_id: ID) {
        getAllMenuSchedules(business_id: $business_id, outlet_id: $outlet_id) {
            successful
            data
            message
        }
    }
`;
export const GETSINGLEMENUSCHEDULES = gql `
    query getAllMenuSchedules($id: ID, $business_id: ID, $outlet_id: ID) {
        getAllMenuSchedules(id: $id, business_id: $business_id, outlet_id: $outlet_id) {
            successful
            data
            message
        }
    }
`;
export const GETSINGLESESSIONQUERY = gql `
    query getAllSession($business_id: ID, $outlet_id: ID, $id: ID) {
        getAllSession(business_id: $business_id, outlet_id: $outlet_id, id: $id) {
            message
            data
        }
    }
`;
export const GETALLSESSIONQUERY = gql `
    query getAllSession($business_id: ID, $outlet_id: ID, $id: ID) {
        getAllSession(business_id: $business_id, outlet_id: $outlet_id, id: $id) {
            message
            data
        }
    }
`;
export const GETPRODUCTSUMMARYQUERY = gql `
    query getProductSummary($business_id: ID, $outlet_id: ID) {
        getProductSummary(business_id: $business_id, outlet_id: $outlet_id) {
            message
            data
        }
    }
`;
export const PRODUCTSGETQUERY = gql `
    query getProducts($outlet_id: ID!, $business_id: ID!, $cat_id: ID!, $offset: ID!) {
        getProducts(
            outlet_id: $outlet_id
            business_id: $business_id
            cat_id: $cat_id
            offset: $offset
        ) {
            successful
            data
            message
        }
    }
`;
export const GLOBALCATEGORIESGETQUERY = gql `
    query getGlobalCategories($business_id: ID, $outlet_id: ID) {
        getAllGlobalCategory(business_id: $business_id, outlet_id: $outlet_id) {
            message
            data
            successful
        }
    }
`;
export const GETINVOICESUMMARYQUERY = gql `
    query getInvoiceSummary(
        $start_date: String
        $end_date: String
        $business_id: ID
        $outlet_id: ID
    ) {
        getInvoiceSummary(
            start_date: $start_date
            end_date: $end_date
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            message
            data
        }
    }
`;
export const GETHIESTSELLINGQUERY = gql `
    query getHiestSelling(
        $start_date: String
        $end_date: String
        $business_id: ID
        $outlet_id: ID
    ) {
        getHiestSelling(
            start_date: $start_date
            end_date: $end_date
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            message
            data
        }
    }
`;
export const GETSALESCOUNTQUERY = gql `
    query getSalesCount($start_date: String, $end_date: String, $business_id: ID, $outlet_id: ID) {
        getSalesCount(
            start_date: $start_date
            end_date: $end_date
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            message
            data
        }
    }
`;
export const GETCUSTOMERSUMMARYQUERY = gql `
    query getCustomerSummary(
        $start_date: String
        $end_date: String
        $business_id: ID
        $outlet_id: ID
    ) {
        getCustomerSummary(
            start_date: $start_date
            end_date: $end_date
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            message
            data
        }
    }
`;
export const GETINVOICECOUNTQUERY = gql `
    query getInvoiceCount(
        $start_date: String
        $end_date: String
        $business_id: ID
        $outlet_id: ID
    ) {
        getInvoiceCount(
            start_date: $start_date
            end_date: $end_date
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            message
            data
        }
    }
`;
export const GETALLLOYALTYRULESSETTINGSQUERY = gql `
    query getAllLoyaltyrulessettings($id: ID, $business_id: ID, $outlet_id: ID) {
        getAllLoyaltyrulessettings(id: $id, business_id: $business_id, outlet_id: $outlet_id) {
            id
            events
            points
            condition
            value
        }
    }
`;
export const GETALLLOYALTYSETTINGSQUERY = gql `
    query getAllLoyaltysettings($id: ID, $business_id: ID, $outlet_id: ID) {
        getAllLoyaltysettings(id: $id, business_id: $business_id, outlet_id: $outlet_id) {
            points
            value
            redemptions
        }
    }
`;
export const REPOSITORYLOADMOREGETQUERY = gql `
    query getAllRepository($limit: ID, $offset: ID, $search: String) {
        getAllRepository(limit: $limit, offset: $offset, search: $search) {
            successful
            totalRecords
            data
            message
        }
    }
`;
export const REPOSITORYLOADMOREFILTERQUERY = gql `
    query getAllRepository($limit: ID, $offset: ID, $search: String) {
        getAllRepository(limit: $limit, offset: $offset, search: $search) {
            successful
            totalRecords
            data
            message
        }
    }
`;
export const COUPONSGETBYIDQUERY = gql `
    query getAllCoupon($id: ID, $business_id: ID, $outlet_id: ID) {
        getAllCoupons(id: $id, business_id: $business_id, outlet_id: $outlet_id) {
            successful
            message
            data
        }
    }
`;
export const COUPONSGETQUERY = gql `
    query getAllCoupon($business_id: ID, $outlet_id: ID) {
        getAllCoupons(business_id: $business_id, outlet_id: $outlet_id) {
            successful
            message
            data
        }
    }
`;
export const DASHBORADSUMMARYGETQUERY = gql `
    query getSummary($business_id: ID, $outlet_id: ID) {
        getSummary(business_id: $business_id, outlet_id: $outlet_id, date: "current_date1") {
            message
            data
        }
    }
`;
export const DASHBORADGETQUERY = gql `
    query getDashboard($business_id: ID, $outlet_id: ID, $start_date: String, $end_date: String) {
        getDashboard(
            business_id: $business_id
            outlet_id: $outlet_id
            start_date: $start_date
            end_date: $end_date
        ) {
            message
            data
        }
    }
`;
export const EMPLOYEEGETQUERY = gql `
    query getEmployee($business_id: ID, $outlet_id: ID) {
        getAllEmployee(business_id: $business_id, outlet_id: $outlet_id) {
            successful
            message
            data
        }
    }
`;
export const DISCOUNTGETQUERY = gql `
    query getDiscountType($business_id: ID, $outlet_id: ID) {
        getAllDisctype(business_id: $business_id, outlet_id: $outlet_id) {
            id
            disc_name
            disc_value
            is_active
        }
    }
`;
export const PAYMENTTYPESGETQUERY = gql `
    query getPaymentType($business_id: ID, $outlet_id: ID) {
        getAllPaytype(business_id: $business_id, outlet_id: $outlet_id) {
            id
            name
            is_active
            c_ts
            is_enable
        }
    }
`;
export const EMP_ROELSGETQUERY = gql `
    query getEmpRole($business_id: ID, $outlet_id: ID) {
        getAllRoles(business_id: $business_id, outlet_id: $outlet_id) {
            id
            name
            display_as
            allow_pos
            u_ts
            c_ts
            is_active
        }
    }
`;
export const PAYTYPEGETQUERY = gql `
    query getPaytype {
        getAllPaytype {
            id
            name
            is_active
        }
    }
`;
export const CATEGORIESGETQUERY = gql `
    query getCategories($business_id: ID, $outlet_id: ID, $filter_option: String) {
        getAllCategory(
            business_id: $business_id
            outlet_id: $outlet_id
            filter_option: $filter_option
        ) {
            id
            cat_name
            is_pos_on
            cat_image
            tag
            color_code
            order_align
            parent_id
        }
    }
`;
export const CATEGORIESSINGLEGETQUERY = gql `
    query getAllSubCategory($id: ID, $outlet_id: ID) {
        getAllSubCategory(id: $id, outlet_id: $outlet_id) {
            id
            cat_name
            is_pos_on
            cat_image
            tag
            color_code
            available_items
        }
    }
`;
export const REPOSITORYGETQUERY = gql `
    query getRepository {
        getAllRepository {
            id
            repository_name
            repo_image
            is_active
        }
    }
`;
export const GROUPCATEGETQUERY = gql `
    query getGroupcategory($business_id: ID, $outlet_id: ID) {
        getAllGroupCategory(business_id: $business_id, outlet_id: $outlet_id) {
            successful
            data
        }
    }
`;
export const GETSALESTRENDS = gql `
    query getSalesTrends($business_id: ID, $outlet_id: ID) {
        getSalesTrends(business_id: $business_id, outlet_id: $outlet_id) {
            message
            data
        }
    }
`;
export const ROLESGETQUERY = gql `
    query getRole($business_id: ID, $outlet_id: ID) {
        getAllRoles(business_id: $business_id, outlet_id: $outlet_id) {
            id
            name
            display_as
            allow_pos
            u_ts
            c_ts
            is_active
        }
    }
`;
export const EMPTYPESGETQUERY = gql `
    query getEmptype($business_id: ID, $outlet_id: ID) {
        getAllEmptype(business_id: $business_id, outlet_id: $outlet_id) {
            id
            emp_type
            is_active
        }
    }
`;
export const UNITESGETQUERY = gql `
    query getUnit($business_id: ID, $outlet_id: ID) {
        getAllUnit(business_id: $business_id, outlet_id: $outlet_id) {
            id
            unit
            description
            is_active
        }
    }
`;
export const UOMSGETQUERY = gql `
    query getUom($business_id: ID, $outlet_id: ID) {
        getAllUom(business_id: $business_id, outlet_id: $outlet_id) {
            id
            name
            description
            is_active
        }
    }
`;
export const ADDONSGETQUERY = gql `
    query getAddon($business_id: ID, $outlet_id: ID) {
        getAllAddon(business_id: $business_id, outlet_id: $outlet_id) {
            id
            name
            description
            is_active
        }
    }
`;
/* export const TAXGETQUERY = gql`
  query getTax {
    getAllTax {
      id
      tax_name
      tax_pct
      tax_label
      is_active
      is_enable
    }
  }
`; */
export const VARIATIONSGETQUERY = gql `
    query getVariation($business_id: ID, $outlet_id: ID) {
        getAllVariation(business_id: $business_id, outlet_id: $outlet_id) {
            id
            v_name
            description
            is_active
        }
    }
`;
export const TAXESGETQUERY = gql `
    query getTax($business_id: ID, $outlet_id: ID) {
        getAllTax(business_id: $business_id, outlet_id: $outlet_id) {
            id
            tax_name
            tax_pct
            tax_label
            is_active
            is_enable
        }
    }
`;
export const MISCELLANEOUSGETQUERY = gql `
    query getAllMiscellaneous($business_id: ID, $outlet_id: ID) {
        getAllMiscellaneous(business_id: $business_id, outlet_id: $outlet_id) {
            id
            name
            percentage
            is_active
            is_enable
        }
    }
`;
export const USERSGETQUERY = gql `
    query getUsers($business_id: ID, $outlet_id: ID, $user_from: String) {
        getAllUsers(business_id: $business_id, outlet_id: $outlet_id, user_from: $user_from) {
            id
            email
            first_name
            last_name
            mobile
            is_active
            role_id
            outlet_id
            business_id
            password
            c_ts
            roles {
                id
                name
            }
        }
    }
`;
export const SINGLEUSERSGETQUERY = gql `
    query getUser($id: ID!) {
        getAllUsers(id: $id) {
            id
            email
            is_active
            first_name
            last_name
            role_id
            mobile
            profile_img
            password
            address
            roles {
                id
                name
            }
        }
    }
`;
export const GETALLSETTINGS = gql `
    query getAllSettings($outlet_id: ID!, $business_id: ID!) {
        getAllSettings(outlet_id: $outlet_id, business_id: $business_id) {
            id
            sales_report
            m_yly_sales_report
            monthly_customer_data
            inventory_alert
            tip
            language
            currency
            currency_code
            loyalty
            show_prod_img_on_pos
            ebable_cron
            enable_kot
            card_config
            enable_order_edit
            enable_multi_screen
            enable_split_bill
            website_logo
            website_url
            restaurant_details
            enable_online_order
            reset_order_no
            enable_passcode
            enable_weekendspl_img
            passcode
            enable_service_charge
            enable_reserve_table
            service_charge
            publish_website
            custom_domain
            website_theme_color
            website_font_style
            facebook_link
            instagram_link
            twitter_link
            youtube_link
            aboutus
            name
            contact
            altcontact
            mail
            custom_domain
        }
    }
`;
export const GETALLOUTLET = gql `
    query getAllOutlet {
        getAllOutlet {
            business_name
            contact_name
            mobile
            is_active
            outlet_img
            email
            business {
                id
                business_name
            }
            country {
                id
                name
            }
        }
    }
`;
export const GETSINGLEBUSINESS = gql `
    query getSingle($id: ID!) {
        getAllBusiness(id: $id) {
            id
            business_name
            display_as
            business_img
            business_owner
            mobile
            email
            b_t_id
            businesstype {
                id
                b_type_cat
                b_type_value
            }
            outlet {
                id
                business_name
                contact_name
                mobile
                address
                address_one
                outlet_img
                email
                business_mobile
                website_url
                role
                country {
                    id
                    name
                }
            }
        }
    }
`;
export const GETSINGLEOUTLET = gql `
    query getSingleOut($id: ID!) {
        getAllOutlet(id: $id) {
            business_name
            contact_name
            mobile
            is_active
            outlet_img
            email
            address
            address_one
            city
            zipcode
            business {
                id
                business_name
            }
            country {
                id
                name
            }
        }
    }
`;
export const GETCUSTOMERSQUERY = gql `
    query getAllCustomer($outlet_id: ID!, $business_id: ID!) {
        getAllCustomers(outlet_id: $outlet_id, business_id: $business_id) {
            id
            email
            first_name
            last_name
            mobile
            address1
            address2
            anniversaary
            is_active
            is_favorite
            do_not_disturb
            tax_no
            c_ts
            profile_img
            zipcode
            dob
            tax_no
            zipcode
            area
        }
    }
`;
export const GETTICKETSQUERY = gql `
    query getAllTicketIssue($outlet_id: ID!, $business_id: ID!) {
        getAllTickets(outlet_id: $outlet_id, business_id: $business_id) {
            message
            successful
            data
        }
    }
`;
export const GETSINGLETICKETSQUERY = gql `
    query getSingleTicketIssue($id: ID!) {
        getAllTickets(id: $id) {
            message
            successful
            data
        }
    }
`;
export const GETTABLECONFIGURATION = gql `
    query getTableConfiguration($area_id: ID!, $floor_id: ID!, $outlet_id: ID!, $business_id: ID!) {
        getTableConfiguration(
            area_id: $area_id
            floor_id: $floor_id
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            message
            successful
            data
        }
    }
`;
export const COMBOGETQUERY = gql `
    query getmealcompo($business_id: ID!, $outlet_id: ID!) {
        getAllMealCompo(business_id: $business_id, outlet_id: $outlet_id) {
            successful
            data
        }
    }
`;
export const GETKOTORDERLIST = gql `
    query getKotOrderList(
        $id: ID
        $search: String
        $order_no: String
        $today: String
        $business_id: ID!
        $outlet_id: ID!
        $start_date: String
        $end_date: String
    ) {
        getKotOrderList(
            id: $id
            search: $search
            order_no: $order_no
            today: $today
            business_id: $business_id
            outlet_id: $outlet_id
            start_date: $start_date
            end_date: $end_date
        ) {
            successful
            data
            message
        }
    }
`;
export const GLOBALCATEGORYGETQUERY = gql `
    query getAllCategory($business_id: ID!, $outlet_id: ID!) {
        getAllCategory(business_id: $business_id, outlet_id: $outlet_id) {
            id
            cat_name
            cat_image
            is_active
            repository_id
            parent_id
            repository {
                id
                repository_name
                repo_image
            }
        }
    }
`;
