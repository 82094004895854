import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CalendarIcon, FilterIcon } from '@heroicons/react/outline';
import DatePicker from 'react-datepicker';
import { Icon, Parent } from '..';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
const ReportFiltter = ({ open, setOpen, startDate, setStartDate, endDate, deleteRecord, setEndDate }) => {
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [S_date, setS_date] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const changeDate = (date) => {
        setS_date(date);
        setStartDate(date);
    };
    const cancelButtonRef = useRef(null);
    return (_jsx(Transition.Root, Object.assign({ show: open, as: Fragment }, { children: _jsx(Dialog, Object.assign({ as: "div", className: "fixed z-10 inset-0 overflow-y-auto", initialFocus: cancelButtonRef, onClose: setOpen }, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-center min-h-screen text-center" }, { children: [_jsx(Transition.Child, Object.assign({ as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(Dialog.Overlay, { className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" }, void 0) }), void 0), _jsx("span", Object.assign({ className: "hidden sm:inline-block sm:align-middle sm:h-screen", "aria-hidden": "true" }, { children: "\u200B" }), void 0), _jsx(Transition.Child, Object.assign({ as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95", enterTo: "opacity-100 translate-y-0 sm:scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 translate-y-0 sm:scale-100", leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" }, { children: _jsxs("div", Object.assign({ className: "inline-block align-bottom bg-white rounded-lg px-4 sm:pb-6 py-6 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:p-6 m-2" }, { children: [_jsxs("div", { children: [_jsx("div", Object.assign({ className: classNames(businessType == 'Retail'
                                                ? ' border-blue-600'
                                                : ' border-blue-600', 'mx-auto flex  items-center justify-center h-12 w-12 border-solid rounded-full border-2  ') }, { children: _jsx(FilterIcon, { className: classNames(businessType == 'Retail'
                                                    ? 'text-blue-500'
                                                    : 'text-blue-500', 'h-4 w-4 '), "aria-hidden": "true" }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "mt-3  text-center sm:mt-5" }, { children: [_jsx(Dialog.Title, Object.assign({ as: "h3", className: "text-lg leading-6 font-medium text-gray-900" }, { children: t('filter') }), void 0), _jsx("div", Object.assign({ className: "mt-2 w-full" }, { children: _jsxs(Parent, Object.assign({ className: " flex gap-4" }, { children: [_jsxs(Parent, Object.assign({ className: "mt-1 flex relative" }, { children: [_jsx(DatePicker, { closeOnScroll: (e) => e.target === document, selected: S_date, onChange: (date) => changeDate(date), maxDate: new Date(), dateFormat: "dd/MM/yyyy", className: classNames(businessType == 'Retail'
                                                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                                                            : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm block w-full sm:text-xs border-gray-200 text-gray-400 rounded-md font-poppins text-sm font-normal py-3') }, void 0), _jsx(Parent, Object.assign({ className: "absolute top-1/4 right-3" }, { children: _jsx(Icon, { tag: CalendarIcon, className: "w-5 h-5 text-gray-400 text-base items-center font-medium font-poppins " }, void 0) }), void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: "mt-1 flex relative" }, { children: [_jsx(DatePicker, { closeOnScroll: (e) => e.target === document, selected: endDate, onChange: (date) => setEndDate(date), maxDate: new Date(), minDate: startDate, dateFormat: "dd/MM/yyyy", className: classNames(businessType == 'Retail'
                                                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                                                            : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm block w-full sm:text-xs border-gray-200 text-gray-400 rounded-md font-poppins text-sm font-normal py-3') }, void 0), _jsx(Parent, Object.assign({ className: "absolute top-1/4 right-3" }, { children: _jsx(Icon, { tag: CalendarIcon, className: "w-5 h-5 text-gray-400 text-base items-center font-medium font-poppins " }, void 0) }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0)] }, void 0), _jsx("div", Object.assign({ className: " pt-4 flex items-center justify-center " }, { children: _jsx("button", Object.assign({ type: "button", className: classNames(businessType == 'Retail'
                                            ? 'bg-blue-500 hover:bg-blue-700'
                                            : 'bg-blue-600 hover:bg-blue-600', 'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-sm font-medium text-white  focus:outline-none  sm:col-start-2 sm:text-sm'), onClick: () => setOpen(false), ref: cancelButtonRef }, { children: t('cancel') }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default ReportFiltter;
