/** @format */

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_TA } from "./ta/translations";
import { TRANSLATIONS_DE } from "./de/translations";
i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: true,
    lng: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en",
    fallbackLng: localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      en: {
        translations: TRANSLATIONS_EN,
      },
      ta: {
        translations: TRANSLATIONS_TA,
      },
      de: {
        translations: TRANSLATIONS_DE,
      },
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
