import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * /* Copyright (C) efficientworks.co.uk - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Contact <ravi.raya@gmail.com>, Auto Generated Code October 2021
 *
 * @format
 */
import { useState, useEffect } from 'react';
import { Button, Icon, Input, Parent, Text } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import { PlusIcon, ShoppingCartIcon, SearchIcon } from '@heroicons/react/solid';
import MenuSchedTable from './MenuScheduleTable';
import SlideOverlay from '../../Component/SlideOverlay/SlideOverlay';
import { useQuery } from '@apollo/client';
import { CATEGORIESGETQUERY, GETALLMENUSCHEDULES } from '../../GraphqlQuery/Query/Query';
import DataNotFound from '../DataNotFound/DataNotFound';
import { CATEGORIESFIELDS, STARTPAGE } from '../../Utilities/Constants';
import { CATEGORIESADDQUERY, CATEGORIESUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import SuccessPopUp from '../../Component/SuccessPopUp/SuccessPopUp';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames, filterFunction } from '../../Utilities/Helpers';
const MenuSched = ({}) => {
    const { t } = useTranslation();
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const [apiResultArray, setApiResultArray] = useState([]);
    const [filterDataList, setFilterDataList] = useState([]);
    const [singleObj, setSingleObj] = useState({});
    const [columns, setColumns] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [catTitle, setCatTitle] = useState('');
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const tableHead = [
        {
            id: 1,
            title: t('schedule_name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('images'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('days'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('timing'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    setTimeout(() => {
        setShowSuccess(false);
    }, 3000);
    const sliderOpen = (value, title) => {
        getFields();
        setOpen(value);
        setSliderTitle(title);
    };
    const { loading, error, data, refetch } = useQuery(CATEGORIESGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const { loading: loading1, error: error1, data: data1, refetch: refetch1 } = useQuery(GETALLMENUSCHEDULES, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const loadData = (values, from) => {
        refetch1();
    };
    const filterData = (e) => {
        setCurrentPageNumber(1);
        const filterText = e.target.value;
        let filertKey = {
            name: 'name'
        };
        setFilterDataList(filterFunction(apiResultArray, filterText, filertKey, 1));
    };
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((obj) => obj.id === id);
        setSingleObj(Obj);
        setOpen(value);
        setSliderTitle(title);
    };
    const getFields = () => {
        const fields = CATEGORIESFIELDS.fields;
        let singleObjData = {};
        let columnsArray = [];
        let index = 0;
        fields.map((item) => {
            if (item.create === true && item.edit === true) {
                singleObjData[item['field name']] = '';
            }
            if (item.show_in_ui === true) {
                columnsArray.push({
                    id: index,
                    title: item['display_name'],
                    className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
                });
                index++;
            }
        });
        if (columnsArray) {
            if (columnsArray.length > 0) {
                columnsArray.push({
                    id: columnsArray.length - 1,
                    title: 'Actions',
                    className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
                });
            }
        }
        setColumns(columnsArray);
        setSingleObj(singleObjData);
    };
    const addItems = () => {
        localStorage.setItem('menu_schedule_id', '');
        window.location.href = '#/add-schedule';
    };
    useEffect(() => {
        var _a, _b;
        refetch1();
        if (data1) {
            localStorage.setItem('loadData', 'false');
            setApiResultArray((_a = data1 === null || data1 === void 0 ? void 0 : data1.getAllMenuSchedules) === null || _a === void 0 ? void 0 : _a.data);
            setFilterDataList((_b = data1 === null || data1 === void 0 ? void 0 : data1.getAllMenuSchedules) === null || _b === void 0 ? void 0 : _b.data);
        }
        getFields();
    }, [data1]);
    const reload = localStorage.getItem('loadData');
    useEffect(() => {
        if (reload == true) {
            refetch1();
        }
    }, [reload]);
    return (_jsxs(Parent, Object.assign({ className: "lg:px-12 md:px-6 pb-9 px-4" }, { children: [_jsx(Parent, Object.assign({ className: " right-10 top-14 absolute flex justify-end " }, { children: showSuccess && (_jsx(SuccessPopUp, { setShowSuccess: setShowSuccess, title: t('successfully_created'), description: t('category_successfully_created') }, void 0)) }), void 0), loading ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames(businessType == 'Retail' ? 'border-blue-900' : 'border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames(businessType == 'Retail' ? 'text-blue-900' : 'text-blue-900', ' font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsxs(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-3" }, { children: [_jsxs(Parent, Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx(Parent, Object.assign({ className: "flex-1 min-w-0" }, { children: _jsx(Input, { name: "search", className: classNames(businessType == 'Retail'
                                        ? 'focus:ring-blue-600 focus:border-blue-600'
                                        : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm focus:outline-none w-48 sm:w-60 block px-3 pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "search", placeHolder: t('search'), type: "text", labelText: t('search'), labelClass: "block text-sm font-poppins font-medium text-gray-300 pb-1", onChange: (e) => filterData(e), iconRequired: true, labelRequired: false, iconTag: SearchIcon }, void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "flex-shrink-0 flex-1" }, { children: [_jsx(Parent, Object.assign({ className: "hidden sm:flex sm:justify-end" }, { children: _jsx(Button, { text: t('add_schedule'), className: classNames(businessType == 'Retail'
                                                ? 'text-blue-600 bg-blue-100 hover:bg-blue-600'
                                                : 'text-blue-600 bg-blue-100 hover:bg-blue-600', 'group inline-flex items-center px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md  hover:text-white  focus:outline-none'), onClick: () => addItems() }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "sm:hidden flex justify-end " }, { children: _jsx(Parent, Object.assign({ className: "group inline-flex items-center px-4 sm:py-2 py-3 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => addItems() }, { children: _jsx(Icon, { tag: PlusIcon, className: "w-5 h-5 text-blue-600 group-hover:text-white rounded-md " }, void 0) }), void 0) }), void 0)] }), void 0)] }), void 0), filterDataList && filterDataList.length > 0 ? (_jsx(MenuSchedTable, { onClick: sliderOpen, resultData: filterDataList, getSingleObj: getSingleObj, columns: tableHead, reloadData: loadData, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, total: filterDataList && filterDataList.length > 0
                            ? filterDataList.length
                            : 0 }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('menu_schedule_are_not_available_click_add_menu_schedule_to_create_new_menu_schedule'), iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: ShoppingCartIcon }, void 0)), _jsx(SlideOverlay, { open: open, setOpen: setOpen, title: sliderTitle, from: "combo", overLayWidth: "max-w-md", catWidth: "grid grid-cols-1", reloadData: loadData, singleObj: singleObj, fields: CATEGORIESFIELDS.fields, addQuery: CATEGORIESADDQUERY, updateQuery: CATEGORIESUPDATEQUERY, setShowSuccess: setShowSuccess, setCatTitle: setCatTitle }, void 0)] }), void 0))] }), void 0));
};
export default MenuSched;
