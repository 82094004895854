var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-types */
/** @format */
import { useState, useEffect } from 'react';
import { EyeIcon, PencilIcon } from '@heroicons/react/solid';
import { Parent, Icon, Link, Text } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import apiCall from '../../../API/apiCall';
import OrderView from '../../Component/SlideOverlay/OrderView';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import { APILINK } from '../../Utilities/ApiLink';
import EditOrder from '../../Component/SlideOverlay/EditOrder';
import { format, parseISO } from 'date-fns';
import ServerSidePagination from '../Pagination/ServerSidePagination';
const OrdersTable = ({ resultData, total, getOrder, getItem, setCurrentPageNumber, currentPageNumber, offset, setOffset, items, pageLimit, dataLimit, getPaginatedData, setDataLimit, startIndex, endIndex, currentPage, setCurrentPage, strt, setStrt, end, setEnd }) => {
    const { t } = useTranslation();
    const [editOne, setEditOne] = useState();
    const [showInfo, setShowInfo] = useState(false);
    const [showInfoEdit, setShowInfoEdit] = useState(false);
    const [editItem, setEditItem] = useState([]);
    const [tableData, setTableData] = useState(resultData);
    const [showAlert, setShowAlert] = useState(false);
    const [id, setId] = useState('');
    const [userName, setUserName] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const [filterOrder, setFilterOrder] = useState();
    const [token, setToken] = useState(localStorage.getItem('token'));
    // const [deleteRecordQuery] = useMutation(ORDERCANCELQUERY);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const orderColumns = [
        {
            id: 1,
            title: t('order_no'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('customer'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('order_type'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('type'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: t('status'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 6,
            title: `${t('Tip')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 7,
            title: `${t('amount')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 8,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const showViewInfo = (value, id) => {
        getSingleOrder(value, id);
    };
    const showEditInfo = (value, id) => {
        setShowInfoEdit(value);
        getSingleOrderOne(value, id);
    };
    const getSingleOrderOne = (value, id) => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(`${APILINK.order_get}${id}`, 'GET', token, {})
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e;
            const arrayVal = (_d = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.order_item) === null || _d === void 0 ? void 0 : _d.map((item) => {
                return Object.assign(Object.assign({}, item), { id: item.prod_id });
            });
            setEditItem(arrayVal);
            yield setFilterOrder((_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.data);
            setShowInfoEdit(value);
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    const getSingleOrder = (value, id) => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(`${APILINK.order_get}${id}`, 'GET', token, {})
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            var _f;
            yield setFilterOrder((_f = response === null || response === void 0 ? void 0 : response.data) === null || _f === void 0 ? void 0 : _f.data);
            setShowInfo(value);
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    useEffect(() => {
        setTableData(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    useEffect(() => {
        const value = localStorage.getItem('enableOrderEdit');
        setEditOne(value);
    }, []);
    const showConfirmation = (value, id, Name) => {
        setShowAlert(value);
        setId(id);
        setUserName(Name);
    };
    // const deleteRecord = () => {
    //     deleteRecordQuery({
    //         variables: {
    //             id: parseInt(id),
    //             type: 'delete'
    //         }
    //     }).then(() => {
    //         getOrder();
    //         setShowAlert(false);
    //         getSingleOrder;
    //     });
    // };
    const isToday = (dateString) => {
        if (typeof dateString !== 'string') {
            return false;
        }
        try {
            const today = format(new Date(), 'yyyy-MM-dd');
            const date = format(parseISO(dateString), 'yyyy-MM-dd');
            return date === today;
        }
        catch (error) {
            console.error('Invalid date string:', dateString, error);
            return false;
        }
    };
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-xs font-poppins font-medium text-gray-400", columns: orderColumns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: resultData.map((person) => (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.order_no }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsxs(Parent, Object.assign({ className: "flex flex-col " }, { children: [_jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: `${person.first_name == null
                                                                ? t('guest')
                                                                : person.first_name} ${person.last_name == null
                                                                ? ''
                                                                : person.last_name}` }), void 0), _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal mt-1 text-gray-400" }, { children: `${person.mobile == null ? '' : person.mobile}` }), void 0)] }), void 0) }), void 0), businessType == 'Retail' ? ('') : (_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins text-left font-normal text-gray-800" }, { children: person.order_from === '1'
                                                        ? t('online_order')
                                                        : person.is_takeaway === '0'
                                                            ? t('dine_in')
                                                            : t('take_away') }), void 0) }), void 0)), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins text-left font-normal text-gray-800" }, { children: person.pay_type === 'UPI'
                                                        ? `${person.pay_type} - ${person.other_payment}`
                                                        : person.pay_type
                                                            ? person.pay_type
                                                            : '-' }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: person.status === 'Draft' ? (_jsx(Parent, Object.assign({ className: "bg-yellow-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-yellow-500 text-center" }, { children: t('in_progress') }), void 0) }), void 0)) : person.status === 'Settled' ? (_jsx(Parent, Object.assign({ className: "bg-green-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-green-500 text-center" }, { children: t('settled') }), void 0) }), void 0)) : person.status === 'Cancelled' ? (_jsx(Parent, Object.assign({ className: "bg-red-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-red-500 text-center" }, { children: t('cancelled') }), void 0) }), void 0)) : (_jsx(Parent, Object.assign({ className: "bg-blue-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-blue-500 text-center" }, { children: t('returned') }), void 0) }), void 0)) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins text-right font-normal text-gray-800" }, { children: person.tip_amount }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins text-right font-normal text-gray-800" }, { children: person.total }), void 0) }), void 0), _jsxs(Td, Object.assign({ className: classNames(person.status === 'Settled' && editOne === '1'
                                                    ? 'px-6 py-2.5 whitespace-nowrap text-center flex justify-start text-sm gap-2.5 font-medium w-6/6'
                                                    : 'px-6 py-2.5 whitespace-nowrap text-center text-sm flex justify-start gap-2.5 font-medium w-6/6') }, { children: [_jsx(Link, Object.assign({ className: classNames(businessType === 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group inline-flex items-center px-3 py-3 cursor-pointer shadow-custom rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('view'), onClick: () => showViewInfo(true, person.id) }, { children: _jsx(Icon, { tag: EyeIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0), person.order_from !== '1' &&
                                                        person.status === 'Settled' &&
                                                        editOne === '1' && (_jsx(Link, Object.assign({ className: classNames(businessType === 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group inline-flex items-center px-3 py-3 cursor-pointer shadow-custom rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('Edit'), onClick: () => showEditInfo(true, person.id) }, { children: _jsx(Icon, { tag: PencilIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0))] }), void 0)] }, person.id))) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(ServerSidePagination, { totalData: resultData, items: items, pageLimit: pageLimit, dataLimit: dataLimit, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, total: total, from: 'item', offset: offset, setOffset: setOffset, getItem: getItem, currentPage: currentPage, setCurrentPage: setCurrentPage, strt: strt, setStrt: setStrt, end: end, setEnd: setEnd }, void 0), _jsx(OrderView, { open: showInfo, setOpen: setShowInfo, filterOrder: filterOrder, title: t('order_details') }, void 0), _jsx(EditOrder, { open: showInfoEdit, setOpen: setShowInfoEdit, filterOrder: filterOrder, title: t('edit_order'), editItem: editItem, getOrder: getOrder }, void 0)] }), void 0));
};
export default OrdersTable;
