var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { COMBODEALSADDQUERY, COMBODEALUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { CameraIcon } from '@heroicons/react/solid';
import { useMutation } from '@apollo/client';
import { Parent, Text, Icon, Input, Image, Button } from '..';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import MultiSelect from '../MultiSelect/MultiSelect';
import { imageOnErrorHandler } from '../../Utilities/Helpers';
let defalutImg = require('../../assets/media/no-image.png');
const AddCombo = ({ singleObj, reloadData, setOpen, setCatTitle, setShowSuccess, apiResultCombo, editGroupCat, editCategory }) => {
    const { t } = useTranslation();
    const [id, setId] = useState('');
    const [addComboQuery, { data, loading, error }] = useMutation(COMBODEALSADDQUERY);
    const [updateComboDataQuery, { data: data1, loading: loading1, error: error1 }] = useMutation(COMBODEALUPDATEQUERY);
    const [apiResultArray, setApiResultArray] = useState(apiResultCombo);
    const [select, setSelect] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const [uploadImg, setUploadImg] = useState(singleObj.combo_img);
    const [onOffVal, setonOffVal] = useState('1');
    const [onOffValMsg, setonOffValMsg] = useState('1');
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const [selectValid, setSelectValid] = useState('');
    const ComboSchema = Yup.object().shape({
        combo_name: Yup.string()
            .required(t('combo_name_is_required'))
            .max(25, t('combo_name_not_more_than_25_characters')),
        price: Yup.string().required(t('combo_amount_is_required'))
    });
    const saveData = (values, resetForm) => __awaiter(void 0, void 0, void 0, function* () {
        resetForm();
        yield setBtnLoading(true);
        setBtnDisable(true);
        let parems = {
            combo_name: values.combo_name,
            products: select,
            combo_img: uploadImg,
            price: parseFloat(values.price),
            outlet_id: outletId,
            business_id: businessId
        };
        if (id) {
            parems.id = id;
            parems.is_active = '1';
            updateComboDataQuery({
                variables: parems
            })
                .then((result) => {
                setBtnLoading(false);
                setBtnDisable(false);
                reloadData(result, 'update');
                setOpen(false);
            })
                .catch((err) => {
                setBtnLoading(false);
                setBtnDisable(false);
                console.log(err, 'err');
            });
        }
        else {
            addComboQuery({
                variables: parems
            }).then((result) => {
                setBtnLoading(false);
                setBtnDisable(false);
                reloadData(result, 'add');
                setOpen(false);
                setShowSuccess(true);
                setCatTitle(values.name);
            });
        }
    });
    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setUploadImg(reader.result);
            }
        };
        reader.readAsDataURL(e.target.files[0]);
    };
    const validSelect = (type, select, handleSubmit) => {
        if (select.length > 0) {
            setSelectValid('');
        }
        else {
            setSelectValid(t('select_product_is_required'));
        }
        if (type == 'save') {
            handleSubmit();
        }
    };
    useEffect(() => {
        if (singleObj) {
            setId(singleObj.id);
        }
        if (singleObj.is_pos_on) {
            setonOffVal(singleObj.is_pos_on);
            setonOffValMsg(singleObj.is_pos_on);
        }
        if ((editCategory === null || editCategory === void 0 ? void 0 : editCategory.length) > 0) {
            let sele = [];
            editCategory === null || editCategory === void 0 ? void 0 : editCategory.map((item) => {
                sele.push(item.value);
            });
            setSelect(sele.toString());
        }
    }, []);
    return (_jsx(Formik, Object.assign({ initialValues: singleObj, onSubmit: (values, { resetForm }) => saveData(values, resetForm), validationSchema: ComboSchema, enableReinitialize: true }, { children: ({ handleChange, handleBlur, handleSubmit, values, touched, isValid, isSubmitting, errors, resetForm }) => (_jsxs("form", { children: [_jsx(Parent, { children: _jsxs(Parent, { children: [_jsxs(Parent, Object.assign({ className: " w-52 mx-auto h-48 relative  " }, { children: [_jsx(Parent, Object.assign({ className: " transparent cursor-pointer absolute bottom-0 right-0 p-3 flex bg-gray-900 opacity-50 " }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsxs(Text, Object.assign({ className: " text-left cursor-pointer", as: "label" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "text-left text-white z-0" }, { children: _jsx(Icon, { tag: CameraIcon, className: "w-5 h-5 text-white text-base items-center font-medium font-poppins" }, void 0) }), void 0), _jsx("input", { id: "file-upload", name: "file-upload", type: "file", accept: "image/*", className: "sr-only", onChange: (e) => imageHandler(e) }, void 0)] }), void 0) }), void 0) }), void 0), _jsx(Image, { className: "w-full h-48 object-cover rounded-lg ", src: uploadImg ? uploadImg : defalutImg, onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "Home img" }, void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: " pt-5" }, { children: [_jsxs(Parent, Object.assign({ className: "sm:col-span-3" }, { children: [_jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Text, Object.assign({ as: "label", className: classNames(businessType == 'Retail'
                                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                                            : 'focus:ring-blue-600 focus:border-blue-600', ' block sm:text-sm border-black-300 text-gray-700 rounded-md font-poppins text-sm font-medium pt-2 pb-3 ') }, { children: t('combo_name') }), void 0), _jsx(Text, Object.assign({ as: "label", className: "block text-sm ml-1 font-medium text-red-700  " }, { children: "*" }), void 0)] }), void 0), _jsx(Input, { type: "text", name: "combo_name", id: "combo_name", className: classNames(businessType == 'Retail'
                                                    ? 'focus:ring-blue-600 focus:border-blue-600'
                                                    : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm block w-full sm:text-sm border-gray-200 text-gray-400 rounded-md font-poppins font-normal py-2.5 '), labelClass: "block text-sm font-poppins font-medium text-gray-600", labelText: "", onChange: handleChange('combo_name'), onBlur: handleBlur('combo_name'), value: values.combo_name }, void 0), errors.combo_name && touched.combo_name && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: errors.combo_name }, void 0))] }), void 0), _jsxs(Parent, Object.assign({ className: "mt-2" }, { children: [_jsxs(Parent, Object.assign({ className: "flex  items-center" }, { children: [_jsx(Text, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                                            : 'focus:ring-blue-600 focus:border-blue-600', ' block sm:text-sm border-black-300 text-gray-700 rounded-md font-poppins text-sm font-medium py-3 '), as: "h3" }, { children: t('select_menu_items') }), void 0), _jsx(Text, Object.assign({ as: "label", className: "block text-sm ml-1 font-medium text-red-700 py-3 " }, { children: "*" }), void 0)] }), void 0), _jsx(MultiSelect, { apiResultArray: apiResultArray, setSelect: setSelect, select: select, editCategory: editCategory, editGroupCat: editGroupCat, validSelect: validSelect }, void 0), selectValid && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: selectValid }, void 0))] }), void 0), _jsxs(Parent, Object.assign({ className: "sm:col-span-3" }, { children: [_jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Text, Object.assign({ as: "label", className: classNames(businessType == 'Retail'
                                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                                            : 'focus:ring-blue-600 focus:border-blue-600', ' block sm:text-sm border-black-300 text-gray-700 rounded-md font-poppins text-sm font-medium pt-5 pb-3 ') }, { children: `${t('combo_amount')} (${currencySymbol})` }), void 0), _jsx(Text, Object.assign({ as: "label", className: "block text-sm ml-1 font-medium text-red-700  " }, { children: "*" }), void 0)] }), void 0), _jsx(Input, { type: "number", name: "price", id: "price", className: classNames(businessType == 'Retail'
                                                    ? 'focus:ring-blue-600 focus:border-blue-600'
                                                    : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm block w-full sm:text-sm border-gray-200 text-gray-400 rounded-md font-poppins font-normal py-2.5 '), labelClass: "block text-sm font-poppins font-medium text-gray-600", labelText: "", onChange: handleChange('price'), onBlur: handleBlur('price'), value: values.price }, void 0), errors.price && touched.price && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: errors.price }, void 0))] }), void 0)] }), void 0)] }, void 0) }, void 0), _jsx(Parent, Object.assign({ className: "pt-5 " }, { children: _jsxs(Parent, Object.assign({ className: "flex justify-end" }, { children: [_jsx(Parent, { children: _jsx(Button, Object.assign({ disabled: isSubmitting, onClick: () => {
                                        handleSubmit(), validSelect('select cat', editCategory, '');
                                    }, text: id ? t('update') : t('create'), className: businessType == 'Retail'
                                        ? classNames(btnLoading ? 'opacity-50 cursor-not-allowed' : '', 'inline-flex justify-center items-center mr-4 px-4 py-2 border-2 border-blue-600 shadow-custom text-base font-poppins font-medium rounded-md text-white bg-blue-600 ')
                                        : classNames(btnLoading ? 'opacity-50 cursor-not-allowed' : '', 'inline-flex justify-center items-center mr-4 px-4 py-1.5 border-2 border-blue-600 shadow-custom text-base font-poppins font-medium rounded-md text-white bg-blue-600 ') }, { children: btnLoading && (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3" }, { children: _jsx(Parent, { className: "animate-spin rounded-full h-5 w-5 border-b-2 border-white-900" }, void 0) }), void 0)) }), void 0) }, void 0), _jsx(Parent, { children: _jsx(Button, { onClick: () => setOpen(false), text: t('cancel'), className: "inline-flex justify-center items-center px-4 py-1.5 border-2  shadow-custom text-base font-poppins bg-blue-100 font-medium rounded-md hover:bg-blue-600 text-blue-600 hover:text-white " }, void 0) }, void 0)] }), void 0) }), void 0)] }, void 0)) }), void 0));
};
export default AddCombo;
