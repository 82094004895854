import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Checkbox } from '../../../../Component/index';
import DataNotFound from '../../../DataNotFound/DataNotFound';
import SelectCategoriesTable from './SelectCategoriesTable';
import { useTranslation } from 'react-i18next';
import '../../../../../translations/i18n';
const SelectCategories = ({ title, apiCatArray, setApiCatArray, setProDeteils }) => {
    const { t } = useTranslation();
    const [catCheckItem, setCatCheckItem] = useState(false);
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const [targetCat, setTargetCat] = useState([]);
    const [apiResultArray, setApiResultArray] = useState([]);
    const [is_all_categories, setIs_all_categories] = useState(0);
    const sliderOpen = (value, title) => {
        setOpen(value);
        setSliderTitle(title);
    };
    const Tablecolumns = [
        {
            id: 1,
            title: (_jsxs(Parent, Object.assign({ className: "flex" }, { children: [_jsx(Checkbox, { type: "checkbox", name: "products", id: "", checked: catCheckItem, className: "focus:ring-transparent h-4 w-4 mr-2 text-blue-500 items-center justfy-center border-gray-300 rounded", onChange: (e) => changeTargetCustomer(e) }, void 0), t('image')] }), void 0)),
            className: 'px-2 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('categories_name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((obj) => obj.id === id);
        setOpen(value);
        setSliderTitle(title);
    };
    const changeTargetCustomer = (e) => {
        if (e.target.checked == true) {
            setIs_all_categories(1);
            setCatCheckItem(true);
            let oldCust = [];
            let oldpro = [];
            apiCatArray.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: true });
                let ca = {
                    id: item.id
                };
                oldpro.push(ca);
                oldCust.push(cust);
                setTargetCat(oldpro);
                setApiResultArray(oldCust);
                setApiCatArray(oldCust);
            });
        }
        else {
            setIs_all_categories(0);
            setCatCheckItem(false);
            setTargetCat([]);
            let oldCust = [];
            apiResultArray.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: false });
                oldCust.push(cust);
                setApiResultArray(oldCust);
                setApiCatArray(oldCust);
            });
        }
    };
    const checks = (e) => {
        const oldpro = [...targetCat];
        setCatCheckItem(false);
        if (e.target.checked) {
            let date = parseInt(e.target.id);
            oldpro.push(date);
            setTargetCat(oldpro);
            let oldItem = [];
            apiCatArray.map((item) => {
                if (item.id == e.target.id) {
                    let cust = Object.assign(Object.assign({}, item), { checked: true });
                    oldItem.push(cust);
                }
                else {
                    let cust = Object.assign({}, item);
                    oldItem.push(cust);
                }
            });
            setApiCatArray(oldItem);
            setApiResultArray(oldItem);
        }
        else {
            let oldItem = [];
            apiCatArray.map((item) => {
                if (item.id == e.target.id) {
                    let cust = Object.assign(Object.assign({}, item), { checked: false });
                    oldItem.push(cust);
                }
                else {
                    let cust = Object.assign({}, item);
                    oldItem.push(cust);
                }
            });
            setApiCatArray(oldItem);
            setApiResultArray(oldItem);
            const filDate = oldpro.filter((data) => {
                return (data = e.target.id);
            });
            setTargetCat(filDate);
        }
    };
    const categoryCheckBox = () => {
        let catItem = [];
        apiCatArray.map((audience, item) => {
            if (audience.checked) {
                let cat = Object.assign(Object.assign({}, item), { checked: true });
                catItem.push(cat);
            }
        });
        if (apiCatArray.length > 0) {
            if (catItem.length == apiCatArray.length) {
                setCatCheckItem(true);
            }
            else {
                setCatCheckItem(false);
            }
        }
    };
    useEffect(() => {
        return setApiResultArray(apiCatArray);
    }, [title]);
    useEffect(() => {
        categoryCheckBox();
    }, []);
    useEffect(() => {
        setProDeteils('cat', is_all_categories, targetCat);
    }, [is_all_categories, targetCat.length]);
    return (_jsx(Parent, { children: _jsx(Parent, Object.assign({ className: "" }, { children: apiCatArray && apiCatArray.length > 0 ? (_jsx(SelectCategoriesTable, { onClick: sliderOpen, resultData: apiCatArray, getSingleObj: getSingleObj, columns: Tablecolumns, checks: checks, total: apiCatArray && apiCatArray.length > 0 ? apiCatArray.length : 0, reloadData: function (value, from) {
                    throw new Error('Function not implemented.');
                } }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('product_details_are_not_available_click_select_products_or_select_categories'), iconTag: 'symbol', iconClassName: '' }, void 0)) }), void 0) }, void 0));
};
export default SelectCategories;
