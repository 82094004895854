import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import React, { useEffect, useState } from 'react';
import './PrintBillStyle.css';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
const OrderPrintBill = React.forwardRef((props, ref) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    const { t } = useTranslation();
    const currencyType = localStorage.getItem('currencyType');
    const [qtyCount, setQtyCount] = useState(0);
    const [oneItem, setOneItem] = useState(0);
    const [tip, setTip] = useState(localStorage.getItem('tip'));
    const [today, settoday] = useState(new Date());
    const [qty, setQty] = useState(0);
    const firstName = localStorage.getItem('firstName');
    const Mobile = localStorage.getItem('mobile');
    const lastName = localStorage.getItem('lastName');
    const buinessName = localStorage.getItem('buinessName');
    const [tabId, setTabId] = useState(localStorage.getItem('TabId'));
    const [tableId, settableId] = useState({
        id: '',
        name: ''
    });
    const address = localStorage.getItem('address');
    const columns = [
        {
            id: 1,
            title: t('item'),
            className: ' text-left text-sm font-poppins font-normal text-gray-400'
        },
        {
            id: 2,
            title: t('qty'),
            className: ' text-left  text-sm font-poppins font-normal text-gray-400'
        },
        {
            id: 3,
            title: t('price'),
            className: ' text-left  text-sm font-poppins font-normal text-gray-400'
        },
        {
            id: 4,
            title: t('total'),
            className: ' text-left  text-sm font-poppins font-normal text-gray-900'
        }
    ];
    const tableOrder = props.tableDetails;
    useEffect(() => {
        var _a, _b, _c;
        if (props.details_outlet) {
            let total = 0;
            let one = 0;
            let totalQty = 0;
            ((_a = props.details_outlet) === null || _a === void 0 ? void 0 : _a.order_item) &&
                ((_c = (_b = props.details_outlet) === null || _b === void 0 ? void 0 : _b.order_item) === null || _c === void 0 ? void 0 : _c.map((cart) => {
                    total = total + parseInt(cart.quantity);
                    one = one + parseFloat(cart.price);
                    totalQty = totalQty + parseFloat(cart.quantity);
                }));
            setQtyCount(total);
            setOneItem(one);
            setQty(totalQty);
            settableId(tableId);
        }
    }, [props.details_outlet]);
    return (_jsx("div", Object.assign({ ref: ref, style: {
            margin: '0',
            padding: '0',
            fontFamily: 'Poppins, sans-serif'
        }, className: "printBillContainer" }, { children: _jsxs("div", Object.assign({ style: {
                backgroundColor: 'white',
                fontWeight: 'bold',
                width: '100%',
                fontSize: '9px'
            } }, { children: [_jsxs("table", Object.assign({ width: "100%", style: { width: '100%', textAlign: 'center', marginBottom: '5px' } }, { children: [_jsx("tr", { children: _jsx("td", Object.assign({ style: {
                                    fontSize: '12px',
                                    color: '#000000',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontWeight: 'bold'
                                } }, { children: buinessName && buinessName }), void 0) }, void 0), _jsx("tr", { children: _jsx("td", Object.assign({ style: { fontSize: '12px' } }, { children: address }), void 0) }, void 0), _jsx("tr", { children: _jsx("td", Object.assign({ style: { fontSize: '12px' } }, { children: `Phone No : ${Mobile}` }), void 0) }, void 0)] }), void 0), _jsxs("table", Object.assign({ width: "100%", style: { width: '100%', textAlign: 'center' } }, { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                        borderTop: '2px dashed #000000',
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: `Date:${Moment(new Date()).format('DD/MM/YYYY')}` }), void 0), _jsx("td", Object.assign({ style: {
                                        borderTop: '2px dashed #000000',
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: `Time:${Moment(new Date()).format('hh:mm:ss A')}` }), void 0)] }, void 0), _jsxs("tr", { children: [((_a = props.details_outlet) === null || _a === void 0 ? void 0 : _a.is_takeaway) == 1 ? (_jsx("td", Object.assign({ style: { padding: '5px', textAlign: 'left', fontSize: '12px' } }, { children: `Type: Take Away` }), void 0)) : (_jsx("td", Object.assign({ style: { padding: '5px', textAlign: 'left', fontSize: '12px' } }, { children: `Biller:${(_b = props === null || props === void 0 ? void 0 : props.details_outlet) === null || _b === void 0 ? void 0 : _b.biller_name}` }), void 0)), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: props.details_outlet.first_name === null ? (_jsx(_Fragment, { children: `Customer : Guest` }, void 0)) : (_jsx(_Fragment, { children: `Customer Name: ${`${props.details_outlet.first_name} ${props.details_outlet.last_name}`}` }, void 0)) }), void 0)] }, void 0), _jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        whiteSpace: 'nowrap'
                                    } }, { children: `Bill.No: ${props.details_outlet.order_bill_no}` }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px',
                                        whiteSpace: 'nowrap'
                                    } }, { children: ` Order.No: ${props.details_outlet.order_no}` }), void 0)] }, void 0), _jsxs("tr", { children: [((_c = props.details_outlet) === null || _c === void 0 ? void 0 : _c.is_takeaway) == 1 ? (_jsxs("td", Object.assign({ style: {
                                        borderBottom: '2px dashed #000000',
                                        padding: '0 5px 5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: [`Biller:${(_d = props === null || props === void 0 ? void 0 : props.details_outlet) === null || _d === void 0 ? void 0 : _d.biller_name}`, " ", _jsx("br", {}, void 0)] }), void 0)) : tableOrder && ((_e = tableOrder[0]) === null || _e === void 0 ? void 0 : _e.waiter_name) ? (_jsx("td", Object.assign({ style: {
                                        borderBottom: '2px dashed #000000',
                                        padding: '0 5px 5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: `Waiter:${tableOrder && ((_f = tableOrder[0]) === null || _f === void 0 ? void 0 : _f.waiter_name)}` }), void 0)) : (_jsx("td", { style: {
                                        borderBottom: '2px dashed #000000',
                                        padding: '0 5px 5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, void 0)), _jsx("td", { style: {
                                        borderBottom: '2px dashed #000000',
                                        padding: '0 5px 5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, void 0)] }, void 0)] }), void 0), _jsx("table", Object.assign({ width: "100%", style: { width: '100%' } }, { children: _jsx("tr", Object.assign({ style: { textAlign: 'center' } }, { children: _jsx("td", Object.assign({ style: {
                                borderBottom: '2px dashed #000000',
                                padding: '5px',
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            }, colSpan: 2 }, { children: "Bill Receipt" }), void 0) }), void 0) }), void 0), _jsxs("table", Object.assign({ width: "100%", style: { width: '100%' } }, { children: [_jsxs("tr", { children: [_jsx("th", Object.assign({ style: {
                                        textAlign: 'left',
                                        padding: '5px',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        borderBottom: '2px dashed #000000'
                                    } }, { children: "Item" }), void 0), _jsx("th", Object.assign({ style: {
                                        textAlign: 'center',
                                        padding: '5px',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        borderBottom: '2px dashed #000000'
                                    } }, { children: "Qty" }), void 0), _jsx("th", Object.assign({ style: {
                                        textAlign: 'right',
                                        padding: '5px',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        borderBottom: '2px dashed #000000'
                                    } }, { children: "Price" }), void 0), _jsx("th", Object.assign({ style: {
                                        textAlign: 'right',
                                        padding: '5px',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        borderBottom: '2px dashed #000000'
                                    } }, { children: "Total" }), void 0)] }, void 0), ((_g = props.details_outlet) === null || _g === void 0 ? void 0 : _g.order_item) &&
                            ((_j = (_h = props.details_outlet) === null || _h === void 0 ? void 0 : _h.order_item) === null || _j === void 0 ? void 0 : _j.map((cart, index) => {
                                var _a, _b;
                                return (_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                                padding: '5px',
                                                textOverflow: 'ellipsis',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                fontSize: '12px',
                                                wordWrap: 'break-word'
                                            } }, { children: cart.status == 'Returned'
                                                ? `${cart.prod_name} (${cart.status})`
                                                : ((_a = props.details_outlet) === null || _a === void 0 ? void 0 : _a.status) == 'Cancelled'
                                                    ? `${cart.prod_name} (${(_b = props.details_outlet) === null || _b === void 0 ? void 0 : _b.status})`
                                                    : cart.prod_name }), void 0), _jsx("td", Object.assign({ style: {
                                                padding: '5px',
                                                textAlign: 'center',
                                                fontSize: '12px'
                                            } }, { children: cart.quantity }), void 0), _jsx("td", Object.assign({ style: {
                                                textAlign: 'right',
                                                padding: '5px',
                                                fontSize: '12px'
                                            } }, { children: parseFloat(cart.price).toFixed(2) }), void 0), _jsx("td", Object.assign({ style: {
                                                textAlign: 'right',
                                                padding: '5px',
                                                fontSize: '12px'
                                            } }, { children: parseFloat(cart.total_price).toFixed(2) }), void 0)] }, index));
                            }))] }), void 0), _jsxs("table", Object.assign({ width: "100%", style: { width: '100%' } }, { children: [_jsxs("tr", Object.assign({ style: { fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' } }, { children: [_jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        borderTop: '2px dashed #000000'
                                    } }, { children: "Total Items" }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px',
                                        borderTop: '2px dashed #000000'
                                    } }, { children: (_l = (_k = props.details_outlet) === null || _k === void 0 ? void 0 : _k.order_item) === null || _l === void 0 ? void 0 : _l.length }), void 0)] }), void 0), _jsxs("tr", Object.assign({ style: { fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' } }, { children: [_jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: "SubTotal" }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: `${parseFloat((_m = props.details_outlet) === null || _m === void 0 ? void 0 : _m.sub_total).toFixed(2)}` }), void 0)] }), void 0), tip == '1' && (_jsxs("tr", Object.assign({ style: { fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' } }, { children: [_jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: ((_o = props.details_outlet) === null || _o === void 0 ? void 0 : _o.tip_percentage) == 0.0
                                        ? `Tip`
                                        : `Tip ${(_p = props.details_outlet) === null || _p === void 0 ? void 0 : _p.tip_percentage}%` }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: `${parseFloat((_q = props.details_outlet) === null || _q === void 0 ? void 0 : _q.tip_amount).toFixed(2)}` }), void 0)] }), void 0)), ((_r = props.details_outlet) === null || _r === void 0 ? void 0 : _r.order_tax) &&
                            ((_s = props.details_outlet) === null || _s === void 0 ? void 0 : _s.order_tax.map((taxItem, index) => {
                                return (_jsxs("tr", Object.assign({ style: {
                                        fontFamily: 'Poppins, sans-serif',
                                        fontWeight: 'bold'
                                    } }, { children: [_jsx("td", Object.assign({ style: {
                                                padding: '5px',
                                                textAlign: 'left',
                                                fontSize: '12px'
                                            } }, { children: `${taxItem.tax_name} @ ${taxItem.tax_pct}%` }), void 0), _jsx("td", Object.assign({ style: {
                                                padding: '5px',
                                                textAlign: 'right',
                                                fontSize: '12px'
                                            } }, { children: `${parseFloat(taxItem.tax_amount).toFixed(2)}` }), void 0)] }), index));
                            })), ((_t = props.details_outlet) === null || _t === void 0 ? void 0 : _t.mgnt_disc_amt) && (_jsxs("tr", Object.assign({ style: { fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' } }, { children: [_jsxs("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: ["Service Charge @", parseFloat(props.details_outlet.service_charge_pct), "%"] }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: `${parseFloat(props.details_outlet.service_charge_amt).toFixed(2)}` }), void 0)] }), void 0)), ((_u = props.details_outlet) === null || _u === void 0 ? void 0 : _u.mgnt_disc_amt) && (_jsxs("tr", Object.assign({ style: { fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' } }, { children: [_jsxs("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: ["Discount @", parseFloat(props.details_outlet.mgnt_disc_pct), "%"] }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: `${parseFloat(props.details_outlet.mgnt_disc_amt).toFixed(2)}` }), void 0)] }), void 0)), ((_v = props.details_outlet) === null || _v === void 0 ? void 0 : _v.is_takeaway) == 1 && (_jsxs("tr", Object.assign({ style: {
                                fontFamily: 'Poppins, sans-serif',
                                fontWeight: 'bold'
                            } }, { children: [_jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: "Container" }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: ` ${parseFloat(props.details_outlet.miscellaneous).toFixed(2)}` }), void 0)] }), void 0)), ((_w = props.details_outlet) === null || _w === void 0 ? void 0 : _w.tip_amount) && (_jsxs("tr", Object.assign({ style: { fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' } }, { children: [_jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px'
                                    } }, { children: "Tips" }), void 0), _jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px'
                                    } }, { children: `${parseFloat(props.details_outlet.tip_amount).toFixed(2)}` }), void 0)] }), void 0)), _jsxs("tr", Object.assign({ style: { fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' } }, { children: [_jsx("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        borderBottom: (((_x = props.details_outlet) === null || _x === void 0 ? void 0 : _x.coupon_code) ||
                                            ((_y = props.details_outlet) === null || _y === void 0 ? void 0 : _y.redeem_value) != 0) &&
                                            '2px dashed #000000'
                                    } }, { children: "TOTAL" }), void 0), _jsxs("td", Object.assign({ style: {
                                        padding: '5px',
                                        textAlign: 'right',
                                        fontSize: '12px',
                                        borderBottom: (((_z = props.details_outlet) === null || _z === void 0 ? void 0 : _z.coupon_code) ||
                                            ((_0 = props.details_outlet) === null || _0 === void 0 ? void 0 : _0.redeem_value) != 0) &&
                                            '2px dashed #000000'
                                    } }, { children: [' ', `${props.currency} ${(parseFloat(((_1 = props.details_outlet) === null || _1 === void 0 ? void 0 : _1.total) || 0) +
                                            parseFloat(((_2 = props.details_outlet) === null || _2 === void 0 ? void 0 : _2.tip_amount) || 0)).toFixed(2)}`] }), void 0)] }), void 0)] }), void 0), (((_3 = props.details_outlet) === null || _3 === void 0 ? void 0 : _3.coupon_code) || ((_4 = props.details_outlet) === null || _4 === void 0 ? void 0 : _4.redeem_value) != 0) && (_jsxs(_Fragment, { children: [_jsx("table", Object.assign({ width: "100%", style: { width: '100%' } }, { children: _jsx("tr", Object.assign({ style: { textAlign: 'center' } }, { children: _jsx("td", Object.assign({ style: {
                                        borderBottom: '2px dashed #000000',
                                        padding: '5px',
                                        fontFamily: 'Poppins, sans-serif',
                                        fontWeight: 'bold',
                                        fontSize: '12px'
                                    }, colSpan: 2 }, { children: "Discount" }), void 0) }), void 0) }), void 0), _jsx("table", Object.assign({ width: "100%", style: { width: '100%' } }, { children: _jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                            padding: '5px',
                                            textAlign: 'left',
                                            fontSize: '12px'
                                        } }, { children: "Coupon/Loyalty" }), void 0), _jsx("td", Object.assign({ style: {
                                            padding: '5px',
                                            textAlign: 'right',
                                            fontSize: '12px'
                                        } }, { children: props.details_outlet.coupon_code
                                            ? `${parseFloat(props.details_outlet.coupon_discount).toFixed(2)}`
                                            : `${parseFloat(props.details_outlet.redeem_value).toFixed(2)}` }), void 0)] }, void 0) }), void 0)] }, void 0)), _jsx("table", Object.assign({ width: "100%", style: { width: '100%' } }, { children: _jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                    borderTop: '2px dashed #000000',
                                    borderBottom: '2px dashed #000000',
                                    padding: '5px',
                                    textAlign: 'left',
                                    fontSize: '12px'
                                } }, { children: `Paid by (${props.type == 'UPI'
                                    ? props.details_outlet.other_payment
                                    : props.type})` }), void 0), _jsxs("td", Object.assign({ style: {
                                    borderTop: '2px dashed #000000',
                                    borderBottom: '2px dashed #000000',
                                    padding: '5px',
                                    textAlign: 'right',
                                    fontSize: '12px'
                                } }, { children: [' ', `${props.currency} ${(parseFloat(((_5 = props.details_outlet) === null || _5 === void 0 ? void 0 : _5.total) || 0) +
                                        parseFloat(((_6 = props.details_outlet) === null || _6 === void 0 ? void 0 : _6.tip_amount) || 0)).toFixed(2)}`] }), void 0)] }, void 0) }), void 0), _jsx("table", Object.assign({ width: "100%", style: {
                        width: '100%',
                        marginTop: '10px',
                        marginBottom: '10px',
                        fontSize: '12px'
                    } }, { children: _jsx("tr", { children: _jsxs("td", Object.assign({ style: { padding: '5px', textAlign: 'center', fontSize: '16px' }, colSpan: 2 }, { children: [' ', _jsx("label", { children: "****" }, void 0), " ", _jsx("label", { children: "Thank you" }, void 0), " ", _jsx("label", { children: "****" }, void 0), ' '] }), void 0) }, void 0) }), void 0)] }), void 0) }), void 0));
});
OrderPrintBill.displayName = 'OrderPrintBill'; // to clear the ESLint warning.
export default OrderPrintBill;
