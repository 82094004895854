var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import { classNames } from '../../Utilities/Helpers';
import { Parent, Text } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import Loyalty from '../Loyalty/Loyalty';
import Redeemtion from '../Loyalty/Redeemtion';
import apiCall from '../../../API/apiCall';
import { APILINK } from '../../Utilities/ApiLink';
const LoyaltyTab = ({}) => {
    const { t } = useTranslation();
    const tabs = [
        { id: 1, name: t('loyalty_points'), href: '#', content: Loyalty },
        {
            id: 2,
            name: t('coupons_redemption'),
            href: '#',
            content: Redeemtion
        }
    ];
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [loy, setLoy] = useState([]);
    const [cop, setCop] = useState([]);
    const [loyPoint, setLoyPoint] = useState(0);
    const [redPoint, setRedPoint] = useState(0);
    const [coupCount, setCoupCount] = useState(0);
    const [coupVal, setCoupVal] = useState(0);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const getOrder = () => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(APILINK.order_get, 'GET', token, {
            business_id: businessId,
            outlet_id: outletId
        })
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            let arr = [...loy];
            let arr1 = [...loy];
            let lpoint = 0;
            let repoint = 0;
            let copDis = 0;
            let CopCount = 0;
            (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.map((a) => {
                if (!(a === null || a === void 0 ? void 0 : a.coupon_code)) {
                    let b = Object.assign({}, a);
                    arr.push(b);
                    lpoint = lpoint + a.loyalty_points;
                    repoint = repoint + a.redeem_points;
                }
                if (a === null || a === void 0 ? void 0 : a.coupon_code) {
                    let b = Object.assign({}, a);
                    CopCount = CopCount + 1;
                    copDis = copDis + parseFloat(a.coupon_discount);
                    arr1.push(b);
                }
            });
            setCop(arr1);
            setLoy(arr);
            setLoyPoint(lpoint);
            setRedPoint(repoint);
            setCoupCount(CopCount);
            setCoupVal(copDis);
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    useEffect(() => {
        getOrder();
    }, []);
    return (_jsx(Parent, Object.assign({ className: "lg:px-12 px-4 md:px-6 pb-9" }, { children: _jsx(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom mt-3" }, { children: _jsx(Parent, Object.assign({ className: "px-5 pb-5 pt-1" }, { children: _jsxs(Tab.Group, { children: [_jsx(Tab.List, Object.assign({ className: "-mb-px flex space-x-8 border-b-2 border-gray-200", "aria-label": "Tabs" }, { children: tabs.map((item) => {
                                return (_jsx(Tab, { children: ({ selected }) => {
                                        return (_jsx(Parent, Object.assign({ className: businessType == 'Retail'
                                                ? classNames(selected
                                                    ? 'border-indigo-500 text-indigo-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm')
                                                : classNames(selected
                                                    ? 'border-indigo-500 text-indigo-500'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm') }, { children: _jsx(Text, { as: "span", label: item.name }, void 0) }), item.id));
                                    } }, item.id));
                            }) }), void 0), _jsx(Tab.Panels, { children: tabs.map((item) => {
                                const Tag = item.content;
                                return (_jsx(Tab.Panel, { children: _jsx(Tag, { loy: loy, cop: cop, loyPoint: loyPoint, redPoint: redPoint, coupCount: coupCount, coupVal: coupVal }, void 0) }, item.id));
                            }) }, void 0)] }, void 0) }), void 0) }), void 0) }), void 0));
};
export default LoyaltyTab;
