import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SearchIcon, ShoppingCartIcon } from '@heroicons/react/solid';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { classNames } from '../../Utilities/Helpers';
import { Parent, Input } from '../../Component';
import { useQuery } from '@apollo/client';
import { STARTPAGE } from '../../Utilities/Constants';
import { GLOBALCATEGORIESGETQUERY } from '../../GraphqlQuery/Query/Query';
import CategoryGlobalList from './CategoryGlobalList';
import DataNotFound from '../../Containers/DataNotFound/DataNotFound';
const CategoryGlobal = ({ AddList }) => {
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [apiResultArray, setApiResultArray] = useState([]);
    const [filterDataList, setFilterDataList] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const { loading, error, data, refetch } = useQuery(GLOBALCATEGORIESGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const filterData = (e) => {
        setCurrentPageNumber(1);
        const filterText = e.target.value;
        const filterList = apiResultArray.filter((item) => {
            return ((item.cat_name && item.cat_name.toLowerCase().includes(filterText.toLowerCase())) ||
                (item.tag && item.tag.toLowerCase().includes(filterText.toLowerCase())));
        });
        setFilterDataList(filterList);
    };
    useEffect(() => {
        if (data) {
            setApiResultArray(data.getAllGlobalCategory[0].data);
            setFilterDataList(data.getAllGlobalCategory[0].data);
        }
    }, [data]);
    return (_jsxs(Parent, Object.assign({ className: "" }, { children: [_jsx(Parent, Object.assign({ className: "flex justify-between items-center" }, { children: _jsx(Parent, { children: _jsx(Input, { name: "search", className: classNames(businessType == 'Retail'
                            ? 'focus:ring-blue-600 focus:border-blue-600'
                            : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm focus:outline-none block px-3 pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "search", placeHolder: t('search'), type: "text", labelText: t('search'), labelClass: "block text-sm font-poppins font-medium text-gray-300 pb-1", iconRequired: true, onChange: (e) => filterData(e), labelRequired: false, iconTag: SearchIcon }, void 0) }, void 0) }), void 0), filterDataList && filterDataList.length > 0 ? (_jsx(CategoryGlobalList, { filterDataList: filterDataList, total: filterDataList && filterDataList.length > 0 ? filterDataList.length : 0, AddList: AddList }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('global_categories_are_not_available'), iconClassName: "h-16 w-16 text-blue-600 m-auto mb-2", iconTag: ShoppingCartIcon }, void 0))] }), void 0));
};
export default CategoryGlobal;
