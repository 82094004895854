var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect, useRef } from 'react';
import { EyeIcon, DownloadIcon } from '@heroicons/react/solid';
import { Parent, Icon, Link } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import Pagination from '../Pagination/Pagination';
import { DATALIMIT, PAGELIMIT } from '../../Utilities/Constants';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import SubscriptionView from '../../Component/SlideOverlay/subscriptionView';
import apiCall from '../../../API/apiCall';
import { APILINK } from '../../Utilities/ApiLink';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const SubscriptionTable = ({ resultData, total, columns, currentPageNumber, setCurrentPageNumber, setSubscriptionGet, token, subsNumber, startDate, endDate, dueDate }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { t } = useTranslation();
    const pdfRef = useRef(null);
    const [selectedCurrency, setSelectedCurrency] = useState(localStorage.getItem('symbol'));
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [cusView, setCusView] = useState();
    const [showInfo, setShowInfo] = useState(false);
    const [columnsData, setColumnsData] = useState(columns);
    const [tableData, setTableData] = useState((_a = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _a === void 0 ? void 0 : _a.subscription_histroy);
    const [subscrip, setSubscrip] = useState([]);
    const [totalMonths, setTotalMonths] = useState();
    const [subscriptionInvoice, setSubscriptionInvoice] = useState({});
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const dataTable = resultData && ((_b = resultData[0]) === null || _b === void 0 ? void 0 : _b.subscription_histroy);
    const getPaginatedData = (pageNumber) => {
        var _a, _b, _c, _d, _e, _f;
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        const list = (_b = (_a = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _a === void 0 ? void 0 : _a.subscription_histroy) === null || _b === void 0 ? void 0 : _b.slice(startIndex, endIndex);
        setEndIndex(((_d = (_c = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _c === void 0 ? void 0 : _c.subscription_histroy) === null || _d === void 0 ? void 0 : _d.length) > endIndex
            ? endIndex
            : (_f = (_e = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _e === void 0 ? void 0 : _e.subscription_histroy) === null || _f === void 0 ? void 0 : _f.length);
        setStartIndex(startIndex + 1);
        setTableData(list);
    };
    const setSingleSubscription = (id) => {
        const singleDate = dataTable.filter((item) => item.s_h_id == id);
        setSubscriptionInvoice(singleDate);
        const start = new Date(singleDate[0].payment_start_date);
        const end = new Date(singleDate[0].payment_end_date);
        const months = (end.getFullYear() - start.getFullYear()) * 12;
        const sDate = start.getMonth();
        const eDate = end.getMonth();
        const totalValidityMonths = months + eDate - sDate;
        setTotalMonths(totalValidityMonths);
    };
    const showViewInfo = (value, id) => {
        getSubscription(id);
        setShowInfo(value);
    };
    const getSubscription = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(APILINK.subscription_get, 'GET', token, {
            id: id
        })
            .then((response) => {
            const responseData = response.data.data;
            setSubscrip(responseData);
        })
            .catch((err) => {
            console.log(err);
        });
    });
    const exportPDF = (id) => {
        setSingleSubscription(id);
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'landscape';
        const doc = new jsPDF(orientation, unit, size);
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text('center', 40, pageWidth);
        doc.html(pdfRef.current, {
            callback: function (docu) {
                docu.save('invoice.pdf');
            },
            width: doc.internal.pageSize.getWidth(),
            windowWidth: doc.internal.pageSize.getWidth()
        });
    };
    useEffect(() => {
        var _a;
        setTableData((_a = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _a === void 0 ? void 0 : _a.subscription_histroy);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, (_c = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _c === void 0 ? void 0 : _c.subscription_histroy]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: " align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "", columns: columnsData }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: tableData === null || tableData === void 0 ? void 0 : tableData.map((person) => {
                                        var _a, _b, _c;
                                        return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: (_a = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _a === void 0 ? void 0 : _a.subscription_no }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: classNames((person === null || person === void 0 ? void 0 : person.status) == 'Success'
                                                            ? 'text-sm font-poppins p-1.5 text-center rounded-2xl bg-green-100 font-normal text-green-500'
                                                            : 'text-sm font-poppins p-1.5 text-center rounded-2xl bg-red-100 font-normal text-red-500') }, { children: person === null || person === void 0 ? void 0 : person.status }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: Moment(person === null || person === void 0 ? void 0 : person.payment_start_date).format('DD-MMM-YYYY') }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: Moment(person === null || person === void 0 ? void 0 : person.payment_end_date).format('DD-MMM-YYYY') }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: `${(_b = person === null || person === void 0 ? void 0 : person.feature_id) === null || _b === void 0 ? void 0 : _b.split(',').length} ${t('features')}` }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person === null || person === void 0 ? void 0 : person.paid_amount }), void 0) }), void 0), _jsxs(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap  text-sm font-medium w-1/7" }, { children: [_jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                                ? 'hover:bg-blue-600'
                                                                : 'hover:bg-blue-600', ' cursor-pointer group mr-5 inline-flex items-center px-3 py-3  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('view'), onClick: () => showViewInfo(true, person === null || person === void 0 ? void 0 : person.s_h_id) }, { children: _jsx(Icon, { tag: EyeIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                                ? 'hover:bg-blue-600'
                                                                : 'hover:bg-blue-600', ' cursor-pointer group mr-5 inline-flex items-center px-3 py-3  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('download'), onClick: () => exportPDF(person === null || person === void 0 ? void 0 : person.s_h_id) }, { children: _jsx(Icon, { tag: DownloadIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0)] }), void 0)] }, (_c = person[0]) === null || _c === void 0 ? void 0 : _c.id));
                                    }) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Parent, Object.assign({ className: "hidden" }, { children: _jsxs("div", Object.assign({ ref: pdfRef, style: {
                        margin: '0',
                        padding: '0',
                        fontFamily: 'Poppins sans-serif'
                    }, className: "printBillContainer" }, { children: [_jsx("div", Object.assign({ style: {
                                backgroundColor: 'white',
                                width: '100%',
                                fontSize: '9px',
                                paddingTop: '15px'
                            } }, { children: _jsx("table", Object.assign({ style: {
                                    width: '90%',
                                    margin: '0 auto',
                                    borderSpacing: '0',
                                    padding: '20px',
                                    backgroundColor: '#5850ff'
                                } }, { children: _jsx("tr", { children: _jsx("th", Object.assign({ style: {
                                            textAlign: 'center',
                                            fontSize: '18px',
                                            lineHeight: '21px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#ffffff',
                                            paddingBottom: '20px',
                                            paddingLeft: '10px'
                                        } }, { children: "Invoice" }), void 0) }, void 0) }), void 0) }), void 0), _jsxs("table", Object.assign({ style: {
                                width: ' 90%',
                                margin: ' 0 auto',
                                paddingBottom: '30px',
                                borderSpacing: '0',
                                paddingTop: '15px'
                            } }, { children: [_jsxs("tr", { children: [_jsx("th", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '5px'
                                            } }, { children: "Subscription No" }), void 0), _jsx("td", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'right',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '5px'
                                            } }, { children: subsNumber }), void 0), _jsx("th", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '5px',
                                                paddingLeft: '30px'
                                            } }, { children: "Status" }), void 0), _jsx("td", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'right',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '5px'
                                            } }, { children: (_d = subscriptionInvoice[0]) === null || _d === void 0 ? void 0 : _d.status }), void 0)] }, void 0), _jsxs("tr", { children: [_jsx("th", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '15px'
                                            } }, { children: "Due Date" }), void 0), _jsx("td", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'right',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '15px'
                                            } }, { children: Moment(dueDate).format('DD -MMM-YYYY') }), void 0), _jsx("th", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'bold',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '15px',
                                                paddingLeft: '30px'
                                            } }, { children: "Start Date" }), void 0), _jsx("td", Object.assign({ style: {
                                                width: '25%',
                                                textAlign: 'right',
                                                fontSize: '12px',
                                                lineHeight: ' 21px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: ' #000000',
                                                paddingBottom: '15px'
                                            } }, { children: Moment(startDate).format('DD-MMM-YYYY') }), void 0)] }, void 0)] }), void 0), _jsx("table", Object.assign({ style: {
                                width: '90%',
                                margin: '0 auto',
                                borderSpacing: '0'
                            } }, { children: _jsx("tr", { children: _jsx("td", Object.assign({ style: {
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '18px',
                                        lineHeight: '21px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Poppins, sans-serif',
                                        color: '#000000',
                                        paddingBottom: '20px',
                                        borderTop: '1px solid #E5E7EB'
                                    } }, { children: "Features" }), void 0) }, void 0) }), void 0), _jsxs("table", Object.assign({ style: {
                                width: '90%',
                                margin: '0 auto',
                                borderSpacing: '0',
                                padding: '20px'
                            } }, { children: [_jsxs("tr", Object.assign({ className: "" }, { children: [_jsx("th", Object.assign({ style: {
                                                textAlign: 'left',
                                                fontSize: '15px',
                                                lineHeight: '21px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: '#5850ff',
                                                borderTop: '1px solid #E5E7EB',
                                                borderBottom: '1px solid #E5E7EB',
                                                paddingBottom: '20px'
                                            } }, { children: "Subscription" }), void 0), _jsx("th", Object.assign({ style: {
                                                textAlign: 'center',
                                                fontSize: '15px',
                                                lineHeight: '21px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: '#5850ff',
                                                paddingBottom: '20px',
                                                borderTop: '1px solid #E5E7EB',
                                                borderBottom: '1px solid #E5E7EB',
                                                paddingRight: '5rem'
                                            } }, { children: "Validity" }), void 0), _jsx("th", Object.assign({ style: {
                                                textAlign: 'right',
                                                fontSize: '15px',
                                                lineHeight: '21px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Poppins, sans-serif',
                                                color: '#5850ff',
                                                borderBottom: '1px solid #E5E7EB',
                                                borderTop: '1px solid #E5E7EB',
                                                marginBottom: '10px',
                                                paddingBottom: '20px'
                                            } }, { children: "Amount" }), void 0)] }), void 0), (_f = (_e = subscriptionInvoice[0]) === null || _e === void 0 ? void 0 : _e.features) === null || _f === void 0 ? void 0 : _f.map((item) => {
                                    return (_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                                    textAlign: 'left',
                                                    fontSize: '12px',
                                                    lineHeight: '21px',
                                                    fontWeight: 'normal',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    color: '#000000',
                                                    paddingBottom: '15px'
                                                } }, { children: item === null || item === void 0 ? void 0 : item.name }), void 0), _jsx("td", Object.assign({ style: {
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    lineHeight: '21px',
                                                    fontWeight: 'normal',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    color: '#000000',
                                                    paddingBottom: '15px',
                                                    paddingRight: '5rem'
                                                } }, { children: `${totalMonths} \u00A0Months` }), void 0), _jsxs("td", Object.assign({ style: {
                                                    textAlign: 'right',
                                                    fontSize: '12px',
                                                    lineHeight: '21px',
                                                    fontWeight: 'normal',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    color: '#000000',
                                                    paddingBottom: '15px'
                                                } }, { children: [selectedCurrency === '₹' ? (_jsx("span", Object.assign({ className: "px-1" }, { children: "INR" }), void 0)) : (selectedCurrency + ' '), ' ', item === null || item === void 0 ? void 0 : item.price] }), void 0)] }, item.id));
                                })] }), void 0), _jsx("table", Object.assign({ style: {
                                width: '90%',
                                margin: '0 auto',
                                paddingBottom: '30px',
                                borderSpacing: '0',
                                paddingTop: '20px'
                            } }, { children: _jsxs("tr", { children: [_jsx("th", Object.assign({ style: {
                                            width: '80%',
                                            textAlign: 'right',
                                            fontSize: '18px',
                                            lineHeight: '21px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#5850ff',
                                            paddingBottom: '20px',
                                            borderTop: '1px solid #E5E7EB',
                                            borderBottom: '1px solid #E5E7EB'
                                        } }, { children: "Total" }), void 0), _jsx("td", Object.assign({ style: {
                                            width: '20%',
                                            textAlign: 'right',
                                            fontSize: '18px',
                                            lineHeight: '21px',
                                            fontWeight: 'normal',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            paddingBottom: '20px',
                                            borderTop: '1px solid #E5E7EB',
                                            borderBottom: '1px solid #E5E7EB'
                                        } }, { children: ` ${selectedCurrency === '₹' ? ' INR ' : selectedCurrency + ' '} ${(_g = subscriptionInvoice[0]) === null || _g === void 0 ? void 0 : _g.paid_amount}` }), void 0)] }, void 0) }), void 0)] }), void 0) }), void 0), _jsx(Pagination, { items: tableData, pageLimit: pageLimit, dataLimit: dataLimit, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, totalData: (_h = resultData === null || resultData === void 0 ? void 0 : resultData[0]) === null || _h === void 0 ? void 0 : _h.subscription_histroy, total: total }, void 0), _jsx(SubscriptionView, { open: showInfo, cusView: cusView, setOpen: setShowInfo, title: t('subscription_view'), subscriptionArr: subscrip }, void 0)] }), void 0));
};
export default SubscriptionTable;
