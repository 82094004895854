import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Image, Text, Checkbox } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import Pagination from '../Pagination/Pagination';
import { GLOBALDATALIMIT, PAGELIMIT } from '../../Utilities/Constants';
import ViewInfo from '../../Component/SlideOverlay/ViewInfo';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import { imageOnErrorHandler } from '../../Utilities/Helpers';
let defalutImg = require('../../assets/media/no-image.png');
const GlobalItemsTable = ({ columns, resultData, total, checks, setCurrentPageNumber, currentPageNumber }) => {
    const { t } = useTranslation();
    const [dataLimit, setDataLimit] = useState(GLOBALDATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [items, setItems] = useState(resultData);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [showInfo, setShowInfo] = useState(false);
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        const list = resultData.slice(startIndex, endIndex);
        setEndIndex(resultData.length > endIndex ? endIndex : resultData.length);
        setStartIndex(startIndex + 1);
        setItems(list);
    };
    useEffect(() => {
        setItems(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "py-0 align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200" }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: items === null || items === void 0 ? void 0 : items.map((person, index) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                                        return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "py-2.5 whitespace-nowrap w-1/6" }, { children: _jsxs(Parent, Object.assign({ className: "flex items-center " }, { children: [_jsx(Parent, { children: _jsx(Checkbox, { type: "checkbox", name: 'item.cat_name', checked: person.checked, onChange: (e) => checks(e, index), className: "focus:ring-transparent h-4 mr-2 ml-2  w-4 text-blue-500 items-center justfy-center border-gray-300 rounded ", id: person.id }, void 0) }, void 0), _jsx(Parent, Object.assign({ className: "ml-4 w-10  h-10 object-cover rounded-lg" }, { children: _jsx(Image, { className: "w-full h-full object-cover rounded-lg ", src: `${person.product_img
                                                                        ? person.product_img
                                                                        : require('../../assets/media/no-image.png')}`, alt: "girl img", onError: (e) => imageOnErrorHandler(e, defalutImg) }, void 0) }), void 0)] }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-wrap w-2/6" }, { children: _jsxs(Parent, Object.assign({ className: "flex flex-col " }, { children: [_jsx(Parent, Object.assign({ className: " " }, { children: _jsx(Text, Object.assign({ className: "text-sm font-normal font-poppins  text-gray-800", as: "p" }, { children: person.prod_name }), void 0) }), void 0), _jsxs(Parent, Object.assign({ className: " flex pt-1  items-center " }, { children: [_jsx(Parent, Object.assign({ className: "  flex   " }, { children: (person.variation &&
                                                                            ((_b = (_a = JSON.parse(person.variation).Variation1) === null || _a === void 0 ? void 0 : _a.colorOf) === null || _b === void 0 ? void 0 : _b.length) > 0) ||
                                                                            (person.variation &&
                                                                                ((_d = (_c = JSON.parse(person.variation).Variation2) === null || _c === void 0 ? void 0 : _c.sizeOf) === null || _d === void 0 ? void 0 : _d.length) > 0) ? (_jsx(Text, { className: "text-xs bg-yellow-400 rounded-full px-2 py-1 items-center font-medium  font-poppins text-white ", as: "p", label: "V" }, void 0)) : ('') }), void 0), _jsx(Parent, Object.assign({ className: "flex" }, { children: person.addon &&
                                                                            ((_e = JSON.parse(person.addon).addOn) === null || _e === void 0 ? void 0 : _e.length) >
                                                                                0 ? (_jsx(Text, { className: classNames((person.variation &&
                                                                                ((_g = (_f = JSON.parse(person.variation)
                                                                                    .Variation1) === null || _f === void 0 ? void 0 : _f.colorOf) === null || _g === void 0 ? void 0 : _g.length) > 0) ||
                                                                                (person.variation &&
                                                                                    ((_j = (_h = JSON.parse(person.variation).Variation2) === null || _h === void 0 ? void 0 : _h.sizeOf) === null || _j === void 0 ? void 0 : _j.length) > 0)
                                                                                ? ' -ml-2'
                                                                                : '', 'text-xs  bg-blue-400 rounded-full px-2 py-1 items-center font-medium  font-poppins text-white '), as: "p", label: "A" }, void 0)) : ('') }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: " " }, { children: _jsx(Text, Object.assign({ className: "text-sm  font-normal  font-poppins text-gray-800", as: "p" }, { children: person.sku && person.sku != 'NULL'
                                                                    ? person.sku
                                                                    : '' }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: " " }, { children: _jsx(Text, Object.assign({ className: "text-sm  font-normal  font-poppins text-gray-800", as: "p" }, { children: `${person.unit_title != null
                                                                    ? person.unit_title
                                                                    : ''}${person.uom_title != null
                                                                    ? `${person.uom_title}`
                                                                    : ''}` }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: " " }, { children: _jsx(Text, Object.assign({ className: "text-sm text-right font-normal font-poppins text-gray-800", as: "p" }, { children: person.price }), void 0) }), void 0) }), void 0) }), void 0)] }, person.id));
                                    }) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Pagination, { totalData: resultData, items: items, pageLimit: pageLimit, dataLimit: dataLimit, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, total: total, from: 'item' }, void 0), _jsx(ViewInfo, { open: showInfo, setOpen: setShowInfo, title: "Customer Information" }, void 0)] }), void 0));
};
export default GlobalItemsTable;
