import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-var-requires */
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Image, Text } from '../../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../../Component/Table';
import TableHeader from '../../TableHeaders/TableHeader';
import Pagination from '../../Pagination/Pagination';
import { DATALIMIT, PAGELIMIT, STARTPAGE } from '../../../Utilities/Constants';
import { imageOnErrorHandler } from '../../../Utilities/Helpers';
let defalutImg = require('../../../assets/media/no-image.png');
const KitchenOrderTable = ({ className, onClick, resultData, getSingleObj, columns, total, setToolTip }) => {
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [kitchen, setKitchen] = useState(resultData);
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        const list = resultData.slice(startIndex, endIndex);
        setEndIndex(resultData.length > endIndex ? endIndex : resultData.length);
        setStartIndex(startIndex + 1);
        setKitchen(list);
    };
    useEffect(() => {
        setKitchen(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col " }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200" }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-xs font-poppins font-medium text-gray-400", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: kitchen.map((item) => {
                                        var _a, _b, _c, _d;
                                        return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap " }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-normal font-poppins text-gray-800" }, { children: item.is_takeaway == '0'
                                                                ? 'Dining Table'
                                                                : 'Take away' }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 lg:px-3 py-2.5 whitespace-nowrap" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-normal font-poppins text-gray-800" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center " }, { children: _jsxs(Parent, Object.assign({ className: "flex overflow-hidden -space-x-4 lg:-space-x-6" }, { children: [item.sub_table_cust &&
                                                                        item.sub_table_cust.map((cust) => {
                                                                            return (_jsx(Image, { className: "inline-block h-8 w-8 rounded-full ring-2 ring-white cursor-pointer", src: cust.product_img
                                                                                    ? cust.product_img
                                                                                    : defalutImg, onError: (e) => imageOnErrorHandler(e, defalutImg), title: cust === null || cust === void 0 ? void 0 : cust.prod_name }, cust.id));
                                                                        }), (item === null || item === void 0 ? void 0 : item.product_item) &&
                                                                        ((_a = item === null || item === void 0 ? void 0 : item.product_item) === null || _a === void 0 ? void 0 : _a.length) > 4 && (_jsx(Parent, Object.assign({ className: "h-8 w-8 ring-2 ring-white  bg-green-500 rounded-full flex justify-center items-center cursor-pointer" }, { children: _jsxs(Text, Object.assign({ as: "span", title: setToolTip(item === null || item === void 0 ? void 0 : item.sub_table_cust), className: "text-sm font-normal text-white" }, { children: ["+", ((_b = item === null || item === void 0 ? void 0 : item.product_item) === null || _b === void 0 ? void 0 : _b.length) - 4] }), void 0) }), void 0))] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-normal font-poppins text-gray-800" }, { children: (item === null || item === void 0 ? void 0 : item.is_takeaway) == '0'
                                                            ? (item === null || item === void 0 ? void 0 : item.tbl_details) &&
                                                                ((_c = item === null || item === void 0 ? void 0 : item.tbl_details[0]) === null || _c === void 0 ? void 0 : _c.waiter_name)
                                                            : item === null || item === void 0 ? void 0 : item.biller_name }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-normal font-poppins text-gray-800" }, { children: (_d = item === null || item === void 0 ? void 0 : item.product_item) === null || _d === void 0 ? void 0 : _d.length }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap" }, { children: _jsx(Parent, Object.assign({ className: "text-sm  text-right font-normal font-poppins text-gray-800" }, { children: item === null || item === void 0 ? void 0 : item.total }), void 0) }), void 0)] }, item.id));
                                    }) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Pagination, { items: kitchen, pageLimit: pageLimit, dataLimit: dataLimit, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, totalData: resultData, total: total }, void 0)] }), void 0));
};
export default KitchenOrderTable;
