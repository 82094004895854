import chroma from 'chroma-js';
import { COLORS } from '../../Utilities/Color';
const colorCode = COLORS.blue_600;
export const colourStyles = {
    control: (styles) => (Object.assign(Object.assign({}, styles), { backgroundColor: COLORS.white })),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = chroma(colorCode);
        return Object.assign(Object.assign({}, styles), { backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? colorCode
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined, color: 'black', ':active': Object.assign(Object.assign({}, styles[':active']), { backgroundColor: !isDisabled
                    ? isSelected
                        ? colorCode
                        : color.alpha(0.3).css()
                    : undefined }) });
    },
    multiValue: (styles, { data }) => {
        const color = chroma(colorCode);
        return Object.assign(Object.assign({}, styles), { backgroundColor: color.alpha(0.1).css() });
    },
    multiValueLabel: (styles, { data }) => (Object.assign(Object.assign({}, styles), { color: colorCode })),
    multiValueRemove: (styles, { data }) => (Object.assign(Object.assign({}, styles), { color: colorCode, ':hover': Object.assign(Object.assign({}, styles[':hover']), { backgroundColor: colorCode, color: COLORS.white }) }))
};
