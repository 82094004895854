import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
export default function ResetPopup({ open, setOpen, resetData, resetForm }) {
    return (_jsx(Transition.Root, Object.assign({ show: open, as: Fragment }, { children: _jsx(Dialog, Object.assign({ as: "div", className: "fixed z-20 inset-0 overflow-y-auto", onClose: setOpen }, { children: _jsxs("div", Object.assign({ className: "flex items-center  justify-center min-h-screen text-center p-0" }, { children: [_jsx(Transition.Child, Object.assign({ as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(Dialog.Overlay, { className: "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" }, void 0) }), void 0), _jsx("span", Object.assign({ className: "hidden sm:inline-block sm:align-middle sm:h-screen", "aria-hidden": "true" }, { children: "\u200B" }), void 0), _jsx(Transition.Child, Object.assign({ as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95", enterTo: "opacity-100 translate-y-0 sm:scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 translate-y-0 sm:scale-100", leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" }, { children: _jsxs("div", Object.assign({ className: "relative inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-sm w-full p-6 mx-4 sm:mx-0" }, { children: [_jsx("div", Object.assign({ className: "absolute right-0 top-0 hidden pr-4 pt-4 sm:block" }, { children: _jsxs("button", Object.assign({ type: "button", className: "rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none", onClick: () => {
                                            resetData(resetForm);
                                            setOpen(false);
                                        } }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Close" }), void 0), _jsx(XIcon, { className: "h-6 w-6", "aria-hidden": "true" }, void 0)] }), void 0) }), void 0), _jsxs("div", Object.assign({ className: "sm:flex sm:items-start" }, { children: [_jsx("div", Object.assign({ className: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10" }, { children: _jsx(ExclamationIcon, { className: "h-6 w-6 text-red-600", "aria-hidden": "true" }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left" }, { children: [_jsx(Dialog.Title, Object.assign({ as: "h3", className: "text-base font-semibold leading-6 text-gray-900" }, { children: "Reset Alert" }), void 0), _jsx("div", Object.assign({ className: "mt-2" }, { children: _jsx("p", Object.assign({ className: "text-sm text-gray-500" }, { children: "Are you really want to reset all the details?" }), void 0) }), void 0)] }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" }, { children: [_jsx("button", Object.assign({ type: "button", className: "inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto", onClick: () => {
                                                resetData(resetForm), setOpen(false);
                                            } }, { children: "Yes, Reset" }), void 0), _jsx("button", Object.assign({ type: "button", className: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto", onClick: () => setOpen(false) }, { children: "Cancel" }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }), void 0));
}
