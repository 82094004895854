import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Icon, Link, Text, Parent } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import Layout from '../../Layouts/Layout';
import PageHeader from '../../Layouts/Core/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import Config from '../../assets/Icons/Config';
import OutletDetails from '../../assets/Icons/OutletDetails';
import OutletTiming from '../../assets/Icons/OutletTiming';
import Users from '../../assets/Icons/Users';
import Employees from '../../assets/Icons/Employees';
import POS from '../../assets/Icons/PosSession';
import StripeICon from '../../assets/Icons/Stripe';
import Loyalty from '../../assets/Icons/Loyalty';
import General from '../../assets/Icons/GeneralSettings';
import { COLORS } from '../../Utilities/Color';
import InstructionIcon from '../../assets/Icons/InstructionIcon';
import OnlineOrderIcon from '../../../app/assets/Icons/OnlineOrderIcon';
import { useQuery } from '@apollo/client';
import { GETALLSETTINGS } from '../../GraphqlQuery/Query/Query';
import { classNames } from '../../Utilities/Helpers';
// eslint-disable-next-line no-empty-pattern
const SettingsIcon = ({}) => {
    const history = useHistory();
    const handleClick = (id, link) => {
        history.push(link);
    };
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false, show: 'block' },
        {
            title: t('settings'),
            href: '#/settings',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: t('outlet_settings'),
            href: '#/outlet-settings',
            current: true,
            show: 'block'
        }
    ];
    const { loading: loadingSetting, error: errorSetting, data: dataSetting, refetch: refetchSetting } = useQuery(GETALLSETTINGS, {
        variables: {
            business_id: localStorage.getItem('businessId'),
            outlet_id: localStorage.getItem('outletId')
        }
    });
    const NAVIGATIONSETTINGSARRAY = [
        {
            id: 1,
            title: t('general'),
            icon: General,
            link: '/general',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 2,
            title: t('outlet_details'),
            icon: OutletDetails,
            link: '/outlet-details',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 3,
            title: t('configuration'),
            icon: Config,
            link: '/config',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 4,
            title: t('outlet_timings'),
            icon: OutletTiming,
            link: '/outlet-timings',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 5,
            title: t('users'),
            icon: Users,
            link: '/users',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 6,
            title: t('employees'),
            icon: Employees,
            link: '/emplyoee',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 7,
            title: t('pos_session'),
            icon: POS,
            link: '/pos-session',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 9,
            title: t('loyalty_settings'),
            icon: Loyalty,
            link: '/loyalty-settings',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 10,
            title: t('subscription'),
            icon: Loyalty,
            link: '/subscription',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 11,
            title: t('instructions'),
            icon: InstructionIcon,
            link: '/setupconfig',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        },
        {
            id: 11,
            title: t('online_order'),
            icon: OnlineOrderIcon,
            link: '/Online-orders',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: (dataSetting === null || dataSetting === void 0 ? void 0 : dataSetting.getAllSettings[0].enable_online_order) === '1' ? false : true
        },
        {
            id: 12,
            title: t('stripe_setup'),
            icon: StripeICon,
            link: '/stripesettings',
            color: businessType == 'Retail' ? COLORS.blue_600 : COLORS.blue_600,
            disabled: false
        }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('settings'), breadcrumbs: breadCrumbs }, void 0), _jsx(Parent, Object.assign({ className: " lg:px-12 md:px-6 pb-9 px-4" }, { children: _jsx(ContainerSection, Object.assign({ className: " overflow-hidden rounded-lg  py-5 mt-3" }, { children: _jsx(Parent, Object.assign({ className: "md:flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: "flex flex-wrap  w-full " }, { children: NAVIGATIONSETTINGSARRAY.map((item) => {
                                return (_jsx(Parent, Object.assign({ className: "md:w-3/12 w-6/12" }, { children: _jsx(Link, Object.assign({ className: "cursor-pointer", title: item.title, onClick: item.disabled === false
                                            ? () => handleClick('id', item.link)
                                            : // eslint-disable-next-line @typescript-eslint/no-empty-function
                                                () => { } }, { children: _jsx(Parent, Object.assign({ className: classNames(item.disabled === true ? 'opacity-60' : '', 'bg-white items-center border-gray-200 border-2 rounded-md hover:border-blue-600 hover:shadow-custom ') }, { children: _jsxs(Parent, Object.assign({ className: "items-center hover:shadow-custom py-16" }, { children: [_jsx(Icon, { tag: item.icon, color: item.color, className: "w-12 h-12 text-blue-600 mx-auto rounded-md hover:text-white bg-gray-50  hover:shadow-custom p-3" }, void 0), _jsx(Text, { className: "font-poppins text-base font-medium text-center truncate w-full text-gray-500 pt-4 px-2", as: "p", label: item.title }, void 0)] }), void 0) }), void 0) }), void 0) }), item.id));
                            }) }), void 0) }), void 0) }), void 0) }), void 0)] }, void 0));
};
export default SettingsIcon;
