import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab } from '@headlessui/react';
import { UserIcon, IdentificationIcon, KeyIcon, ArrowLeftIcon } from '@heroicons/react/solid';
import { classNames } from '../Utilities/Helpers';
import Users from './Users/Users';
import Roles from './Roles/Roles';
import RolePermission from './RolePermission/RolePermission';
import { Button, Icon, Parent, Text } from '../Component/index';
import ContainerSection from './ContainerSection/ContainerSection';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import { useHistory } from 'react-router-dom';
const UserTab = ({}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const tabs = [
        { id: 1, name: t('users'), href: '#', icon: UserIcon, content: Users },
        {
            id: 2,
            name: t('roles'),
            href: '#',
            icon: IdentificationIcon,
            content: Roles
        },
        {
            id: 3,
            name: t('role_permissions'),
            href: '#',
            icon: KeyIcon,
            content: RolePermission
        }
    ];
    return (_jsx(Parent, Object.assign({ className: "lg:px-12 md:px-6 pb-9 px-4" }, { children: _jsxs(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-3" }, { children: [_jsx(Button, Object.assign({ text: t('back'), className: "group inline-flex items-center mr-4 mb-2 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => history.push('/outlet-settings') }, { children: _jsx(Icon, { tag: ArrowLeftIcon, className: "w-4 h-4 text-blue-600 group-hover:text-white rounded-md mr-2" }, void 0) }), void 0), _jsxs(Tab.Group, { children: [_jsx(Tab.List, Object.assign({ className: "overflow-x-auto -mb-px flex space-x-8 border-b-2 border-gray-200", "aria-label": "Tabs" }, { children: tabs.map((item) => {
                                return (_jsx(Tab, { children: ({ selected }) => {
                                        return (_jsxs(Parent, Object.assign({ className: classNames(selected
                                                ? 'border-blue-600 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'), onClick: () => localStorage.setItem('scrollX', '') }, { children: [_jsx(Icon, { tag: item.icon, className: classNames(selected
                                                        ? 'text-blue-600'
                                                        : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5'), "aria-hidden": "true" }, void 0), _jsx(Text, { as: "span", label: item.name }, void 0)] }), item.id));
                                    } }, item.id));
                            }) }), void 0), _jsx(Tab.Panels, { children: tabs.map((item) => {
                                const Tag = item.content;
                                return (_jsx(Tab.Panel, { children: _jsx(Tag, {}, void 0) }, item.id));
                            }) }, void 0)] }, void 0)] }), void 0) }), void 0));
};
export default UserTab;
