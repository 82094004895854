import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import Users from '../Containers/UsersTab';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const User = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false, show: 'block' },
        {
            title: t('settings'),
            href: '#/settings',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: t('outlet_settings'),
            href: '#/outlet-settings',
            current: false,
            show: 'hidden sm:block'
        },
        { title: t('users'), href: '#/users', current: true, show: 'block' }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('users'), breadcrumbs: breadCrumbs }, void 0), _jsx(Users, {}, void 0)] }, void 0));
};
export default User;
