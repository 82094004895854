import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { t } from 'i18next';
import { imageHandlerPngJpg } from '../../Utilities/Helpers';
import { Text } from '../../Component';
import { SERVERURL } from '../../Utilities/Constants';
import { useEffect, useState } from 'react';
const UploadImage = () => {
    return (_jsxs("div", Object.assign({ className: "w-48 h-48 border-dashed rounded border-2 border-[#9CA3AF] flex flex-col items-center justify-center" }, { children: [_jsx("svg", Object.assign({ width: "44", height: "43", viewBox: "0 0 44 43", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M25.5832 7.16634H11.2498C10.2995 7.16634 9.38804 7.54387 8.71604 8.21587C8.04403 8.88788 7.6665 9.79932 7.6665 10.7497V28.6663M7.6665 28.6663V32.2497C7.6665 33.2 8.04403 34.1115 8.71604 34.7835C9.38804 35.4555 10.2995 35.833 11.2498 35.833H32.7498C33.7002 35.833 34.6116 35.4555 35.2836 34.7835C35.9556 34.1115 36.3332 33.2 36.3332 32.2497V25.083M7.6665 28.6663L15.8831 20.4498C16.5551 19.778 17.4663 19.4006 18.4165 19.4006C19.3667 19.4006 20.2779 19.778 20.9499 20.4498L25.5832 25.083M36.3332 17.9163V25.083M36.3332 25.083L33.4916 22.2414C32.8196 21.5697 31.9083 21.1923 30.9582 21.1923C30.008 21.1923 29.0967 21.5697 28.4248 22.2414L25.5832 25.083M25.5832 25.083L29.1665 28.6663M32.7498 7.16634H39.9165M36.3332 3.58301V10.7497M25.5832 14.333H25.6011", stroke: "#9CA3AF", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, void 0) }), void 0), _jsx("p", Object.assign({ className: "text-xs font-poppins font-semibold text-blue-600" }, { children: "Upload Image" }), void 0), _jsx("p", Object.assign({ className: "text-xs font-poppins font-semibold text-gray-600" }, { children: "or drag and drop" }), void 0), _jsx("p", Object.assign({ className: "text-xs font-poppins font-normal text-gray-400" }, { children: "PNG, JPG, up to 10MB" }), void 0), _jsx("input", { type: "file", id: "", name: "", className: "absolute inset-0 w-full h-full opacity-0 cursor-pointer", hidden: true }, void 0)] }), void 0));
};
export default UploadImage;
export const UploadImageCustom = ({ imageValue, setImageValue, imageErr, setImageErr, imageRequiredText, page }) => {
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsxs("div", Object.assign({ className: "relative w-52 h-52 border-dashed rounded-md border border-[#9CA3AF] flex flex-col items-center justify-center" }, { children: [imageValue && (_jsx("img", { className: "absolute rounded-md w-48 h-48 ", src: imageValue, alt: "" }, void 0)), imageValue === '' ? (_jsxs(_Fragment, { children: [_jsx("svg", Object.assign({ width: "44", height: "43", viewBox: "0 0 44 43", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M25.5832 7.16634H11.2498C10.2995 7.16634 9.38804 7.54387 8.71604 8.21587C8.04403 8.88788 7.6665 9.79932 7.6665 10.7497V28.6663M7.6665 28.6663V32.2497C7.6665 33.2 8.04403 34.1115 8.71604 34.7835C9.38804 35.4555 10.2995 35.833 11.2498 35.833H32.7498C33.7002 35.833 34.6116 35.4555 35.2836 34.7835C35.9556 34.1115 36.3332 33.2 36.3332 32.2497V25.083M7.6665 28.6663L15.8831 20.4498C16.5551 19.778 17.4663 19.4006 18.4165 19.4006C19.3667 19.4006 20.2779 19.778 20.9499 20.4498L25.5832 25.083M36.3332 17.9163V25.083M36.3332 25.083L33.4916 22.2414C32.8196 21.5697 31.9083 21.1923 30.9582 21.1923C30.008 21.1923 29.0967 21.5697 28.4248 22.2414L25.5832 25.083M25.5832 25.083L29.1665 28.6663M32.7498 7.16634H39.9165M36.3332 3.58301V10.7497M25.5832 14.333H25.6011", stroke: "#9CA3AF", strokeWidth: "2", strokeLinecap: "round", strokeLinejoin: "round" }, void 0) }), void 0), _jsx("p", Object.assign({ className: "text-sm font-poppins font-semibold text-blue-600" }, { children: "Upload Image" }), void 0), _jsx("p", Object.assign({ className: "text-sm font-poppins font-normal text-gray-500" }, { children: page === 'WeekendSpl'
                                        ? 'PNG, JPG, GIF, up to 1MB'
                                        : 'PNG, JPG, up to 1MB' }), void 0)] }, void 0)) : (_jsx(_Fragment, {}, void 0)), imageValue === undefined || imageValue === '' ? (_jsx("input", { type: "file", accept: page === 'WeekendSpl'
                                ? 'image/png,image/jpeg,image/gif'
                                : 'image/png,image/jpeg', onChange: (e) => {
                                imageHandlerPngJpg(e, setImageErr, setImageValue, undefined, page);
                            }, className: "absolute inset-0 w-full h-full opacity-0 cursor-pointer" }, void 0)) : (_jsxs("label", Object.assign({ htmlFor: "desktop-user-photo", style: { left: '7px', top: '6px' }, className: "absolute w-48 h-48 rounded-md bg-black bg-opacity-75 flex items-center justify-center text-sm font-medium text-white opacity-0 hover:opacity-100 focus-within:hidden" }, { children: [_jsx("span", { children: t('change') }, void 0), _jsx("input", { type: "file", onChange: (e) => {
                                        imageHandlerPngJpg(e, setImageErr, setImageValue);
                                    }, className: "absolute inset-0 w-full h-full opacity-0 cursor-pointer" }, void 0)] }), void 0))] }), void 0), imageErr && (_jsx(Text, { as: "p", className: "mt-1 leading-4 font-normal text-left text-xs text-red-600 max-w-xs1", label: 'PNG and JPG images are the only file types allowed, and the file size should be less than 1MB' }, void 0)), (imageValue === undefined || imageValue === '') && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: imageRequiredText }, void 0))] }, void 0) }, void 0));
};
export const UploadPdf = ({ imageValue, setImageValue, imageErr, setImageErr, imageRequiredText }) => {
    const [fileUrl, setFileUrl] = useState(null);
    const handleFileChange = (e) => {
        var _a;
        const file = ((_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0]) || null;
        if (file) {
            // Check if the file is a PDF
            if (file.type !== 'application/pdf') {
                setImageErr(true);
                setFileUrl(null);
                setImageValue(null);
                return;
            }
            // Check file size (7MB limit)
            if (file.size > 7 * 1024 * 1024) {
                setImageErr(true);
                setFileUrl(null);
                setImageValue(null);
                return;
            }
            // No errors, set file
            setImageErr(false);
            const url = URL.createObjectURL(file); // Create object URL for preview
            setFileUrl(url); // Save URL for preview
            setImageValue(file); // Save the file object
        }
        else {
            setImageErr(false);
            setFileUrl(null);
            setImageValue(null);
        }
    };
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "relative w-52 h-52 border-dashed rounded-md border border-[#9CA3AF] flex flex-col items-center justify-center" }, { children: [imageValue ? (_jsxs("a", Object.assign({ href: fileUrl !== null && fileUrl !== void 0 ? fileUrl : '#', target: "_blank", rel: "noopener noreferrer" }, { children: [_jsx("img", { className: "w-32 h-32 object-cover", src: require('../../assets/media/pdf.png'), alt: "PDF preview" }, void 0), _jsx("p", Object.assign({ className: "text-sm text-gray-500" }, { children: imageValue.name }), void 0)] }), void 0)) : (_jsxs(_Fragment, { children: [_jsx("svg", { width: "44", height: "43", viewBox: "0 0 44 43", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, void 0), _jsx("p", Object.assign({ className: "text-sm font-poppins font-semibold text-blue-600" }, { children: imageRequiredText }), void 0), _jsx("p", Object.assign({ className: "text-sm font-poppins font-normal text-gray-500" }, { children: "Pdf up to 7MB" }), void 0)] }, void 0)), _jsx("input", { type: "file", accept: "application/pdf", onChange: handleFileChange, className: "absolute inset-0 w-full h-full opacity-0 cursor-pointer" }, void 0)] }), void 0), imageErr && (_jsx("p", Object.assign({ className: "mt-1 leading-4 font-normal text-left text-xs text-red-600 max-w-xs1" }, { children: "Only Pdf files are allowed, and the file size should be less than 7MB" }), void 0))] }, void 0));
};
export const UploadImageCustomGif = ({ imageValue, setImageValue, imageErr, setImageErr }) => {
    const [previewUrl, setPreviewUrl] = useState(null);
    useEffect(() => {
        if (imageValue instanceof File) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.onerror = () => {
                setImageErr(true);
                setPreviewUrl(null);
            };
            reader.readAsDataURL(imageValue);
        }
        else if (typeof imageValue === 'string' && imageValue.trim()) {
            const completeUrl = `${SERVERURL}/${imageValue}`;
            setPreviewUrl(completeUrl);
        }
        else {
            setPreviewUrl(null);
        }
    }, [imageValue]);
    const handleFileChange = (e) => {
        var _a;
        const file = ((_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0]) || null;
        if (file) {
            const allowedExtensions = /(\.gif)$/i;
            if (!allowedExtensions.exec(file.name)) {
                setImageErr(true);
                setImageValue(null);
                setPreviewUrl(null);
                return;
            }
            if (file.size > 2 * 1024 * 1024) {
                // File size limit (1MB)
                setImageErr(true);
                setImageValue(null);
                setPreviewUrl(null);
                return;
            }
            setImageErr(false);
            setImageValue(file);
            // Create and set preview URL using FileReader
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.onerror = () => {
                setImageErr(true);
                setPreviewUrl(null);
            };
            reader.readAsDataURL(file);
            // Reset the file input value
            e.target.value = '';
        }
        else {
            setImageValue(null);
            setPreviewUrl(null);
        }
    };
    return (_jsxs("div", { children: [_jsxs("div", Object.assign({ className: "relative w-52 h-52 border-dashed rounded-md border border-[#9CA3AF] flex flex-col items-center justify-center" }, { children: [previewUrl ? (_jsx("img", { className: "absolute rounded-md w-48 h-48", src: previewUrl, alt: "Uploaded preview", onLoad: () => {
                            console.log('Image loaded successfully:', previewUrl);
                        }, onError: (e) => {
                            console.error('Image failed to load:', e);
                            setPreviewUrl(null); // Clear URL if there's an error
                            setImageErr(true);
                        } }, void 0)) : (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "text-sm font-poppins font-semibold text-blue-600" }, { children: "Upload Gif" }), void 0), _jsx("p", Object.assign({ className: "text-sm font-poppins font-normal text-gray-500" }, { children: "Gif up to 2MB" }), void 0)] }, void 0)), _jsx("input", { type: "file", accept: "image/*", onChange: handleFileChange, className: "absolute inset-0 opacity-0 cursor-pointer" }, void 0)] }), void 0), imageErr && (_jsx("p", Object.assign({ className: "mt-1 leading-4 font-normal text-left text-xs text-red-600 max-w-xs1" }, { children: "Only Gif files are allowed, and the file size should be less than 2MB" }), void 0))] }, void 0));
};
