/* eslint-disable react/prop-types */
/** @format */

import React, { useEffect, useState, useRef } from 'react';
import { classNames } from '../../Utilities/Helpers';
import { Table, Thead, Tbody, Tr, Td } from '../Table';
import TableHeader from '../../Containers/TableHeaders/TableHeader';
import OrderPrintBill from '../Bill/OrderPrintBill';
import { Button, Input, Parent, Text } from '..';
import { t } from 'i18next';
import apiCall from '../../../API/apiCall';
import { useReactToPrint } from 'react-to-print';

const MainOrders = ({
    tabId,
    person,
    columns,
    currencySymbol,
    status,
    editItem,
    getOrder,
    title,
    selectedPayType
}) => {
    const [edit, setEdit] = useState();
    const [paymentType, setPaymentType] = useState('');
    const [orderDetails, setOrderDetails] = useState([]);
    const [itemEdit, setItemEdit] = useState(editItem);
    const [tax, setTax] = useState(person.tax);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [total, setTotal] = useState(person.total);
    const [amount, setAmount] = useState(person.sub_total);
    const [selectedTab, setSelectedTab] = useState(tabId);
    const [taxArrays, setTaxArrays] = useState(person?.order_tax);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const editField = (id) => {
        setEdit(id);
    };
    const handleSubmit = (id) => {
        // Filter out the "Service Charge" tax if its tax_amount is zero
        const filteredTaxArrays = taxArrays.filter((tax) => {
            return !(tax.tax_name === 'Service Charge' && parseFloat(tax.tax_amount) === 0);
        });

        // Calculate the total tax amount with proper decimal precision
        const totalTaxAmount = taxArrays.reduce((accumulator, tax) => {
            return accumulator + parseFloat(tax.tax_amount);
        }, 0);

        const roundedTotalTaxAmount = roundToTwoDecimalPlaces(totalTaxAmount);

        const params = {
            ...person,
            id: id,
            tax: roundedTotalTaxAmount.toString(),
            service_charge_amt: serviceChargeAmt,
            mgnt_disc_amt: mgntDiscAmt,
            total: total,
            sub_total: amount,
            coupon_discount: discount,
            discount: discount,
            total_amount: total,
            order_tax: filteredTaxArrays,
            order_item: itemEdit,
            business_id: businessId,
            outlet_id: outletId,
            p_type: selectedPayType ? selectedPayType : person.p_type,
            transaction_no: '',
            other_payment: person.other_payment !== null ? person.other_payment : 'Gpay'
        };

        apiCall
            .PostServerCall('/api/v1/order/update_order', 'PUT', token, params)
            .then((response) => {
                if (response.status == 200) {
                    getOrder();
                }
            });
    };

    // Function to round a number to two decimal places
    function roundToTwoDecimalPlaces(value) {
        return Math.round(value * 100) / 100;
    }

    const handleEdit = (e, id) => {
        const { value } = e.target;
        const editValue = itemEdit?.map((item) => {
            if (id == item.item_id) {
                let val = item.quantity * value;
                return {
                    ...item,
                    is_combo: item.combo_id,
                    price: value,
                    total_price: parseFloat(val).toString(),
                    totalPrice: parseFloat(val).toString(),
                    p_type: person.p_type
                };
            } else {
                return {
                    ...item,
                    totalPrice: item.total_price,
                    is_combo: item.combo_id
                };
            }
        });
        setItemEdit(editValue);
        calTotal(editValue);
    };
    useEffect(() => {
        setItemEdit(editItem);
    }, [editItem]);

    const [serviceChargeAmt, setServiceChargeAmt] = useState(
        person.service_charge_amt ? parseFloat(person.service_charge_amt) : 0
    );
    const [mgntDiscAmt, setMgntDiscAmt] = useState(
        person.mgnt_disc_amt ? parseFloat(person.mgnt_disc_amt) : 0
    );
    const [mgntDiscPct, setMgntDiscPct] = useState(
        person.mgnt_disc_pct ? parseFloat(person.mgnt_disc_pct) : 0
    );
    const [discount, setDiscount] = useState(0);
    useEffect(() => {
        if (person && person.order_item) {
            calTotal(person.order_item);
        }
    }, [person, person.order_item]);
    // const calTotal = (item) => {
    //     let amount = 0;
    //     let discount = parseFloat(person.discount || 0);
    //     let manageDis = parseFloat(person.mgnt_disc_amt || 0);
    //     let manageDisPct = parseFloat(person.mgnt_disc_pct || 0);
    //     let miscellaneousTotal = parseFloat(person.miscellaneous || 0);
    //     let serviceChargePct = parseFloat(person.service_charge_pct || 0);
    //     let totalAmount = 0;
    //     let taxTotal = 0;

    //     // Calculate the amount based on item prices and quantities
    //     item.forEach((data) => {
    //         amount += parseFloat(data.total_price);
    //     });
    //     setAmount(amount);

    //     // Calculate the total tax and update tax amounts
    //     const taxValue = person.order_tax.map((tax) => {
    //         let individualTaxAmount = 0;
    //         if (tax.tax_name !== 'Service Charge') {
    //             individualTaxAmount = amount * (parseFloat(tax.tax_pct) / 100);
    //             taxTotal += individualTaxAmount;
    //         } else {
    //             taxTotal += parseFloat(tax.tax_amount);
    //         }

    //         return {
    //             ...tax,
    //             tax_amount: parseFloat(individualTaxAmount).toFixed(2).toString()
    //         };
    //     });

    //     if (taxValue?.length > 0) {
    //         setTaxArrays(taxValue);
    //     }

    //     // Calculate service charge amount based on percentage
    //     let serviceChargeAmt = 0;
    //     if (serviceChargePct > 0) {
    //         serviceChargeAmt = amount * (serviceChargePct / 100);
    //     }
    //     setServiceChargeAmt(serviceChargeAmt.toFixed(2));

    //     // Update management discount amount based on percentage or fixed amount
    //     let updatedManageDisAmt = 0;
    //     if (manageDisPct > 0) {
    //         updatedManageDisAmt = amount * (manageDisPct / 100);
    //     } else {
    //         updatedManageDisAmt = manageDis;
    //     }
    //     setMgntDiscAmt(updatedManageDisAmt.toFixed(2));

    //     // Calculate total amount including taxes, discounts, and miscellaneous
    //     totalAmount =
    //         amount +
    //         taxTotal +
    //         miscellaneousTotal +
    //         serviceChargeAmt -
    //         discount -
    //         updatedManageDisAmt;

    //     setTotal(totalAmount.toFixed(2));
    // };

    const calTotal = (item) => {
        let amount = 0;
        let manageDis = parseFloat(person.mgnt_disc_amt || 0);
        let manageDisPct = parseFloat(person.mgnt_disc_pct || 0);
        let serviceChargePct = parseFloat(person.service_charge_pct || 0);
        let miscellaneousTotal = parseFloat(person.miscellaneous || 0);
        let taxTotal = 0;
        let discount = parseFloat(person.discount || 0);

        // Calculate the amount based on item prices and quantities
        item.forEach((data) => {
            amount += parseFloat(data.total_price);
        });

        // Debugging logs
        console.log('Initial amount:', amount);
        console.log('manageDis:', manageDis);
        console.log('manageDisPct:', manageDisPct);

        // Calculate management discount amount
        let updatedManageDisAmt = 0;
        if (manageDisPct > 0) {
            updatedManageDisAmt = amount * (manageDisPct / 100);
        } else {
            updatedManageDisAmt = manageDis;
        }

        // Debugging log
        console.log('Updated Management Discount Amount:', updatedManageDisAmt);

        // Calculate the discount based on `disc_name`
        let discValue = parseFloat(person.disc_value || 0);
        let calculatedDiscount = 0;

        // Check if disc_name is "Fixed Amount"
        if (person.disc_name === 'Fixed Amount') {
            calculatedDiscount = discValue;
        } else {
            calculatedDiscount = (discValue / 100) * amount;
        }

        // Update discount with the calculated value
        setDiscount(calculatedDiscount.toFixed(2));

        // Subtract discount and management discount to get amount after discount
        const amountAfterDiscount = amount - calculatedDiscount - updatedManageDisAmt;

        // Calculate the total tax and update tax amounts
        const taxValue = person.order_tax.map((tax) => {
            let individualTaxAmount = 0;
            if (tax.tax_name !== 'Service Charge') {
                individualTaxAmount = amountAfterDiscount * (parseFloat(tax.tax_pct) / 100);
                taxTotal += individualTaxAmount;
            } else {
                taxTotal += parseFloat(tax.tax_amount);
            }

            return {
                ...tax,
                tax_amount: parseFloat(individualTaxAmount).toFixed(2).toString()
            };
        });

        if (taxValue?.length > 0) {
            setTaxArrays(taxValue);
        }

        // Calculate service charge amount based on percentage
        let serviceChargeAmt = 0;
        if (serviceChargePct > 0) {
            serviceChargeAmt = amountAfterDiscount * (serviceChargePct / 100);
        }
        setServiceChargeAmt(serviceChargeAmt.toFixed(2));

        // Final total amount calculation
        const totalAmount = amountAfterDiscount + taxTotal + serviceChargeAmt;

        setAmount(amount);
        setMgntDiscAmt(updatedManageDisAmt.toFixed(2));
        setTotal(totalAmount.toFixed(2));

        // Debugging output
        console.log('amountAfterDiscount', amountAfterDiscount);
        console.log('taxTotal', taxTotal);
        console.log('serviceChargeAmt', serviceChargeAmt);
        console.log('amount', amount);
        console.log('discount', discount);
        console.log('calculatedDiscount', calculatedDiscount);
    };

    const noPopup = function (target) {
        return new Promise(() => {
            const data = target.contentWindow.document.documentElement.outerHTML;
            const blob = new Blob([data], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
            window.electronAPI.printComponent(url, (responsey) => {});
        });
    };

    let componentRef = useRef(null);
    let popupHide = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;
    let popupShow = popupHide
        ? {
              pageStyle: ' @media print { @page {margin:20px 3px}}',
              content: () => componentRef.current,
              documentTitle: 'Order Report',
              print: noPopup
          }
        : {
              pageStyle: ' @media print { @page {margin:20px 3px}}',
              content: () => componentRef.current,
              documentTitle: 'Order Report'
          };

    const handlePrint = useReactToPrint(popupShow);

    const printOrder = (id, type) => {
        setPaymentType(type);
        singleOrderOne(id);
    };

    const singleOrderOne = async (id) => {
        var param = {
            id: id,
            business_id: businessId,
            outlet_id: outletId
        };
        await apiCall
            .GetServerCall(`api/v1/order/${id}`, 'GET', token, param)
            .then(async (response) => {
                setOrderDetails(response.data.data[0]);
                await handlePrint();
            })
            .catch((err) => {});
    };

    return (
        <Parent className="">
            <Parent className="mt-5 overflow-auto">
                <Table className="min-w-full divide-y ">
                    <Thead className="mb-5 ">
                        <TableHeader
                            scope="col"
                            className="px-3 py-6 text-left text-sm font-poppins rounded-t-md font-medium bg-blue-600 text-white"
                            columns={columns}
                        />
                    </Thead>
                    <Tbody className="bg-white ">
                        {itemEdit &&
                            itemEdit?.map((item, index) => {
                                return (
                                    <Tr
                                        key={item.id}
                                        className={classNames(index % 2 == 0 ? '' : 'bg-gray-100')}>
                                        <Td className="px-3 py-2.5 whitespace-nowrap w-1/4">
                                            <Parent className="flex items-center">
                                                <Parent className="text-xs font-poppins font-medium text-gray-700">
                                                    {item.prod_name}
                                                </Parent>
                                            </Parent>
                                        </Td>
                                        <Td className="px-3 py-2.5 whitespace-nowrap w-1/4">
                                            <Parent
                                                className={classNames(
                                                    status == 'kot' || selectedTab == 2
                                                        ? 'text-xs text-right font-poppins font-medium text-gray-700'
                                                        : 'text-xs font-poppins font-medium text-gray-700'
                                                )}>
                                                {item.quantity}
                                            </Parent>
                                        </Td>
                                        {status !== 'kot' && selectedTab !== 2 && (
                                            <>
                                                {title == 'Edit Order' ||
                                                title == 'Edit online Order' ? (
                                                    <Td className="px-3 py-2.5 whitespace-nowrap w-1/4">
                                                        {edit != item.item_id ? (
                                                            <Parent
                                                                className="text-xs font-poppins font-medium  text-right text-gray-700"
                                                                onClick={() =>
                                                                    editField(item.item_id)
                                                                }>
                                                                {item.price}
                                                            </Parent>
                                                        ) : (
                                                            <Input
                                                                type="number"
                                                                name="total"
                                                                className="text-xs font-poppins font-medium text-right  text-gray-700"
                                                                id="total"
                                                                onChange={(e) =>
                                                                    handleEdit(e, item.item_id)
                                                                }
                                                                value={item.price}
                                                            />
                                                        )}
                                                    </Td>
                                                ) : (
                                                    <Td className="px-3 py-2.5 whitespace-nowrap w-1/4">
                                                        <Parent className="text-xs font-poppins font-medium  text-right text-gray-700">
                                                            {item.price}
                                                        </Parent>
                                                    </Td>
                                                )}
                                                <Td className="px-3 py-2.5 whitespace-nowrap w-1/4">
                                                    <Parent>
                                                        <Parent className="text-xs font-poppins font-medium text-right  text-gray-700">
                                                            {parseFloat(item.total_price).toFixed(
                                                                2
                                                            )}
                                                        </Parent>
                                                    </Parent>
                                                </Td>
                                            </>
                                        )}
                                    </Tr>
                                );
                            })}
                    </Tbody>
                </Table>
                <Parent className="hidden">
                    <OrderPrintBill
                        ref={componentRef}
                        currency={currencySymbol}
                        details_outlet={orderDetails}
                        type={paymentType}
                    />
                </Parent>
            </Parent>
            <Parent className="flex">
                <Parent className="w-6/12"></Parent>
                {status !== 'kot' && selectedTab !== 2 ? (
                    <Parent className="flex flex-col sm:w-6/12">
                        {taxArrays
                            ?.filter((tax) => tax.tax_name !== 'Service Charge')
                            .map((tax) => {
                                return (
                                    <>
                                        <Parent className="flex ">
                                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                                <Text
                                                    as="p"
                                                    className="font-poppins font-medium text-sm text-gray-700">
                                                    {`${tax.tax_name} ${tax.tax_pct}(%)`}
                                                </Text>
                                            </Parent>
                                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                                <Text
                                                    as="p"
                                                    className="text-sm font-poppins text-right font-medium text-gray-400">
                                                    {` (+) ${currencySymbol} ${parseFloat(
                                                        tax.tax_amount
                                                    ).toFixed(2)}`}
                                                </Text>
                                            </Parent>
                                        </Parent>
                                    </>
                                );
                            })}
                        <Parent className="flex ">
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="font-poppins font-medium text-sm text-gray-700">
                                    {`Service Charge ${
                                        person.service_charge_pct !== null
                                            ? parseFloat(person.service_charge_pct).toFixed()
                                            : '0'
                                    } (%)`}
                                </Text>
                            </Parent>
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="text-sm font-poppins text-right font-medium text-gray-400">
                                    {`(+) ${currencySymbol} ${
                                        isNaN(serviceChargeAmt) ||
                                        serviceChargeAmt === null ||
                                        serviceChargeAmt === undefined
                                            ? '0.00'
                                            : parseFloat(serviceChargeAmt).toFixed(2)
                                    }`}
                                </Text>
                            </Parent>
                        </Parent>

                        {status !== 'kot' && (
                            <>
                                <Parent className="flex ">
                                    <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                        <Text
                                            as="p"
                                            className="font-poppins font-medium  text-sm text-gray-700">
                                            {`${t('miscellaneous')} `}
                                        </Text>
                                    </Parent>
                                    <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                        <Text
                                            as="p"
                                            className="text-sm font-poppins text-right font-medium text-gray-400">
                                            {` (+) ${currencySymbol} ${
                                                person.miscellaneous ? person.miscellaneous : '0.00'
                                            }`}
                                        </Text>
                                    </Parent>
                                </Parent>
                            </>
                        )}
                        <Parent className="flex ">
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="font-poppins font-medium text-sm  text-gray-700">
                                    {`${t('coupon_loyalty')} `}
                                </Text>
                            </Parent>
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="text-sm font-poppins text-right font-medium text-gray-400">
                                    {`(-) ${currencySymbol} ${
                                        person.coupon_code
                                            ? parseFloat(discount).toFixed(2)
                                            : parseFloat(person.redeem_value || 0).toFixed(2)
                                    }`}
                                </Text>
                            </Parent>
                        </Parent>
                        <Parent className="flex ">
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="font-poppins font-medium text-sm text-gray-700">
                                    {`Discount ${parseFloat(mgntDiscPct).toFixed()} (%)`}
                                </Text>
                            </Parent>
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="text-sm font-poppins text-right font-medium text-gray-400">
                                    {`(-) ${currencySymbol} ${
                                        mgntDiscAmt ? Number(mgntDiscAmt).toFixed(2) : '0.00'
                                    }`}
                                </Text>
                            </Parent>
                        </Parent>
                        <Parent className="flex ">
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="font-poppins font-medium  text-sm text-gray-700">
                                    {`${t('tips')} `}
                                </Text>
                            </Parent>

                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="text-sm font-poppins text-right font-medium text-gray-400">
                                    {`  ${currencySymbol} ${
                                        person.tip_amount ? person.tip_amount : '0.00'
                                    }`}
                                </Text>
                            </Parent>
                        </Parent>

                        <Parent className="flex ">
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="font-poppins font-medium text-sm  text-gray-700">
                                    {`${t('total')} `}
                                </Text>
                            </Parent>
                            <Parent className="px-3 py-2.5 whitespace-nowrap w-3/6">
                                <Text
                                    as="p"
                                    className="text-sm font-poppins  text-right font-medium text-gray-400">
                                    {`  ${currencySymbol}
            ${total}`}
                                </Text>
                            </Parent>
                        </Parent>
                    </Parent>
                ) : (
                    ''
                )}
            </Parent>
            {title ? (
                <Parent className="flex justify-end items-center pt-5">
                    <Button
                        onClick={() => handleSubmit(person.id)}
                        text={t('update')}
                        className="inline-flex justify-center items-center mr-4 px-4 py-1.5 border-2 border-blue-600 shadow-custom text-base font-poppins font-medium rounded-md text-white bg-blue-600 "
                    />
                </Parent>
            ) : (
                person.status !== 'Draft' &&
                status !== 'kot' &&
                selectedTab !== 2 && (
                    <Parent className="flex items-center justify-end">
                        <Parent className="items-center justify-center flex">
                            <Button
                                text={t('print')}
                                className=" bg-blue-600 flex h-9 rounded-md items-center text-xs text-white px-5 py-3 w-full font-poppins font-semibold justify-center"
                                onClick={() => printOrder(person.id, person.pay_type)}></Button>
                        </Parent>
                    </Parent>
                )
            )}
        </Parent>
    );
};

export default MainOrders;
