var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Parent, Nav, Text, Link, Select } from '../../Component';
import { classNames } from '../../Utilities/Helpers';
import { PAGESIZE } from '../../Utilities/Constants';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
const ServerSidePagination = ({ pageLimit, dataLimit, setDataLimit, getPaginatedData, setCurrentPageNumber, startIndex, endIndex, total, from, totalData, offset, setOffset, getItem, currentPage, setCurrentPage, strt, setStrt, end, setEnd }) => {
    const { t } = useTranslation();
    const [optionItems, setOptionItems] = useState(PAGESIZE);
    const [allData, setAllData] = useState(totalData);
    const [pages, setPages] = useState(Math.ceil((allData === null || allData === void 0 ? void 0 : allData.length) / dataLimit) == 0 ? 1 : Math.ceil((allData === null || allData === void 0 ? void 0 : allData.length) / dataLimit));
    const pagess = [];
    for (let i = 1; i <= Math.ceil(total / dataLimit); i++) {
        pagess.push(i);
    }
    const changePage = (event) => {
        setCurrentPage(Number(event.target.id));
        from == 'item' && setCurrentPageNumber(Number(event.target.id));
        getPaginatedData(Number(event.target.id));
    };
    const indexOfLastItem = currentPage * dataLimit;
    const indexOfFirstItem = indexOfLastItem - dataLimit;
    const renderPageNumbers = pagess.map((number) => {
        if (number < end + 1 && number > strt) {
            return (_jsx("li", Object.assign({ id: number, onClick: (e) => changePage(e), className: classNames(currentPage === number
                    ? 'bg-blue-50 border-blue-600 text-blue-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-blue-50 hover:text-blue-600', 'z-10 relative cursor-pointer inline-flex items-center px-3 py-1 border font-poppins text-sm font-medium') }, { children: number }), number));
        }
        else {
            return null;
        }
    });
    const goToNextPage = () => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPage(currentPage + 1);
        from == 'item' && setCurrentPageNumber(currentPage + 1);
        if (currentPage + 1 > end) {
            setEnd(end + pageLimit);
            setStrt(strt + pageLimit);
        }
        getPaginatedData(currentPage + 1);
    });
    const goToPreviousPage = () => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPage(currentPage - 1);
        from == 'item' && setCurrentPageNumber(currentPage - 1);
        if ((currentPage - 1) % pageLimit == 0) {
            setEnd(end - pageLimit);
            setStrt(strt - pageLimit);
        }
        getPaginatedData(currentPage - 1);
    });
    const dataLimitChange = (event) => {
        setPages(Math.ceil((allData === null || allData === void 0 ? void 0 : allData.length) / parseInt(event.target.value)) == 0
            ? 1
            : Math.ceil((allData === null || allData === void 0 ? void 0 : allData.length) / parseInt(event.target.value)));
        setDataLimit(parseInt(event.target.value));
        setCurrentPage(1);
        from == 'item' && setCurrentPageNumber(1);
        setStrt(0);
        setEnd(5);
    };
    return (_jsxs(Parent, Object.assign({ className: "bg-white  pt-6 flex items-center justify-between pb-3" }, { children: [_jsxs(Parent, Object.assign({ className: "flex-1 flex justify-between sm:hidden" }, { children: [_jsx(Link, Object.assign({ onClick: goToPreviousPage, className: classNames(currentPage == pagess[0] ? 'disabled text-gray-300' : ' text-gray-700', 'relative inline-flex items-center px-2 py-1 cursor-pointer rounded-md border border-gray-300 bg-white text-sm font-poppins font-regular  hover:bg-blue-600 hover:text-white') }, { children: t('previous') }), void 0), _jsx(Link, Object.assign({ onClick: goToNextPage, className: classNames(currentPage == pagess[pagess.length - 1]
                            ? 'disabled text-gray-300'
                            : ' text-gray-700', 'relative inline-flex cursor-pointer items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-roboto font-normal text-gray-300 hover:bg-blue-600 hover:text-white') }, { children: t('next') }), void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: "hidden sm:flex-1 sm:flex sm:items-center sm:justify-between" }, { children: [_jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsxs(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal text-gray-700 flex items-center mr-6" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "mr-3" }, { children: t('page_size') }), void 0), _jsx(Select, { value: dataLimit, onChange: dataLimitChange, optionItems: optionItems, name: "pagination", id: "pagination", className: "mt-1 cursor-pointer ml-auto block shadow-sm font-poppins font-normal px-4 pr-10 py-2 text-gray-700 text-sm border-gray-300 focus:outline-none rounded-md" }, void 0)] }), void 0), _jsxs(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal text-gray-700" }, { children: [t('showing'), ' ', _jsx(Text, Object.assign({ as: "span", className: "font-normal" }, { children: startIndex }), void 0), ' ', t('to'), ' ', _jsx(Text, Object.assign({ as: "span", className: "font-normal" }, { children: endIndex }), void 0), ' ', t('of'), ' ', _jsx(Text, Object.assign({ as: "span", className: "font-normal" }, { children: total }), void 0), ' ', t('results')] }), void 0)] }), void 0), _jsx(Parent, { children: _jsxs(Nav, Object.assign({ className: "relative z-0 inline-flex rounded-md shadow-sm -space-x-px", "aria-label": "Pagination" }, { children: [_jsxs(Link, Object.assign({ onClick: goToPreviousPage, className: classNames(currentPage == pagess[0]
                                        ? 'disabled text-gray-300'
                                        : ' text-gray-700', 'relative inline-flex items-center px-2 py-1 cursor-pointer rounded-l-md border border-gray-300 bg-white text-sm font-poppins font-regular  hover:bg-blue-600 hover:text-white') }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Previous" }), void 0), _jsx(ChevronLeftIcon, { className: "h-5 w-5", "aria-hidden": "true" }, void 0)] }), void 0), renderPageNumbers, _jsxs(Link, Object.assign({ onClick: goToNextPage, className: classNames(currentPage == pagess[pagess.length - 1]
                                        ? 'disabled text-gray-300'
                                        : ' text-gray-700', 'relative inline-flex cursor-pointer items-center px-2 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-roboto font-normal text-gray-300 hover:bg-blue-600 hover:text-white') }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Next" }), void 0), _jsx(ChevronRightIcon, { className: "h-5 w-5", "aria-hidden": "true" }, void 0)] }), void 0)] }), void 0) }, void 0)] }), void 0)] }), void 0));
};
export default ServerSidePagination;
