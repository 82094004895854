import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import React from 'react';
import { Icon } from '..';
import '../../../translations/i18n';
import posIcon from '../../assets/Icons/posIcon';
const PrintBill = React.forwardRef((props, ref) => {
    var _a, _b, _c, _d, _e;
    return (_jsx("div", Object.assign({ ref: ref, style: {
            margin: '0',
            padding: '0',
            fontFamily: 'Poppins sans-serif'
        }, className: "printBillContainer" }, { children: _jsxs("div", Object.assign({ style: {
                backgroundColor: 'white',
                width: '100%',
                fontSize: '9px',
                paddingTop: '15px'
            } }, { children: [_jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: ' 0 auto',
                        borderSpacing: '0',
                        paddingTop: '15px',
                        paddingBottom: '15px'
                    } }, { children: _jsx("tr", { children: _jsx("td", Object.assign({ style: { width: '100%' } }, { children: _jsx(Icon, { tag: posIcon, className: 'w-4 h-4' }, void 0) }), void 0) }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: '0 auto',
                        paddingBottom: '12px',
                        borderSpacing: '0',
                        paddingTop: '10px'
                    } }, { children: _jsxs("tr", { children: [_jsxs("th", Object.assign({ style: {
                                    width: '50%',
                                    textAlign: 'left',
                                    fontSize: '18px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins , sans-serif',
                                    color: '#000000'
                                } }, { children: [_jsx("span", Object.assign({ style: {
                                            color: '#5850ff',
                                            fontSize: '18px',
                                            lineHeight: '21px',
                                            fontWeight: 'bold',
                                            paddingRight: '2px',
                                            fontFamily: 'Poppins , sans-serif'
                                        } }, { children: "Sales Summary" }), void 0), "Report"] }), void 0), _jsx("td", Object.assign({ style: {
                                    textAlign: 'right',
                                    width: ' 50%',
                                    fontSize: '16px',
                                    lineHeight: ' 21px',
                                    fontWeight: 'bold',
                                    color: ' #8c8c8c',
                                    fontFamily: 'Poppins, sans-serif'
                                } }, { children: props.date }), void 0)] }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: ' 90%',
                        margin: ' 0 auto',
                        paddingBottom: '30px',
                        borderSpacing: '0',
                        paddingTop: '20px'
                    } }, { children: _jsx("tr", { children: _jsx("td", Object.assign({ style: {
                                width: '100%',
                                textAlign: 'left',
                                fontSize: '14px',
                                lineHeight: ' 21px',
                                fontWeight: 'normal',
                                fontFamily: 'Poppins, sans-serif',
                                color: ' #000000',
                                paddingTop: '15px',
                                paddingBottom: '10px'
                            } }, { children: "The Sales summary report provides detailed insight from daily sales,expenses,tax and payments(Cash, Cards and UPI etc.)" }), void 0) }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: ' 90%',
                        margin: ' 0 auto',
                        borderSpacing: '0'
                    } }, { children: _jsxs("tr", { children: [_jsx("td", Object.assign({ style: { verticalAlign: 'top', width: '50%', paddingTop: '15px' } }, { children: _jsxs("table", Object.assign({ "cell-spacing": "0", "cell-padding": "0", style: { width: '100%', borderSpacing: '0', border: '0' } }, { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        lineHeight: '18px',
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        color: '#ffffff',
                                                        background: '#4a43df',
                                                        padding: '15px 0'
                                                    } }, { children: "Report" }), void 0), _jsx("td", { style: {
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        color: '#ffffff',
                                                        background: '#4a43df',
                                                        padding: '15px 0'
                                                    } }, void 0), _jsx("td", { style: {
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        fontWeight: 'bold',
                                                        width: '100%',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        color: '#ffffff',
                                                        background: '#4a43df',
                                                        padding: '15px 0'
                                                    } }, void 0)] }, void 0), (_a = props === null || props === void 0 ? void 0 : props.dataOne) === null || _a === void 0 ? void 0 : _a.map((item) => {
                                            return (_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                                            width: '60%',
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'left',
                                                            fontSize: '12px',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            lineHeight: '15px',
                                                            color: '#000000',
                                                            padding: '12px 0 12px 24px'
                                                        } }, { children: item.name }), void 0), _jsx("td", Object.assign({ style: {
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'right',
                                                            fontSize: '12px',
                                                            lineHeight: '15px',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            color: '#000000',
                                                            padding: '12px 24px 12px 0'
                                                        } }, { children: item.stat }), void 0)] }, void 0));
                                        }), (_b = props === null || props === void 0 ? void 0 : props.dataFour) === null || _b === void 0 ? void 0 : _b.map((item) => {
                                            return (_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                                            width: '50%',
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'left',
                                                            fontSize: '12px',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            lineHeight: '15px',
                                                            color: '#000000',
                                                            padding: '12px 0 12px 24px'
                                                        } }, { children: item.name }), void 0), _jsx("td", Object.assign({ style: {
                                                            width: '50%',
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'right',
                                                            fontSize: '12px',
                                                            lineHeight: '15px',
                                                            color: '#000000',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            padding: '12px 24px 12px 0'
                                                        } }, { children: `${item.stat}` }), void 0)] }, void 0));
                                        })] }), void 0) }), void 0), _jsx("td", Object.assign({ style: { verticalAlign: 'top', width: '50%', paddingTop: '15px' } }, { children: _jsxs("table", Object.assign({ "cell-spacing": "0", "cell-padding": "0", style: { width: '100%', borderSpacing: '0', border: '0' } }, { children: [_jsxs("tr", { children: [_jsx("td", Object.assign({ colSpan: 2, style: {
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        width: '100%',
                                                        fontWeight: 'bold',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        color: '#ffffff',
                                                        background: ' #5850ff',
                                                        padding: ' 15px 0'
                                                    } }, { children: "Amount" }), void 0), _jsx("td", { style: {
                                                        textAlign: 'center',
                                                        fontSize: '12px',
                                                        lineHeight: '18px',
                                                        width: '100%',
                                                        fontWeight: 'bold',
                                                        fontFamily: 'Poppins, sans-serif',
                                                        color: '#ffffff',
                                                        background: ' #5850ff',
                                                        padding: ' 15px 0'
                                                    } }, void 0)] }, void 0), (_c = props === null || props === void 0 ? void 0 : props.dataTwo) === null || _c === void 0 ? void 0 : _c.map((item) => {
                                            return (_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                                            width: '50%',
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'left',
                                                            fontSize: '12px',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            lineHeight: '15px',
                                                            color: '#000000',
                                                            padding: '12px 0 12px 24px'
                                                        } }, { children: item.name }), void 0), _jsx("td", Object.assign({ style: {
                                                            width: '50%',
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'right',
                                                            fontSize: '12px',
                                                            lineHeight: '15px',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            color: '#000000',
                                                            padding: '12px 24px 12px 0'
                                                        } }, { children: ` ${item.stat}` }), void 0)] }, void 0));
                                        }), (_d = props === null || props === void 0 ? void 0 : props.dataThree) === null || _d === void 0 ? void 0 : _d.map((item) => {
                                            return (_jsxs("tr", { children: [_jsx("td", Object.assign({ style: {
                                                            width: '50%',
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'left',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            lineHeight: '15px',
                                                            fontSize: '12px',
                                                            color: '#000000',
                                                            padding: '12px 0 12px 24px'
                                                        } }, { children: item.name }), void 0), _jsx("td", Object.assign({ style: {
                                                            width: '50%',
                                                            fontWeight: 'normal',
                                                            paddingBottom: '10px',
                                                            textAlign: 'right',
                                                            fontSize: '12px',
                                                            lineHeight: '15px',
                                                            fontFamily: 'Poppins, sans-serif',
                                                            color: '#000000',
                                                            padding: '12px 24px 12px 0'
                                                        } }, { children: `${item.stat}` }), void 0)] }, void 0));
                                        })] }), void 0) }), void 0)] }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: ' 90%',
                        margin: ' 0 auto',
                        borderSpacing: ' 0',
                        marginTop: '35px'
                    } }, { children: _jsxs("tr", { children: [_jsx("td", { style: {
                                    width: '15%',
                                    height: '25px',
                                    background: '#00b179'
                                } }, void 0), _jsx("td", { style: {
                                    width: '14%',
                                    height: '25px',
                                    background: ' #2155e1'
                                } }, void 0), _jsx("td", { style: {
                                    width: '14%',
                                    height: '25px',
                                    background: '#0086a6'
                                } }, void 0), _jsx("td", { style: {
                                    width: '14%',
                                    height: '25px',
                                    background: '#f5942c'
                                } }, void 0), _jsx("td", { style: {
                                    width: '14%',
                                    height: '25px',
                                    background: '#814dee'
                                } }, void 0), _jsx("td", { style: {
                                    width: '14%',
                                    height: '25px',
                                    background: '#7fccf0'
                                } }, void 0), _jsx("td", { style: {
                                    width: '15%',
                                    height: '25px',
                                    background: '#2b419b'
                                } }, void 0)] }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: '0 auto',
                        borderSpacing: ' 0',
                        marginTop: '35px'
                    } }, { children: _jsxs("tr", { children: [_jsx("th", { style: {
                                    width: ' 4px',
                                    height: '16px',
                                    background: '#00b179'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'Cash') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: '4px',
                                    height: '16px',
                                    background: '#2155e1'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'Card') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: '4px',
                                    height: ' 16px',
                                    background: '#0086a6'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'UPI') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: ' 4px',
                                    height: '16px',
                                    background: '#f5942c'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'Tax') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: '4px',
                                    height: '16px',
                                    background: '#814dee'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'Manager discount') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px',
                                            whiteSpace: 'nowrap'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            })] }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: '0 auto',
                        borderSpacing: ' 0',
                        marginTop: '35px'
                    } }, { children: _jsxs("tr", { children: [_jsx("th", { style: {
                                    width: ' 4px',
                                    height: '16px',
                                    background: '#814dee'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'Expenses') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: '4px',
                                    height: '16px',
                                    background: '#8203'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'Service Charge') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px',
                                            whiteSpace: 'nowrap'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: '4px',
                                    height: ' 16px',
                                    background: '#EF4444'
                                } }, void 0), props.dataTwo.map((item) => {
                                if (item.name == 'Loyolty/Coupon') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px',
                                            whiteSpace: 'nowrap'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `${item.name} ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: ' 4px',
                                    height: '16px',
                                    background: '#7fccf0'
                                } }, void 0), props.dataThree.map((item) => {
                                if (item.name == 'Miscellaneous Amount') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px',
                                            whiteSpace: 'nowrap'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `Miscellaneous ${item.percentage}%`] }), void 0));
                                }
                            }), _jsx("th", { style: {
                                    width: '4px',
                                    height: '16px',
                                    background: '#2b419b'
                                } }, void 0), (_e = props.dataThree) === null || _e === void 0 ? void 0 : _e.map((item) => {
                                if (item.name == 'Tip Amount') {
                                    return (_jsxs("td", Object.assign({ style: {
                                            fontSize: '12px',
                                            lineHeight: ' 15px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Poppins, sans-serif',
                                            color: '#000000',
                                            width: '100px'
                                        } }, { children: [_jsx("span", { style: { marginRight: '5px' } }, void 0), `Tip ${item.percentage}%`] }), void 0));
                                }
                            })] }, void 0) }), void 0)] }), void 0) }), void 0));
});
PrintBill.displayName = 'printBill'; //to clear the ESLint warning.
export default PrintBill;
