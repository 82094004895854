import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-key */
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Text, Input, Select, Button } from '..';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const OrderFilter = ({ setOpen, title, changeInp, startTimeFill, setStartTimeFill, closeTimeFill, setCloseTimeFill, filterOrderData, setFilterOrderData, filter_id, setFilter_id, setOrderStatus, setSelectOrderType, statusId, setStatusId, orderId, setOrderId, setKotOrderStatus, tabId, setPayType }) => {
    const { t } = useTranslation();
    const orderstatus = [
        {
            id: 1,
            name: t('settled'),
            value: ''
        },
        {
            id: 2,
            name: t('returned'),
            value: ''
        },
        {
            id: 3,
            name: t('cancelled'),
            value: ''
        },
        {
            id: 4,
            name: t('in_progress'),
            value: ''
        }
    ];
    const kotOrderstatus = [
        {
            id: 1,
            name: t('queue'),
            value: ''
        },
        {
            id: 2,
            name: t('preparing'),
            value: ''
        },
        {
            id: 3,
            name: t('ready'),
            value: ''
        }
    ];
    const orderType = [
        {
            id: 1,
            name: t('cash'),
            value: '2'
        },
        {
            id: 2,
            name: t('card'),
            value: '1'
        },
        {
            id: 3,
            name: t('upi'),
            value: '0'
        }
    ];
    const filterBy = [
        {
            id: 1,
            label: 'Select',
            value: 'select'
        },
        {
            id: 2,
            label: t('order_no'),
            value: 'order_no'
        },
        {
            id: 3,
            label: t('select_status'),
            value: 'status'
        },
        {
            id: 4,
            label: t('select_payment_type'),
            value: 'pay_type'
        },
        {
            id: 5,
            label: t('select_date_range'),
            value: 'date'
        }
    ];
    const filterByOne = [
        {
            id: 1,
            label: 'Select',
            value: 'select'
        },
        {
            id: 2,
            label: t('order_no'),
            value: 'order_no'
        },
        {
            id: 3,
            label: t('select_status'),
            value: 'status'
        },
        {
            id: 5,
            label: t('select_date_range'),
            value: 'date'
        }
    ];
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [filArr, setFilArr] = useState([]);
    const reset = () => {
        setFilter_id('select');
        setStatusId(1);
        setStartTimeFill(new Date());
        setCloseTimeFill(new Date());
        changeInp('select');
        setFilterOrderData('');
        setOpen(false);
        setOrderId(1);
    };
    const [selectedStatus, setSelectedStatus] = useState('');
    const filFun = () => {
        var _a, _b;
        const selectedFilterOption = ((_a = filterBy.find((item) => item.value === filter_id)) === null || _a === void 0 ? void 0 : _a.value) || '';
        const selectedFilterOptionOne = ((_b = filterByOne.find((item) => item.value === filter_id)) === null || _b === void 0 ? void 0 : _b.value) || '';
        let enteredValue = '';
        let startDate = '';
        let endDate = '';
        if (filter_id === 'status') {
            const selectedStatusElement = document.querySelector('input[name="select_status"]:checked');
            if (selectedStatusElement) {
                enteredValue = selectedStatusElement.value;
            }
            else {
                console.error('No status selected');
                return;
            }
            switch (enteredValue) {
                case 'Cancelled':
                    enteredValue = 'Cancelled';
                    break;
                case 'Settled':
                    enteredValue = 'Settled';
                    break;
                case 'Returned':
                    enteredValue = 'Returned';
                    break;
                case 'In Progress':
                    enteredValue = 'Draft';
                    break;
                case 'Queue':
                    enteredValue = '2';
                    break;
                case 'Preparing':
                    enteredValue = '3';
                    break;
                case 'Ready':
                    enteredValue = '4';
                    break;
                default:
                    console.error('Invalid status');
                    return;
            }
        }
        else if (filter_id === 'order_no') {
            enteredValue = filterOrderData;
        }
        else if (filter_id === 'pay_type') {
            const selectedPayTypeElement = document.querySelector('input[name="pay_type"]:checked');
            if (selectedPayTypeElement) {
                enteredValue = selectedPayTypeElement.value;
            }
            else {
                console.error('No payment type selected');
                return;
            }
        }
        else if (filter_id === 'date') {
            startDate = startTimeFill;
            endDate = closeTimeFill;
            if (!startDate || !endDate) {
                console.error('Date range not selected');
                return;
            }
        }
        else if (filter_id === 'new_filter') {
            const selectedNewFilterElement = document.querySelector('input[name="new_filter"]:checked');
            if (selectedNewFilterElement) {
                enteredValue = selectedNewFilterElement.value;
            }
            else {
                console.error('No new filter selected');
                return;
            }
        }
        changeInp(filter_id, selectedFilterOption || selectedFilterOptionOne, enteredValue, startDate, endDate);
    };
    const handleStatusSelection = (statusValue) => {
        setSelectedStatus(statusValue);
    };
    const [selectedPayType, setSelectedPayType] = useState('');
    const handlePayTypeSelection = (payTypeValue) => {
        setSelectedPayType(payTypeValue);
    };
    const statusData = (value) => {
        setOrderStatus(value.name);
        setStatusId(value.id);
        setKotOrderStatus(value.value);
    };
    const orderData = (value) => {
        setPayType(value.name);
        setSelectOrderType(value.value);
        setOrderId(value.id);
    };
    useEffect(() => {
        tabId == 1 ? setFilArr(orderstatus) : setFilArr(kotOrderstatus);
    }, [tabId]);
    return (_jsx(Parent, { children: _jsxs(Parent, { children: [_jsx(Parent, { children: _jsxs(Parent, Object.assign({ className: " pb-4 w-full" }, { children: [_jsxs(Parent, Object.assign({ className: "flex gap-4 " }, { children: [_jsx(Select, { optionItems: title == 'KOT' ? filterByOne : filterBy, name: "cat_id", id: "cat_id", className: classNames(businessType == 'Retail'
                                            ? 'focus:ring-blue-500 focus:border-blue-500'
                                            : 'focus:ring-blue-500 focus:border-blue-500', ' py-3  w-full block font-poppins font-normal text-gray-600 text-sm leading-none sm:text-xs border-gray-200 rounded-md'), value: filter_id, onChange: (e) => setFilter_id(e.target.value) }, void 0), _jsx(Button, { text: t('filter'), className: classNames(businessType == 'Retail' ? 'bg-blue-600' : 'bg-blue-600', 'inline-flex justify-center items-center  px-4 py-2 border border-transparent shadow-custom text-sm font-poppins font-medium rounded-md text-white hover:text-white hover:bg-blur-600 focus:outline-none'), onClick: () => filFun() }, void 0), _jsx(Button, { text: t('reset'), onClick: () => reset(), className: classNames(businessType == 'Retail' ? 'bg-blue-600' : 'bg-blue-600', 'inline-flex justify-center items-center  px-4 py-2 border border-transparent shadow-custom text-sm font-poppins font-medium rounded-md text-white hover:text-white hover:bg-blur-600 focus:outline-none') }, void 0)] }), void 0), filter_id == 'order_no' ? (_jsx(Parent, Object.assign({ className: "flex justify-between mt-2 w-full pt-4" }, { children: _jsx(Input, { type: "text", name: "category_name", id: "category_name", className: classNames(businessType == 'Retail'
                                        ? 'focus:ring-blue-600 focus:border-blue-600'
                                        : 'focus:ring-blue-600 focus:border-blue-600', ' py-2.5 shadow-sm w-60 block font-poppins text-gray-400 placeholder:text-gray-300 text-sm leading-none sm:text-sm border-gray-200 rounded-md '), labelClass: "font-poppins font-medium text-gray-900", labelText: t('order_no'), labelRequired: true, onChange: (e) => setFilterOrderData(e.target.value), value: filterOrderData }, void 0) }), void 0)) : ('')] }), void 0) }, void 0), filter_id === 'status' && (_jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "flex items-center pt-4" }, { children: _jsx(Text, { as: "h2", className: "font-poppins font-medium text-sm text-blue-600", label: t('select_status') }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "grid gap-4 mt-4 grid-cols-2" }, { children: filArr === null || filArr === void 0 ? void 0 : filArr.map((person) => (_jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Input, { id: "", name: "select_status", type: "radio", value: person.name, checked: person.id === statusId, className: classNames(businessType === 'Retail'
                                            ? 'text-blue-600'
                                            : 'text-blue-600', 'focus:ring-transparent mt-0 h-4 w-4 border-gray-300'), onChange: () => statusData(person) }, void 0), _jsx(Text, { as: "label", className: "ml-3 block text-sm font-poppins pr-10 font-medium text-gray-700", label: person.name }, void 0)] }), person.id))) }), void 0)] }, void 0)), filter_id == 'pay_type' && (_jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "flex items-center pt-4" }, { children: _jsx(Text, { as: "h2", className: "font-poppins font-medium text-sm text-blue-600", label: t('select_payment_type') }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "grid gap-4 mt-4 grid-cols-2" }, { children: orderType.map((item) => (_jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Input, { name: "pay_type", type: "radio", value: item.name, checked: item.name === selectedPayType, className: classNames(businessType === 'Retail'
                                            ? 'text-blue-600'
                                            : 'text-blue-600', 'focus:ring-transparent mt-0 h-4 w-4 border-gray-300'), id: "", onChange: () => handlePayTypeSelection(item.name) }, void 0), _jsx(Text, Object.assign({ as: "label", className: "ml-3 block text-sm font-poppins pr-10 font-medium text-gray-700" }, { children: item.name }), void 0)] }), item.id))) }), void 0)] }, void 0)), filter_id == 'date' && (_jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "flex items-center pt-4" }, { children: _jsx(Text, { as: "h2", className: "font-poppins font-medium text-sm text-blue-600", label: t('select_date_range') }, void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "flex gap-8" }, { children: [_jsxs(Parent, { children: [_jsx(Text, { as: "p", className: "font-poppins font-medium text-sm text-gray-700 pt-4", label: t('start_date') }, void 0), _jsx(DatePicker, { selected: startTimeFill, onChange: (date) => setStartTimeFill(date), maxDate: new Date(), className: classNames(businessType === 'Retail'
                                                ? 'focus:ring-blue-600 focus:border-blue-600'
                                                : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm w-full block px-4 py-2.5 mt-4 text-sm border-gray-200 text-gray-400 rounded-md font-poppins font-medium') }, void 0)] }, void 0), _jsxs(Parent, { children: [_jsx(Text, { as: "p", className: "font-poppins font-medium text-sm text-gray-700 pt-4", label: t('end_date') }, void 0), _jsx(DatePicker, { selected: closeTimeFill, onChange: (date) => setCloseTimeFill(date), maxDate: new Date(), minDate: startTimeFill, className: classNames(businessType === 'Retail'
                                                ? 'focus:ring-blue-600 focus:border-blue-600'
                                                : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm w-full block px-4 py-2.5 mt-4 text-sm border-gray-200 text-gray-400 rounded-md font-poppins font-medium') }, void 0)] }, void 0)] }), void 0)] }, void 0))] }, void 0) }, void 0));
};
export default OrderFilter;
