var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import { useState, useEffect, useRef } from 'react';
import { CheckIcon, ArrowLeftIcon, CalendarIcon, UserGroupIcon } from '@heroicons/react/solid';
import { Parent, Icon, Link, Text, Nav, Button, Input, Select, Checkbox } from '../../Component';
import { STARTPAGE } from '../../Utilities/Constants';
import Moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import { COUPONSUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { classNames } from '../../Utilities/Helpers';
import ContainerSection from '../ContainerSection/ContainerSection';
import Layout from '../../Layouts/Layout';
import PageHeader from '../../Layouts/Core/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import TargetAudience from './TargetAudience';
import SelectProducts from './ProductDetails/SelectProducts/SelectProducts';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CATEGORIESGETQUERY, DISCOUNTGETQUERY, GETCUSTOMERSQUERY } from '../../GraphqlQuery/Query/Query';
import apiCall from '../../../API/apiCall';
import SelectCategories from './ProductDetails/SelectCategories/SelectCategories';
import { useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { APILINK } from '../../Utilities/ApiLink';
import DataNotFound from '../DataNotFound/DataNotFound';
const AddCoupons = ({}) => {
    const singleCoupenData = localStorage.getItem('singleCopData');
    let a = singleCoupenData && JSON.parse(singleCoupenData);
    let start = Moment(a && a[0].valid_from, 'YYYY-MM-DD').format('YYYY-MM-DD hh:mm:ss');
    let end = Moment(a && a[0].valid_till, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss');
    const { t } = useTranslation();
    const stepsData = [
        {
            name: 'Step 1',
            display_name: t('step_1'),
            description: t('coupon_details'),
            status: 'current'
        },
        {
            name: 'Step 2',
            display_name: t('step_2'),
            description: t('target_audience'),
            status: 'upcoming'
        },
        {
            name: 'Step 3',
            display_name: t('step_3'),
            description: t('product_details'),
            status: 'upcoming'
        }
    ];
    const history = useHistory();
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [steps, setSetps] = useState(stepsData);
    const [activeStep, setActiveStep] = useState('Step 1');
    const [formikData, setFormikData] = useState(a && a[0]);
    const [open, setOpen] = useState(false);
    const [stepVal, setstepVal] = useState(true);
    const [apiCustArray, setApiCustArray] = useState([]);
    const [fillCustArray, setFillCustArray] = useState([]);
    const [apiItemArray, setApiItemArray] = useState([]);
    const [fillItemArray, setFillItemArray] = useState([]);
    const [fillCatArray, setFillCatArray] = useState([]);
    const [apiCatArray, setApiCatArray] = useState([]);
    const [sliderTitle, setSliderTitle] = useState('');
    const [discountAfterTax, setDiscountAfterTax] = useState(0);
    const [selectCust, setSelectCust] = useState(false);
    const [title, setTitle] = useState('cat');
    const [tarId, setTarId] = useState('0');
    const [proId, setProId] = useState('2');
    const [favorites, setFavorites] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [apiDisArray, setApiDisArray] = useState([]);
    const [valid_from, setValid_from] = useState(new Date());
    const [valid_till, setValid_till] = useState(new Date());
    const [targetCust, setTargetCust] = useState([]);
    const [is_all_customers, setIs_all_customers] = useState(0);
    const [is_all_products, setIs_all_products] = useState(0);
    const [is_all_categories, setIs_all_categories] = useState(0);
    const [targetCat, setTargetCat] = useState([]);
    const [targetProd, setTargetProd] = useState([]);
    const CouponId = localStorage.getItem('CouponId');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [btnLoading, setBtnLoading] = useState(false);
    const [checkSelectedCutomer, setCheckSelectedCustomer] = useState(true);
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const { data } = useQuery(DISCOUNTGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const { data: dataone, refetch: refetchone, loading: loadingone } = useQuery(GETCUSTOMERSQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const { data: datatwo } = useQuery(CATEGORIESGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const [updateCoupon] = useMutation(COUPONSUPDATEQUERY);
    const AddouponSchema = Yup.object().shape({
        coupon_name: Yup.string().required(t('coupon_name_is_required')),
        coupon_code: Yup.string().required(t('coupon_code_is_required')),
        disc_type_id: Yup.string().required(t('discount_type_is_required')),
        disc_value: Yup.number().required(t('percent_is_required'))
    });
    const Tablecolumns = [
        {
            id: 1,
            title: t('name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('phone'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('created_date'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('zipcode'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const favDrop = [
        {
            label: 'Select',
            value: '',
            hidden: true
        },
        {
            label: 'All',
            value: 'All'
        },
        {
            label: 'Yes',
            value: 'Yes'
        },
        {
            label: 'No',
            value: 'No'
        }
    ];
    const Tar_Audience = [
        { id: '1', title: 'All Customers' },
        { id: '2', title: 'Selected Customers' }
    ];
    const Prod_Details = [
        { id: '1', title: 'Select Products' },
        { id: '2', title: 'Select Categories' }
    ];
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false, show: 'block' },
        {
            title: t('customer'),
            href: '#/customers',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: t('coupon'),
            href: '#/coupon',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: CouponId ? t('edit_coupon') : t('add_coupon'),
            href: '#/addcoupons',
            current: true,
            show: 'block'
        }
    ];
    const SetProductDetails = (proTitle, all, single) => __awaiter(void 0, void 0, void 0, function* () {
        if (proTitle == 'pro') {
            yield setIs_all_products(all);
            yield setTargetProd(single);
            yield setIs_all_categories(0);
            yield setTargetCat([]);
        }
        else {
            yield setIs_all_categories(all);
            yield setTargetCat(single);
            yield setIs_all_products(0);
            yield setTargetProd([]);
        }
    });
    const checks = (e, title) => {
        if (title == 'tax') {
            if (e.target.checked) {
                setDiscountAfterTax(1);
            }
            else {
                setDiscountAfterTax(0);
            }
        }
        else if (title == 'Target') {
            let oldCust = [];
            const oldData = [...targetCust];
            if (e.target.checked) {
                let date = parseInt(e.target.id);
                oldData.push(date);
                setTargetCust(oldData);
                fillCustArray.map((item) => {
                    if (item.id == e.target.id) {
                        let cust = Object.assign(Object.assign({}, item), { checked: true });
                        oldCust.push(cust);
                    }
                    else {
                        let cust = Object.assign({}, item);
                        oldCust.push(cust);
                    }
                    // setApiCustArray(oldCust);
                    setFillCustArray(oldCust);
                });
            }
            else {
                let oldCust = [];
                fillCustArray.map((item) => {
                    if (item.id == e.target.id) {
                        let cust = Object.assign(Object.assign({}, item), { checked: false });
                        oldCust.push(cust);
                    }
                    else {
                        let cust = Object.assign({}, item);
                        oldCust.push(cust);
                    }
                    setFillCustArray(oldCust);
                });
                const filDate = oldData.filter((data) => {
                    return data != e.target.id;
                });
                setTargetCust(filDate);
            }
        }
    };
    const changeFavorites = (e) => {
        const filterText = e.target.value;
        if (filterText == 'All') {
            setFavorites('');
            filterDataList('');
        }
        else if (filterText == 'Yes') {
            setFavorites('1');
            filterDataList('1');
        }
        else {
            setFavorites('0');
            filterDataList('0');
        }
    };
    const filterDataList = (val) => {
        const filterText = val;
        setCurrentPageNumber(1);
        const filterList = apiCustArray.filter((item) => {
            var _a, _b, _c, _d;
            if (zipCode && val) {
                return ((_a = item.is_favorite) === null || _a === void 0 ? void 0 : _a.includes(filterText)) && ((_b = item.zipcode) === null || _b === void 0 ? void 0 : _b.includes(zipCode));
            }
            else if (zipCode) {
                return (_c = item.zipcode) === null || _c === void 0 ? void 0 : _c.includes(zipCode);
            }
            else {
                return (_d = item.is_favorite) === null || _d === void 0 ? void 0 : _d.includes(filterText);
            }
        });
        setFillCustArray(filterList);
    };
    // get product item api call
    const getItem = () => __awaiter(void 0, void 0, void 0, function* () {
        var param = { business_id: businessId, outlet_id: outletId };
        yield apiCall
            .GetServerCall(APILINK.product_get, 'GET', token, param)
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            let oldItem = [];
            response.data.data.map((item) => {
                let it = Object.assign(Object.assign({}, item), { name: item.prod_name, image: item.product_img, checked: true });
                oldItem.push(it);
                setApiItemArray(oldItem);
                setFillItemArray(oldItem);
            });
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    // check all target customer
    const changeTargetCustomer = (tar) => {
        setTarId(tar.id);
        if (tar.title == 'All Customers') {
            setSelectCust(false);
            setTargetCust([]);
            setIs_all_customers(1);
            let oldCust = [];
            apiCustArray.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: true });
                setCheckSelectedCustomer(false);
                oldCust.push(cust);
                setApiCustArray(oldCust);
                setFillCustArray(oldCust);
            });
        }
        else {
            setSelectCust(true);
            setIs_all_customers(0);
            let oldCust = [];
            apiCustArray.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: false });
                setCheckSelectedCustomer(true);
                oldCust.push(cust);
                setApiCustArray(oldCust);
                setFillCustArray(oldCust);
            });
        }
    };
    // changr product detials titles
    const changeProductDetails = (pro) => {
        setProId(pro.id);
        if (pro.title == 'Select Products') {
            setTitle('pro');
            let oldItem = [];
            apiItemArray.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: false });
                oldItem.push(cust);
                setApiItemArray(oldItem);
            });
        }
        else
            setTitle('cat');
        let oldCategory = [];
        apiCatArray.map((item) => {
            let category = Object.assign(Object.assign({}, item), { checked: false });
            oldCategory.push(category);
            setApiCatArray(oldCategory);
        });
    };
    const sliderOpen = (value, title) => {
        setOpen(value);
        setSliderTitle(title);
    };
    //  next button function
    const onChangeStep = (value, step, data) => {
        if (step == 'Step 1') {
            setstepVal(true);
        }
        else {
            setstepVal(false);
        }
        let pIndex;
        setFormikData(data);
        if (step == 'Step 2') {
            refetchone();
        }
        const newArray = steps.map((item, index) => {
            if (item.name === step) {
                pIndex = index - 1;
                item.status = 'current';
                return item;
            }
            else if (step === 'Done') {
                item.status = 'complete';
                return item;
            }
            return item;
        });
        if (pIndex < newArray.length)
            newArray[pIndex].status = 'complete';
        setSetps(newArray);
        setActiveStep(step);
    };
    //  Previous button function
    const onChangePreviousStep = (step) => {
        if (step == 'Step 1') {
            setstepVal(true);
        }
        else {
            setstepVal(false);
        }
        let pIndex;
        const restArray = steps.map((item1, index) => {
            if (item1.name === step) {
                pIndex = index + 1;
                item1.status = 'current';
                return item1;
            }
            return item1;
        });
        restArray[pIndex].status = 'upcoming';
        setSetps(restArray);
        setActiveStep(step);
    };
    // Api call function
    const apiCallFun = () => __awaiter(void 0, void 0, void 0, function* () {
        yield setBtnLoading(true);
        const params = {
            coupon_name: formikData.coupon_name,
            coupon_code: formikData.coupon_code,
            valid_from: Moment(valid_from).format('YYYY-MM-DD'),
            valid_till: Moment(valid_till).format('YYYY-MM-DD'),
            disc_type_id: parseInt(formikData.disc_type_id),
            disc_value: parseInt(formikData.disc_value),
            apply_tax_after_disc: discountAfterTax.toString(),
            outlet_id: outletId,
            business_id: businessId,
            is_all_customers: is_all_customers,
            is_all_products: is_all_products,
            customers: targetCust,
            products: targetProd,
            category: targetCat
        };
        yield apiCall
            .PostServerCall(APILINK.coupen_insert, 'POST', token, params)
            .then(() => {
            localStorage.setItem('showSuccessCoup', 'true');
            setBtnLoading(false);
            history.push('/coupon');
        })
            .catch((err) => {
            console.log(err, 'err');
        });
    });
    // update api call
    const updateApiFun = (val) => __awaiter(void 0, void 0, void 0, function* () {
        yield setBtnLoading(true);
        const params = {
            id: CouponId,
            coupon_name: val.coupon_name,
            coupon_code: val.coupon_code,
            valid_from: Moment(valid_from).format('YYYY-MM-DD'),
            valid_till: Moment(valid_till).format('YYYY-MM-DD'),
            disc_type_id: parseInt(val.disc_type_id),
            disc_value: parseInt(val.disc_value),
            apply_tax_after_disc: discountAfterTax.toString(),
            outlet_id: outletId,
            business_id: businessId
        };
        updateCoupon({
            variables: params
        }).then(() => {
            setBtnLoading(false);
            history.push('/coupon');
        });
    });
    const couponCodegeneration = (data, length) => __awaiter(void 0, void 0, void 0, function* () {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        data.coupon_code = !CouponId ? result.toString() : formikData.coupon_code;
        yield setFormikData(data);
    });
    useEffect(() => {
        if (data) {
            let oldDis = [];
            oldDis.push({
                label: 'Select Discount Type',
                value: '',
                hidden: true
            });
            data.getAllDisctype.map((cat) => {
                let dis = {
                    label: cat.disc_name,
                    value: cat.id
                };
                oldDis.push(dis);
                setApiDisArray(oldDis);
            });
        }
        if (datatwo) {
            let oldCata = [];
            datatwo.getAllCategory.map((item) => {
                let cata = Object.assign(Object.assign({}, item), { name: item.cat_name, image: item.cat_image, checked: true });
                oldCata.push(cata);
                setApiCatArray(oldCata);
                setFillCatArray(oldCata);
            });
        }
    }, [data, dataone, datatwo]);
    useEffect(() => {
        changeTargetCustomer({ id: '2', title: 'Selected Customers' });
    }, [apiCustArray.length]);
    useEffect(() => {
        getItem();
    }, []);
    useEffect(() => {
        if (dataone) {
            let oldCust = [];
            dataone.getAllCustomers.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: false });
                oldCust.push(cust);
                setApiCustArray(oldCust);
                setFillCustArray(oldCust);
            });
        }
    }, [dataone]);
    useEffect(() => {
        filterDataList(favorites);
    }, [zipCode]);
    useEffect(() => {
        setValid_from(a && a[0].valid_from ? parseISO(start) : new Date());
        setValid_till(a && a[0].valid_till ? parseISO(end) : new Date());
        setDiscountAfterTax(a && a[0].apply_tax_after_disc ? a && a[0].apply_tax_after_disc : 0);
    }, [CouponId]);
    useEffect(() => {
        if (formikData) {
            couponCodegeneration(formikData, 8);
        }
    }, [formikData]);
    const validFromRef = useRef(null);
    const validTillRef = useRef(null);
    return (_jsx(_Fragment, { children: _jsxs(Layout, { children: [_jsx(PageHeader, { title: CouponId ? t('edit_coupon') : t('add_coupon'), breadcrumbs: breadCrumbs }, void 0), _jsx(Parent, Object.assign({ className: "lg:px-12 md:px-6 pb-9 px-4" }, { children: _jsx(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-3" }, { children: _jsxs(Parent, Object.assign({ className: " flex md:flex-col lg:flex-row flex-col" }, { children: [!CouponId && (_jsxs(Parent, Object.assign({ className: "lg:w-72 sm:w-full sm:mt-0  sm:text-left  " }, { children: [stepVal && (_jsx(Button, Object.assign({ text: t('back'), className: "group inline-flex items-center mr-4 mb-4 mt-2 px-4 py-2 border border-transparent shadow-custom text-sm font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => history.push('/coupon') }, { children: _jsx(Icon, { tag: ArrowLeftIcon, className: "w-4 h-4 text-blue-600 group-hover:text-white rounded-md mr-2" }, void 0) }), void 0)), _jsx(Text, { as: "h3", className: " mt-2 text-2xl leading-6 font-medium font-poppins  text-gray-800 mb-5 ", label: CouponId
                                                ? t('edit_coupon_details')
                                                : t('add_coupon_details') }, void 0), _jsx(Parent, Object.assign({ className: "mt-2 lg:w-64" }, { children: _jsx(Parent, { children: _jsx(Nav, Object.assign({ "aria-label": "Progress", className: '' }, { children: _jsx(Parent, Object.assign({ className: "grid grid-cols-3 lg:block lg:overflow-hidden" }, { children: steps.map((step, stepIdx) => (_jsx(Parent, Object.assign({ className: classNames(stepIdx !== steps.length - 1
                                                                ? 'pb-10'
                                                                : '', 'relative') }, { children: step.status === 'complete' ? (_jsxs(_Fragment, { children: [stepIdx !==
                                                                        steps.length - 1 ? (_jsx(Parent, { className: "-ml-px absolute mt-0.5 top-4 left-4 w-full lg:w-0.5 lg:h-full h-0.5 bg-blue-600", "aria-hidden": "true" }, void 0)) : null, _jsxs(Link, Object.assign({ className: "relative flex flex-col lg:flex-row items-start group" }, { children: [_jsx(Text, Object.assign({ className: "h-9 flex items-center", as: "span" }, { children: _jsx(Text, Object.assign({ as: "span", className: "relative z-5 w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full group-hover:bg-blue-600" }, { children: _jsx(CheckIcon, { className: "w-5 h-5 text-white", "aria-hidden": "true" }, void 0) }), void 0) }), void 0), _jsxs(Text, Object.assign({ as: "span", className: "lg:ml-4 min-w-0 flex flex-col" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "text-xs font-semibold tracking-wide uppercase text-blue-600" }, { children: step.display_name }), void 0), _jsx(Text, Object.assign({ as: "span", className: "text-sm text-gray-800" }, { children: step.description }), void 0)] }), void 0)] }), void 0)] }, void 0)) : step.status === 'current' ? (_jsxs(_Fragment, { children: [stepIdx !==
                                                                        steps.length - 1 ? (_jsx(Parent, { className: "-ml-px absolute mt-0.5 top-4 left-4 w-full lg:w-0.5 lg:h-full h-0.5 bg-gray-300", "aria-hidden": "true" }, void 0)) : null, _jsxs(Link, Object.assign({ className: "relative flex flex-col lg:flex-row items-start group", "aria-current": "step" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "h-9 flex items-center", "aria-hidden": "true" }, { children: _jsx(Text, Object.assign({ as: "span", className: "relative z-5 w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full" }, { children: _jsx(Text, { as: "span", className: "h-2.5 w-2.5 bg-blue-600 rounded-full" }, void 0) }), void 0) }), void 0), _jsxs(Text, Object.assign({ as: "span", className: "lg:ml-4 min-w-0 flex flex-col" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "text-xs font-semibold tracking-wide uppercase " }, { children: step.display_name }), void 0), _jsx(Text, Object.assign({ as: "span", className: "text-sm text-gray-800" }, { children: step.description }), void 0)] }), void 0)] }), void 0)] }, void 0)) : (_jsxs(_Fragment, { children: [stepIdx !==
                                                                        steps.length - 1 ? (_jsx(Parent, { className: "-ml-px absolute mt-0.5 top-4 left-4 w-full lg:w-0.5 lg:h-full h-0.5 bg-gray-300", "aria-hidden": "true" }, void 0)) : null, _jsxs(Link, Object.assign({ className: "relative flex flex-col lg:flex-row items-start group" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "h-9 flex items-center", "aria-hidden": "true" }, { children: _jsx(Text, Object.assign({ as: "span", className: "relative z-5 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400" }, { children: _jsx(Text, { as: "span", className: "h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" }, void 0) }), void 0) }), void 0), _jsxs(Text, Object.assign({ as: "span", className: "lg:ml-4 min-w-0 flex flex-col" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "text-xs font-semibold tracking-wide uppercase text-gray-500" }, { children: step.display_name }), void 0), _jsx(Text, Object.assign({ as: "span", className: "text-sm text-gray-500" }, { children: step.description }), void 0)] }), void 0)] }), void 0)] }, void 0)) }), step.name))) }), void 0) }), void 0) }, void 0) }), void 0)] }), void 0)), _jsxs(Parent, Object.assign({ className: classNames(!CouponId ? 'lg:border-l-2 lg:pl-5 overflow-auto' : '', ' w-full overflow-auto ') }, { children: [_jsx(Formik, Object.assign({ initialValues: formikData, onSubmit: (values) => !CouponId
                                                ? onChangeStep(true, 'Step 2', values)
                                                : updateApiFun(values), validationSchema: AddouponSchema, enableReinitialize: true }, { children: ({ handleChange, handleBlur, handleSubmit, values, touched, errors }) => (_jsx(Parent, Object.assign({ className: activeStep === 'Step 1' ? '' : 'hidden' }, { children: _jsxs(Parent, Object.assign({ className: "sm:mt-0  sm:text-left  " }, { children: [CouponId && (_jsx(Button, Object.assign({ text: t('back'), className: "group inline-flex items-center mr-4 mb-4 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => history.push('/coupon') }, { children: _jsx(Icon, { tag: ArrowLeftIcon, className: "w-5 h-5 text-blue-600 group-hover:text-white rounded-md mr-2" }, void 0) }), void 0)), _jsx(Text, { as: "h3", className: " mt-2 text-2xl leading-6 font-medium font-poppins  text-gray-800 mb-5 ", label: !CouponId
                                                                ? t('coupon_details')
                                                                : t('edit_coupon_details') }, void 0), _jsxs(Parent, Object.assign({ className: "mt-2" }, { children: [_jsxs(Parent, Object.assign({ className: " pb-7 " }, { children: [_jsxs(Parent, Object.assign({ className: "w-full" }, { children: [_jsxs(Parent, Object.assign({ className: "flex" }, { children: [_jsx(Text, { as: "p", className: " text-sm font-poppins font-normal text-gray-700", label: t('coupon_code') }, void 0), _jsx(Text, { as: "label", className: "block text-sm ml-1 font-medium text-red-700", label: "*" }, void 0)] }), void 0), _jsx(Text, { as: "p", className: " text-sm mt-2 text-left font-poppins font-medium text-gray-700", label: values && values.coupon_code }, void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: " mt-6 w-full" }, { children: [_jsxs(Parent, Object.assign({ className: "flex" }, { children: [_jsx(Text, { as: "p", className: " text-sm font-poppins font-normal text-gray-700", label: t('coupon_name') }, void 0), _jsx(Text, { as: "label", className: "block text-sm ml-1 font-medium text-red-700", label: "*" }, void 0)] }), void 0), _jsx(Input, { name: "Coupon Name", className: "shadow-sm focus:border-blue-600  w-full block h-10  items-center   mt-2  text-sm border-gray-200 text-gray-700 rounded-md font-poppins text-left font-leading-none ", id: "search", type: "text", onChange: handleChange('coupon_name'), onBlur: handleBlur('coupon_name'), value: values && values.coupon_name }, void 0), errors.coupon_name &&
                                                                                    touched.coupon_name && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: errors.coupon_name }, void 0))] }), void 0), _jsx(Parent, Object.assign({ className: " flex justify-between mt-6 " }, { children: _jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Checkbox, { id: "", name: "notification-method", type: "checkbox", checked: discountAfterTax == 1
                                                                                            ? true
                                                                                            : false, className: "focus:ring-transparent mt-0 h-4 w-4  rounded-sm text-blue-600 border-gray-300", onChange: (e) => checks(e, 'tax') }, void 0), _jsx(Text, { as: "label", className: "ml-4 text-sm font-poppins font-medium mt-1  text-gray-700", label: t('apply_discount_after_tax') }, void 0)] }), void 0) }), void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: " grid  xl:grid-cols-2  sm:gap-4" }, { children: [_jsxs(Parent, Object.assign({ className: "" }, { children: [_jsxs(Parent, Object.assign({ className: "flex" }, { children: [_jsx(Text, { as: "label", className: "block text-sm mb-1 font-poppins font-normal text-gray-700 pb-1", label: t('discount_type') }, void 0), _jsx(Text, { as: "label", className: "block text-sm ml-1 font-medium text-red-700", label: "*" }, void 0)] }), void 0), _jsx(Select, { optionItems: apiDisArray, name: "disc_type_id", id: "disc_type_id", className: "shadow-sm focus:border-blue-600 w-full block py-2.5 text-sm border-gray-200 text-gray-400 rounded-md font-poppins font-normal", onChange: handleChange('disc_type_id'), onBlur: handleBlur('disc_type_id'), value: values && values.disc_type_id }, void 0), errors.disc_type_id &&
                                                                                    touched.disc_type_id && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: errors.disc_type_id }, void 0))] }), void 0), _jsxs(Parent, Object.assign({ className: "w-full" }, { children: [_jsxs(Parent, Object.assign({ className: "flex" }, { children: [_jsx(Text, { as: "p", className: " text-sm font-poppins font-normal text-gray-700", label: t('percent_fixed_amount') }, void 0), _jsx(Text, { as: "label", className: "block text-sm ml-1 font-medium text-red-700", label: "*" }, void 0)] }), void 0), _jsx(Input, { name: "percentage", className: "shadow-sm focus:border-blue-600  w-full block h-10  items-center   mt-2  text-sm border-gray-200 text-gray-700 rounded-md font-poppins text-left font-leading-none ", id: "search", type: "number", onChange: handleChange('disc_value'), onBlur: handleBlur('disc_value'), value: values && values.disc_value }, void 0), errors.disc_value &&
                                                                                    touched.disc_value && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: errors.disc_value }, void 0))] }), void 0), _jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "flex sm:mt-4 w-full items-center" }, { children: _jsx(Text, { as: "label", className: " text-sm font-poppins font-normal text-gray-700", label: t('valid_from') }, void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "mt-1 flex relative w-full" }, { children: [_jsx(DatePicker, { closeOnScroll: (e) => e.target === document, selected: valid_from, ref: validFromRef, onChange: (date) => {
                                                                                                setValid_from(date);
                                                                                                setValid_till(date);
                                                                                            }, minDate: new Date(), onKeyDown: (e) => {
                                                                                                e.preventDefault();
                                                                                            }, dateFormat: "dd/MM/yyyy", className: "shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full sm:text-xs border-gray-200 text-gray-400 rounded-md font-poppins text-sm font-normal py-3" }, void 0), _jsx(Parent, Object.assign({ className: 'right-4 absolute top-1/4 ' }, { children: _jsx(Icon, { onClick: () => {
                                                                                                    var _a;
                                                                                                    return (_a = validFromRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(true);
                                                                                                }, tag: CalendarIcon, className: "w-5 h-5 cursor-pointer text-gray-400 text-base items-center font-medium font-poppins " }, void 0) }), void 0)] }), void 0)] }, void 0), _jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "flex sm:mt-4 w-full items-center" }, { children: _jsx(Text, { as: "label", className: " text-sm font-poppins font-normal text-gray-700", label: t('valid_till') }, void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "mt-1 flex relative w-full" }, { children: [_jsx(DatePicker, { closeOnScroll: (e) => e.target === document, selected: valid_till, ref: validTillRef, onChange: (date) => setValid_till(date), minDate: valid_from, onKeyDown: (e) => {
                                                                                                e.preventDefault();
                                                                                            }, dateFormat: "dd/MM/yyyy", className: "shadow-sm focus:ring-blue-600 focus:border-blue-600 block w-full sm:text-xs border-gray-200 text-gray-400 rounded-md font-poppins text-sm font-normal py-3" }, void 0), _jsx(Parent, Object.assign({ className: 'right-4 absolute top-1/4 ' }, { children: _jsx(Icon, { onClick: () => {
                                                                                                    var _a;
                                                                                                    return (_a = validTillRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(true);
                                                                                                }, tag: CalendarIcon, className: "w-5 h-5 cursor-pointer text-gray-400 text-base items-center font-medium font-poppins " }, void 0) }), void 0)] }), void 0)] }, void 0)] }), void 0), _jsx(Parent, Object.assign({ className: "bg-white pt-5 w-full sm:px-0 sm:flex  justify-self-end sm:flex-row-reverse" }, { children: CouponId ? (_jsx(Button, Object.assign({ text: t('update'), disabled: btnLoading, className: classNames(btnLoading
                                                                            ? 'opacity-50 cursor-not-allowed'
                                                                            : '', 'w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 sm:ml-3 sm:w-auto '), onClick: () => handleSubmit() }, { children: btnLoading && (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3" }, { children: _jsx(Parent, { className: "animate-spin rounded-full h-5 w-5 border-b-2 border-white-900" }, void 0) }), void 0)) }), void 0)) : (_jsx(Button, { text: t('next'), disabled: btnLoading, className: "w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 text-blue-600 bg-blue-100 text-base font-medium hover:text-white hover:bg-blue-600 sm:ml-3 sm:w-auto ", onClick: () => handleSubmit() }, void 0)) }), void 0)] }), void 0)] }), void 0) }), void 0)) }), void 0), !CouponId && (_jsxs(_Fragment, { children: [_jsx(Parent, Object.assign({ className: activeStep === 'Step 2' ? '' : 'hidden' }, { children: _jsxs(Parent, Object.assign({ className: "text-center sm:mt-0 sm:text-left  " }, { children: [_jsx(Text, { as: "h3", className: " mt-2 text-2xl leading-6 font-medium font-poppins text-left text-gray-800  ", label: t('target_audience') }, void 0), _jsx(Parent, Object.assign({ className: "mt-4 flex items-center" }, { children: Tar_Audience.map((tar) => {
                                                                    return (_jsxs(Parent, Object.assign({ className: "flex items-center sm:text-center text-left" }, { children: [_jsx(Input, { name: tar.title, type: "radio", className: "focus:ring-transparent mt-0 h-4 w-4 text-blue-600 border-gray-300", id: tar.title, onChange: () => changeTargetCustomer(tar), checked: tar.id == tarId }, void 0), _jsx(Text, { as: "label", className: "ml-3 block text-sm font-poppins pr-10 mt-4 font-medium pb-3 text-gray-700", label: tar.title }, void 0)] }), void 0));
                                                                }) }), void 0), selectCust && (_jsxs(Parent, Object.assign({ className: "my-2 flex flex-col xl:items-center xl:flex-row " }, { children: [_jsxs(Parent, Object.assign({ className: " flex items-start flex-col sm:text-center text-left" }, { children: [_jsx(Text, { as: "span", className: " mt-1  text-sm leading-6 font-medium font-poppins  text-gray-800  ", label: t('favorites') }, void 0), _jsx(Select, { optionItems: favDrop, name: "Favorites", id: "Favorites", onChange: (e) => changeFavorites(e), className: "shadow-sm md:mb-3 mt-1 mb-0 focus:border-blue-600 block px-3 py-3 w-full sm:w-56 text-sm border-gray-200 text-gray-700 placeholder:text-gray-300 rounded-md font-poppins font-medium" }, void 0)] }), void 0), _jsx(Parent, Object.assign({ className: "mt-4 sm:mt-0 xl:pl-4  min-w-0" }, { children: _jsx(Input, { name: "ZipCode", className: "shadow-sm md:mb-3 focus:border-blue-600 block px-3 w-full sm:w-56 py-3 mt-1 text-sm border-gray-200 text-gray-700 placeholder:text-gray-300 rounded-md font-poppins font-medium", id: "ZipCode", type: "text", labelText: t('zipcode'), labelClass: "block text-sm font-poppins font-medium text-gray-700 mt-1 ", onChange: (e) => setZipCode(e.target.value), labelRequired: true }, void 0) }), void 0)] }), void 0)), _jsxs(Parent, Object.assign({ className: "" }, { children: [fillCustArray && fillCustArray.length > 0 ? (_jsx(TargetAudience, { onClick: sliderOpen, resultData: fillCustArray, columns: Tablecolumns, checks: checks, setCurrentPageNumber: setCurrentPageNumber, condition: targetCust.length > 0 ||
                                                                            !checkSelectedCutomer, currentPageNumber: currentPageNumber, total: fillCustArray &&
                                                                            fillCustArray.length > 0
                                                                            ? fillCustArray.length
                                                                            : 0, reloadData: function () {
                                                                            throw new Error('Function not implemented.');
                                                                        } }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('customers_are_not_available'), iconClassName: classNames('text-blue-600 h-16 w-16  m-auto mb-2'), iconTag: UserGroupIcon }, void 0)), _jsxs(Parent, Object.assign({ className: "bg-white py-3  w-full sm:px-0 sm:flex  justify-self-end sm:flex-row-reverse" }, { children: [_jsx(Button, { text: t('next'), className: "w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto ", onClick: () => (targetCust.length > 0 ||
                                                                                    !checkSelectedCutomer) &&
                                                                                    onChangeStep(true, 'Step 3', formikData) }, void 0), _jsx(Button, { text: t('previous'), className: " mt-3 sm:mt-0 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-blue-100 text-base font-medium text-blue-600 hover:text-white hover:bg-blue-600 sm:ml-3 sm:w-auto ", onClick: () => onChangePreviousStep('Step 1') }, void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx(Parent, Object.assign({ className: activeStep === 'Step 3' ? '' : 'hidden' }, { children: _jsxs(Parent, Object.assign({ className: " text-center sm:mt-0 sm:text-left  " }, { children: [_jsx(Text, { as: "h3", className: " mt-2 text-2xl leading-6 font-medium text-left font-poppins  text-gray-800  ", label: t('product_details') }, void 0), _jsx(Parent, Object.assign({ className: "mt-4 flex items-center" }, { children: Prod_Details.map((pro) => {
                                                                    return (_jsxs(Parent, Object.assign({ className: "flex items-center sm:text-center text-left" }, { children: [_jsx(Input, { name: pro.title, type: "radio", className: "focus:ring-transparent mt-0 h-4 w-4 text-blue-600 border-gray-300", id: pro.title, onChange: () => changeProductDetails(pro), checked: pro.id == proId }, void 0), _jsx(Text, { as: "label", className: "ml-3 block text-sm font-poppins pr-10 mt-4 font-medium pb-3 text-gray-700", label: pro.title }, void 0)] }), void 0));
                                                                }) }), void 0), _jsx(Parent, { children: title == 'pro' ? (_jsx(SelectProducts, { title: title, setApiItemArray: setApiItemArray, apiItemArray: apiItemArray, setProDeteils: SetProductDetails }, void 0)) : title == 'cat' ? (_jsx(SelectCategories, { title: title, apiCatArray: apiCatArray, setApiCatArray: setApiCatArray, setProDeteils: SetProductDetails }, void 0)) : ('') }, void 0), _jsxs(Parent, Object.assign({ className: "bg-white py-3 w-full sm:px-0 sm:flex  justify-self-end sm:flex-row-reverse" }, { children: [_jsx(Button, Object.assign({ text: t('finish'), className: classNames(btnLoading
                                                                            ? 'opacity-50 cursor-not-allowed'
                                                                            : '', 'w-full inline-flex items-center justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm'), onClick: () => apiCallFun() }, { children: btnLoading && (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3" }, { children: _jsx(Parent, { className: "animate-spin rounded-full h-5 w-5 border-b-2 border-white-900" }, void 0) }), void 0)) }), void 0), _jsx(Button, { text: t('previous'), className: "mt-3 sm:mt-0 w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 bg-blue-100 text-base font-medium text-blue-600 hover:text-white hover:bg-blue-600 sm:ml-3 sm:w-auto ", onClick: () => onChangePreviousStep('Step 2') }, void 0)] }), void 0)] }), void 0) }), void 0)] }, void 0))] }), void 0)] }), void 0) }), void 0) }), void 0)] }, void 0) }, void 0));
};
export default AddCoupons;
