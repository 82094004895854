import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * /* Copyright (C) efficientworks.co.uk - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Contact <ravi.raya@gmail.com>, Auto Generated Code October 2021
 *
 * @format
 */
import { useState, useEffect } from 'react';
import { Parent, Text } from '../../Component/index';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CATEGORIESGETQUERY, CATEGORIESSINGLEGETQUERY } from '../../GraphqlQuery/Query/Query';
import { CATEGORIESFIELDS } from '../../Utilities/Constants';
import SuccessPopUp from '../../Component/SuccessPopUp/SuccessPopUp';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import RestCatList from './RestCatList';
import SlideOverlay from '../../Component/SlideOverlay/SlideOverlay';
import { CATEGORIESADDQUERY, CATEGORIESUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
const RestCategoriy = ({}) => {
    const { t } = useTranslation();
    const [expandID, setExpandID] = useState('');
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const [apiResultArray, setApiResultArray] = useState([]);
    const [apiSubCategoriesArray, setApiSubCategoriesArray] = useState([]);
    const [filterDataList, setFilterDataList] = useState([]);
    const [filterSubCategoriesList, setFilterSubCategoriesList] = useState([]);
    const [singleObj, setSingleObj] = useState({});
    const [columns, setColumns] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [catTitle, setCatTitle] = useState('');
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [mulCat, setMulCat] = useState([]);
    const [CatId, setCatId] = useState();
    const [filterDataLists, setFilterDataLists] = useState([]);
    const [parent_ID, setParent_ID] = useState('');
    setTimeout(() => {
        setShowSuccess(false);
    }, 3000);
    const { loading, error, data, refetch } = useQuery(CATEGORIESGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const { loading: cat_lod, error: cat_err, data: cat_data, refetch: cat_refetch } = useQuery(CATEGORIESSINGLEGETQUERY, { variables: { id: CatId } });
    const [getSubCatgories, { loading: SubCatgoriesLoading, error: SubCatgoriesError, data: SubCatgoriesData }] = useLazyQuery(CATEGORIESSINGLEGETQUERY);
    const loadData = () => {
        refetch();
        cat_refetch();
    };
    const getFields = () => {
        const fields = CATEGORIESFIELDS.fields;
        let singleObjData = {};
        let columnsArray = [];
        let index = 0;
        fields.map((item) => {
            if (item.create === true && item.edit === true) {
                singleObjData[item['field name']] = '';
            }
            if (item.show_in_ui === true) {
                columnsArray.push({
                    id: index,
                    title: item['display_name'],
                    className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
                });
                index++;
            }
        });
        if (columnsArray) {
            if (columnsArray.length > 0) {
                columnsArray.push({
                    id: columnsArray.length - 1,
                    title: 'Actions',
                    className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
                });
            }
        }
        setColumns(columnsArray);
        setSingleObj(singleObjData);
    };
    const changeShow = (id) => {
        id == expandID ? setExpandID('') : setExpandID(id);
        setCatId(id);
        getSubCatgories({ variables: { id: id } });
    };
    useEffect(() => {
        if (data) {
            setApiResultArray(data.getAllCategory);
            setFilterDataList(data.getAllCategory);
            cat_refetch();
        }
        getFields();
    }, [data]);
    useEffect(() => {
        if (cat_data) {
            let chgCat = mulCat.map((item) => {
                if (item.id == CatId) {
                    setExpandID(item.id);
                    return Object.assign(Object.assign({}, item), { show: !item.show, sub_cat: cat_data.getAllSubCategory });
                }
                else {
                    return Object.assign(Object.assign({}, item), { show: false });
                }
            });
            setMulCat(chgCat);
        }
    }, [cat_data]);
    useEffect(() => {
        if (SubCatgoriesData) {
            setApiSubCategoriesArray(SubCatgoriesData.getAllSubCategory);
            setFilterSubCategoriesList(SubCatgoriesData.getAllSubCategory);
            let chgCat = mulCat.map((item) => {
                if (item.id == CatId) {
                    setExpandID(item.id);
                    return Object.assign(Object.assign({}, item), { show: !item.show, sub_cat: SubCatgoriesData.getAllSubCategory });
                }
                else {
                    return Object.assign(Object.assign({}, item), { show: false });
                }
            });
            setMulCat(chgCat);
        }
    }, [SubCatgoriesData]);
    useEffect(() => {
        if (data) {
            const cats = [];
            data.getAllCategory.map((cat) => {
                cats.push(Object.assign(Object.assign({}, cat), { show: false }));
            });
            setMulCat(cats);
            setFilterDataLists(cats);
        }
    }, [data]);
    return (_jsxs(Parent, Object.assign({ className: "lg:px-12 md:px-6 pb-9 px-4" }, { children: [_jsx(Parent, Object.assign({ className: " right-10 top-14 absolute flex justify-end " }, { children: showSuccess && (_jsx(SuccessPopUp, { setShowSuccess: setShowSuccess, title: t('successfully_created'), description: t('category_successfully_created') }, void 0)) }), void 0), loading ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames(businessType == 'Retail' ? 'border-blue-900' : 'border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames(businessType == 'Retail' ? 'text-blue-900' : 'text-blue-900', ' font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsx(Parent, Object.assign({ className: " " }, { children: _jsx(RestCatList, { filterDataList: filterDataList, filterSubCategoriesList: filterSubCategoriesList, setFilterSubCategoriesList: setFilterSubCategoriesList, apiSubCategoriesArray: apiSubCategoriesArray, mulCat: mulCat, expandID: expandID, CatId: CatId, refetch: loadData, changeShow: changeShow, total: filterDataList && filterDataList.length > 0 ? filterDataList.length : 0 }, void 0) }), void 0)), _jsx(SlideOverlay, { open: open, setOpen: setOpen, title: sliderTitle, from: "categories", overLayWidth: "max-w-md", catWidth: "grid grid-cols-1", reloadData: refetch, singleObj: singleObj, fields: CATEGORIESFIELDS.fields, addQuery: CATEGORIESADDQUERY, updateQuery: CATEGORIESUPDATEQUERY, setShowSuccess: setShowSuccess, setCatTitle: setCatTitle, parent_ID: parent_ID }, void 0)] }), void 0));
};
export default RestCategoriy;
