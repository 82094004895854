import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import RestCategoriy from '../Containers/RestCategory/RestCategory';
const Category = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false },
        { title: t('category'), href: '#/category', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('category'), breadcrumbs: breadCrumbs }, void 0), _jsx(RestCategoriy, { title: t('category') }, void 0)] }, void 0));
};
export default Category;
