/** @format */
export const COLORS = {
    white: '#ffffff',
    purple_500: '#7C3AED',
    blue_600: '#2563EB',
    indigo_500: '#5850ff',
    yellow_300: '#FBBF24',
    yellow_400: '#F59E0B',
    aqua_green: '#34D399',
    deep_saffron: '#fb923c',
    rose: '#ec4899',
    carmine_pink: '#ef4444',
    red_500: '#EF4444',
    red_100: '#FFCDD2',
    orange_500: '#F97316',
    orange_100: '#FFEDD5',
    green_400: '#10B981',
    green_500: '#34D399',
    yellow_700: '#FB923C',
    gray_400: '#9CA3AF',
    apple_blossom: '#aa4b4b',
    chinese_silver: '#c8c8c8',
    cloud_burst: '#374151',
    gray_700: '#6B7280',
    violet_blue: '#8203',
    blueAlpha: 'rgba(37, 99, 235, 0.5)',
    greenAlpha: 'rgba(16, 185, 129, 0.5)',
    purpleAlpha: 'rgba(124, 58, 237, 0.5)',
    yellowAlpha: 'rgba(245, 158, 11, 0.5)',
    purple_logo: '#5850FF',
    light_blue_500: ' #0EA5E9',
    cyan_600: '#0891B2',
    cyan_100: '#CFFAFE',
    skyAlpha: 'rgba(14, 165, 233, 0.5)',
    cyanAlpha: 'rgba(8, 145, 178, 0.5)',
    violet_600: '#8B5CF6',
    deep_violet: '#314CA9',
    sunset_ember: '#f97316'
};
