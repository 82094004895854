import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import React from 'react';
import { Icon } from '..';
import '../../../translations/i18n';
import posIcon from '../../assets/Icons/posIcon';
const SalesSummaryPrint = React.forwardRef((props, ref) => {
    var _a;
    return (_jsx("div", Object.assign({ ref: ref, style: {
            margin: '0',
            padding: '0',
            fontFamily: 'PT Mono, monospace,'
        }, className: "printBillContainer" }, { children: _jsxs("div", Object.assign({ style: {
                backgroundColor: 'white',
                width: '100%',
                fontSize: '9px',
                paddingTop: '15px'
            } }, { children: [_jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: ' 0 auto',
                        borderSpacing: '0',
                        paddingTop: '15px',
                        paddingBottom: '15px'
                    } }, { children: _jsx("tr", { children: _jsx("td", Object.assign({ style: { width: '100%' } }, { children: _jsx(Icon, { tag: posIcon, className: 'w-17 h-5' }, void 0) }), void 0) }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: '0 auto',
                        paddingBottom: '12px',
                        borderSpacing: '0'
                    } }, { children: _jsxs("tr", { children: [_jsxs("th", Object.assign({ style: {
                                    width: '50%',
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: [_jsx("span", Object.assign({ style: {
                                            color: '#5850ff',
                                            fontSize: '16px',
                                            lineHeight: '21px',
                                            fontWeight: 'bold',
                                            paddingRight: '2px',
                                            fontFamily: 'Poppins '
                                        } }, { children: "Sales Summary" }), void 0), "Table"] }), void 0), _jsx("td", Object.assign({ style: {
                                    textAlign: 'right',
                                    width: ' 50%',
                                    fontSize: '16px',
                                    lineHeight: ' 21px',
                                    fontWeight: 'bold',
                                    color: ' #8c8c8c',
                                    fontFamily: 'Poppins'
                                } }, { children: props.date }), void 0)] }, void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: '0 auto',
                        paddingBottom: '12px',
                        borderSpacing: '0'
                    } }, { children: _jsxs("tr", Object.assign({ style: { borderBottom: '1px solid #000000' } }, { children: [_jsx("th", Object.assign({ style: {
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: "Invoice" }), void 0), _jsx("th", Object.assign({ style: {
                                    width: '20%',
                                    textAlign: 'center',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontFamily: 'Poppins ',
                                    color: '#000000',
                                    fontWeight: 'bold'
                                } }, { children: "Date" }), void 0), _jsx("th", Object.assign({ style: {
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: "Order Status" }), void 0), _jsx("th", Object.assign({ style: {
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: "Amount" }), void 0), _jsx("th", Object.assign({ style: {
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: "Tax" }), void 0), _jsx("th", Object.assign({ style: {
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: "Discount" }), void 0), _jsx("th", Object.assign({ style: {
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: "Tip" }), void 0), _jsx("th", Object.assign({ style: {
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    paddingTop: '10px',
                                    fontWeight: 'bold',
                                    fontFamily: 'Poppins ',
                                    color: '#000000'
                                } }, { children: "Total" }), void 0)] }), void 0) }), void 0), _jsx("table", Object.assign({ style: {
                        width: '90%',
                        margin: '0 auto',
                        paddingBottom: '12px',
                        borderSpacing: '0'
                    } }, { children: (_a = props === null || props === void 0 ? void 0 : props.data) === null || _a === void 0 ? void 0 : _a.map((item) => {
                        return (
                        // eslint-disable-next-line react/jsx-key
                        _jsxs("tr", Object.assign({ style: { borderBottom: '1px solid #000000' } }, { children: [_jsx("td", Object.assign({ style: {
                                        textAlign: 'left',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: item === null || item === void 0 ? void 0 : item.id }), void 0), _jsx("td", Object.assign({ style: {
                                        textAlign: 'left',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: item === null || item === void 0 ? void 0 : item.date }), void 0), _jsx("td", Object.assign({ style: {
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: item === null || item === void 0 ? void 0 : item.status }), void 0), _jsx("td", Object.assign({ style: {
                                        textAlign: 'right',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: item === null || item === void 0 ? void 0 : item.amount }), void 0), _jsx("td", Object.assign({ style: {
                                        textAlign: 'right',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: item === null || item === void 0 ? void 0 : item.tax }), void 0), _jsx("td", Object.assign({ style: {
                                        textAlign: 'right',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: (Number(item.discount) + Number(item.mgnt_disc_amt)).toFixed(2) }), void 0), _jsx("td", Object.assign({ style: {
                                        textAlign: 'right',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: item === null || item === void 0 ? void 0 : item.tip_amount }), void 0), _jsx("td", Object.assign({ style: {
                                        textAlign: 'right',
                                        fontSize: '16px',
                                        lineHeight: '21px',
                                        paddingTop: '10px',
                                        fontFamily: 'Poppins ',
                                        color: '#000000'
                                    } }, { children: item === null || item === void 0 ? void 0 : item.total }), void 0)] }), void 0));
                    }) }), void 0)] }), void 0) }), void 0));
});
SalesSummaryPrint.displayName = 'SalesSummaryPrint';
export default SalesSummaryPrint;
