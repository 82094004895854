import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ShoppingBagIcon } from '@heroicons/react/solid';
import { classNames } from '../../Utilities/Helpers';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../../Containers/TableHeaders/TableHeader';
import { Parent, Text } from '../../Component';
import { t } from 'i18next';
import DataNotFound from '../../Containers/DataNotFound/DataNotFound';
const ReturnOrders = ({ person, columns, currencySymbol }) => {
    return (_jsx(Parent, Object.assign({ className: "" }, { children: person.status === 'Returned' ? (_jsxs(Parent, Object.assign({ className: "mt-5 relative" }, { children: [_jsxs(Table, Object.assign({ className: "min-w-full divide-y " }, { children: [_jsx(Thead, Object.assign({ className: "mb-5 " }, { children: _jsx(TableHeader, { scope: "col", className: "px-3 py-6 text-left text-sm font-poppins rounded-t-md font-medium bg-blue-600 text-white", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white " }, { children: person.order_item &&
                                person.order_item.map((item, index) => {
                                    return item.status === 'Returned' ? (_jsxs(Tr, Object.assign({ className: classNames(index % 2 == 0 ? '' : 'bg-gray-100') }, { children: [_jsx(Td, Object.assign({ className: "px-3 py-2.5 whitespace-nowrap w-1/4" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-xs font-poppins font-medium text-gray-700" }, { children: item.prod_name }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-3 py-2.5 whitespace-nowrap w-1/4" }, { children: _jsx(Parent, Object.assign({ className: "text-xs font-poppins font-medium text-gray-700" }, { children: item.return_quantity }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-3 py-2.5 whitespace-nowrap w-1/4" }, { children: _jsx(Parent, Object.assign({ className: "text-xs font-poppins font-medium  text-right text-gray-700" }, { children: item.price }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-3 py-2.5 whitespace-nowrap w-1/4" }, { children: _jsx(Parent, Object.assign({ className: "text-xs font-poppins font-medium text-right  text-gray-700" }, { children: (parseFloat(item.return_quantity) *
                                                        parseFloat(item.price)).toFixed(2) }), void 0) }), void 0)] }), item.id)) : ('');
                                }) }), void 0)] }), void 0), person.status === 'Returned' ? (_jsx(Parent, Object.assign({ className: "flex justify-end mt-5" }, { children: _jsx(Parent, Object.assign({ className: "" }, { children: _jsxs(Parent, Object.assign({ className: " flex" }, { children: [_jsx(Text, Object.assign({ as: "p", className: "font-poppins font-medium  text-sm text-gray-700" }, { children: t('including_tax') }), void 0), _jsx(Text, Object.assign({ as: "p", className: "text-sm px-3 font-poppins text-right font-medium text-gray-400" }, { children: `( ${currencySymbol} ${person.return_total} )` }), void 0)] }), void 0) }), void 0) }), void 0)) : ('')] }), void 0)) : (_jsx(Parent, Object.assign({ className: "flex flex-col  align-middle justify-center w-full h-full items-center" }, { children: _jsx(DataNotFound, { textClassName: "py-3 text-base text-blue-600 font-poppins font-medium text-center ", textString: t('no_returned_order'), iconClassName: "h-16 w-16 text-blue-600 m-auto mb-2", iconTag: ShoppingBagIcon }, void 0) }), void 0)) }), void 0));
};
export default ReturnOrders;
