import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { Parent, Icon, Link, Text, Nav } from '../../../Component';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { classNames } from '../../../Utilities/Helpers';
const SlideNavbar = ({ navItems, navigationBottom }) => {
    const [busType, setBusType] = useState(localStorage.getItem('business_type'));
    return (_jsx(Parent, Object.assign({ className: "flex-grow flex flex-col mt-5 lg:mt-0 md:w-full lg:w-62 bg-white py-8 px-6 h-screen transform transition-colors ease-in-out duration-15 overflow-y-auto" }, { children: _jsxs(Nav, Object.assign({ className: "flex-1 space-y-2 ", "aria-label": "Sidebar" }, { children: [navItems.map((item) => item.children && item.children.length > 0 ? (_jsx(Disclosure, Object.assign({ as: "div", className: "space-y-1" }, { children: ({ open }) => (_jsxs(_Fragment, { children: [_jsxs(Disclosure.Button, Object.assign({ className: busType == 'Retail'
                                    ? classNames(item.current
                                        ? 'text-blue-600'
                                        : 'text-gray-400 hover:bg-gray-200 hover:text-blue-600 ', 'group w-full flex items-center pl-2 py-2 text-sm font-poppins text-left font-medium leading-5 rounded-md iconsetup')
                                    : classNames(item.current
                                        ? 'text-blue-600'
                                        : 'text-gray-400 hover:bg-gray-200 hover:text-blue-600 ', 'group w-full flex items-center pl-2 py-2 text-sm font-poppins text-left font-medium leading-5 rounded-md iconsetup') }, { children: [_jsx(Icon, { tag: item.icon, color: item.subMenuColor, className: busType == 'Retail'
                                            ? classNames(item.current
                                                ? 'text-blue-600'
                                                : 'text-gray-400 group-hover:text-blue-600', 'mr-3 flex-shrink-0 h-5 w-5 iconcolor')
                                            : classNames(item.current
                                                ? 'text-blue-600'
                                                : 'text-gray-400 group-hover:text-blue-600', 'mr-3 flex-shrink-0 h-5 w-5 iconcolor'), "aria-hidden": item.current }, void 0), _jsx(Text, { as: "span", label: item.name, className: "flex-1 text-sm font-medium" }, void 0), _jsx(Icon, { tag: ChevronRightIcon, color: item.subMenuColor, className: busType == 'Retail'
                                            ? classNames(item.current
                                                ? 'text-blue-600 rotate-90'
                                                : 'text-gray-400', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-blue-600 transition-colors ease-in-out duration-150')
                                            : classNames(item.current
                                                ? 'text-blue-600 rotate-90'
                                                : 'text-gray-400', 'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-blue-600 transition-colors ease-in-out duration-150') }, void 0)] }), void 0), _jsx(Disclosure.Panel, Object.assign({ className: "space-y-1", static: item.current }, { children: item.children
                                    ? item.children.map((subItem) => (_jsx("a", Object.assign({ href: subItem.href, className: busType == 'Retail'
                                            ? classNames(subItem.current
                                                ? 'bg-gray-200 text-blue-600'
                                                : 'text-gray-400 hover:bg-gray-200 hover:text-blue-600', 'list-item group w-full  items-center pl-4 pr-2 py-2 text-sm font-poppins px-6 font-medium rounded-md')
                                            : classNames(subItem.current
                                                ? 'bg-gray-200 text-blue-600'
                                                : 'text-gray-400 hover:bg-gray-200 hover:text-blue-600', 'list-item group w-full  items-center pl-4 pr-2 py-2 text-sm font-poppins px-6 font-medium rounded-md') }, { children: subItem.name }), subItem.name)))
                                    : '' }), void 0)] }, void 0)) }), item.name)) : (_jsx(Parent, { children: _jsxs(Link, Object.assign({ href: item.href, className: busType == 'Retail'
                            ? classNames(item.current
                                ? 'bg-gray-200 text-blue-600'
                                : ' text-gray-400 hover:bg-gray-200 hover:text-blue-600', 'group w-full flex items-center pl-2 py-2 text-sm font-poppins font-medium leading-5 rounded-md iconsetup')
                            : classNames(item.current
                                ? 'bg-gray-200 text-blue-600'
                                : ' text-gray-400 hover:bg-gray-200 hover:text-blue-600', 'group w-full flex items-center pl-2 py-2 text-sm font-poppins font-medium leading-5 rounded-md iconsetup') }, { children: [_jsx(Icon, { tag: item.icon, color: item.subMenuColor, className: busType == 'Retail'
                                    ? classNames(item.current
                                        ? 'text-blue-600'
                                        : 'text-gray-400 group-hover:text-blue-600', 'mr-3 flex-shrink-0 h-5 w-5 iconcolor')
                                    : classNames(item.current
                                        ? 'text-blue-600'
                                        : 'text-gray-400 group-hover:text-blue-600', 'mr-3 flex-shrink-0 h-5 w-5 iconcolor'), "aria-hidden": "true" }, void 0), item.name] }), void 0) }, item.name))), navigationBottom === null || navigationBottom === void 0 ? void 0 : navigationBottom.map((item) => (_jsx(Parent, { children: _jsxs(Link, Object.assign({ onClick: () => { var _a; return item.onClick(item.link, ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) == 0 ? 0 : 1); }, href: item.href, className: classNames(item.current
                            ? 'bg-gray-200 text-blue-600'
                            : ' text-gray-400 hover:bg-gray-200 hover:text-blue-600', 'group cursor-pointer w-full flex items-center pl-2 py-2 text-sm font-poppins font-medium leading-5 rounded-md iconsetup') }, { children: [_jsx(Icon, { tag: item.icon, color: item.subMenuColor, className: classNames(item.current
                                    ? 'text-blue-600'
                                    : 'text-gray-400 group-hover:text-blue-600', 'mr-3 flex-shrink-0 h-5 w-5 iconcolor'), "aria-hidden": "true" }, void 0), item.name] }), void 0) }, item.name)))] }), void 0) }), void 0));
};
export default SlideNavbar;
