import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { Parent, Icon, Link } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../../Containers/TableHeaders/TableHeader';
import Pagination from '../../Containers/Pagination/Pagination';
import { DATALIMIT, PAGELIMIT } from '../../Utilities/Constants';
import ConfirmationAlert from '../../Component/ConfirmationAlert/ConfirmationAlert';
import ViewInfo from '../../Component/SlideOverlay/ViewInfo';
import { useMutation } from '@apollo/client';
import { ADDONSDELETEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { t } from 'i18next';
import { classNames } from '../../Utilities/Helpers';
const AddonsTable = ({ className, onClick, resultData, getSingleObj, columns, total, reloadData, currentPageNumber, setCurrentPageNumber }) => {
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [showAlert, setShowAlert] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [columnsData, setColumnsData] = useState(columns);
    const [tableData, setTableData] = useState(resultData);
    const [id, setId] = useState('');
    const [deleteName, setDeleteName] = useState('');
    const [deleteRecordQuery, { data, loading, error }] = useMutation(ADDONSDELETEQUERY);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        const list = resultData.slice(startIndex, endIndex);
        setEndIndex(resultData.length > endIndex ? endIndex : resultData.length);
        setStartIndex(startIndex + 1);
        setTableData(list);
    };
    const showConfirmation = (value, id, deleteName) => {
        setShowAlert(value);
        setId(id);
        setDeleteName(deleteName);
    };
    const deleteRecord = () => {
        deleteRecordQuery({
            variables: {
                id: parseInt(id),
                is_active: '0'
            }
        }).then((result) => {
            reloadData('', '');
            setShowAlert(false);
        });
    };
    useEffect(() => {
        setTableData(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: " align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columnsData }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: tableData.map((person) => (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.name }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.description }), void 0) }), void 0), _jsxs(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap text-center text-sm font-medium w-1/6" }, { children: [_jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group mr-4 inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('edit'), onClick: () => getSingleObj(person.id, true, t('edit_addon')) }, { children: _jsx(Icon, { tag: PencilIcon, className: "h-3.5 w-3.5  group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('delete'), onClick: () => showConfirmation(true, person.id, person.name) }, { children: _jsx(Icon, { tag: TrashIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0)] }), void 0)] }, person.id))) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Pagination, { items: tableData, pageLimit: pageLimit, dataLimit: dataLimit, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, totalData: resultData, total: total }, void 0), _jsx(ConfirmationAlert, { id: id, open: showAlert, setOpen: setShowAlert, deleteRecord: deleteRecord, title: t('are_you_sure'), description: `${t('do_you_really_want_to_delete')} ${deleteName} ?` }, void 0), _jsx(ViewInfo, { open: showInfo, setOpen: setShowInfo, title: "Customer Information" }, void 0)] }), void 0));
};
export default AddonsTable;
