import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link, Parent, Text } from '../Component/index';
import LogoFullErrorPage from '../assets/Icons/LogoFullErrorPage ';
import { COLORS } from '../Utilities/Color';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const App = () => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: _jsx(Parent, Object.assign({ className: "min-h-screen pt-16 pb-12 flex flex-col bg-white" }, { children: _jsxs("main", Object.assign({ className: "flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8" }, { children: [_jsx(Parent, Object.assign({ className: "flex-shrink-0 flex justify-center" }, { children: _jsxs(Link, Object.assign({ href: "#/login", className: "inline-flex" }, { children: [_jsx(Text, { as: "span", className: "sr-only", label: "Workflow" }, void 0), _jsx(LogoFullErrorPage, { className: "h-20 w-auto mx-auto", color: COLORS.blue_600 }, void 0)] }), void 0) }), void 0), _jsx(Parent, Object.assign({ className: "py-16" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Text, Object.assign({ as: "p", className: "text-sm font-semibold text-blue-600 uppercase tracking-wide" }, { children: t('error') }), void 0), _jsx(Text, Object.assign({ as: "h1", className: "mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl" }, { children: t('page_not_found') }), void 0), _jsx(Text, Object.assign({ as: "p", className: "mt-2 text-base text-gray-500" }, { children: t('sorry_we_couldnt_find_the_page_youre_looking_for') }), void 0), _jsx(Parent, Object.assign({ className: "mt-6" }, { children: _jsxs(Link, Object.assign({ href: "#/login", className: "text-base font-medium text-blue-600 hover:text-blue-500" }, { children: [t('go_back_home'), _jsxs(Text, Object.assign({ as: "span", "aria-hidden": "true" }, { children: [' ', "\u2192"] }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0) }, void 0));
};
export default App;
