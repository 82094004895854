/* eslint-disable react/prop-types */
// PopupComponent.tsx
import React, { useState, Fragment, useEffect } from 'react';
import OnlineorderIcons from '../../Component/OnlineOrderPopup/OnlineorderIcons';
import DinnerTableIcon from '../../assets/Icons/DinnerTableIcon';
import MenuBookIcon from '../../assets/Icons/MenuBookIcon';
import ChefIcon from '../../assets/Icons/ChefIcon';
import ChineseFoodIcon from '../../assets/Icons/ChineseFoodIcon';
import CookingIcon from '../../assets/Icons/CookingIcon';
import DeliveryManIcon from '../../assets/Icons/DeliveryManIcon';
import DietIcon from '../../assets/Icons/DietIcon';
import FastTimeIcon from '../../assets/Icons/FastTimeIcon';
import FoodServiceIcon from '../../assets/Icons/FoodServiceIcon';
import FriedChickenIcon from '../../assets/Icons/FriedChickenIcon';
import HotDogIcon from '../../assets/Icons/HotDogIcon';
import SphagettiIcon from '../../assets/Icons/SphagettiIcon';
import TableDineIcon from '../../assets/Icons/TableDineIcon';
import WeddingdinnerIcon from '../../assets/Icons/WeddingdinnerIcon';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Button, Icon, Parent, Select, Text, Input, List, Image } from '../../Component/index';
import apiCall from '../../../API/apiCall';
import { APILINK } from '../../Utilities/ApiLink';
import { SERVERURL } from '../../Utilities/Constants';

// eslint-disable-next-line react/prop-types
const PopupComponent = ({ open, setOpen, selectedIcon, setSelectedIcon }) => {
    const [selectedImage, setSelectedImage] = useState('');
    const [customImage, setCustomImage] = useState('');
    const [customImageVal, setCustomImageVal] = useState();
    const [selectedIconIndex, setSelectedIconIndex] = useState(null);
    const [iconData, setIconData] = useState([]);
    const [selectedIconId, setSelectedIconId] = useState(selectedIcon.id);

    const handleIconClick = (res) => {
        setSelectedIconId(res.id);
        setSelectedIcon({ id: res.id, img_value: res.image_name });
    };

    // const handleSaveClick = () => {
    //     const selectedIcon = iconData[selectedIconIndex];
    //     console.log('Save button clicked. Selected icon:', selectedIcon);
    //     // Add your save logic here
    // };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setCustomImageVal(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCustomImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveClick = async () => {
        const formData = new FormData();
        formData.append('business_id', localStorage.getItem('businessId'));
        formData.append('outlet_id', localStorage.getItem('outletId'));
        formData.append('type', 'icon');
        formData.append('upload_type', 'image');
        formData.append('setup_tab', 'icon');
        formData.append('file', customImageVal);

        await apiCall
            .PostServerCall(
                APILINK.image_upload_insert,
                'POST',
                localStorage.getItem('token'),
                formData
            )
            .then((response) => {
                console.log(response);
                let respData = response.data.data;
                if (response.data.message.code === '0001') {
                    setSelectedIcon({ id: respData.insertId, img_value: respData.image_name });
                    // setSelectedIcon({ id: respData.insertId, img_value: '0-0-Diet.svg' });
                }
            })
            .catch((err) => {
                console.log(err, 'err');
            });
        // const selectedIcon = iconData[selectedIconIndex];
        // const finalImage = customImage || selectedIcon;
        // console.log('test', finalImage);
        // onSave(finalImage);
    };

    useEffect(() => {
        getIconsImages();
    }, []);

    const getIconsImages = async () => {
        await apiCall
            .GetServerCall(
                `${APILINK.image_upload}/?business_id=${localStorage.getItem(
                    'businessId'
                )}&outlet_id=${localStorage.getItem('outletId')}`,
                'GET',
                localStorage.getItem('token'),
                {}
            )
            .then(async (response) => {
                // console.log(response, 'response');
                if (response.data.message.code === '0001') {
                    setIconData(response.data.data);
                    // setSelectedIconId({ id: selectedIcon.id, img_value: selectedIcon.img_value });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        return;
    };

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-20 inset-0 overflow-y-auto" onClose={setOpen}>
                    <div className="flex items-center  justify-center min-h-screen text-center p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="relative inline-block bg-white rounded-lg px-6 pt-5 pb-4 text-left  shadow-xl transform transition-all my-8 align-middle max-w-4xl w-full overflow-y-scroll  mx-6 ">
                                <div className="popup-container">
                                    <div className=" flex justify-between items-center">
                                        <Text
                                            className="text-lg font-medium text-gray-700 "
                                            as={'p'}>
                                            Upload your icons
                                        </Text>

                                        <div className="flex-shrink-0  cursor-pointer">
                                            <XIcon
                                                className="h-5 w-5 "
                                                aria-hidden="true"
                                                onClick={() => setOpen(false)}
                                            />
                                        </div>
                                    </div>
                                    <div className="bg-gray-200 h-px rotate-[-90deg] w-full mt-2" />
                                    <div className="icons-section py-2 ">
                                        <Text className="text-lg font-medium text-gray-700" as="p">
                                            Default icons
                                        </Text>
                                        <Text className="text-sm text-gray-700  mb-3" as="p">
                                            Kindly use these icons for your needs. Icons are
                                            transparent.
                                        </Text>

                                        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-6 max-h-52 overflow-auto">
                                            {selectedIconId === 0
                                                ? iconData.map((res) => (
                                                      <div key={res.id} className="px-2">
                                                          <Image
                                                              className="flex-shrink-0 cursor-pointer w-14"
                                                              key={res.id}
                                                              onClick={() => handleIconClick(res)}
                                                              src={`${SERVERURL}${res.image_name}`}
                                                          />
                                                      </div>
                                                  ))
                                                : iconData.map((res, index) => (
                                                      <div key={res.id} className="relative px-2">
                                                          <Image
                                                              onClick={() => handleIconClick(res)}
                                                              className="flex-shrink-0 cursor-pointer w-14"
                                                              src={`${SERVERURL}${res.image_name}`}
                                                          />
                                                          {selectedIconId === res.id ? (
                                                              <div className="absolute bottom-0 left-16">
                                                                  <svg
                                                                      width="16"
                                                                      height="16"
                                                                      viewBox="0 0 16 16"
                                                                      fill="none"
                                                                      xmlns="http://www.w3.org/2000/svg">
                                                                      <circle
                                                                          cx="8"
                                                                          cy="8"
                                                                          r="8"
                                                                          fill="#10B981"
                                                                      />
                                                                      <path
                                                                          fillRule="evenodd"
                                                                          clipRule="evenodd"
                                                                          d="M11.8598 6.12904C11.9496 6.21178 12 6.32393 12 6.44086C12 6.55779 11.9496 6.66994 11.8598 6.75267L7.38628 10.871C7.29641 10.9536 7.17459 11 7.04757 11C6.92056 11 6.79873 10.9536 6.70886 10.871L4.15255 8.51765C4.10546 8.47725 4.06769 8.42854 4.04149 8.37441C4.0153 8.32029 4.00121 8.26186 4.00007 8.20261C3.99894 8.14337 4.01078 8.08452 4.03488 8.02958C4.05899 7.97463 4.09487 7.92472 4.14039 7.88282C4.1859 7.84092 4.24012 7.80789 4.2998 7.7857C4.35948 7.76351 4.42341 7.75261 4.48776 7.75365C4.55212 7.7547 4.61559 7.76767 4.67438 7.79178C4.73318 7.8159 4.78609 7.85067 4.82997 7.89402L7.04757 9.93552L11.1824 6.12904C11.2723 6.04641 11.3941 6 11.5211 6C11.6481 6 11.77 6.04641 11.8598 6.12904Z"
                                                                          fill="white"
                                                                      />
                                                                  </svg>
                                                              </div>
                                                          ) : (
                                                              <></>
                                                          )}
                                                      </div>
                                                  ))}
                                            {/* <ChefIcon />
                                            <ChineseFoodIcon />
                                            <CookingIcon />
                                            <DeliveryManIcon />
                                            <DietIcon />
                                            <DinnerTableIcon />
                                            <FastTimeIcon />
                                            <FoodServiceIcon />
                                            <FriedChickenIcon />
                                            <HotDogIcon />
                                            <MenuBookIcon />
                                            <SphagettiIcon />
                                            <TableDineIcon />
                                            <WeddingdinnerIcon /> */}
                                        </div>
                                    </div>

                                    <div className="bg-gray-200 h-px rotate-[-90deg] w-full " />
                                    <div className="image-section py-4 ">
                                        <Text
                                            className="text-lg font-medium text-gray-700 "
                                            as={'p'}>
                                            Upload own icons
                                        </Text>
                                        <Text
                                            className="text-sm font-normal text-gray-700 mt-2"
                                            as={'p'}>
                                            Transparent PNG or SVG icons are accepted. Dimension 56
                                            X 56
                                        </Text>

                                        <Parent className="space-y-1 border-2 mt-5 py-5 border-dashed text-center ">
                                            <Parent className=" ">
                                                {
                                                    <svg
                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                        stroke="currentColor"
                                                        fill="none"
                                                        viewBox="0 0 48 48"
                                                        aria-hidden="true">
                                                        <path
                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                }
                                            </Parent>
                                            <Parent className="flex flex-col text-sm text-center text-gray-600">
                                                <Text
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 "
                                                    as="label">
                                                    <Text as="span">{'Upload a file'}</Text>
                                                    <Input
                                                        id="file-upload"
                                                        name="file-upload"
                                                        type="file"
                                                        className="sr-only"
                                                        value={''}
                                                        onChange={handleFileChange}
                                                    />
                                                </Text>
                                                <Text className="pt-1" as="p">
                                                    {/* drag and drop */}
                                                </Text>
                                            </Parent>
                                            <Text className="text-xs text-gray-500" as="p">
                                                {'PNG or SVG up to 1MB'}
                                            </Text>
                                        </Parent>
                                        {/* to show image after choosed */}
                                        {customImage && (
                                            <div className="flex justify-center p-2">
                                                <Text
                                                    className="text-xs text-gray-500"
                                                    as="p"></Text>
                                                <img
                                                    src={customImage}
                                                    className="absolute rounded-md w-24 h-24 text-center p-3 "
                                                    alt="Uploaded Image"
                                                />
                                            </div>
                                        )}

                                        {selectedImage && !customImage && (
                                            <div className="selected-icon-container">
                                                <img src={selectedImage} alt="Selected Image" />
                                                <div className="tick-mark">&#10003;</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className=" flex justify-between items-center right-5 bottom-1">
                                        <Text
                                            className="text-lg font-medium text-gray-700 "
                                            as={'p'}></Text>

                                        <button
                                            disabled={customImage === ''}
                                            onClick={handleSaveClick}
                                            className="disabled:opacity-50  px-6 py-2 border border-sm rounded-md shadow-sm bg-blue-600 text-white hover:text-blue-700 hover:bg-blue-300">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default PopupComponent;
