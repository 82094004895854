import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Parent, Text, Image } from '../../Component/index';
import { imageOnErrorHandler } from '../../Utilities/Helpers';
let defalutImg = require('../../assets/media/no-image.png');
const Selected = ({ scheduleName, list, businessType, list2 }) => {
    return (_jsx(Parent, Object.assign({ className: "flex overflow-x-auto " }, { children: scheduleName === 'Category'
            ? list.map((img) => {
                return (_jsx(Parent, Object.assign({ className: "  rounded-lg flex    bg-white mr-5 " }, { children: _jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "w-11 h-11" }, { children: img.img ? (_jsx(Image, { className: " w-full h-full object-cover rounded-lg ", src: img.img
                                        ? img.img
                                        : require('../../assets/media/no-image.png'), onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "Home img" }, void 0)) : (_jsx(Image, { className: " w-full h-full object-cover rounded-lg ", src: img.cat_image
                                        ? img.cat_image
                                        : require('../../assets/media/no-image.png'), onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "Home img" }, void 0)) }), void 0), img.name ? (_jsx(Text, Object.assign({ as: "p", className: "font-normal text-xs truncate w-11 text-gray-700 text-center py-2" }, { children: img.name }), void 0)) : (_jsx(Text, Object.assign({ as: "p", className: "font-normal text-xs truncate w-11 text-gray-700 text-center py-2" }, { children: img.cat_name }), void 0))] }, void 0) }), void 0));
            })
            : list2.map((img) => {
                return (_jsx(Parent, Object.assign({ className: "  rounded-lg flex    bg-white mr-5 " }, { children: _jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "w-11 h-11" }, { children: img.img ? (_jsx(Image, { className: " w-full h-full object-cover rounded-lg ", src: img.img
                                        ? img.img
                                        : require('../../assets/media/no-image.png'), onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "Home img" }, void 0)) : (_jsx(Image, { className: " w-full h-full object-cover rounded-lg ", src: img.product_img
                                        ? img.product_img
                                        : require('../../assets/media/no-image.png'), onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "Home img" }, void 0)) }), void 0), img.name ? (_jsx(Text, Object.assign({ as: "p", className: "font-normal text-xs truncate w-11 text-gray-700 text-center py-2" }, { children: img.name }), void 0)) : (_jsx(Text, Object.assign({ as: "p", className: "font-normal text-xs truncate w-11 text-gray-700 text-center py-2" }, { children: img.prod_name }), void 0))] }, void 0) }), void 0));
            }) }), void 0));
};
export default Selected;
