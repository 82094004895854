import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Icon, Text, Parent, Checkbox } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import { CashIcon } from '@heroicons/react/solid';
import Layout from '../../Layouts/Layout';
import PageHeader from '../../Layouts/Core/PageHeader/PageHeader';
import { useMutation, useQuery } from '@apollo/client';
import { PAYTYPESUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { PAYMENTTYPESGETQUERY } from '../../GraphqlQuery/Query/Query';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../Utilities/Helpers';
const PaymentType = ({ reloadData }) => {
    const [name, setName] = useState([]);
    const [apiResultArray, setApiResultArray] = useState([]);
    const { loading, error, data, refetch } = useQuery(PAYMENTTYPESGETQUERY);
    const [updatePayType, { data: data1, loading: loading1, error: error1 }] = useMutation(PAYTYPESUPDATEQUERY);
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const breadCrumbs = [
        // { title: t('dashboard'), href: '#/dashboard', current: false },
        { title: t('settings'), href: '#/settings', current: false },
        { title: t('payment_type'), href: '#/payment-type', current: true }
    ];
    const loadData = () => {
        refetch();
    };
    const updatePay = (event, id) => {
        updatePayType({
            variables: {
                id: id,
                is_enable: event.target.checked === true ? '1' : '0'
            }
        }).then((result) => {
            loadData();
        });
    };
    useEffect(() => {
        if (data)
            setApiResultArray(data.getAllPaytype);
    }, [data]);
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: "PaymentType", breadcrumbs: breadCrumbs }, void 0), _jsx(Parent, Object.assign({ className: " max-w-8xl w-full px-11 align-middle inline-block " }, { children: _jsx(ContainerSection, Object.assign({ className: "bg-white max-w-8xl rounded-lg shadow-custom  px-5 py-5 mt-3" }, { children: _jsx(Parent, Object.assign({ className: "flex  flex-wrap w-full justify-between pt-10 px-10 pb-40" }, { children: apiResultArray &&
                            apiResultArray.map((person) => {
                                return (_jsx(Parent, Object.assign({ className: "w-1/5" }, { children: _jsxs(Parent, Object.assign({ className: " hover:bg-white w-3/4 hover:shadow-custom items-center relative p-12 " }, { children: [_jsx(Parent, Object.assign({ className: "flex flex-row-reverse items-center" }, { children: _jsx(Checkbox, { type: "checkbox", name: "cash", value: name, id: "cash", checked: person.is_enable === '1' ? true : false, className: classNames(businessType == 'Retail'
                                                        ? 'text-blue-500 '
                                                        : 'text-yellow-500 ', 'focus:ring-transparent -ml-3 -mt-8 h-4 w-4 absolute items-center border-gray-300 rounded'), onChange: (e) => updatePay(e, person.id) }, void 0) }), void 0), _jsx(Icon, { tag: CashIcon, className: "w-14 h-14 text-white mx-auto rounded-md bg-green-400 p-3" }, void 0), _jsx(Text, Object.assign({ className: "font-poppins text-xl  font-medium text-center text-gray-700 pt-4", as: "p" }, { children: person.name }), void 0), _jsxs(Text, Object.assign({ className: "font-poppins text-xs  font-medium text-center text-gray-700 pt-1", as: "p" }, { children: ["Payment type using ", person.name] }), void 0)] }), void 0) }), person.id));
                            }) }), void 0) }), void 0) }), void 0)] }, void 0));
};
export default PaymentType;
