import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { classNames } from '../../Utilities/Helpers';
import { Parent, Text } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import Order from '../Orders/Orders';
import KotOrders from './KotOrder/KotOrders';
import OnlineOrders from './OnlineOrder/Onlineorders';
const KotOrder = ({}) => {
    const { t } = useTranslation();
    const tabs = [
        { id: 1, name: t('all_orders'), href: '#', content: Order },
        { id: 2, name: t('kot_orders'), href: '#', content: KotOrders },
        { id: 3, name: t('Online_orders'), href: '#', content: OnlineOrders }
    ];
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [loading, setLoading] = useState(false);
    const [tabId, setTabId] = useState(1);
    const tabClick = (id) => {
        setTabId(id);
    };
    return (_jsx(Parent, Object.assign({ className: "lg:px-12 md:px-6 mt-3 px-4 pb-9" }, { children: loading ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames(businessType == 'Retail' ? 'border-blue-900' : 'border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames(businessType == 'Retail' ? 'text-blue-900' : 'text-blue-900', ' font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsx(ContainerSection, Object.assign({ className: 'bg-white max-w-8xl rounded-lg shadow-custom  px-5 py-5 mt-3' }, { children: businessType == 'Retail' ? ('') : (_jsx(Parent, { children: _jsxs(Tab.Group, { children: [_jsx(Tab.List, Object.assign({ className: "-mb-px  flex space-x-8 border-b-2 border-gray-200 justify-around sm:justify-start", "aria-label": "Tabs" }, { children: tabs.map((item) => {
                                return (_jsx(Tab, { children: ({ selected }) => {
                                        return (_jsx(Parent, Object.assign({ onClick: () => tabClick(item.id), className: businessType == 'Retail'
                                                ? classNames(selected
                                                    ? 'border-blue-600 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center pb-4 px-1 border-b-2 font-medium text-sm')
                                                : classNames(selected
                                                    ? 'border-blue-600 text-blue-600'
                                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'group inline-flex items-center pb-4 px-1 border-b-2 font-medium text-sm') }, { children: _jsx(Text, { as: "span", label: item.name }, void 0) }), item.id));
                                    } }, item.id));
                            }) }), void 0), _jsx(Tab.Panels, { children: tabs.map((item) => {
                                const Tag = item.content;
                                return (_jsx(Tab.Panel, { children: _jsx(Tag, { tabId: tabId }, void 0) }, item.id));
                            }) }, void 0)] }, void 0) }, void 0)) }), void 0)) }), void 0));
};
export default KotOrder;
