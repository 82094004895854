import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab } from '@headlessui/react';
import { KeyIcon } from '@heroicons/react/solid';
import { Parent, Text } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import viewAddOn from './viewAddOns';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
const Tabs = ({ items, handelChanges, apiResultArray, setSize, handelFilter, deletFilter, returnData, addOns, add, removeAdd, removeVari, variation }) => {
    const { t } = useTranslation();
    const tabs = [
        {
            id: 2,
            name: t('add_on'),
            href: '#',
            icon: KeyIcon,
            content: viewAddOn
        }
    ];
    return (_jsxs(Parent, Object.assign({ className: "pb-5" }, { children: [_jsx(Parent, Object.assign({ className: "py-5" }, { children: _jsx(Text, Object.assign({ className: "block text-base  font-medium  font-poppins text-gray-800  ", as: "p" }, { children: t('addons') }), void 0) }), void 0), _jsx(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg" }, { children: _jsxs(Tab.Group, { children: [_jsx(Tab.List, Object.assign({ className: "-mb-px px-3 flex space-x-8 ", "aria-label": "Tabs" }, { children: tabs === null || tabs === void 0 ? void 0 : tabs.map((item) => {
                                return (_jsx(Tab, { children: ({ selected }) => {
                                        return _jsx(_Fragment, {}, void 0);
                                    } }, item === null || item === void 0 ? void 0 : item.id));
                            }) }), void 0), _jsx(Tab.Panels, { children: tabs === null || tabs === void 0 ? void 0 : tabs.map((item) => {
                                const Tag = item === null || item === void 0 ? void 0 : item.content;
                                return (_jsx(Tab.Panel, { children: _jsx(Tag, { items: items, handelChanges: handelChanges, apiResultArray: apiResultArray, setSize: setSize, handelFilter: handelFilter, deletFilter: deletFilter, returnData: returnData, addOns: addOns, add: add, removeAdd: removeAdd, removeVari: removeVari, variation: variation }, void 0) }, item === null || item === void 0 ? void 0 : item.id));
                            }) }, void 0)] }, void 0) }), void 0)] }), void 0));
};
export default Tabs;
