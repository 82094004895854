import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import PosSession from '../Containers/PosSession/PosSession';
const PosSessionPage = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false, show: 'block' },
        {
            title: t('settings'),
            href: '#/settings',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: t('outlet_settings'),
            href: '#/outlet-settings',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: t('pos_session'),
            href: '#/pos-session',
            current: true,
            show: 'block'
        }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('pos_session'), breadcrumbs: breadCrumbs }, void 0), _jsx(PosSession, {}, void 0)] }, void 0));
};
export default PosSessionPage;
