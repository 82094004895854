var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import { Icon, Link, Nav } from '../../../Component';
import { classNames } from '../../../Utilities/Helpers';
const SideNavbar = (_a) => {
    var { navItems, setSidebarOpen } = _a, props = __rest(_a, ["navItems", "setSidebarOpen"]);
    const [busType, setBusType] = useState(localStorage.getItem('business_type'));
    return (_jsx(Nav, Object.assign({ "aria-label": "Sidebar", className: "py-6 hidden lg:flex flex-col items-center space-y-3" }, { children: navItems.map((item) => (_jsx(Link, Object.assign({ title: item.name, onClick: () => { var _a; return item.onClick(item.link, ((_a = item.children) === null || _a === void 0 ? void 0 : _a.length) == 0 ? 0 : 1); }, className: busType == 'Retail'
                ? classNames(item.current
                    ? 'bg-blue-700 text-white'
                    : 'text-blue-50 hover:text-white ', 'flex items-center p-2.5 rounded-lg hover:text-white hover:bg-blue-700 cursor-pointer')
                : classNames(item.current
                    ? 'bg-blue-700 text-white'
                    : 'text-blue-50 hover:text-white ', 'flex items-center p-2.5 rounded-lg hover:text-white hover:bg-blue-700 cursor-pointer') }, { children: _jsx(Icon, { tag: item.icon, color: item.color, className: "h-6 w-6", "aria-hidden": "true" }, void 0) }), item.name))) }), void 0));
};
export default SideNavbar;
