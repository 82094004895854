var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { Parent, Icon, Image, Link, Text, Input, Checkbox } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import ConfirmationAlert from '../../Component/ConfirmationAlert/ConfirmationAlert';
import ViewInfo from '../../Component/SlideOverlay/ViewInfo';
import { useHistory } from 'react-router';
import apiCall from '../../../API/apiCall';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames, imageOnErrorHandler, getRoleName } from '../../Utilities/Helpers';
import { APILINK } from '../../Utilities/ApiLink';
let defalutImg = require('../../assets/media/no-image.png');
import ServerSidePagination from '../Pagination/ServerSidePagination';
const ItemsTable = ({ columns, resultData, total, getItem, checks, setCurrentPageNumber, currentPageNumber, offset, setOffset, items, pageLimit, dataLimit, getPaginatedData, setDataLimit, startIndex, endIndex, currentPage, setCurrentPage, setItems, strt, setStrt, end, setEnd }) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [editProductId, setEditProductId] = useState();
    const history = useHistory();
    const [token, setToken] = useState(localStorage.getItem('token'));
    const showConfirmation = (value, Id, Name) => {
        setShowAlert(value);
        setUserId(Id);
        setUserName(Name);
    };
    const editProduct = (id) => {
        setEditProductId(id);
    };
    const handleProductEdit = (e, id) => {
        const { value } = e.target;
        const productEditValue = items === null || items === void 0 ? void 0 : items.map((item) => {
            if (id == item.id) {
                return Object.assign(Object.assign({}, item), { price: value });
            }
            else {
                return item;
            }
        });
        setItems(productEditValue);
        updateProductPrice(id, value);
    };
    const updateProductPrice = (id, value) => __awaiter(void 0, void 0, void 0, function* () {
        let params = {
            id: id,
            price: value
        };
        yield apiCall
            .PostServerCall(`${APILINK.product_inline_update}${id}`, 'PUT', token, params)
            .then((response) => { })
            .catch((err) => {
            console.log(err);
        });
    });
    const editItem = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(`${APILINK.product_get}${id}`, 'GET', token, {})
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            let responsData = response.data.data[0];
            localStorage.setItem('apiSiArray', JSON.stringify(responsData));
        }))
            .catch((err) => {
            console.log(err);
        });
        localStorage.setItem('prodectId', id);
        history.push('/add-item');
    });
    const viewItem = (id) => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(`${APILINK.product_get}${id}`, 'GET', token, {})
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            let responsData = response.data.data[0];
            localStorage.setItem('apiSiArray', JSON.stringify(responsData));
        }))
            .catch((err) => {
            console.log(err);
        });
        localStorage.setItem('prodectId', id);
        history.push('/view-item');
    });
    const deleteUser = () => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPageNumber(1);
        yield apiCall
            .PostServerCall(`${APILINK.product_delete}${userId}`, 'DELETE', token, {})
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            getItem();
        }))
            .catch((err) => {
            console.log(err);
        });
        setShowAlert(false);
    });
    useEffect(() => {
        setItems(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "py-0 align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200" }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: items.map((person, index) => {
                                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                                        return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "py-2.5 whitespace-nowrap w-1/6" }, { children: _jsxs(Parent, Object.assign({ className: "flex items-center " }, { children: [_jsxs(Parent, { children: [' ', _jsx(Checkbox, { type: "checkbox", name: 'item.cat_name', checked: person.checked, onChange: (e) => checks(e, index), className: classNames(businessType == 'Retail'
                                                                            ? 'text-blue-500'
                                                                            : 'text-blue-500', 'focus:ring-transparent h-4 mr-2 ml-2  w-4 items-center justfy-center border-gray-300 rounded '), id: person.id }, void 0)] }, void 0), _jsx(Parent, Object.assign({ className: "ml-2 w-10  h-10 object-cover rounded-lg" }, { children: _jsx(Image, { className: "w-full h-full object-cover rounded-lg ", src: person.product_img
                                                                        ? person.product_img
                                                                        : require('../../assets/media/no-image.png'), onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "girl img" }, void 0) }), void 0)] }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5  w-2/6" }, { children: _jsxs(Parent, Object.assign({ className: "flex flex-col " }, { children: [_jsx(Text, Object.assign({ className: `text-sm font-normal font-poppins text-gray-800 ${person.prod_name.length > 20
                                                                    ? 'truncate-md'
                                                                    : ''}`, as: "p" }, { children: person.prod_name }), void 0), _jsxs(Parent, Object.assign({ className: classNames(person.variation
                                                                    ? ' flex pt-1  items-center '
                                                                    : '') }, { children: [_jsx(Parent, Object.assign({ className: "flex" }, { children: (person.variation &&
                                                                            ((_b = (_a = JSON.parse(person.variation).Variation1) === null || _a === void 0 ? void 0 : _a.colorOf) === null || _b === void 0 ? void 0 : _b.length) > 0) ||
                                                                            (person.variation &&
                                                                                ((_d = (_c = JSON.parse(person.variation).Variation2) === null || _c === void 0 ? void 0 : _c.sizeOf) === null || _d === void 0 ? void 0 : _d.length) > 0) ? (_jsx(Text, { className: "text-xs bg-yellow-400 rounded-full px-2 py-1 items-center font-medium  font-poppins text-white ", as: "p", label: "V" }, void 0)) : ('') }), void 0), _jsx(Parent, Object.assign({ className: "flex" }, { children: person.addon &&
                                                                            ((_e = JSON.parse(person.addon).addOn) === null || _e === void 0 ? void 0 : _e.length) >
                                                                                0 ? (_jsx(Text, { className: classNames((person.variation &&
                                                                                ((_g = (_f = JSON.parse(person.variation)
                                                                                    .Variation1) === null || _f === void 0 ? void 0 : _f.colorOf) === null || _g === void 0 ? void 0 : _g.length) > 0) ||
                                                                                (person.variation &&
                                                                                    ((_j = (_h = JSON.parse(person.variation).Variation2) === null || _h === void 0 ? void 0 : _h.sizeOf) === null || _j === void 0 ? void 0 : _j.length) > 0)
                                                                                ? '-ml-2'
                                                                                : '', 'text-xs bg-blue-400 rounded-full px-2 py-1 items-center font-medium  font-poppins text-white '), as: "p", label: "A" }, void 0)) : ('') }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-4 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsxs(Parent, Object.assign({ className: " flex items-center " }, { children: [person.type == 'Veg' ? (_jsx("img", { src: "/src/assets/media/veg.png", className: "h-4  mt-1 w-4 ", alt: "" }, void 0)) : person.type == 'Beverages' ? (_jsx("img", { src: "/src/assets/media/beverage.png", className: "h-4  mt-1 w-4 ", alt: "" }, void 0)) : (_jsx("img", { src: "/src/assets/media/nonVeg.png", className: "h-4  mt-1 w-4 ", alt: "" }, void 0)), _jsx(Text, Object.assign({ className: classNames(person.type == 'Beverages'
                                                                    ? 'text-sm ml-2 mt-1 font-regular w-12 truncate md:text-clip font-poppins text-gray-800'
                                                                    : 'text-sm ml-2 mt-1 font-regular font-poppins text-gray-800'), as: "p" }, { children: person.sku && person.sku != 'NULL'
                                                                    ? person.type
                                                                    : '' }), void 0)] }), void 0) }), void 0), person.id == editProductId ? (_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: " " }, { children: _jsx(Input, { type: "number", name: "total", className: "text-xs font-poppins font-medium text-right  text-gray-700", id: "total", onChange: (e) => handleProductEdit(e, person.id), value: person.price }, void 0) }), void 0) }), void 0)) : (_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: " " }, { children: _jsx(Text, Object.assign({ className: "text-sm  text-right font-regular font-poppins text-gray-800", as: "p", onClick: () => editProduct(person.id) }, { children: person.price }), void 0) }), void 0) }), void 0) }), void 0)), businessType == 'Retail' && (_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: " " }, { children: _jsx(Text, Object.assign({ className: "text-sm  font-regular  font-poppins text-gray-800", as: "p" }, { children: person.available_qty }), void 0) }), void 0) }), void 0) }), void 0)), _jsxs(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap text-center text-sm font-medium w-1/6" }, { children: [_jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                                ? 'hover:bg-blue-600'
                                                                : 'hover:bg-blue-600', 'group mr-4 inline-flex items-center px-3 py-3  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none cursor-pointer'), title: t('view'), onClick: () => viewItem(person.id) }, { children: _jsx(Icon, { tag: EyeIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(getRoleName() !== 'Admin'
                                                                ? ' cursor-not-allowed opacity-50'
                                                                : 'cursor-pointer', 'group mr-4 hover:bg-blue-600 inline-flex items-center px-3 py-3  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none cursor-pointer'), title: t('edit'), disabled: getRoleName() !== 'Admin', onClick: () => getRoleName() == 'Admin' && editItem(person.id) }, { children: _jsx(Icon, { tag: PencilIcon, className: "h-3.5 w-3.5  group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(getRoleName() !== 'Admin'
                                                                ? ' cursor-not-allowed opacity-50'
                                                                : 'cursor-pointer', 'group hover:bg-blue-600 inline-flex items-center px-3 py-3  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none cursor-pointer'), title: t('delete'), onClick: () => getRoleName() == 'Admin' &&
                                                                showConfirmation(true, person.id, person.prod_name) }, { children: _jsx(Icon, { tag: TrashIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0)] }), void 0)] }, person.id));
                                    }) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(ServerSidePagination, { totalData: resultData, items: items, pageLimit: pageLimit, dataLimit: dataLimit, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, total: total, from: 'item', offset: offset, setOffset: setOffset, getItem: getItem, currentPage: currentPage, setCurrentPage: setCurrentPage, strt: strt, setStrt: setStrt, end: end, setEnd: setEnd }, void 0), _jsx(ConfirmationAlert, { id: userId, open: showAlert, setOpen: setShowAlert, deleteRecord: deleteUser, title: t('are_you_sure'), description: `${t('do_you_really_want_to_delete')} ${userName} ?` }, void 0), _jsx(ViewInfo, { open: showInfo, setOpen: setShowInfo, title: "Customer Information" }, void 0)] }), void 0));
};
export default ItemsTable;
