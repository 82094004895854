var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Parent, Text, Link } from '..';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { getRoleName } from '../../Utilities/Helpers';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
const DotIcon = (_a) => {
    var { className, children, person, editClick, name, dot_view, onClick, getFields, showConfirmation, catId, catName, sliderOpen } = _a, props = __rest(_a, ["className", "children", "person", "editClick", "name", "dot_view", "onClick", "getFields", "showConfirmation", "catId", "catName", "sliderOpen"]);
    const { t } = useTranslation();
    return (_jsx(Parent, { children: _jsxs(Menu, Object.assign({ as: "div", className: "relative inline-block text-left" }, { children: [_jsx(Parent, { children: _jsxs(Menu.Button, Object.assign({ className: " bg-blue-50 py-2 px-2.5 rounded-full flex items-center text-blue-600 focus:outline-none focus:ring-transparent" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "sr-only" }, { children: t('open_options') }), void 0), _jsx(DotsHorizontalIcon, { className: "h-6 w-5", "aria-hidden": "true" }, void 0)] }), void 0) }, void 0), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, Object.assign({ className: "origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" }, { children: _jsxs(Parent, Object.assign({ className: "py-1" }, { children: [_jsx(Menu.Item, { children: ({ active }) => (_jsx(Link
                                    // href="#"
                                    , Object.assign({ 
                                        // href="#"
                                        title: t('add_sub_category'), onClick: () => sliderOpen(true, t('add_sub_category'), catId), className: classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm hover:text-blue-600 cursor-pointer') }, { children: t('add_sub_category') }), void 0)) }, void 0), dot_view === 'products' ? ('') : (_jsx(Menu.Item, { children: ({ active }) => (_jsx(Link
                                    // href="#"
                                    , Object.assign({ 
                                        // href="#"
                                        title: t('edit'), disabled: getRoleName() !== 'Admin', onClick: () => getRoleName() == 'Admin' &&
                                            sliderOpen(true, t('edit_category'), catId), className: classNames(getRoleName() !== 'Admin'
                                            ? ' cursor-not-allowed opacity-50 '
                                            : 'cursor-pointer', active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700', 'block px-4 py-2 text-sm hover:text-blue-600 cursor-pointer') }, { children: t('edit') }), void 0)) }, void 0)), _jsx(Menu.Item, { children: ({ active }) => (_jsx(Link, Object.assign({ onClick: () => getRoleName() == 'Admin' &&
                                            showConfirmation(true, catId, catName), title: t('delete'), disabled: getRoleName() !== 'Admin', className: classNames(getRoleName() !== 'Admin'
                                            ? ' cursor-not-allowed opacity-50'
                                            : 'cursor-pointer', active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm hover:text-blue-600 cursor-pointer') }, { children: t('delete') }), void 0)) }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0));
};
export default DotIcon;
