import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import ReportsContainer from '../Containers/Reports/Reports';
const Report = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false },
        { title: t('report'), href: '#/reports', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('report'), breadcrumbs: breadCrumbs }, void 0), _jsx(ReportsContainer, { title: t('report') }, void 0)] }, void 0));
};
export default Report;
