import { jsx as _jsx } from "react/jsx-runtime";
/** @format */
import { useEffect, useState } from 'react';
import { colourStyles } from './data';
import Select from 'react-select';
const MultiSelect = ({ apiResultArray, setSelect, select, editCategory, editGroupCat, validSelect }) => {
    const [colourOptions, setColourOptions] = useState(apiResultArray);
    let sele = [];
    const multiSel = (options) => {
        options.map((item) => {
            sele.push(item.value);
        });
        setSelect(sele.toString());
        editGroupCat(options);
        validSelect('select cat', options, '');
    };
    useEffect(() => {
        let optionArray = [];
        apiResultArray.map((item) => {
            let finde = false;
            editCategory.map((value) => {
                if (item.value == value.value) {
                    finde = true;
                }
            });
            if (!finde) {
                optionArray.push(item);
            }
        });
        setColourOptions(optionArray);
    }, []);
    return (_jsx(Select, { className: "focus:ring-blue-600 focus:border-blue-600 shadow-sm block w-full sm:text-sm border-gray-200 text-gray-400 rounded-md font-poppins font-normal py-0.5 ", name: "category", value: editCategory, closeMenuOnSelect: false, isMulti: true, options: colourOptions, styles: colourStyles, onChange: (options) => multiSel(options), onBlur: () => validSelect('select cat', editCategory, '') }, void 0));
};
export default MultiSelect;
