import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { Menu, Transition } from '@headlessui/react/';
import { Fragment } from 'react';
import { Parent, Text } from '../../Component';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
const MoreSlate = ({ time }) => {
    const { t } = useTranslation();
    return (_jsxs(Menu, Object.assign({ as: "div", className: "  flex-shrink-0" }, { children: [_jsx(Parent, { children: _jsxs(Menu.Button, Object.assign({ className: "bg-white flex text-sm focus:outline-none" }, { children: [_jsx("span", Object.assign({ className: "sr-only" }, { children: "Open user menu" }), void 0), _jsx("span", Object.assign({ className: "inline-block " }, { children: _jsx(Parent, Object.assign({ className: "h-5  ml-2 ring-2 px-2 ring-white  bg-blue-600 rounded-full flex justify-center items-center" }, { children: _jsx(Text, Object.assign({ as: "span", className: "text-xs font-normal text-white" }, { children: t('more') }), void 0) }), void 0) }), void 0)] }), void 0) }, void 0), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, Object.assign({ className: "origin-top-right absolute z-40 right-0 mt-2 w-52 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" }, { children: time.map((time, index) => {
                        return (index != 0 && (_jsx(Menu.Item, { children: _jsxs(Parent, Object.assign({ className: "text-sm p-3 border-b-2 flex items-center  font-poppins font-normal text-gray-800 hover:bg-gray-100 hover:text-blue-400" }, { children: [time.strt, " - ", time.cls] }), void 0) }, void 0)));
                    }) }), void 0) }), void 0)] }), void 0));
};
export default MoreSlate;
