var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
const Select = (_a) => {
    var { className, name, id, children, optionItems, value, defaultValue, placeHolder, onChange, onBlur } = _a, props = __rest(_a, ["className", "name", "id", "children", "optionItems", "value", "defaultValue", "placeHolder", "onChange", "onBlur"]);
    return (_jsx("select", Object.assign({ id: id, name: name, className: className, placeholder: placeHolder, value: value, defaultValue: defaultValue, onChange: onChange, onBlur: onBlur }, { children: optionItems === null || optionItems === void 0 ? void 0 : optionItems.map((item) => {
            return (_jsxs(_Fragment, { children: [item.img && (_jsx("img", { src: item.img, alt: "", className: "flex-shrink-0 h-4 w-4 rounded" }, void 0)), _jsx("option", Object.assign({ value: item.value, hidden: item.hidden }, { children: item.label }), item.value)] }, void 0));
        }) }), void 0));
};
export default Select;
