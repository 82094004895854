import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Image, Text } from '../../Component/index';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import { useQuery } from '@apollo/client';
import { ADDONSGETQUERY } from '../../GraphqlQuery/Query/Query';
import TableHeader from '../TableHeaders/TableHeader';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
const Category = [
    {
        label: 'Select ',
        value: '1'
    }
];
const ViewAddOn = ({ addOns, add, removeAdd }) => {
    const { t } = useTranslation();
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const columns = [
        {
            id: 1,
            title: (_jsx(Text, { className: " p-4 text-left text-sm col-span-1 font-poppins font-medium text-gray-400", as: "p", label: t('type') }, void 0)),
            className: ''
        },
        {
            id: 2,
            title: (_jsx(Text, { className: "  p-4 text-center text-sm col-span-1 font-poppins font-medium text-gray-400 ", as: "p", label: t('add_on') }, void 0))
        },
        {
            id: 3,
            title: (_jsx(Text, { className: "  p-4 text-right col-span-1 text-sm font-poppins font-medium text-gray-400", as: "p", label: `${t('price')} (${currencySymbol})` }, void 0))
        }
    ];
    const [apiAddonArray, setApiAddonArray] = useState([]);
    const [addOnId, setaddOnId] = useState(0);
    const [addRows, setaddRows] = useState([
        {
            label: 'val'
        }
    ]);
    const { loading, error, data, refetch } = useQuery(ADDONSGETQUERY);
    useEffect(() => {
        if (data) {
            let oldaddon = [];
            oldaddon.push({
                label: 'Select Addon',
                value: '',
                hidden: true
            });
            data.getAllAddon.map((addon) => {
                let cata = {
                    label: addon.name,
                    value: addon.id
                };
                oldaddon.push(cata);
                setApiAddonArray(oldaddon);
            });
        }
    }, [data]);
    useEffect(() => {
        if (add)
            setaddRows(add);
    }, [add]);
    return (_jsx(Parent, Object.assign({ className: "overflow-hidden border-b  border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y  divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "" }, { children: _jsx(TableHeader, { scope: "col", className: "text-left text-sm font-poppins font-medium text-gray-400", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: addRows.map((addOns) => (_jsx(_Fragment, { children: _jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-4 py-2.5  whitespace-nowrap w-2/6" }, { children: _jsxs(Parent, Object.assign({ className: "flex col-span-1 " }, { children: [_jsx(Image, { src: addOns.type_name == 'Veg'
                                                    ? require('../../assets/media/veg.png')
                                                    : addOns.type_name == 'Non-Veg'
                                                        ? require('../../assets/media/nonVeg.png')
                                                        : addOns.type_name == 'Egg'
                                                            ? require('../../assets/media/egg.png')
                                                            : '', className: "h-4 mt-1 w-4 " }, void 0), _jsx(Text, { className: "block text-sm  font-normal pl-2 font-poppins text-gray-600   ", as: "p", label: addOns.type_name }, void 0)] }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-4 py-2.5  whitespace-nowrap w-2/6" }, { children: _jsx(Text, { as: "label", className: "block  text-sm font-medium col-span-1 font-poppins text-gray-600 text-center  ", label: addOns.addon_name }, void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-4 py-2.5 whitespace-nowrap w-2/6" }, { children: _jsx(Text, { as: "label", className: "block  text-sm font-medium col-span-1 font-poppins text-right text-gray-600  ", label: addOns.price }, void 0) }), void 0)] }, addOns.id) }, void 0))) }), void 0)] }), void 0) }), void 0));
};
export default ViewAddOn;
