import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { Parent, Icon, Image, Link, Text } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import Pagination from '../Pagination/Pagination';
import { DATALIMIT, PAGELIMIT } from '../../Utilities/Constants';
import ConfirmationAlert from '../../Component/ConfirmationAlert/ConfirmationAlert';
import { useMutation } from '@apollo/client';
import { GROUPCATEDELETEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import { useHistory } from 'react-router-dom';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { imageOnErrorHandler } from '../../Utilities/Helpers';
let defalutImg = require('../../assets/media/no-image.png');
const GroupCateTable = ({ className, onClick, resultData, getSingleObj, columns, total, reloadData, currentPageNumber, setCurrentPageNumber }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [showAlert, setShowAlert] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [columnsData, setColumnsData] = useState(columns);
    const [tableData, setTableData] = useState(resultData);
    const [id, setId] = useState('');
    const [deleteName, setDeleteName] = useState('');
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [comView, setComView] = useState();
    const [deleteRecordQuery, { data, loading, error }] = useMutation(GROUPCATEDELETEQUERY);
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        const list = resultData.slice(startIndex, endIndex);
        setEndIndex(resultData.length > endIndex ? endIndex : resultData.length);
        setStartIndex(startIndex + 1);
        setTableData(list);
    };
    const showConfirmation = (value, id, deleteName) => {
        setShowAlert(value);
        setId(id);
        setDeleteName(deleteName);
    };
    const deleteRecord = () => {
        deleteRecordQuery({
            variables: {
                id: parseInt(id),
                is_active: '0'
            }
        }).then((result) => {
            reloadData('', '');
            setShowAlert(false);
        });
    };
    useEffect(() => {
        setTableData(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columnsData }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: tableData &&
                                        tableData.map((person) => {
                                            var _a, _b, _c, _d;
                                            return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/5" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.id }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/5" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.group_cat_name }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/5 " }, { children: _jsx(Parent, Object.assign({ className: "flex sm:absolute items-center " }, { children: _jsxs(Parent, Object.assign({ className: "flex sm:relative bottom-3  -space-x-2" }, { children: [(_a = person.category) === null || _a === void 0 ? void 0 : _a.map((applicant) => {
                                                                        return (_jsx(Parent, { children: _jsx(Image, { className: "inline-block h-7 w-7 cursor-pointer rounded-full ring-2 shadow-custom ring-white", src: applicant.cat_image
                                                                                    ? applicant.cat_image
                                                                                    : require('../../assets/media/no-image.png'), onError: (e) => imageOnErrorHandler(e, defalutImg), title: applicant.cat_name }, void 0) }, applicant.id));
                                                                    }), ((_b = person === null || person === void 0 ? void 0 : person.category) === null || _b === void 0 ? void 0 : _b.length) > 4 && (_jsxs(Menu, Object.assign({ as: "div", className: " absolute -right-5 inline-block text-left " }, { children: [_jsx("div", { children: _jsx(Menu.Button, Object.assign({ className: "inline-flex justify-center w-full rounded-md text-sm font-normal text-gray-700 hover:bg-gray-50  " }, { children: _jsx(Parent, Object.assign({ className: "h-7 w-7 ring-2 ring-white  bg-blue-600 rounded-full flex justify-center items-center" }, { children: _jsxs(Text, Object.assign({ as: "span", className: "text-sm font-normal text-white" }, { children: ["+", ((_c = person === null || person === void 0 ? void 0 : person.category) === null || _c === void 0 ? void 0 : _c.length) - 4] }), void 0) }), void 0) }), void 0) }, void 0), _jsx(Transition, Object.assign({ as: Fragment, enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95" }, { children: _jsx(Menu.Items, Object.assign({ className: "origin-top-right absolute h-80 overflow-y-auto right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y z-10 divide-gray-100 focus:outline-none" }, { children: (_d = person === null || person === void 0 ? void 0 : person.cat_Data) === null || _d === void 0 ? void 0 : _d.map((applicant) => (_jsx(_Fragment, { children: _jsx(Menu.Item, { children: ({ active }) => (_jsxs(Parent, Object.assign({ className: "flex items-center px-4 py-2" }, { children: [_jsx(Image, { className: "inline-block h-7 w-7 rounded-full ring-2 ring-white", onError: (e) => imageOnErrorHandler(e, defalutImg), src: person.type ==
                                                                                                            'Item'
                                                                                                            ? applicant.cat_image
                                                                                                                ? applicant.cat_image
                                                                                                                : defalutImg
                                                                                                            : applicant.cat_image
                                                                                                                ? applicant.cat_image
                                                                                                                : defalutImg }, applicant.cat_name), _jsx("a", Object.assign({ className: 'text-gray-700 block px-4 py-2 text-sm' }, { children: person.type ==
                                                                                                            'Item'
                                                                                                            ? applicant.prod_name
                                                                                                            : applicant.cat_name }), void 0)] }), void 0)) }, void 0) }, void 0))) }), void 0) }), void 0)] }), void 0))] }), void 0) }), void 0) }), void 0), _jsxs(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap text-center text-sm font-normal w-1/5" }, { children: [_jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                                    ? 'hover:bg-blue-600'
                                                                    : 'hover:bg-blue-600', 'group mr-4 inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white  focus:outline-none'), title: t('edit'), onClick: () => getSingleObj(person.id, true, t('edit_grp')) }, { children: _jsx(Icon, { tag: PencilIcon, className: "h-3.5 w-3.5  group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                                    ? 'hover:bg-blue-600'
                                                                    : 'hover:bg-blue-600', 'group inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white  focus:outline-none'), title: t('delete'), onClick: () => showConfirmation(true, person.id, person.combo_name) }, { children: _jsx(Icon, { tag: TrashIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0)] }), void 0)] }, person.id));
                                        }) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Pagination, { items: tableData, pageLimit: pageLimit, dataLimit: dataLimit, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, totalData: resultData, total: total }, void 0), _jsx(ConfirmationAlert, { id: id, open: showAlert, setOpen: setShowAlert, deleteRecord: deleteRecord, title: t('are_you_sure'), description: `${t('products_assigned_to_the')} ${deleteName} ${t('category_will_be_deleted_do_you_want_to_proceed')}` }, void 0)] }), void 0));
};
export default GroupCateTable;
