import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import Settings from '../Containers/Settings/Settings';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const Setting = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false },
        { title: t('settings'), href: '#/settings', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('settings'), breadcrumbs: breadCrumbs }, void 0), _jsx(Settings, { title: t('settings') }, void 0)] }, void 0));
};
export default Setting;
