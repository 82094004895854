import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Routes from './app/Routers/Routes';
export const AppContext = React.createContext({
    itemsFilterCategory: [],
    setItemsFilterCategory: () => { }
});
function App() {
    const [itemsFilterCategory, setItemsFilterCategory] = React.useState([]);
    return (_jsx(AppContext.Provider, Object.assign({ value: {
            itemsFilterCategory,
            setItemsFilterCategory
        } }, { children: _jsx(Routes, {}, void 0) }), void 0));
}
export default App;
