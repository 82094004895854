var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Parent } from '../../../Component';
import Main from '../../../Component/Main/Main';
const Content = (_a) => {
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    return (_jsx(Main, Object.assign({ className: "overflow-x-hidden flex-1 bg-gray-200 border-t border-gray-200 lg:flex overflow-y-auto" }, { children: _jsx(Parent, Object.assign({ "aria-labelledby": "primary-heading", className: "min-w-0 flex-1 h-full flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: "" }, { children: _jsx(Parent, Object.assign({ className: "h-full relative" }, { children: children }), void 0) }), void 0) }), void 0) }), void 0));
};
export default Content;
