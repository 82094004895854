import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-empty-pattern */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * /* Copyright (C) efficientworks.co.uk - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Contact <ravi.raya@gmail.com>, Auto Generated Code October 2021
 *
 * @format
 */
import { useState, useEffect } from 'react';
import { Input, Parent, Text } from '../../Component/index';
import { UserGroupIcon, SearchIcon } from '@heroicons/react/solid';
import MiscellaneousTable from './MiscellaneousTable';
import SlideOverlay from '../../Component/SlideOverlay/SlideOverlay';
import { useQuery } from '@apollo/client';
import { MISCELLANEOUSGETQUERY } from '../../GraphqlQuery/Query/Query';
import { CREATEMISCELLANEOUSADDQUERY, CREATEMISCELLANEOUSUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import DataNotFound from '../DataNotFound/DataNotFound';
import { STARTPAGE, MISCELLANEOUS } from '../../Utilities/Constants';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames, filterFunction } from '../../Utilities/Helpers';
const Miscellaneous = ({}) => {
    const { t } = useTranslation();
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const tableHead = [
        {
            id: 1,
            title: t('name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: `${t('amount')} (${currencySymbol})`,
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const [apiResultArray, setApiResultArray] = useState([]);
    const [filterDataList, setFilterDataList] = useState([]);
    const [singleObj, setSingleObj] = useState({});
    const [columns, setColumns] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const sliderOpen = (value, title) => {
        getFields();
        setOpen(value);
        setSliderTitle(title);
    };
    const { loading, error, data, refetch } = useQuery(MISCELLANEOUSGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const loadData = (values, from) => {
        refetch();
    };
    const filterData = (e) => {
        setCurrentPageNumber(1);
        const filterText = e.target.value;
        let filertKey = {
            name: 'name'
        };
        setFilterDataList(filterFunction(apiResultArray, filterText, filertKey, 1));
    };
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((obj) => obj.id === id);
        setSingleObj(Obj);
        setOpen(value);
        setSliderTitle(title);
    };
    const getFields = () => {
        const fields = MISCELLANEOUS.fields;
        let singleObjData = {};
        let columnsArray = [];
        let index = 0;
        fields.map((item) => {
            if (item.create === true && item.edit === true) {
                singleObjData[item['field name']] = '';
            }
            if (item.show_in_ui === true) {
                columnsArray.push({
                    id: index,
                    title: item['display_name'],
                    className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
                });
                index++;
            }
        });
        if (columnsArray) {
            if (columnsArray.length > 0) {
                columnsArray.push({
                    id: columnsArray.length - 1,
                    title: t('actions'),
                    className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
                });
            }
        }
        setColumns(columnsArray);
        setSingleObj(singleObjData);
    };
    useEffect(() => {
        if (data) {
            setApiResultArray(data.getAllMiscellaneous);
            setFilterDataList(data.getAllMiscellaneous);
        }
        getFields();
    }, [data]);
    return (_jsx(_Fragment, { children: loading ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: "animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 border-blue-900" }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: " font-poppins font-medium text-lg text-blue-900 mt-2" }, void 0)] }), void 0) }), void 0)) : (_jsxs(Parent, Object.assign({ className: "px-3 " }, { children: [_jsx(Parent, Object.assign({ className: "mt-5 md:mt-0 flex items-center justify-between  " }, { children: _jsx(Parent, Object.assign({ className: "flex-1 min-w-0" }, { children: _jsx(Input, { name: "search", className: "w-48 sm:w-60 shadow-sm focus:border-blue-600 block px-4 pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium", id: "search", placeHolder: t('enter_miscellaneous'), type: "text", labelText: t('search'), labelRequired: false, iconRequired: true, iconTag: SearchIcon, onChange: (e) => filterData(e), labelClass: "block text-sm font-poppins font-medium text-gray-600 pb-1" }, void 0) }), void 0) }), void 0), filterDataList && filterDataList.length > 0 ? (_jsx(MiscellaneousTable, { onClick: sliderOpen, resultData: filterDataList, getSingleObj: getSingleObj, columns: tableHead, reloadData: loadData, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, total: filterDataList && filterDataList.length > 0
                        ? filterDataList.length
                        : 0 }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('miscellaneous_are_not_available_click_add_miscellaneous_to_create_new_miscellaneous'), iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: UserGroupIcon }, void 0)), _jsx(SlideOverlay, { open: open, setOpen: setOpen, title: sliderTitle, from: "miscellaneous_master", overLayWidth: "max-w-md", reloadData: loadData, singleObj: singleObj, fields: MISCELLANEOUS.fields, addQuery: CREATEMISCELLANEOUSADDQUERY, updateQuery: CREATEMISCELLANEOUSUPDATEQUERY }, void 0)] }), void 0)) }, void 0));
};
export default Miscellaneous;
