import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { Parent, Icon, Image, Link } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import Pagination from '../Pagination/Pagination';
import { DATALIMIT, PAGELIMIT } from '../../Utilities/Constants';
import ConfirmationAlert from '../../Component/ConfirmationAlert/ConfirmationAlert';
import ViewCombo from '../../Component/SlideOverlay/ViewCombo';
import { useMutation } from '@apollo/client';
import { COMBODELETEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import { imageOnErrorHandler } from '../../Utilities/Helpers';
let defalutImg = require('../../assets/media/no-image.png');
const ComboTable = ({ className, onClick, resultData, getSingleObj, columns, total, reloadData, currentPageNumber, setCurrentPageNumber }) => {
    const { t } = useTranslation();
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [showAlert, setShowAlert] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [columnsData, setColumnsData] = useState(columns);
    const [tableData, setTableData] = useState(resultData);
    const [id, setId] = useState('');
    const [deleteName, setDeleteName] = useState('');
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [comView, setComView] = useState();
    const [deleteRecordQuery, { data, loading, error }] = useMutation(COMBODELETEQUERY);
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        const list = resultData.slice(startIndex, endIndex);
        setEndIndex(resultData.length > endIndex ? endIndex : resultData.length);
        setStartIndex(startIndex + 1);
        setTableData(list);
    };
    const showViewInfo = (value, data) => {
        setComView(data);
        setShowInfo(value);
    };
    const showConfirmation = (value, id, deleteName) => {
        setShowAlert(value);
        setId(id);
        setDeleteName(deleteName);
    };
    const deleteRecord = () => {
        deleteRecordQuery({
            variables: {
                id: parseInt(id),
                is_active: '0'
            }
        }).then((result) => {
            reloadData('', '');
            setShowAlert(false);
        });
    };
    useEffect(() => {
        setTableData(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columnsData }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: tableData.map((person) => (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/5" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center " }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: _jsx(Image, { className: " w-10 h-10 object-cover rounded-lg ", src: person.combo_img
                                                                ? person.combo_img
                                                                : defalutImg, onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "Home img" }, void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/5" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.combo_name }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/5" }, { children: _jsx(Parent, Object.assign({ className: "" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-right text-gray-800" }, { children: person.total_amt }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/5" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-right text-gray-800" }, { children: person.price }), void 0) }), void 0), _jsxs(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap text-center text-sm font-normal w-1/5" }, { children: [_jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group mr-4 inline-flex items-center px-3 py-3  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none cursor-pointer'), title: t('view'), onClick: () => showViewInfo(true, person) }, { children: _jsx(Icon, { tag: EyeIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group mr-4 inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white  focus:outline-none'), title: t('edit'), onClick: () => getSingleObj(person.id, true, t('edit_combo')) }, { children: _jsx(Icon, { tag: PencilIcon, className: "h-3.5 w-3.5  group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white  focus:outline-none'), title: t('delete'), onClick: () => showConfirmation(true, person.id, person.combo_name) }, { children: _jsx(Icon, { tag: TrashIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0)] }), void 0)] }, person.id))) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Pagination, { items: tableData, pageLimit: pageLimit, dataLimit: dataLimit, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, totalData: resultData, total: total }, void 0), _jsx(ConfirmationAlert, { id: id, open: showAlert, setOpen: setShowAlert, deleteRecord: deleteRecord, title: t('are_you_sure'), description: `${t('are_you_sure_to_delete_this')} ${deleteName} ${'?'}` }, void 0), _jsx(ViewCombo, { open: showInfo, comView: comView, setOpen: setShowInfo, title: "View Combo" }, void 0)] }), void 0));
};
export default ComboTable;
