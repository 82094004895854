import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import EmpRoels from '../Containers/EmpRoels/EmpRoels';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const breadCrumbs = [
    { title: 'Home', href: '#', current: false },
    { title: 'Settings', href: '#', current: false },
    { title: 'Employee Role', href: '#', current: true }
];
const EmpRole = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t("home"), href: "#/dashboard", current: false },
        { title: t('settings'), href: '#/settings', current: false },
        { title: t('employee_role'), href: '#/emp-role', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('employee_role'), breadcrumbs: breadCrumbs }, void 0), _jsx(EmpRoels, { title: t('employee_role') }, void 0)] }, void 0));
};
export default EmpRole;
