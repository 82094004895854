var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Tr, Th } from '../../Component/Table';
const TableHeader = (_a) => {
    var { className, columns } = _a, props = __rest(_a, ["className", "columns"]);
    return (_jsx(Tr, Object.assign({ className: className }, { children: columns.map((item) => {
            return (item && (_jsx(Th, Object.assign({ className: item.className }, props, { children: item.title }), item.title)));
        }) }), void 0));
};
export default TableHeader;
