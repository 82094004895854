import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import Coupons from '../Containers/Coupons/Coupons';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const Coupon = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t("home"), href: "#/dashboard", current: false, show: "block" },
        {
            title: t('customer'),
            href: '#/customers',
            current: false,
            show: 'hidden sm:block'
        },
        { title: t('coupon'), href: '#/coupon', current: true, show: 'block' }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('coupon'), breadcrumbs: breadCrumbs }, void 0), _jsx(Coupons, { title: "Coupons" }, void 0)] }, void 0));
};
export default Coupon;
