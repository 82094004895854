import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Text } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import { useQuery } from '@apollo/client';
import { GETTICKETSQUERY } from '../../GraphqlQuery/Query/Query';
import { Tab } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import AddDefect from '../../Component/IssueTracking/AddDefect';
import TrackingList from '../../Component/IssueTracking/TrackingList';
import SuccessPopUp from '../../Component/SuccessPopUp/SuccessPopUp';
const Users = ({}) => {
    const { t } = useTranslation();
    const [showSuccess, setShowSuccess] = useState(false);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [issues_data, setIssues_data] = useState([]);
    const tabs = [
        {
            id: 1,
            name: t('new_ticket'),
            href: '#',
            current: false,
            content: AddDefect
        },
        {
            id: 2,
            name: t('tickets_list'),
            href: '#',
            current: true,
            content: TrackingList
        }
    ];
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }
    const { loading, error, data, refetch } = useQuery(GETTICKETSQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const loadData = (values, from) => {
        refetch();
    };
    useEffect(() => {
        if (data) {
            setIssues_data(data.getAllTickets.data);
        }
    }, [data]);
    setTimeout(() => {
        setShowSuccess(false);
    }, 3000);
    return (_jsxs(Parent, Object.assign({ className: "lg:px-12 md:px-6 pb-9 px-4" }, { children: [_jsx(Parent, Object.assign({ className: " right-10 top-14 absolute flex justify-end " }, { children: showSuccess && (_jsx(SuccessPopUp, { setShowSuccess: setShowSuccess, title: t('successfully_created'), description: t('ticket_successfully_created') }, void 0)) }), void 0), _jsx(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom px-5 pb-5 mt-3" }, { children: _jsxs(Tab.Group, { children: [_jsx(Tab.List, Object.assign({ className: "-mb-px flex space-x-8 justify-around sm:justify-start border-gray-100 border-b-2", "aria-label": "Tabs" }, { children: tabs.map((item) => {
                                return (_jsx(Tab, { children: ({ selected }) => {
                                        return (_jsx(Parent, Object.assign({ className: classNames(selected
                                                ? ' text-blue-600 text-base border-blue-600 border-b-2 font-poppins'
                                                : ' text-gray-500 text-base hover:text-blue-600 font-poppins ', 'group inline-flex items-center py-5  px-1  font-poppins font-medium text-base') }, { children: _jsx(Text, { as: "span", label: item.name }, void 0) }), item.id));
                                    } }, item.id));
                            }) }), void 0), _jsx(Tab.Panels, { children: tabs.map((item) => {
                                const Tag = item.content;
                                return (_jsx(Tab.Panel, { children: _jsx(Tag, { payId: item.id, reloadData: loadData, setShowSuccess: setShowSuccess, issues_data: issues_data, total: issues_data && issues_data.length > 0
                                            ? issues_data.length
                                            : 0 }, void 0) }, item.id));
                            }) }, void 0)] }, void 0) }), void 0)] }), void 0));
};
export default Users;
