import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import GlobalCategories from '../Containers/GlobalCategories/GlobalCategories';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const GlobalCategory = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false },
        { title: t('global_category'), href: '#/global-category', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('global_category'), breadcrumbs: breadCrumbs }, void 0), _jsx(GlobalCategories, { title: t('global_category') }, void 0)] }, void 0));
};
export default GlobalCategory;
