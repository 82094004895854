import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import GroupCate from '../Containers/GroupingCategories/GroupingCategories';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const Group = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t("home"), href: "#/dashboard", current: false },
        { title: t('grouping_categories'), href: '#/group-cate', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('grouping_categories'), breadcrumbs: breadCrumbs }, void 0), _jsx(GroupCate, { title: t('grouping_categories') }, void 0)] }, void 0));
};
export default Group;
