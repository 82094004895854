var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { Parent, Nav, Link, Icon, Select } from '../../../Component';
import PageTitle from './PageTitle';
import { useQuery } from '@apollo/client';
import { classNames } from '../../../Utilities/Helpers';
import Store from '../../../assets/Icons/Store';
import Cutlery from '../../../assets/Icons/Cutlery';
import { GETSINGLEBUSINESS } from '../.././../GraphqlQuery/Query/Query';
const PageHeader = (_a) => {
    var { className, children, title, breadcrumbs } = _a, props = __rest(_a, ["className", "children", "title", "breadcrumbs"]);
    const [sticky, setSticky] = useState(false);
    const [optionItems, setOptionItems] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId') ? localStorage.getItem('outletId') : '1');
    const { loading, error, data, refetch } = useQuery(GETSINGLEBUSINESS, {
        variables: { id: localStorage.getItem('businessId') }
    });
    const [businessType, setBusinessType] = useState(localStorage.getItem('BusinessTypeId'));
    const trackScroll = () => {
        if (typeof window === 'undefined') {
            return;
        }
        else {
            setSticky(window.scrollY >= 200);
        }
    };
    useEffect(() => {
        document.addEventListener('scroll', trackScroll);
        return () => {
            document.removeEventListener('scroll', trackScroll);
        };
    }, []);
    useEffect(() => {
        var _a;
        if (data) {
            const newOptionItems = (_a = data === null || data === void 0 ? void 0 : data.getAllBusiness[0]) === null || _a === void 0 ? void 0 : _a.outlet.map((item, index) => {
                return {
                    value: item.id,
                    label: item.business_name,
                    select: index == 0 ? true : false
                };
            });
            setOptionItems(newOptionItems);
        }
        refetch();
    }, [data]);
    const onOutletChange = (e) => {
        localStorage.setItem('outletId', e.target.value);
        localStorage.setItem('outletName', e.target.options[e.target.selectedIndex].text);
        window.location.reload();
    };
    return (_jsx(Parent, Object.assign({ className: classNames(sticky
            ? 'sticky left-0 right-0 top-0 bg-white shadow-custom translate-y-0'
            : '-translate-y-full', 'lg:px-12 md:px-6 pt-7 pb-3 px-4') }, { children: _jsxs(Parent, Object.assign({ className: "grid sm:grid-cols-2" }, { children: [_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(PageTitle, { className: "text-2xl font-poppins font-medium  leading-7 text-gray-700 sm:truncate mb-3", title: title }, void 0), _jsx(Parent, { children: _jsx(Nav, Object.assign({ className: "flex", "aria-label": "Breadcrumb" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center " }, { children: breadcrumbs.map((item, index) => {
                                        if (index == 0) {
                                            return (_jsx(Parent, { children: _jsx(Parent, Object.assign({ className: classNames(item.show) }, { children: _jsx(Link, Object.assign({ href: item.href, className: "text-sm font-poppins font-medium text-gray-400 hover:text-gray-500" }, { children: item.title }), void 0) }), void 0) }, item.title));
                                        }
                                        else {
                                            return (_jsx(Parent, Object.assign({ className: classNames(item.show, 'items-center') }, { children: _jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Icon, { tag: ChevronRightIcon, className: "flex-shrink-0 h-4 w-4 text-gray-400", "aria-hidden": "true" }, void 0), _jsx(Link, Object.assign({ href: item.href, className: classNames(item.current
                                                                ? 'text-gray-500'
                                                                : 'text-gray-400', 'ml-4 text-sm font-poppins font-medium hover:text-gray-500') }, { children: item.title }), void 0)] }), void 0) }), item.title));
                                        }
                                    }) }), void 0) }), void 0) }, void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: "mt-3 sm:mt-0  flex-shrink-0 flex sm:justify-end items-center  min-w-0" }, { children: [businessType === '1' ? (_jsx(Parent, Object.assign({ className: " bg-white mr-3 flex items-center justify-center h-10 w-10 rounded-full shadow-custom" }, { children: _jsx(Icon, { tag: Store, className: " h-9 w-9 ", "aria-hidden": "true" }, void 0) }), void 0)) : (_jsx(Parent, Object.assign({ className: " bg-white mr-3 flex items-center justify-center h-10 w-10 rounded-full shadow-custom" }, { children: _jsx(Icon, { tag: Cutlery, className: " h-9 w-9 ", "aria-hidden": "true" }, void 0) }), void 0)), _jsx(Select, { optionItems: optionItems, name: "outlet", id: "outlet", value: outletId, className: " block text-gray-500 font-poppins font-normal sm:w-2/4 px-4 pr-10 py-2 text-base border-gray-200 focus:outline-none rounded-md", onChange: (e) => onOutletChange(e) }, void 0)] }), void 0)] }), void 0) }), void 0));
};
export default PageHeader;
