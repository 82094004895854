import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Input, Parent, Checkbox } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import Layout from '../../Layouts/Layout';
import PageHeader from '../../Layouts/Core/PageHeader/PageHeader';
import { useMutation } from '@apollo/client';
import { TAXESGETQUERY } from '../../GraphqlQuery/Query/Query';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import { TAXUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { classNames } from '../../Utilities/Helpers';
const columns = [
    {
        id: 1,
        title: t('tax_name'),
        className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
    },
    {
        id: 2,
        title: t('tax_label'),
        className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
    },
    {
        id: 3,
        title: t('tax_percentage'),
        className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
    },
    {
        id: 4,
        title: '',
        className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
    }
];
const TaxDetails = ({}) => {
    const [tax, setTax] = useState();
    const [apiResultArray, setApiResultArray] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const { loading, error, data, refetch } = useQuery(TAXESGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const [updateTax, { data: data1, loading: loading1, error: error1 }] = useMutation(TAXUPDATEQUERY);
    const loadData = () => {
        refetch();
    };
    const taxUpdate = (event, id) => {
        updateTax({
            variables: {
                id: id,
                is_enable: event.target.checked === true ? '1' : '0'
            }
        }).then((result) => {
            loadData();
        });
    };
    const breadCrumbs = [
        // { title: 'dashboard', href: '#/dashboard', current: false },
        { title: 'settings', href: '#/settings', current: false },
        { title: 'tax_details', href: '#/tax-details', current: true }
    ];
    useEffect(() => {
        if (data)
            setApiResultArray(data.getAllTax);
    }, [data]);
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: "Tax Details", breadcrumbs: breadCrumbs }, void 0), _jsx(Parent, Object.assign({ className: " max-w-8xl w-full px-11 align-middle inline-block " }, { children: _jsx(ContainerSection, Object.assign({ className: "bg-white max-w-8xl rounded-lg shadow-custom  px-5 py-5 mt-3" }, { children: _jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "py-5 align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: apiResultArray &&
                                                apiResultArray.map((person) => {
                                                    return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: _jsx(Input, { type: "text", name: " tax_name", id: "tax_name", placeHolder: " VAT", className: classNames(businessType == 'Retail'
                                                                                ? 'focus:ring-blue-500 focus:border-blue-500'
                                                                                : 'focus:ring-yellow-400 focus:border-yellow-400', 'shadow-sm  w-full block font-roboto text-gray-400 text-xs leading-none sm:text-sm border-gray-300 rounded-md '), labelClass: "", labelText: " ", value: person.tax_name }, void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: _jsx(Input, { type: "text", name: " tax_name", id: "tax_name", placeHolder: " VAT", className: classNames(businessType == 'Retail'
                                                                                ? 'focus:ring-blue-500 focus:border-blue-500'
                                                                                : 'focus:ring-yellow-400 focus:border-yellow-400', 'shadow-sm  w-full block font-roboto text-gray-400 text-xs leading-none sm:text-sm border-gray-300 rounded-md '), labelClass: "", labelText: " ", value: person.tax_label }, void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: _jsx(Input, { type: "text", name: " tax_name", id: "tax_name", placeHolder: " VAT", className: classNames(businessType == 'Retail'
                                                                                ? 'focus:ring-blue-500 focus:border-blue-500'
                                                                                : 'focus:ring-yellow-400 focus:border-yellow-400', 'shadow-sm  w-full block font-roboto text-gray-400 text-xs leading-none sm:text-sm border-gray-300 rounded-md '), labelClass: "", labelText: " ", value: person.tax_pct }, void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins text-center font-normal text-gray-800" }, { children: _jsx(Checkbox, { type: "checkbox", name: "tax", value: tax, id: "tax", checked: person.is_enable === '1'
                                                                            ? true
                                                                            : false, className: classNames(businessType == 'Retail'
                                                                            ? 'text-blue-500'
                                                                            : 'text-yellow-500', 'focus:ring-transparent  h-4 w-4 items-center border-gray-300 rounded'), onChange: (e) => taxUpdate(e, person.id) }, void 0) }), void 0) }), void 0)] }, person.id));
                                                }) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0) }), void 0) }), void 0)] }, void 0));
};
export default TaxDetails;
