import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useEffect, useState } from 'react';
import { Parent, Input } from '..';
import { useLazyQuery } from '@apollo/client';
import { classNames, filterFunction } from '../../Utilities/Helpers';
import { DATALIMIT, STARTPAGE } from '../../Utilities/Constants';
import { UserGroupIcon, SearchIcon } from '@heroicons/react/solid';
import { GETSINGLETICKETSQUERY } from '../../GraphqlQuery/Query/Query';
import DataNotFound from '../../Containers/DataNotFound/DataNotFound';
import TrackingTable from './TrackingTable';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
const TrackingList = ({ issues_data, total }) => {
    const { t } = useTranslation();
    const [issues, setIssues] = useState(issues_data);
    const [filterDataList, setFilterDataList] = useState(issues_data);
    const [getSingleTicketIssue, { loading, error, data, refetch }] = useLazyQuery(GETSINGLETICKETSQUERY);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const columns = [
        {
            id: 1,
            title: t('ticket_no'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('title'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('outlet_name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('status'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: t('priority'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 6,
            title: t('seviority'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 7,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const filterData = (e) => {
        setCurrentPageNumber(1);
        const filterText = e.target.value;
        let filertKey = {
            name: 'title',
            name1: 'status',
            name2: 'priority',
            name3: 'serverity'
        };
        setIssues(filterFunction(filterDataList, filterText, filertKey, 4));
    };
    useEffect(() => {
        setIssues(issues_data);
        setFilterDataList(issues_data);
    }, [currentPageNumber, dataLimit, issues_data, data]);
    return (_jsx(Parent, { children: _jsxs(Parent, { children: [_jsx(Parent, Object.assign({ className: "flex-1 min-w-0 pt-5" }, { children: _jsx(Input, { name: "search", className: classNames(businessType == 'Retail'
                            ? 'focus:ring-blue-600 focus:border-blue-600'
                            : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm  block px-3 pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "search", placeHolder: t('search'), type: "text", labelText: 'search', labelClass: "block text-sm font-poppins font-normal text-gray-600 pb-1", labelRequired: false, iconRequired: true, iconTag: SearchIcon, onChange: (e) => filterData(e) }, void 0) }), void 0), _jsx(Parent, { children: issues && (issues === null || issues === void 0 ? void 0 : issues.length) > 0 ? (_jsx(TrackingTable, { setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, columns: columns, resultData: issues, total: issues_data && issues_data.length > 0 ? issues_data.length : 0 }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: "Tickets are not available. Click Add Tickets to create new Tickets", iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: UserGroupIcon }, void 0)) }, void 0)] }, void 0) }, void 0));
};
export default TrackingList;
