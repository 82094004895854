var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Parent } from '../Component';
import AsideMenu from './Core/Aside/AsideMenu';
import Content from './Core/Content/Content';
const Layout = (_a) => {
    var { className, children } = _a, props = __rest(_a, ["className", "children"]);
    return (_jsxs(Parent, Object.assign({ className: "h-screen z-0 flex flex-col lg:flex-row bg-gray-50 overflow-hidden" }, { children: [_jsx(AsideMenu, {}, void 0), _jsx(Content, Object.assign({ className: "overflow-y-auto" }, { children: children }), void 0)] }), void 0));
};
export default Layout;
