/* eslint-disable no-dupe-keys */
/** @format */

export const TRANSLATIONS_EN = {
    dashboard: 'Dashboard',
    home: 'Home',
    settings: 'Settings',
    addons: 'Addons',
    addon: 'Addon',
    products: 'Products',
    secret_key: 'Secret Key',
    publishable_key: 'Publishable Key',
    stripe_Updated_successfully: 'Stripe Updated ',
    stripe_saved_successfully: 'Stripe Saved',
    stripe_deets_saved_successfully: 'Stripe details saved successfullly',
    stripe_deets_updated_successfully: 'Stripe details updated successfullly',
    Aboutus_Updated_successfully: 'About us Updated ',
    Aboutus_deets_saved_successfully: 'About us details saved successfullly',
    Aboutus_saved_successfully: 'About us Saved',
    Aboutus_deets_updated_successfully: 'About us details updated successfullly',
    mobile_no_more_than_12_numbers: 'Mobile no should be minimum 10 digits',
    search_itemname: 'Enter Item name',
    enter_combo_name: 'Enter Combo name',
    enter_customer_name: 'Enter Customer name',
    enter_coupon_name: 'Enter Coupon name',
    enter_employee_name: 'Enter Employee name',
    enter_addon: 'Enter Addon name',
    enter_miscellaneous: 'Enter Miscellaneous name',
    enter_tax_name: 'Enter Tax name',
    enter_employee_type: 'Enter Employee type',
    enter_employee_role: 'Enter Employee role',
    enter_serial_number: 'Enter Serial number',
    enter_username: 'Enter User name',
    enter_user_role: 'Enter User role',
    stripe_setup: 'Stripe Setup',
    stripe_settings: 'Stripe Settings',
    no_data_available: 'No data available ',
    configuration: 'Configuration',
    customer: 'Customer',
    customer_view: 'Customer View',
    customer_table: 'Customer Table',
    employee: 'Employee',
    employee_role: 'Employee Role',
    employee_type: 'Employee Type',
    employee_attendance: 'Employee Attendance',
    items: 'Items',
    add_item: 'Add Item',
    password_pin: 'Password/PIN',
    edit_item: 'Edit Item',
    get_start: 'Get Start',
    profile_data: 'Profile Data',
    update_your_business_image_now: 'Update Your Business Image Now',
    enable_dual_display: 'Enable Dual Display',
    enable_dual_display_in_pos: 'Enable Dual Display in POS',
    fixed_amount: 'Fixed amount',
    View_Item: 'View Item',
    Profile: 'Profile',
    payment_type: 'Payment Type',
    role: 'Role',
    tax: 'Tax',
    unit: 'Unit',
    uom: 'UOM',
    users: 'Users',
    variation: 'Variation',
    report: 'Report',
    pos_session: 'POS Session',
    outlet_settings: 'Outlet Settings',
    outlet_details: 'Outlet Details',
    outlet_timings: 'Outlet Timings',
    general: 'General',
    coupon: 'Coupon',
    add_coupon: 'Add Coupon',
    edit_coupon: 'Edit Coupon',
    tax_details: 'Tax Details',
    tip_handle: 'Tip Handle',
    manual: 'Manual',
    auto: 'Auto',
    loyalty: 'Loyalty',
    loyalty_settings: 'Loyalty Settings',
    coupons_redemption: 'Coupon Redemption',
    loyalty_points: 'Loyalty Points',
    invoice_summary: 'Invoice Summary',
    inventory_summary: 'Inventory Summary',
    sales_summary: 'Sales Summary',
    high_sales: 'Highest Sales',
    customer_summary: 'Customer summary',
    tax_on_off: 'Tax ON/OFF ',
    item_on_off: 'Item ON/OFF',
    available_qty: 'Available Qty',
    apply_discount: 'Apply Discount',
    search: 'Search',
    filter: 'Filter',
    add_item: 'Add Item',
    open_options: 'Open options',
    coupon_loyalty: ' Coupon / Loyalty',
    selected: 'Selected ',
    all: 'All',
    image: 'Image',
    name: 'Name',
    sku: 'SKU',
    price: 'Price ',
    available_quantity: 'Available Quantity',
    actions: 'Actions',
    product_details: '  Product Details',
    product_code_sku: 'Product Code / SKU',
    product_name: 'Product Name',
    main_category: 'Main Category',
    brand_name: 'Brand Name',
    category: 'Category',
    sale_unit: ' Sale Unit',
    sale_quantity: 'Sale Quantity',
    barcode: 'Barcode',
    uom: ' UOM',
    mark_as_favorite: ' Mark as Favorite',
    age_restricted: ' Age Restricted',
    description: ' Description',
    pricing: ' Pricing',
    discount_type: ' Discount Type',
    percent_fixed_amount: ' Percentage / Fixed ',
    fixed_amount: 'Fixed amount',
    charge_tax_for_this_item: ' Charge tax For this Item',
    images: 'Images',
    stock: 'Stock',
    minimum_quantity: ' Minimum Quanity',
    minimum_quantity_is_to_show_alert_when_quantity_goes_low:
        ' Minimum quantity is to show alert when quantity goes low',
    variant_name: 'Variant Name ',
    quantity: 'Quantity',
    add_on: 'Addons',
    enable_in_pos: 'Enable in POS',
    selected_a_tab: ' Select a tab',
    clear: ' Clear',
    update: 'Update',
    save: 'Save',
    add: 'Add',
    product_code_is_required: 'Product Code is required',
    product_name_is_required: 'Product name is required',
    sale_quantity_is_required: 'Sale quantity is required',
    select_category_is_required: 'Select category is required',
    select_main_category_is_required: 'Select Main category is required',
    select_sub_category_is_required: 'Select sub category is required',
    price_is_required: 'Price is required',
    minimum_quantity_is_required: 'Minimum quantity is required',
    available_quantity_is_required: 'Available quantity is required',
    brand_name_is_required: 'Brand name is required',
    enter_category_name: 'Enter Category Name',
    page_size: 'Page size',
    next: ' Next',
    previous: 'Previous',
    showing: 'Showing',
    to: 'to',
    of: ' of',
    results: ' results',
    option_1: 'Option 1',
    option_2: 'Option 2',
    apply_tax: 'Apply Tax',
    apply: ' Apply',
    cancel: 'Cancel',
    note: ' Note',
    set_to_on_if_you_want_to_charge_tax_for_this_item_in_pos_set_to_off_if_you_do_not_want_to_charge_tax_for_this_item_in_pos:
        'Set to ON if you want to charge tax for this item in POS. Set to OFF, if you do not want to charge tax for this item in POS.',
    set_to_off_if_you_do_not_want_to_show_this_item_in_pos_set_to_on_if_you_want_to_show_this_item_in_pos:
        'Set to OFF if you do not want to show this item in POS. Set to ON, if you want to show this item in POS.',
    this_quantity_will_be_added_to_the_already_available_quantity_eg_if_there_5_products_in_inventory_and_available_quantity_applied_is_100_after_this_operation_available_quantity_becomes_105:
        ' This quantity will be added to the already available quantity. e.g.If there 5 products in inventory and available quantity applied is 100, after this operation available quantity becomes 105.',
    discount_is_applied_by_percentage_only: 'Discount is applied by percentage only.',
    yes_delete_it: 'Yes, delete it',
    yes: 'Yes',
    no: 'No',
    add_customer: 'Add Customer',
    first_name: '  First Name',
    last_name: '  Last Name',
    mobile: ' Mobile',
    date_of_birth: ' Date Of Birth',
    email: 'Email',
    area: ' Area',
    browse_photo: ' Browse Photo',
    click_to_add_profile_picture: ' Click to add profile picture',
    zipcode: ' Postcode',
    tax_number: ' Tax Number',
    address_1: '  Address',
    do_not_disturb: ' Do not disturb',
    first_name_is_required: 'First name is required',
    first_name_not_more_than_25_characters: 'First Name not more than 25 characters',
    last_name_is_required: 'Last name is required',
    last_name_not_more_than_25_characters: 'Last Name not more than 25 characters',
    email_not_valid: 'Email not valid',
    email_is_required: 'Email is required',
    mobile_is_required: 'Mobile is required',
    mobile_number_is_required: 'Mobile number is required',
    mobile_not_more_than_12_numbers: 'Mobile not more than 12 numbers',
    mobile_number_not_more_than_15_characters: 'Mobile number not more than 15 characters',
    address_1_not_more_than_150_characters: 'Address 1 not more than 150 characters',
    zip_not_more_than_10_characters: 'Zip not more than 10 characters',
    close_panel: ' Close panel',
    birthday: ' Birthday',
    address_2: 'Address 2',
    add_category: 'Add Category',
    category_name: 'Category Name',
    on_off: ' ON/OFF',
    on_off_Start_Cash: ' ON/OFF Start Cash',
    tag_name: ' Tag Name',
    category_image: 'Category Image',
    upload_from_device: ' Upload From Device',
    upload_from_repository: 'Upload From Repository',
    category_name_is_required: 'Category name is required',
    category_name_not_more_than_50_characters: 'Category name not more than 50 characters',
    tag_name_is_required: 'Tag name is required',
    edit_category: 'Edit Category',
    PNG_JPG_upt_to_10MB: 'PNG, JPG, up to 10MB',
    or_Drag_and_Drop: 'or drag and drop',
    upload_a_file: 'Upload a file',
    sales_trend: 'Sales Trend',
    inventory: ' Inventory',
    highest_selling_items: ' Highest Selling Items',
    business_name: ' Business Name',
    shop_name: 'Shop Name',
    business_owner: ' Business Owner',
    owner_mobile: ' Owner Mobile',
    owner_email: 'Owner Email',
    outlets: ' Outlets',
    want_to_add_outlet: 'Want to add outlet',
    click_here: 'Click Here',
    common_setting: '  Common Settings',
    language: ' Language',
    currency: 'Currency',
    notification: ' Notification',
    daily_sales_report: 'Daily Sales Report',
    send_daily_sales_report_from_all_outlets_in_one_email:
        'Send daily sales report from all outlets in one email',
    send_monthly_annual_sale_report_from_all_outlets_in_one_email:
        'Send monthly annual sales report from all outlets in one email',
    send_inventory_alert_report_from_all_outlets_in_one_email:
        'Send inventory alert report from all outlets in one email',
    send_monthly_customer_data_report_from_all_outlets_in_one_email:
        'Send monthly customer data report from all outlets in one email',
    monthly_annual_sale_report: 'Monthly Annual Sales Report',
    inventory_alert: 'Inventory Alert',
    monthly_customer_data: 'Monthly Customer Data',
    use_setting: 'Use setting',
    edit_cover: ' Edit Cover',
    contact_details: ' Contact Details',
    tax_master: 'Tax Master',
    employment_types: 'Employment Types',
    add_tax: 'Add Tax',
    add_variation: 'Add Variation',
    add_addon: 'Add Addon',
    add_uom: 'Add UOM',
    add_unit: 'Add Unit',
    add_employment_type: 'Add Employment Type',
    add_employment_role: 'Add Employee Role',
    add_payment_type: 'Add Payment Type',
    open_time: 'Open Time',
    close_time: ' Close Time',
    day_of_week: 'Day of Week',
    leave: 'Leave',
    add_user: 'Add User',
    add_role: 'Add Role',
    add_employee: 'Add Employee',
    id: 'ID',
    phone_no: 'Phone no',
    type: 'Type',
    employee_name: 'Employee Name',
    employee_id: 'Employee ID',
    date_of_joining: ' Date of Joining',
    date_of_leaving: ' Date of Leaving',
    payment: ' Payment',
    employee_name_is_required: 'Employee Name is required',
    employee_name_not_more_than_25_characters: 'Employee Name not more than 25 characters',
    employee_id_is_required: 'Employee ID is required',
    employment_type_is_required: 'Employment Type is required',
    role_is_required: 'Role  is required',
    payment_is_required: 'Payment is required',
    phone_no_is_required: 'Phone No is required',
    address: ' Address',
    session_id: 'Session ID',
    opening_date: 'Opening Date',
    closing_date: 'Closing Date',
    status: 'Status',
    orders: 'Orders',
    amount: 'Amount',
    total: 'Total',
    tax_master: 'Tax Master',
    variation: 'Variation',
    session: '  Session',
    addons: 'Addons',
    UOM: 'UOM',
    sales_unit: 'Sales Unit',
    Employeement_type: 'Employement Type',
    employee_role: 'Employee Role',
    payment_type: 'Payment Type',
    order_no: 'Order No',
    order_type: 'Order Type',
    update_some_common_settings_as_per_your_business_needs:
        'Update Some Common Settings As Per Your Business Needs',
    order_value_$: `Order Value $`,
    coupon_name: 'Coupon Name',
    coupon_code: 'Coupon Code',
    valid_date: 'Valid Date',
    redemption: 'Redemptions',
    valid_till: 'Valid Till',
    outlet_timing: 'Outlet Timing',
    loyalty_settings: 'Loyalty Settings',
    percent: 'Percent',
    coupon_users: 'Coupon Users',
    back: 'Back',
    edit_coupon_details: 'Edit Coupon Details',
    add_coupon_details: 'Add Coupon Details',
    apply_discount_after_tax: 'Apply discount after tax',
    valid_from: 'Vaild From',
    coupon_details: 'Coupon Details',
    target_audience: 'Target Audience',
    favorites: 'Favorites',
    finish: 'Finish',
    coupon_name_is_required: 'Coupon Name is required',
    coupon_code_is_required: 'Coupon Code is required',
    discount_type_is_required: 'Discount Type is required',
    percent_is_required: 'Percent / Fixed amount is required',
    reedem_points: 'Redeem Points',
    value: 'Value',
    loyalty_points: 'Loyalty Points',
    total_loyalty: 'Total Loyalty',
    order_value_d: 'Order Value',
    in_progress: 'In Progress',
    pay_type: 'Pay Type',
    settled: 'Settled',
    cancelled: 'Cancelled',
    returned: 'Returned',
    biller: '  Biller',
    order_status: ' Order Status',
    card_details: '  Card Details',
    customer_details: 'Customer Details',
    paid: 'Paid',
    date: 'Date',
    time: 'Time',
    discount: 'Discount',
    tax: 'Tax',
    grand_total: ' Grand Total',
    total: ' Total',
    item_name: 'Item Name',
    unit_price_$: 'Unit Price',
    total_price_$: 'Total Price',
    business_image: ' Business Image',
    upload: ' Upload',
    browse: 'Browse',
    invoice_no: 'Invoice No',
    payment: 'Payment',
    welcome_to: 'Welcome to',
    providing_affordable_one_step_payment_solutions_across_the_globe_to_support_growing_businesses:
        'Providing affordable one-step payment solutions across the globe to support growing businesses',
    loading: 'Loading',
    employees: 'Employees',
    filter_category: 'Filter Category',
    order_details: 'Order Details',
    order_bill_no: 'Bill no',
    edit_customer: 'Edit Customer',
    edit_business: 'Edit Business',
    created_date: 'Created Date',
    edit_user: 'Edit User',
    edit_role: 'Edit Role',
    view_coupon: 'View Coupon',
    open_time_is_required: 'Open Time is required',
    close_time_is_required: 'Close Time is required',
    event_is_required: 'Event is required',
    redemption_point_is_required: 'Redemption point is required',
    condition_is_required: 'Condition is required',
    value_is_required: 'Value is required',
    points_is_required: 'Points is required',
    loyalty_empty: 'Loyalty Empty',
    do_you_want_to_enable_loyalty_for_your_outlet: ' Do you want to enable loyalty for your outlet',
    enable_loyalty: 'Enable Loyalty',
    point_setting: ' Point Setting',
    points: 'Points',
    value: 'Value',
    points_equals: ' points equals',
    reward_rules: 'Reward Rules',
    event: 'Event',
    condition: ' Condition',
    order_value: ' Order value',
    redemption_rules: 'Redemption Rules',
    customer_can_redeem_when: 'Customer can redeem when',
    profile_details: 'Profile Details',
    edit_profile: 'Edit Profile',
    contact_number: 'Contact Number',
    change_password: 'Change Password',
    password: 'Password',
    reset_password: 'Reset Password',
    current_password_is_required: 'Current password is required',
    current_password_not_match: 'Current password not match',
    new_password_is_required: 'New password is required',
    entered_pin_is_too_short_should_be_4_chars_minimum:
        'Entered Pin is too short - should be 4 chars minimum',
    entered_pin_is_too_long_should_be_6_chars_maximum:
        'Entered Pin is too long - should be 6 chars maximum',
    confirm_password_is_required: 'Confirm password is required',
    passwords_must_match: 'Passwords must match',
    current_password: 'Current Password',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    update_profile: 'Update Profile',
    update_password: 'Update Password',
    change: 'Change',
    coupen_code: 'Coupon Code',
    discount_value: 'Discount Value',
    installation: 'Installation',
    pos_installation: 'EezzyPOS Installation',
    pos_for_windows: 'POS for Windows',
    pos_for_mac: 'POS for Mac',
    download: 'Download',
    installation_for_pos: 'Installation for POS',
    windows: 'Windows',
    upi_payment: 'UPI Payments',
    upi: 'UPI',
    mac: 'Mac',
    you_are_seeing: 'You are seeing',
    last_one_week: 'Last one week',
    data: 'data',
    tracking_issues: 'Tracking Issues',
    new_ticket: 'New Ticket',
    tickets_list: 'Tickets List',
    outlet_name: 'Outlet Name',
    title: 'Title',
    screen_name: 'Screen Name',
    seviority: 'Severity',
    priority: 'Priority',
    description: 'Description',
    file_attachments: 'File Attachments',
    discard: 'Discard',
    defect_id: 'Defect ID',
    ticket_no: 'Ticket No',
    view_ticket: 'View Ticket',
    status: 'Status',
    action: 'Action',
    defect_no: 'Defect No',
    attachments: 'Attachments',
    download: 'Download',
    create: 'Create',
    image_upload_from_device_or_from_repository: 'Image Upload From Device or From Repository',
    total_bills: 'Total Bills',
    returned_bills: 'Returned Bills',
    settled_bills: 'Settled Bills',
    cancelled_bills: 'Cancelled Bills',
    total_amount: 'Total Amount',
    sales_amount: 'Sales Amount',
    tax: 'Tax',
    discount: 'Discount',
    card_payments: 'Card Payments',
    cash_payment: 'Cash Payments',
    total_sales: 'Total Sales',
    total_tax: 'Total Tax',
    select_uom_is_required: 'Select uom is required',
    isbn_upc_gtin_etc: 'ISBN, UPC, GTIN, etc',
    upload_a_file: 'Upload a file',
    png_jpg_gif_up_to_10mb: 'PNG, JPG, GIF up to 10MB',
    enter_minimum_quantity: 'Enter Minimum Quantity',
    enter_available_quantity: 'Enter Available Quantity',
    item: 'Item',
    apply_qty: 'Apply Qty',
    discount_per: 'Discount(%)',
    are_you_sure: 'Are you sure?',
    do_you_really_want_to_delete: 'Do you really want to delete',
    expenses: 'Expenses',
    products_assigned_to_the: 'Products assigned to the',
    category_will_be_deleted_do_you_want_to_proceed:
        'category will be deleted. Do you want to proceed?',
    are_you_sure_to_delete_this: 'Do you want to delete this ',
    menu_will_be_deleted_do_you_want_to_proceed: 'Menu will be deleted. Do you want to proceed?',
    customers_are_not_available_click_add_customer_to_create_new_customer:
        'Customers are not available. Click Add Customer to create new Customer',
    categories_are_not_available_click_add_category_to_create_new_category:
        'Categories are not available. Click Add Category to create new Category',

    coupons_are_not_available_click_add_coupon_to_create_new_coupon:
        'Coupons are not available. Click Add Coupon to create new Coupon',
    employee_are_not_available_click_add_employee_to_create_new_employee:
        'Employee are not available. Click Add Employee to create new Employee',
    employment_role_are_not_available_click_add_employment_role_to_create_new_employment_role:
        'Employment Role are not available. Click Add Employment Role to create new Employment Role',
    employment_type_are_not_available_click_add_employment_type_to_create_new_employment_type:
        'Employment type are not available. Click Add Employment type to create new Employment type',
    items_are_not_available_click_add_item_to_create_new_item:
        'Items are not available. Click Add item to create new item',
    orders_are_not_available: 'Orders are not available.',
    loyalties_are_not_available: 'Loyalties are not available.',
    payment_type_are_not_available_click_add_payment_type_to_create_new_payment_type:
        'Payment Type are not available. Click Add Payment Type to create new Payment Type',
    sales_summary_are_not_available: 'Sales summary are not available.',
    tax_are_not_available_click_add_tax_to_create_new_tax:
        'Tax are not available. Click Add Tax to create new Tax',
    unit_are_not_available_click_add_unit_to_create_new_unit:
        'Unit are not available. Click Add Unit to create new Unit',
    uom_are_not_available_click_add_uom_to_create_new_uom:
        'UOM are not available. Click Add UOM to create new UOM',
    user_are_not_available_click_add_user_to_create_new_user:
        'User are not available. Click Add User to create new user',
    variation_are_not_available_click_add_variation_to_create_new_variation:
        'Variation are not available. Click Add Variation to create new Variation',
    tickets_are_not_available_click_add_tickets_to_create_new_tickets:
        'Tickets are not available. Click Add Tickets to create new Tickets',
    addon_are_not_available_click_add_addon_to_create_new_addon:
        'Addon are not available. Click Add Addon to create new Addon',
    product_details_are_not_available_click_select_products_or_select_categories:
        'Product Details are not available. Click Select Products or Select Categories',
    roles_are_not_available_click_add_role_to_create_new_role:
        'Roles are not available. Click Add Role to create new Role',
    redemption_rules_are_not_available_click_add_to_create_new_redemption_rules:
        'Redemption Rules are not available. Click Add  to create new Redemption Rules',
    tax_name: 'Tax Name',
    tax_label: 'Tax Label',
    tax_percentage: 'Percentage (%)',
    variation_name: 'Variation Name',
    addon_name: 'Addon Name',
    uom_name: 'UOM Name',
    unit_name: 'Unit Name',
    role_name: 'Role Name',
    display_name: 'Display Name',
    created_date: 'Created Date',
    payment_name: 'Payment Name',
    allow_pos_login: 'Allow POS Login',
    roles: 'Roles',
    role_permissions: 'Role Permissions',
    edit_payment_type: 'Edit Payment Type',
    closing_date_time: 'Closing Date & Time',
    open_date_time: 'Open Date & Time',
    view_pos_session: 'View POS Session',
    view_employee: 'View Employee',
    edit_employee: 'Edit Employee',
    timing_same_on_all_days: 'Timing same on all days',
    timing_same_on_all_week_days: 'Timing same on all week days',
    different_on_all_days: 'Different on all days',
    net_sales: 'Net Sales',
    start_date: ' Start Date',
    end_date: ' End Date',
    not_exceeded_than_5_digits: 'Not exceeded than 5 digits',
    edit_variation: 'Edit Variation',
    edit_addon: 'Edit Addon',
    edit_uom: 'Edit UOM',
    edit_unit: 'Edit Unit',
    edit_employment_type: 'Edit Employment Type',
    edit_employment_role: 'Edit Employment Role',
    tax_name_lable: 'Tax Name / Label',
    coupon_redemption_are_not_available: 'Coupon Redemptions are not available.',
    session: 'Session',
    product_details: 'Product Details',
    current: 'current',
    upcoming: 'upcoming',
    step_1: 'Step 1',
    step_2: 'Step 2',
    step_3: 'Step 3',
    edit_tax: 'Edit Tax',
    update_rule: 'Update Rule',
    save_rule: 'Save Rule',
    filter_by: 'Filter by',
    export_csv: 'Export CSV',
    settled_count: 'Settled Count',
    return_count: 'Return Count',
    cancelled: 'Cancelled',
    bill_amount: 'Bill Amount',
    settled_amount: 'Settled Amount',
    return_amount: 'Return Amount',
    cancel_amount: 'Cancel Amount',
    terminal_name: 'Terminal',
    opening_date_time: 'Opening Date & Time',
    billed_by: 'Billed By',
    phone: 'Phone',
    new_customer_summary: 'New Customer Summary',
    customer_id: 'Customer ID',
    customer_on_board_summary: 'Customer On-Board Summary',
    card_amount: 'Card Amount',
    cash_amount: 'Cash Amount',
    expenses_amount: 'Expenses Amount',
    tax_amount: 'Tax Amount',
    out_of_stocks: 'Out of Stock',
    instock_report_summary: 'InStock report summary',
    quantity_available: 'Quantity Avaliable',
    coupon_discount: 'Coupon Discount',
    coupons: 'Coupons',
    title_is_required: 'Title is required',
    title_not_more_than_25_characters: 'Title not more than_25_characters',
    priority_is_required: 'Priority is required',
    description_is_required: 'Description is required',
    description_not_more_than_150_characters: 'Description_not_more_than_150_characters',
    no_reporsitory_image: 'No Repository Image',

    business_information: 'Business Information',
    click_on: 'Click on ',
    outlet_to: 'outlet to',
    view_and_update_settings: 'view and update settings',
    for_this_outlet: 'for this outlet',
    product_code_is_not_less_than: 'Product code is not less than',
    character: 'character',
    product_code_is_not_more_than: 'Product code is not more than',
    product_name_is_not_more_than: 'Product name is not more than',
    product_name_is_not_less_than: 'Product name is not less than',
    select_all: 'Select All',
    terms_conditions: 'Terms & Conditions',
    privacy_policy: 'Privacy Policy',
    PrivacyPolicy: 'Privacy Policy',
    no_of_sold: 'Sold Quantity',
    no_category_displayed: 'No category Displayed',
    no_subcategory_displayed: 'No Sub Category Displayed',
    start_scan: 'Start Scan',
    percentage: 'Percentage',
    select: 'Select ',
    on: 'On',
    off: 'Off',
    anniversary: 'Anniversary',
    dob: 'DOB ',
    return: 'Return',
    in_stock: 'In Stock',
    total_customers: 'Total Customers',
    new_customers: 'New Customers',
    view: 'View',
    click_on_the_eezzypos_for_windows_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine:
        'Click on the EezzyPOS for Windows on the POS/Till machine. This downloads a zip file on the machine.',
    unzip_the_file_and_click_on_epos_exe_file_to_run_the_installation_for_pos:
        'Unzip the file and click on epos.exe file to run the installation for POS.',
    once_the_installation_is_complete_you_can_launch_the_application_from_program_files_application_name_is_eezzypos:
        'Once the installation is complete, you can launch the application from Program Files. Application Name is eezzyPOS',
    launch_the_eezzypos_application_to_access_the_pos_for_billing_purpose_login_and_use_the_billing_pos_application:
        'Launch the EezzyPOS application to access the POS for billing purpose. Login and use the billing POS application.',
    click_on_the_eezzypos_for_mac_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine:
        'Click on the EezzyPOS for MAC on the POS/Till machine. This downloads a zip file on the machine.',
    unzip_the_file_and_click_on_epos_dmg_file_to_run_the_installation_for_pos:
        'Unzip the file and click on epos.dmg file to run the installation for POS.',
    greater_than: 'Greater than',
    less_than: 'Less than',
    step: 'Step',
    successfully_created: 'Successfully Created',
    items_successfully_created: 'Item successfully created',
    coupon_successfully_created: 'Coupon successfully created',
    category_successfully_created: 'Category successfully created',
    profile_successfully_updated: 'Profile successfully Updated',
    successfully_changed: 'Successfully Changed',
    successfully_updated: 'Successfully Updated',
    password_successfully_changed: 'Password successfully Changed',
    message: 'Message',
    edit: 'Edit',
    delete: 'Delete',
    select_categories: 'Select Categories',
    categories_name: 'Categories Name',
    select_product: 'Select Products',
    ticket_successfully_created: 'Ticket successfully created',
    cash_pay: 'Cash Pay',
    card_pay: 'Card Pay',
    guest: 'Guest',
    sign_in_to_your_account: 'Sign in to your account',
    remember_me: 'Remember me',
    forgot_password: 'Forgot password?',
    sign_in: 'Sign in',
    forgot_pass: 'Forgot Password',
    login: 'Login?',
    password_is_required: 'Password or Pin is required',
    password_is_too_short_should_be_5_chars_minimum:
        'Password is too short - should be 5 chars minimum',
    password_details_sent_to_registered_email: ' Password details sent to registered email',
    menu: 'Name',
    code: 'Code',
    product_code: 'Product Code',
    combo_deals: 'Combo Deals',
    add_combo: 'Add Combo',
    combo_name: 'Combo Name',
    offer_price: 'Offer Price',
    order_align: 'Order Align',
    combo_amount: 'Combo Amount',
    edit_combo: 'Edit Combo',
    saving: 'Saving',
    menu_schedule: 'Menu Schedule',
    add_schedule: 'Add New Schedule',
    schedule_name: 'Schedule Name',
    categories: 'Categories',
    days: 'Days',
    timing: 'Timing',
    edit_schedule: 'Edit Schedule',
    search_categories: 'Search Categories',
    veg: 'Veg',
    non_veg: 'Non-veg',
    select_days: 'Select Days',
    schedule_timimg: 'Schedule Timing',
    start_time: 'Start Time',
    reset: 'Reset',
    done: 'Done',
    edit_menu_schedule: 'Edit Menu Schedule',
    add_menu_schedule: 'Add Menu Schedule',
    grouping_categories: 'Grouping Categories',
    group_name: 'Group Name',
    group_cat: 'Group Category',
    select_category: 'Select Category',
    edit_grp: 'Edit Group Category',
    table_config: 'Table Configuration',
    table_view: 'Table View',
    add_new_area: 'Add New Area',
    generate_table: 'Generate Tables',
    special_note: 'Special Note',
    menu_item: 'Menu Item',
    edit_menu: 'Edit MenuItem',
    product: 'Product',
    kitchen_order_ticket: 'Kitchen Order Ticket',
    table_orders: 'Table Orders',
    take_aways: 'Take Aways',
    table: 'Table',
    main_order: 'Main Order',
    return_order: 'Return Order',
    no_returned_order: 'No Returned Order',
    combo_orders: 'Combo Orders',
    split_orders: 'Split Orders',
    generic_orders: 'Generic Orders',
    waiter_name: 'Waiter Name',
    item_details: 'Item Details',
    select_item: 'Select Item',
    kot: 'KOT',
    kot_orders: 'KOT Orders',
    kot_list: 'KOT List',
    kot_list_are_not_available: 'KOT list are not available.',
    table_no: 'Table No',
    foods: 'Foods',
    pay_by: 'Pay By',
    total_count: 'Total Count',
    new_category: 'New Category',
    add_new_category: 'Add New Category',
    to_creat_new_category_click_here: 'To creat new category Click here',
    category_images: 'Category Images',
    add_sub_category: 'Add Sub Category',
    sub_category_name: 'Sub Category Name',
    edit_sub_category: 'Edit Sub Category',
    business_type: 'Business Type',
    successfully_saved: 'Successfully Saved',
    attendance_successfully_saved: 'Attendance Successfully Saved',
    packing: 'Packing',
    delivery_charge: 'Delivery Charge',
    container: 'container',
    service_charge: 'Service Charge',
    all_orders: 'All Orders',
    select_status: 'Select Status',
    select_order_type: 'Select Order Type',
    both: 'Both',
    dine_in: 'Dine In',
    take_away: 'Take Away',
    select_date_range: 'Select Date Range',
    pending: 'Pending',
    grouping_category_name_is_required: 'Grouping category name is required',
    grouping_category_name_not_more_than_25_characters:
        'Grouping category name not more than 25 characters',
    table_name: 'Table Name',
    seating_capacity: 'Seating Capacity',
    waiter_name: 'Waiter Name',
    table_name_is_required: 'Table name is required',
    seating_capacity_is_required: 'Seating Capacity is required',
    waiter_name_is_required: 'Waiter Name is required',
    area_name_is_required: 'Area Name is required',
    floor_is_required: 'Floor is required',
    select_category_is_required: 'Select category Is required',
    combo_name_is_required: 'Combo name is required',
    combo_name_not_more_than_25_characters: 'Combo name name not more than 25 characters',
    combo_amount_is_required: 'Combo amount is required',
    select_product_is_required: 'Select product Is required',
    combo_deals_are_not_available_click_add_combo_to_create_new_combo:
        'Combo Deals are not available. Click Add Combo to create new Combo',
    menu_schedule_are_not_available_click_add_menu_schedule_to_create_new_menu_schedule:
        'Menu schedule are not available click add menu schedule to create new menu schedule',
    categories_selected: 'Category Selected',
    zipcode_is_required: 'Postcode is required',
    invalid_zipcode: 'Invalid Postcode',
    profile_image: 'Profile Image',
    enable_Ac_charges_in_pos: 'Enable Ac Charges In Pos',
    enable_ac_charges: 'Enable AC Charges',
    create_area: 'Create Area',
    update_area: 'Update Area',
    create_table: 'Create Table',
    update_table: 'Update Table',
    filter_order: 'Filter Order',
    grouping_categories_are_not_available_click_add_category_to_create_new_grouping_category:
        'Grouping categories are not available. Click Add Grouping categories to create new Grouping category',
    grouping_category: 'Grouping Category',
    schedule_name_is_required: 'Schedule name is required',
    selected_category_item_is_required: 'Select atleast one category or item',
    schedule_day_is_required: 'Schedule Day is required',
    schedule_time_is_required: 'Schedule time is required',
    all_days: 'All Days',
    tip_mandatory: 'Tip Mandatory',
    emp_type: 'Employment Types',
    email_address: 'Email Address',
    load_more: 'Load More',
    floor: 'Floor',
    favorite: 'Favorite',
    area_name: 'Area Name',
    refund: 'Refund',
    order_type: 'Order Type',
    more: 'More',
    miscellaneous: 'Miscellaneous',
    tips: 'Tips',
    add_miscellaneous: 'Add Miscellaneous',
    edit_miscellaneous: 'Edit Miscellaneous',
    terms_conditions: 'Terms & Conditions',
    update_date: 'Update date 2023',
    i_accept: 'I accept',
    accept: 'Accept',
    decline: 'Decline',
    error: '404 Error',
    including_tax: 'Including Tax',
    page_not_found: 'Page not found.',
    go_back_home: 'Go back home',
    sorry_we_couldnt_find_the_page_youre_looking_for:
        'Sorry, we couldn’t find the page you’re looking for.',
    miscellaneous_details: 'Miscellaneous Details',
    add_to_list: 'Add to list',
    no_of_products: 'No. of products',
    category_name: 'Enter category name',
    add_to_category: 'Add to category',
    miscellaneous_are_not_available_click_add_miscellaneous_to_create_new_miscellaneous:
        'Miscellaneous are not available. Click Add Miscellaneous to create new Miscellaneous',
    select_menu_items: 'Select Menu Items',
    global_category: 'Global Category',
    global_categories_are_not_available: 'Global Categories are not available',
    selected_category_is_mapped_to_your_business: 'Selected Category is mapped to your business',
    successfully_mapped: 'Successfully mapped',
    global_products: 'Global Products',
    global_products_are_not_available: 'Global Products are not available',
    items_successfully_mapped: 'Items successfully mapped',
    upload_csv: 'Upload',
    barcode_is_required: 'Barcode is required',
    uom_title: 'UOM Title',
    color_code: 'Color code',
    color_code_is_required: 'Color code is required',
    item_image: 'Item image',
    sub_category: 'Sub Category',
    csv_to_10mb: 'Click here to choose CSV file upto 10mb',
    slot_has_been_already_exist: 'Slot has been already exist',
    sub_categories: 'Sub Categories',
    original_price: 'Original Price',
    image_required: 'Image required',
    file_size: 'File size should be less than 2mb',
    to_creat_new_category_click_here:
        'Categories are not available. Click Add Category to create new Category',
    to_create_new_sub_category_click_three_dots_from_catogry_list:
        'Sub Categories are not available. Click three bar menu from the category list to Add Sub Category.',
    to_create_area: 'Table floor are not available. Ask to admin to add floor.',
    sub_categories: 'Sub Categories',
    no_floor_no_area: 'No floor',
    to_remove_this_time_slot: 'Do you really want to remove this time slot?',
    yes_remove_it: 'Yes, remove it',
    count: 'Count',
    menu_items: 'Menu Items',
    is_required: 'is required',
    ac_amount_updated: 'A/c Amount updated successfully',
    successfully_added: 'Successfully Added',
    choose_as_Ac: 'Choose as A/C',
    qty: 'Qty',
    bills: 'Bills',
    sales_summary_datas_are_not_available: 'Sales Summary datas are not available',
    table_configuration: 'Table Configuration',
    card: 'Card',
    choose: 'Choose',
    cash: 'Cash',
    customer_name: 'Customer Name',
    highest_selling: 'Highest Selling',
    closing: 'Closing',
    opening: 'Opening',
    customer_on_board_summary_are_not_available: 'Customer On Board summary are not available',
    highest_selling_are_not_available: 'Highest Sellings are not available',
    on_off_product_image_in_pos: 'ON/OFF Product Image in POS',
    enable_product_image_in_pos: 'Enable Product Image in POS',
    enable_kot: 'Enable KOT',
    enable_enable_kot_in_pos: 'Enable KOT in POS',
    apply_same_starting_cash_daily: 'Apply same starting cash daily',
    on_off_cron: 'On/Off cron',
    tip: 'Tip',
    kot_order: 'KOT Order',
    kot_order_details: 'KOT Order Details',
    miscellaneous_amount: 'Miscellaneous Amount',
    tip_amount: 'Tip Amount',
    enable_card_configuartion: 'Enable Card Configuration',
    enable_card_configuartion_pos: 'Enable Card Configuration in POS',
    in_progress: 'In Progress',
    show_categories: 'Category',
    show_subcategories: 'Sub-Category',
    take_away: 'Take Away',
    queue: 'Queue',
    preparing: 'Preparing',
    ready: 'Ready',
    yes: 'Yes',
    no: 'No',
    print_sales_summary: 'Print Sales Summary',
    sales: 'Sales',
    please_select_customer: 'Please Select Customer',
    set_pin: 'Set Pin',
    password_or_pin: 'Password or Pin',
    enable_edit_orders: 'Enable Edit Orders',
    enable_edit_orders_pos: 'Enable edit orders in POS',
    edit_order: 'Edit Order',
    edit_online_order: 'Edit online Order',
    csv: 'CSV',
    card_configuration: 'Card Configuration',
    add_card_config: 'Add Card Config',
    Serial_no_is_required: 'Serial Number is Required',
    Ip_name_is_required: 'IP Name is Required',
    Serial_no: 'Serial Number',
    Ip_name: 'IP Name',
    Machine_name_is_required: 'Please Select Machine',
    Machine_name: 'Machine Name',
    edit_card_config: 'Edit Card Config',
    subscription: 'Subscription',
    due_date: 'Due Date',
    choose_your_features: 'Choose Your Features',
    subscription_view: 'Subscription View',
    subscription_no:
        'Subscription No                                                                                                   ',
    due_date: 'Due Date',
    start_date: 'Start Date',
    status: 'Status',
    features: 'Features',
    new_subscription: 'New Subscriptions',
    active_subscription: 'Active Subscriptions',
    one_year_validty: '1 Year Validity',
    yearly_subscription: 'Yearly Subscription',
    subscription_period: 'Subscription Period',
    one_year: '1 Year',
    subscription_history: 'Subscription History',
    expire_with_valid_pack: 'Expire with valid pack',
    active: 'Active',
    subscription_data_are_not_available: 'Subscription data are not available',
    card_configuration_are_not_available_click_add_card_config_to_add:
        'Card Configurations are not available click add to create card configuration',
    get_start: 'Get Start',
    profile_data: 'Profile Data',
    update_your_business_image_now: 'Update your business image now',
    update_some_common_settings_as_per_your_business_needs:
        'Update some common settings as per your business needs',
    outlet_timing: 'Outlet Timing',
    profile_completion_status: 'Profile completion status',
    sales_unit: 'Sales Unit',
    employeement_type: 'Employeement Type',
    role_permission: 'Role Permission',
    print: 'Print',
    customers_are_not_available: 'Customers are not available',
    config_settings: 'Config Settings',
    get_start_data:
        "Just getting started? Let's take a look at some of the great features of the app.",
    profile_details_data:
        "Introduced to assist users with organizing can use to make better data decisions in which profile data includes your business name, Email and contact information's.",
    business_image_data:
        'On this section, update business image is to inspire you yo create a profile that will attract and engage people want to know about your company’s mission, vision, and how you can help them solve their problems. And, an impactful and memorable first impression can help you stand out in the digital world.',
    config_data:
        'Configuration feature provides the process of making the arrangement of the parts that make up a whole that self-service option to easily adding a tax master and payment type.',
    users_data:
        'User can manage and give their best efforts every day to achieve the goals of your organization. Getting fresh insights to help business to change/ improve their business strategy for which setting up role and role permission for the users.',
    outlet_timing_data:
        'Outlet timings to make every moment for customer to do Happy Shopping!. Setting up Outlet timing same on all days and different on all days.',
    common_settings:
        'Admin user can allow you to control and update your common settings as per your business needs such as Currency, Language and other Notifications.',
    loyalty_data:
        'On this page you will see the option to add custom rewards, modify default rewards, a both default and any custom rewards you have added. Keep your customer coming back for more, and motivate customers to make recurring purchases as they create the opportunity.',
    instructions: 'Instructions',
    pay: 'Pay',
    enable_split_bill: 'Enable Split Bill in POS',
    split_bill: 'Enable Split Bill',
    WEBSITE_DOMAIN: 'Website Domain',
    create_your_website_domain: 'Create your Website domain',
    THEME: 'Theme Setup',
    choose_your_website_color_theme_and_theme_mode:
        'Choose your website color theme and theme mode',
    website_setup: 'Website Setup',
    Online_Order_Product_list: 'Online order product list',
    edit_domain: 'Edit Domain',
    edit_Website_domain: 'Edit Website Domain',
    edit_domain_details: 'Edit Domain Details',
    add_domain_details: 'Add Domain Details',
    domain_name: 'Domain Name',
    theme_Setup: 'Theme Setup',
    Logo_For_Your_Website: 'Logo for your website',
    Color_style: 'Color Style',
    Font_Style: 'Font Style',
    Website_domain: 'Website Domain',
    product_info: 'Product info',
    publish: 'Publish',
    general_settings: 'General Settings',
    restaurent_details: 'Restaurant Details',
    restaurent_name: 'Restaurant Name',
    restaurent_contact: 'Restaurant Contact',
    restaurent_alt_contact: 'Restaurant Alternate Number',
    restaurent_email: 'Restaurant Email',
    restaurent_details: 'Restaurant Details',
    restaurent_About_us: 'Restaurant About Us',
    socmed_links: 'Social Media Links',
    restaurent_facebook: 'Facebook',
    restaurent_instagram: 'Instagram',
    restaurent_twitter: 'Twitter',
    restaurent_youtube: 'Youtube',
    details_about_restaurent: 'Details about Restaurant',
    Online_orders: 'Online Orders',
    online_order: 'Online Order',
    delivered: 'Delivered',
    online_order_details: 'Online order details',
    online_order_filter: 'Online order filter',
    enable_continuous_bill_no: 'Enable Continuous Bill No',
    enable_continuous_bill_pos: 'Enable Continuous Bill No in POS',
    orderplaced: 'Order Placed',
    add_domain: 'Add Domain',
    enable_online: 'Enable in Online',
    enable_reset: 'enable reset',
    enable_reset_in_pos: 'enable reset in pos',
    Updated_successfully: 'Published Successfully',
    Restaurant_deets_updated_successfully: 'Restaurant is Published Sucessfully',
    saved_successfully: 'Saved Successfully',
    Restaurant_deets_saved_successfully: 'Restaurant Details Saved Successfully',
    enable_online_order: 'Enable online order',
    select_payment_type: 'Select Payment Type',
    payment_status: 'Payment Status',
    custom_domain: 'Custom Domain',
    header_footer: 'Header & Footer',
    homepage: 'Homepage',
    about_us: 'About Us',
    order_now: 'Order Now',
    contact: 'Contact',
    online_order_website_setup: 'Online Order Website Setup',
    edit_online_order_website_setup: 'Edit Online Order Website Setup',
    mobile_should_be_more_than_10_numbers: 'Mobile should be more than 10 numbers',
    enable_passcode_in_pending_orders: 'Enable Passcode in Pending Orders',
    enable_service_charge: 'Enable Service Charge ',
    service_charge: 'Service Charge',
    enable_tip: 'Enable Tip',
    enable_gif: 'Enable Gif',
    prod_order_no: 'Product Order No',
    enable_enable_dinein_in_pos: 'Enable Dinein In Pos',
    enable_dinein: 'Enable Dinein'
};
