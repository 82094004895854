var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs } from "react/jsx-runtime";
const Button = (_a) => {
    var { text, className, children, disabled, onClick } = _a, props = __rest(_a, ["text", "className", "children", "disabled", "onClick"]);
    return (_jsxs("button", Object.assign({ type: "button", disabled: disabled, className: className, onClick: onClick }, { children: [children, text] }), void 0));
};
export default Button;
