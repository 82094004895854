var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-empty-pattern */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/** @format */
import { useState, useEffect } from 'react';
import { Button, Icon, Input, Parent, Text } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import { UserGroupIcon, SearchIcon, PlusIcon } from '@heroicons/react/solid';
import CustomerTable from './CustomersTable';
import SlideOverlay from '../../Component/SlideOverlay/SlideOverlay';
import { useQuery } from '@apollo/client';
import { GETCUSTOMERSQUERY } from '../../GraphqlQuery/Query/Query';
import { CUSTOMERSADDQUERY, CUSTOMERSUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { CUSTOMERSFIELDS, STARTPAGE } from '../../Utilities/Constants';
import DataNotFound from '../DataNotFound/DataNotFound';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames, filterFunction } from '../../Utilities/Helpers';
const Customers = ({}) => {
    const { t } = useTranslation();
    const [active, setActive] = useState(1);
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const [apiResultArray, setApiResultArray] = useState([]);
    const [singleObj, setSingleObj] = useState({});
    const [columns, setColumns] = useState([]);
    const [filterDataList, setFilterDataList] = useState([]);
    const [showErr, setShowErr] = useState(false);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const tableHead = [
        {
            id: 1,
            title: t('name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('mobile'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('favorites'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('created_date'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const sliderOpen = (value, title) => {
        getFields();
        setOpen(value);
        setSliderTitle(title);
    };
    const { loading, error, data, refetch } = useQuery(GETCUSTOMERSQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const loadData = (values, from) => {
        refetch();
    };
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((obj) => obj.id === id);
        setSingleObj(Obj);
        setOpen(value);
        setSliderTitle(title);
    };
    const getFields = () => {
        const fields = CUSTOMERSFIELDS.fields;
        let singleObjData = {};
        let columnsArray = [];
        let index = 0;
        fields.map((item) => {
            if (item.create === true && item.edit === true) {
                singleObjData[item['field name']] = '';
            }
            if (item.show_in_ui === true) {
                columnsArray.push({
                    id: index,
                    title: item['display_name'],
                    className: 'px-6 pt-10 pb-6 text-left text-sm font-poppins font-medium text-gray-400'
                });
                index++;
            }
        });
        if (columnsArray) {
            if (columnsArray.length > 0) {
                columnsArray.push({
                    id: columnsArray.length - 1,
                    title: 'Action',
                    className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
                });
            }
        }
        setColumns(columnsArray);
        setSingleObj(singleObjData);
    };
    const filterData = (e) => {
        setCurrentPageNumber(1);
        const filterText = e.target.value;
        let filertKey = {
            name: 'first_name',
            name1: 'last_name'
        };
        setApiResultArray(filterFunction(filterDataList, filterText, filertKey, 2));
    };
    const convertArrayOfObjectsToCSV = (array) => {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        if (array.length > 0) {
            const keys = Object.keys(array[0]);
            result = '';
            result += 'Customer Information';
            result += lineDelimiter;
            array.forEach((item) => {
                let ctr = 0;
                keys.forEach((key) => {
                    if (ctr > 0)
                        result += columnDelimiter;
                    result += item[key];
                    ctr++;
                });
                result += lineDelimiter;
            });
        }
        return result;
    };
    const downloadCSV = () => __awaiter(void 0, void 0, void 0, function* () {
        const link = document.createElement('a');
        const headers = [
            [
                'First Name',
                'Last Name',
                'Mobile',
                'email',
                'anniversaary',
                'dob',
                'address1',
                'address2'
            ]
        ];
        const costData = apiResultArray.map((cost) => [
            cost.first_name,
            cost.last_name,
            cost.mobile,
            cost.email,
            cost.anniversaary,
            cost.dob,
            cost.address1,
            cost.address2
        ]);
        const cust = headers.concat(costData);
        let csv = yield convertArrayOfObjectsToCSV(cust);
        if (csv == null)
            return;
        const filename = 'Customer.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    });
    useEffect(() => {
        if (data) {
            setApiResultArray(data.getAllCustomers);
            setFilterDataList(data.getAllCustomers);
        }
        getFields();
    }, [data]);
    return (_jsx(Parent, Object.assign({ className: "lg:px-12 md:px-6 px-4 lg:pb-9 md:pb-5 " }, { children: loading ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames(businessType == 'Retail' ? 'border-blue-900' : 'border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames(businessType == 'Retail' ? 'text-blue-900' : 'text-blue-900', ' font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsxs(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-3" }, { children: [_jsxs(Parent, Object.assign({ className: "flex items-center justify-between" }, { children: [_jsx(Parent, Object.assign({ className: "flex-1 min-w-0" }, { children: _jsx(Input, { name: "search", className: classNames(businessType == 'Retail'
                                    ? 'focus:ring-blue-600 focus:border-blue-600'
                                    : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm block w-48 sm:w-60 px-3 pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "search", placeHolder: t('enter_customer_name'), type: "text", labelText: t('search'), labelClass: "block text-base font-poppins font-normal text-gray-600 pb-1", labelRequired: false, iconRequired: true, iconTag: SearchIcon, onChange: (e) => filterData(e) }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "flex-shrink-0 flex-1" }, { children: _jsxs(Parent, Object.assign({ className: "flex justify-end" }, { children: [_jsx(Button, { text: t('add_customer'), className: classNames(businessType == 'Retail'
                                            ? 'text-blue-600 bg-blue-100 hover:bg-blue-600'
                                            : 'text-blue-600 bg-blue-100 hover:bg-blue-600', 'group hidden sm:inline-flex items-center px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md  hover:text-white focus:outline-none'), onClick: () => sliderOpen(true, t('add_customer')) }, void 0), _jsx(Parent, Object.assign({ className: "sm:hidden block " }, { children: _jsx(Parent, Object.assign({ className: "group inline-flex items-center px-4 sm:py-2 py-3 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => sliderOpen(true, t('add_customer')) }, { children: _jsx(Icon, { tag: PlusIcon, className: "w-5 h-5 text-blue-600 group-hover:text-white rounded-md " }, void 0) }), void 0) }), void 0)] }), void 0) }), void 0)] }), void 0), apiResultArray && apiResultArray.length > 0 ? (_jsx(CustomerTable, { onClick: sliderOpen, resultData: apiResultArray, getSingleObj: getSingleObj, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, columns: tableHead, reloadData: loadData, total: apiResultArray && apiResultArray.length > 0
                        ? apiResultArray.length
                        : 0 }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('customers_are_not_available_click_add_customer_to_create_new_customer'), iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: UserGroupIcon }, void 0)), _jsx(SlideOverlay, { overLayWidth: "max-w-2xl", open: open, setOpen: setOpen, title: sliderTitle, from: "customers", reloadData: loadData, singleObj: singleObj, fields: CUSTOMERSFIELDS.fields, addQuery: CUSTOMERSADDQUERY, updateQuery: CUSTOMERSUPDATEQUERY, showErr: showErr, setShowErr: setShowErr }, void 0)] }), void 0)) }), void 0));
};
export default Customers;
