var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { EyeIcon } from '@heroicons/react/solid';
import { Parent, Icon, Link, Text } from '../../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../../Component/Table';
import TableHeader from '../../TableHeaders/TableHeader';
import apiCall from '../../../../API/apiCall';
import OrderView from '../../../Component/SlideOverlay/OrderView';
import { useTranslation } from 'react-i18next';
import '../../../../translations/i18n';
import { classNames } from '../../../Utilities/Helpers';
import { APILINK } from '../../../Utilities/ApiLink';
// import { ORDERCANCELQUERY } from '../../../GraphqlQuery/Mutation/Mutation';
import ServerSidePagination from '../../Pagination/ServerSidePagination';
import { format, parseISO } from 'date-fns';
const KotOrdersTable = ({ resultData, total, tabId, getOrder, getItem, setCurrentPageNumber, currentPageNumber, offset, setOffset, items, pageLimit, dataLimit, getPaginatedData, setDataLimit, startIndex, endIndex, currentPage, setCurrentPage, strt, setStrt, end, setEnd }) => {
    const { t } = useTranslation();
    const [showInfo, setShowInfo] = useState(false);
    const [tableData, setTableData] = useState(resultData);
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const [filterOrder, setFilterOrder] = useState();
    const token = localStorage.getItem('token');
    const [busType, setBusType] = useState(localStorage.getItem('business_type'));
    const orderColumns = [
        {
            id: 1,
            title: t('order_no'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('order_type'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('customer'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        busType == 'Retail'
            ? ''
            : {
                id: 4,
                title: t('waiter_name'),
                className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
            },
        /*  {
            id: 5,
            title: t('payment_type'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        }, */
        // need in featuer
        {
            id: 6,
            title: t('status'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 7,
            title: `${t('amount')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 8,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const showViewInfo = (value, id) => {
        getSingleOrder(value, id);
    };
    const getSingleOrder = (value, id) => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(`${APILINK.order_get}${id}`, 'GET', token, {})
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            yield setFilterOrder((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data);
            setShowInfo(value);
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    useEffect(() => {
        setTableData(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    const [showAlert, setShowAlert] = useState(false);
    const [id, setId] = useState('');
    const [userName, setUserName] = useState('');
    // const [deleteRecordQuery] = useMutation(ORDERCANCELQUERY);
    const showConfirmation = (value, id, Name) => {
        setShowAlert(value);
        setId(id);
        setUserName(Name);
    };
    // const deleteRecord = () => {
    //     deleteRecordQuery({
    //         variables: {
    //             id: parseInt(id),
    //             type: 'delete'
    //         }
    //     }).then(() => {
    //         getOrder();
    //         setShowAlert(false);
    //         getSingleOrder;
    //     });
    // };
    const isToday = (dateString) => {
        if (typeof dateString !== 'string') {
            return false;
        }
        try {
            const today = format(new Date(), 'yyyy-MM-dd');
            const date = format(parseISO(dateString), 'yyyy-MM-dd');
            return date === today;
        }
        catch (error) {
            console.error('Invalid date string:', dateString, error);
            return false;
        }
    };
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: " align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-xs font-poppins font-medium text-gray-400", columns: orderColumns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: tableData.map((person) => {
                                        var _a;
                                        return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.order_no }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.order_from === '1'
                                                            ? t('online_order')
                                                            : person.is_takeaway === '0'
                                                                ? t('dine_in')
                                                                : t('take_away') }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsxs(Parent, Object.assign({ className: "flex flex-col " }, { children: [_jsx(Text, Object.assign({ as: "p", className: "text-sm text-left font-poppins font-normal text-gray-800" }, { children: `${person.first_name == null
                                                                    ? t('guest')
                                                                    : person.first_name} ${person.last_name == null
                                                                    ? ''
                                                                    : person.last_name}` }), void 0), _jsx(Parent, Object.assign({ className: "text-sm font-poppins mt-1 font-normal text-gray-400" }, { children: `${person.mobile == null ? '' : person.mobile}` }), void 0)] }), void 0) }), void 0), busType == 'Retail' ? ('') : (_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins text-left font-normal text-gray-800" }, { children: person.order_from == '1'
                                                            ? '-'
                                                            : person.is_takeaway == '0'
                                                                ? (_a = person === null || person === void 0 ? void 0 : person.tbl_details[0]) === null || _a === void 0 ? void 0 : _a.waiter_name
                                                                : person.biller_name }), void 0) }), void 0)), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: person.kotstatus == 2 || person.kotstatus == 0 ? (_jsx(Parent, Object.assign({ className: " bg-blue-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-blue-500 text-center" }, { children: t('Queue') }), void 0) }), void 0)) : person.kotstatus == 3 ? (_jsx(Parent, Object.assign({ className: " bg-yellow-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-yellow-500 text-center" }, { children: t('Preparing') }), void 0) }), void 0)) : person.kotstatus == 4 ? (_jsx(Parent, Object.assign({ className: " bg-green-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-green-500 text-center" }, { children: t('Ready') }), void 0) }), void 0)) : ('') }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins text-right font-normal text-gray-800" }, { children: person.total }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap text-left text-sm font-medium w-1/6" }, { children: _jsx(Link, Object.assign({ className: classNames(busType == 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group  inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('view'), onClick: () => showViewInfo(true, person.id) }, { children: _jsx(Icon, { tag: EyeIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0) }), void 0)] }, person.id));
                                    }) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(ServerSidePagination, { totalData: resultData, items: items, pageLimit: pageLimit, dataLimit: dataLimit, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, total: total, from: 'item', offset: offset, setOffset: setOffset, getItem: getItem, currentPage: currentPage, setCurrentPage: setCurrentPage, strt: strt, setStrt: setStrt, end: end, setEnd: setEnd }, void 0), _jsx(OrderView, { open: showInfo, setOpen: setShowInfo, filterOrder: filterOrder, title: t('kot_order_details'), tabId: tabId }, void 0)] }), void 0));
};
export default KotOrdersTable;
