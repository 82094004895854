import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import GlobalItems from '../Containers/GlobalProducts/GlobalItems';
const GlobalItem = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false },
        { title: t('global_products'), href: '#/items', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('global_products'), breadcrumbs: breadCrumbs }, void 0), _jsx(GlobalItems, { title: t('items') }, void 0)] }, void 0));
};
export default GlobalItem;
