import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Customers from '../Containers/Customers/Customers';
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const Customer = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false },
        { title: t('customer'), href: '#/customers', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('customer'), breadcrumbs: breadCrumbs }, void 0), _jsx(Customers, { title: t('customer_table') }, void 0)] }, void 0));
};
export default Customer;
