var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable no-empty-pattern */
/** @format */
import { useState, useEffect, useRef } from 'react';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table/index';
import { Parent, Checkbox, Text } from '../../Component/index';
import TableHeader from '../TableHeaders/TableHeader';
import { MENUMAPPINGUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { useMutation } from '@apollo/client';
import apiCall from '../../../API/apiCall';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import { APILINK } from '../../Utilities/ApiLink';
const RolePermission = ({}) => {
    var _a;
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const scrollRef = useRef(null);
    const [roleColumns, setRoleColumns] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [addDataQuery, { data, loading, error }] = useMutation(MENUMAPPINGUPDATEQUERY);
    const [token, setToken] = useState(localStorage.getItem('token'));
    let paths = [
        { id: 1, path: ['/dashboard'] },
        { id: 2, path: ['/items', '/add-item', '/view-item'] },
        { id: 3, path: ['/category'] },
        { id: 5, path: ['/orders'] },
        { id: 6, path: ['/customers'] },
        {
            id: 7,
            path: [
                '/reports',
                '/customers-summary',
                '/invoice-summary',
                '/kitchen-order',
                '/inventory-selling',
                '/sales-summary'
            ]
        },
        {
            id: 8,
            path: [
                '/outlet-settings',
                '/general',
                '/outlet-details',
                '/config',
                '/outlet-timings',
                '/users',
                '/emplyoee',
                '/pos-session',
                '/loyalty-settings',
                '/subscription',
                '/setupconfig',
                '/Online-orders',
                '/stripesettings',
                '/settings'
            ]
        },
        { id: 9, path: ['/coupon', '/addcoupons'] },
        { id: 10, path: ['/loyalty'] },
        { id: 11, path: ['/pos-installation'] },
        { id: 12, path: ['/items', '/add-item', '/view-item'] },
        { id: 13, path: ['/group-cate'] },
        { id: 14, path: ['/menu-schedule', '/add-Schedule'] },
        { id: 15, path: ['/combo-deal'] },
        { id: 16, path: ['/table-view'] }
    ];
    const onFavoriteChange = (e, menu_id, role_id) => {
        var _a, _b;
        let pathItem = paths.find((element) => {
            if (element.id === menu_id) {
                return element;
            }
        });
        if (e.target.checked) {
            addDataQuery({
                variables: {
                    is_enabled: '1',
                    role_id: parseInt(role_id),
                    menu_id: parseInt(menu_id),
                    business_id: businessId,
                    outlet_id: outletId,
                    path: (_a = pathItem === null || pathItem === void 0 ? void 0 : pathItem.path) === null || _a === void 0 ? void 0 : _a.toString()
                }
            }).then((result) => {
                getRolePermissionInfo();
            });
        }
        else {
            addDataQuery({
                variables: {
                    is_enabled: '0',
                    role_id: parseInt(role_id),
                    menu_id: parseInt(menu_id),
                    path: (_b = pathItem === null || pathItem === void 0 ? void 0 : pathItem.path) === null || _b === void 0 ? void 0 : _b.toString()
                }
            }).then((result) => {
                getRolePermissionInfo();
            });
        }
    };
    const getMenu = (arrayData) => {
        let menuArray = [
            {
                id: 0,
                title: 'Role',
                className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
            }
        ];
        arrayData.menus.map((item) => {
            menuArray.push({
                id: item.id,
                title: item.name,
                className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
            });
        });
        setRoleColumns(menuArray);
    };
    const getRolePermission = (arrayData) => {
        setRoleData(arrayData.data);
    };
    const getRolePermissionInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(APILINK.rolemenu_get, 'GET', token, {
            business_id: businessId,
            outlet_id: outletId
        })
            .then((response) => {
            const responseData = response.data;
            if (response.status === 200) {
                getMenu(responseData);
                getRolePermission(responseData);
            }
            else {
            }
        })
            .catch((err) => {
            console.log(err, 'err');
        });
    });
    useEffect(() => {
        getRolePermissionInfo();
    }, []);
    const handleScroll = () => {
        if (scrollRef.current) {
            const scrollX = scrollRef.current.scrollLeft;
            localStorage.setItem('scrollX', scrollX);
        }
    };
    useEffect(() => {
        let scrollX = localStorage.getItem('scrollX');
        if (scrollRef.current) {
            if (scrollX)
                scrollRef.current.scrollLeft = parseInt(scrollX, 10);
        }
    }, [(_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft]);
    return (_jsx(_Fragment, { children: loading ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames(businessType == 'Retail' ? 'border-blue-900' : 'border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames(businessType == 'Retail' ? 'text-blue-900' : 'text-blue-900', ' font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsx(Parent, { children: _jsx(Parent, Object.assign({ className: " pb-3 w-full" }, { children: _jsx(Parent, { children: roleColumns && roleData ? (_jsx("div", Object.assign({ ref: scrollRef, onScroll: handleScroll, className: "overflow-x-auto border-b border-gray-200 cursor-pointer " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400", columns: roleColumns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: roleData.map((person) => {
                                        return (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-2/12" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.name }), void 0) }), void 0) }), void 0), person.permissions.map((item) => {
                                                    return (_jsx(Td, Object.assign({ className: "px-6 py-2.5 text-center whitespace-nowrap w-1/12" }, { children: _jsx(Parent, Object.assign({ className: "items-center text-center" }, { children: _jsx(Parent, Object.assign({ className: "text-xs cursor-pointer font-poppins items-center text-center font-medium text-gray-800" }, { children: _jsx(Checkbox, { type: "checkbox", name: `role_menu_${item.id}`, id: `role_menu_${item.id}`, onChange: (e) => onFavoriteChange(e, item.id, person.id), checked: item.permission ===
                                                                        '1'
                                                                        ? true
                                                                        : false, className: classNames(businessType ==
                                                                        'Retail'
                                                                        ? 'text-blue-500'
                                                                        : 'text-blue-500', ' h-5 w-5 cursor-pointer  focus:ring-transparent text-center items-center border-gray-300 rounded') }, void 0) }), void 0) }), void 0) }), item.id));
                                                })] }, person.id));
                                    }) }), void 0)] }), void 0) }), void 0)) : (_jsx(Parent, {}, void 0)) }, void 0) }), void 0) }, void 0)) }, void 0));
};
export default RolePermission;
