import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
var CryptoJS = require('crypto-js');
import { useState } from 'react';
import { Parent, Image, Text, Link, Icon } from '../../Component/index';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import UnOrderList from '../../../app/Component/List/UnOrderList';
import ListItem from '../../../app/Component/List/ListItem/ListItem';
import { DownloadIcon } from '@heroicons/react/solid';
import { SERVERURL } from '../../Utilities/Constants';
const Installing = ({}) => {
    const { t } = useTranslation();
    const [titleOption, setTitleOption] = useState(t('windows'));
    const [titleTrans, setTitleTrans] = useState('Windows');
    const INSTRUCTIONSARRAY = [
        {
            id: 1,
            title: t('click_on_the_eezzypos_for_windows_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine')
        },
        {
            id: 2,
            title: t('unzip_the_file_and_click_on_epos_exe_file_to_run_the_installation_for_pos')
        },
        {
            id: 3,
            title: t('once_the_installation_is_complete_you_can_launch_the_application_from_program_files_application_name_is_eezzypos')
        },
        {
            id: 4,
            title: t('launch_the_eezzypos_application_to_access_the_pos_for_billing_purpose_login_and_use_the_billing_pos_application')
        }
    ];
    const MACINSTRUCTIONSARRAY = [
        {
            id: 1,
            title: t('click_on_the_eezzypos_for_mac_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine')
        },
        {
            id: 2,
            title: t('unzip_the_file_and_click_on_epos_dmg_file_to_run_the_installation_for_pos')
        },
        {
            id: 3,
            title: t('once_the_installation_is_complete_you_can_launch_the_application_from_program_files_application_name_is_eezzypos')
        },
        {
            id: 4,
            title: t('launch_the_eezzypos_application_to_access_the_pos_for_billing_purpose_login_and_use_the_billing_pos_application')
        }
    ];
    const NAVIGATIONSETTINGSARRAY = [
        {
            id: 1,
            title: t('pos_for_windows'),
            image: require('../../assets/media/Windows.svg'),
            link: `${SERVERURL}eezzyPOS-1.0.0-wind.zip`
        }
    ];
    const handleClick = (id) => {
        if (id === 1) {
            setTitleOption(t('windows'));
            setTitleTrans('Windows');
        }
        else if (id === 2) {
            setTitleOption(t('mac'));
            setTitleTrans('Mac');
        }
    };
    return (_jsxs(Parent, Object.assign({ className: "lg:px-12 md:px-6 pb-9 grid grid-cols-4 gap-x-5 px-4" }, { children: [_jsx(Parent, Object.assign({ className: "col-span-4 xl:col-span-2" }, { children: NAVIGATIONSETTINGSARRAY.map((item) => {
                    return (_jsx(Parent, Object.assign({ className: "bg-white mb-4 col-span-1 cursor-pointer os_box rounded-md shadow-custom", onClick: () => handleClick(item.id) }, { children: _jsxs(Parent, Object.assign({ className: "flex items-center justify-between py-5 px-5" }, { children: [_jsx(Image, { src: item.image
                                        ? item.image
                                        : require('../../assets/media/Mac.svg'), className: "w-14 h-14  text-blue-600 rounded-md  bg-blue-100  p-3" }, void 0), _jsx(Text, { className: "font-poppins text-xl font-medium text-center text-black py-5", as: "p", label: item.title }, void 0), _jsxs(Parent, Object.assign({ className: " flex justify-center items-center" }, { children: [_jsx("a", Object.assign({ href: item.link, download: true, className: " hidden lg:block group inline-flex items-center lg:px-6 xl:px-12 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:bg-blue-600 hover:text-white focus:outline-none " }, { children: t('download') }), void 0), _jsx(Link, Object.assign({ href: item.link, className: ' lg:hidden md:block group inline-flex items-center lg:px-4 lg:py-4 px-3 py-3 border border-transparent shadow-custom text-base font-poppins font-medium rounded-full text-blue-600 bg-blue-100 hover:bg-blue-600 hover:text-white focus:outline-none ', download: true }, { children: _jsx(Icon, { tag: DownloadIcon, className: "h-6 w-6 sm:h-5 sm:w-5 group-hover:text-white text-blue-600" }, void 0) }), void 0)] }), void 0)] }), void 0) }), item.id));
                }) }), void 0), _jsxs(Parent, Object.assign({ className: "col-span-4 xl:col-span-2 p-5 bg-white overflow-hidden" }, { children: [_jsx(Parent, { children: _jsx(Text, { as: "h2", className: "text-2xl font-poppins font-medium text-black", label: `${t('installation_for_pos')} ${titleOption}` }, void 0) }, void 0), titleTrans === 'Windows' || titleTrans === '' ? (_jsx(Parent, Object.assign({ className: "overflow-y-auto  pt-3" }, { children: _jsx(Parent, { children: _jsx(UnOrderList, Object.assign({ className: "list-none ml-3" }, { children: INSTRUCTIONSARRAY.map((item) => {
                                    return (_jsxs(ListItem, Object.assign({ className: "font-poppins text-sm font-normal text-left text-black py-2" }, { children: [_jsx(Text, { as: "span", className: "font-bold font-sm font-poppins mr-2", label: `${t('step')} ${item.id} : ` }, void 0), `${item.title}`] }), item.id));
                                }) }), void 0) }, void 0) }), void 0)) : (_jsx(Parent, Object.assign({ className: "overflow-y-auto pt-3" }, { children: _jsx(Parent, { children: _jsx(UnOrderList, Object.assign({ className: "list-none ml-3" }, { children: MACINSTRUCTIONSARRAY.map((item) => {
                                    return (_jsxs(ListItem, Object.assign({ className: "font-poppins text-sm font-normal text-left text-black py-2" }, { children: [_jsx(Text, { as: "span", className: "font-bold font-sm font-poppins mr-2", label: `${t('step')} ${item.id} : ` }, void 0), `${item.title}`] }), item.id));
                                }) }), void 0) }, void 0) }), void 0))] }), void 0)] }), void 0));
};
export default Installing;
