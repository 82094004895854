var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Parent } from '../../Component';
import CategoryGlobal from '../../Component/GlobalItem/CategoryGlobal';
import ContainerSection from '../ContainerSection/ContainerSection';
import SuccessPopUp from '../../Component/SuccessPopUp/SuccessPopUp';
import apiCall from '../../../API/apiCall';
import { APILINK } from '../../Utilities/ApiLink';
import { GLOBALCATEGORYGETQUERY } from '../../GraphqlQuery/Query/Query';
import { useQuery } from '@apollo/client';
const GlobalCategories = ({}) => {
    const [showpop, setShowpop] = useState(false);
    let token = localStorage.getItem('token');
    const [apiResultArray, setApiResultArray] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const { loading, error, data, refetch } = useQuery(GLOBALCATEGORYGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const AddList = (value) => {
        const params = {
            outlet_id: outletId,
            business_id: businessId,
            id: [value],
            type: 'category'
        };
        apiCall
            .PostServerCall(APILINK.product_update, 'PUT', token, params)
            .then(() => __awaiter(void 0, void 0, void 0, function* () {
            setShowpop(true);
        }))
            .catch((err) => {
            console.log(err, 'err');
        });
    };
    setTimeout(() => {
        setShowpop(false);
    }, 3000);
    useEffect(() => {
        var _a;
        if (data) {
            setApiResultArray((_a = data.getAllCategory) === null || _a === void 0 ? void 0 : _a.data);
        }
    }, [data]);
    return (_jsxs(Parent, Object.assign({ className: " max-w-8xl w-full lg:px-12 px-6 pb-9 align-middle inline-block " }, { children: [_jsx(Parent, Object.assign({ className: " right-5 left-5 sm:left-0 sm:right-10 top-14 absolute flex justify-end " }, { children: showpop && (_jsx(SuccessPopUp, { setShowSuccess: setShowpop, title: t('successfully_mapped'), description: t('selected_category_is_mapped_to_your_business') }, void 0)) }), void 0), _jsx(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-3" }, { children: _jsx(CategoryGlobal, { AddList: AddList }, void 0) }), void 0)] }), void 0));
};
export default GlobalCategories;
