var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Redirect, Route } from 'react-router-dom';
const PrivateRoute = (_a) => {
    var { path, component: Component, children } = _a, rest = __rest(_a, ["path", "component", "children"]);
    const getMenu = localStorage.getItem('menu');
    const menu = (getMenu && JSON.parse(getMenu)) || [];
    const commamRoute = {
        is_enabled: '1',
        path: [
            '/add-item',
            '/profile',
            '/tracking-issue',
            '/view-item',
            '/customers-summary',
            '/invoice-summary',
            '/kitchen-order',
            '/inventory-selling',
            '/sales-summary',
            '/outlet-settings',
            '/general',
            '/outlet-details',
            '/config',
            '/outlet-timings',
            '/users',
            '/emplyoee',
            '/pos-session',
            '/loyalty-settings',
            '/subscription',
            '/setupconfig',
            '/Online-orders',
            '/stripesettings',
            '/add-schedule'
        ]
    };
    menu.push(commamRoute);
    // isAuthorizedRoute check rote access then return true or false
    const isAuthorizedRoute = (path) => {
        let productedRoute = false;
        menu === null || menu === void 0 ? void 0 : menu.map((item) => {
            var _a;
            if (item.is_enabled == '1') {
                (_a = item === null || item === void 0 ? void 0 : item.path) === null || _a === void 0 ? void 0 : _a.map((item) => {
                    if (item == path) {
                        productedRoute = true;
                    }
                });
            }
        });
        return productedRoute;
    };
    return (_jsx(Route, Object.assign({}, rest, { render: (props) => {
            if (localStorage.getItem('isLogin') === '1') {
                return isAuthorizedRoute(path) ? (_jsx(Component, Object.assign({}, props), void 0)) : (_jsx(Redirect, { to: { pathname: '/not-authorized', state: { from: props.location } } }, void 0));
            }
            else {
                return (_jsx(Redirect, { to: { pathname: '/login', state: { from: props.location } } }, void 0));
            }
        } }), void 0));
};
export default PrivateRoute;
