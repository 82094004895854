import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import Items from '../Containers/Items/Items';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
const Item = () => {
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false },
        { title: t('items'), href: '#/items', current: true }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: businessType == 'Retail' ? t('items') : t('menu_item'), breadcrumbs: breadCrumbs }, void 0), _jsx(Items, { title: businessType == 'Retail' ? t('items') : t('menu_item') }, void 0)] }, void 0));
};
export default Item;
