import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { CLIENTURL } from '../../Utilities/Constants';
const httpLink = createHttpLink({
    uri: CLIENTURL
});
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: Object.assign(Object.assign({}, headers), { 
            // authorization: token ? `Bearer ${token}` : "",
            token: token ? `${token}` : '' })
    };
});
export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});
