import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useEffect } from 'react';
import { classNames } from '../../Utilities/Helpers';
import { Parent, Text, Image, Checkbox } from '../../Component/index';
import DataNotFound from '../DataNotFound/DataNotFound';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { imageOnErrorHandler } from '../../Utilities/Helpers';
let defalutImg = require('../../assets/media/no-image.png');
const Listed = ({ scheduleName, list, businessType, catSelection, list2, is_Array }) => {
    useEffect(() => { }, [is_Array]);
    return (_jsx(Parent, Object.assign({ className: classNames(is_Array ? 'h-menu-schedule-one ' : 'h-menu-schedule', ' overflow-y-auto ') }, { children: _jsx(Parent, Object.assign({ className: " grid lg:grid-cols-1 md:grid-cols-2" }, { children: scheduleName === 'Category' ? (list.length > 0 ? (list.map((img1) => {
                return (_jsx(Parent, { children: _jsxs(Parent, Object.assign({ className: "flex gap-x-3 my-2.5 items-center " }, { children: [_jsx(Checkbox, { type: "checkbox", name: img1.pro_name, id: img1.id, checked: img1.checked, className: classNames(businessType == 'Retail'
                                    ? 'text-blue-500'
                                    : 'text-blue-600', 'focus:ring-transparent h-4 ml-2 w-4 items-center justfy-center border-gray-300 rounded'), onChange: (e) => catSelection(e.target, img1, 'img1') }, void 0), _jsxs(Parent, Object.assign({ className: "flex " }, { children: [_jsx(Parent, Object.assign({ className: "w-11 h-11" }, { children: _jsx(Image, { className: " w-full h-full object-cover rounded-md ", src: img1.cat_image ? img1.cat_image : defalutImg, onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "all img" }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "flex items-center pl-2" }, { children: _jsx(Text, Object.assign({ className: "font-poppins text-sm font-normal text-black", as: "span" }, { children: img1.cat_name }), void 0) }), void 0)] }), void 0)] }), void 0) }, void 0));
            })) : (_jsx(Parent, Object.assign({ className: " flex col-span-2 justify-center items-center" }, { children: _jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: "Items / Category not available", iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: ExclamationCircleIcon }, void 0) }), void 0))) : list2.length > 0 ? (list2.map((img1) => {
                return (_jsx(Parent, { children: _jsxs(Parent, Object.assign({ className: "flex gap-x-3 my-2.5 items-center " }, { children: [_jsx(Checkbox, { type: "checkbox", name: img1.pro_name, id: img1.id, checked: img1.checked, className: classNames(businessType == 'Retail'
                                    ? 'text-blue-500'
                                    : 'text-blue-600', 'focus:ring-transparent h-4 ml-2 w-4 items-center justfy-center border-gray-300 rounded'), onChange: (e) => catSelection(e.target, 'img1', img1) }, void 0), _jsxs(Parent, Object.assign({ className: "flex " }, { children: [_jsx(Parent, Object.assign({ className: "w-11 h-11" }, { children: _jsx(Image, { className: " w-full h-full object-cover rounded-md ", src: img1.product_img ? img1.product_img : defalutImg, onError: (e) => imageOnErrorHandler(e, defalutImg), alt: "all img" }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "flex items-center pl-2" }, { children: _jsx(Text, Object.assign({ className: "font-poppins text-sm font-normal text-black", as: "span" }, { children: img1.prod_name }), void 0) }), void 0)] }), void 0)] }), void 0) }, img1.id));
            })) : (_jsx(Parent, Object.assign({ className: " flex col-span-2 justify-center items-center" }, { children: _jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: "Items / Category not available", iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: ExclamationCircleIcon }, void 0) }), void 0)) }), void 0) }), void 0));
};
export default Listed;
