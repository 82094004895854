import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/solid';
import { Parent, Icon, Link } from '../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import TableHeader from '../TableHeaders/TableHeader';
import Pagination from '../Pagination/Pagination';
import { DATALIMIT, PAGELIMIT } from '../../Utilities/Constants';
import ConfirmationAlert from '../../Component/ConfirmationAlert/ConfirmationAlert';
import ViewInfo from '../../Component/SlideOverlay/ViewInfo';
import Moment from 'moment';
import { useMutation } from '@apollo/client';
import { USERDELETEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { t } from 'i18next';
import { classNames } from '../../Utilities/Helpers';
const UsersTable = ({ className, onClick, usersData, getSingleUser, total = 0, reloadData, currentPageNumber, setCurrentPageNumber }) => {
    const columns = [
        {
            id: 1,
            title: t('name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('role'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('email'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('created_date'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [users, setUsers] = useState(usersData);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [showAlert, setShowAlert] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [deleteUserQuery, { data, loading, error }] = useMutation(USERDELETEQUERY);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        const list = usersData.slice(startIndex, endIndex);
        setEndIndex(usersData.length > endIndex ? endIndex : usersData.length);
        setStartIndex(startIndex + 1);
        setUsers(list);
    };
    const showConfirmation = (value, userId, userName) => {
        setShowAlert(value);
        setUserId(userId);
        setUserName(userName);
    };
    const deleteUser = () => {
        deleteUserQuery({
            variables: {
                id: parseInt(userId),
                is_active: '0'
            }
        }).then((result) => {
            reloadData('', '');
            setShowAlert(false);
        });
    };
    useEffect(() => {
        setUsers(usersData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, usersData]);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: " align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: users &&
                                        users.map((person) => (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: `${person.first_name} ${person.last_name}` }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.roles.name }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.email }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/6" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: Moment(person.c_ts).format('DD-MMM-YYYY') }), void 0) }), void 0), _jsxs(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap text-center text-sm font-medium w-1/6" }, { children: [_jsx(Link
                                                        // href="#"
                                                        , Object.assign({ 
                                                            // href="#"
                                                            className: classNames(businessType == 'Retail'
                                                                ? 'hover:bg-blue-600'
                                                                : 'hover:bg-blue-600', 'group mr-4 inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('edit'), onClick: () => getSingleUser(person.id, true, t('edit_user')) }, { children: _jsx(Icon, { tag: PencilIcon, className: "h-3.5 w-3.5  group-hover:text-white text-gray-400" }, void 0) }), void 0), _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                                ? 'hover:bg-blue-600'
                                                                : 'hover:bg-blue-600', 'group inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('delete'), onClick: () => showConfirmation(true, person.id, person.first_name) }, { children: _jsx(Icon, { tag: TrashIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0)] }), void 0)] }, person.id))) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Pagination, { items: users, pageLimit: pageLimit, dataLimit: dataLimit, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, totalData: usersData, endIndex: endIndex, total: total }, void 0), _jsx(ConfirmationAlert, { id: userId, open: showAlert, setOpen: setShowAlert, deleteRecord: deleteUser, title: t('are_you_sure'), description: `${t('do_you_really_want_to_delete')} ${userName} ?` }, void 0), _jsx(ViewInfo, { open: showInfo, setOpen: setShowInfo, title: "Customer Information" }, void 0)] }), void 0));
};
export default UsersTable;
