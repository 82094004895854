/** @format */
import React, { useState, useEffect, useRef } from 'react';
import { FilterIcon, CloudDownloadIcon, UserGroupIcon, SearchIcon } from '@heroicons/react/solid';
import { Button, Icon, Text, Parent, Input } from '../../../Component/index';
import ContainerSection from '../../ContainerSection/ContainerSection';
import {
    Chart as ChartScript,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import Layout from '../../../Layouts/Layout';
import PageHeader from '../../../Layouts/Core/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import '../../../../translations/i18n';
import SalesSummaryTable from './SalesSummaryTable';
import DataNotFound from '../../DataNotFound/DataNotFound';
import { useQuery } from '@apollo/client';
import { GETINVOICESUMMARYQUERY, GETSALESCOUNTQUERY } from '../../../GraphqlQuery/Query/Query';
import Moment from 'moment';
import ReportFiltter from '../../../Component/ReportFiltter/ReportFiltter';
import { classNames } from '../../../Utilities/Helpers';
import printIcon from '../../../assets/Icons/printIcon';
import { useReactToPrint } from 'react-to-print';

import {
    CashIcon,
    CashOneIconProps,
    CardIcon,
    CardAmountIcon,
    TaxPayIcon,
    TaxPaymentIcon,
    ExpensesAmountIcon,
    ExpensesIcon,
    MiscellaneousIconNew,
    TipIconNew,
    PaymentIcon,
    UpiIcon,
    UpiPayIcon
} from '../../../assets/Icons';
import CouponIcon from '../../../assets/Icons/Coupon';
import CouponCountIcon from '../../../assets/Icons/CouponCount';
import DiscountCountIcon from '../../../assets/Icons/DiscountCount';
import ServiceCountIcon from '../../../assets/Icons/ServiceCount';
import ServiceChargeIcon from '../../../assets/Icons/serviceCharge';
import ManagerDiscIcon from '../../../assets/Icons/MangerDiscountIcon';
import { COLORS } from '../../../Utilities/Color';
import SummaryCard from './SummaryCard';
import PrintBill from '../../../Component/Bill/PrintBill';
import 'jspdf-autotable';
import SalesSummaryPrint from '../../../Component/Bill/SalesSummaryPrint';
import { SERVERURL } from '../../../Utilities/Constants';
import { APILINK } from '../../../Utilities/ApiLink';
import ExportFilter from '../../../Component/ReportFiltter/ExportFilter';

const tableData = [
    {
        id: 1,
        date: '01-02-2021',
        pay_type: 'Cash',
        order_status: 'Settled',
        billed_by: 'Rene',
        mobile: '9874563210',
        name: 'Thara',
        total: '435',
        amount: 400,
        tax: 34,
        discount: 0
    },
    {
        id: 2,
        date: '01-02-2021',
        pay_type: 'Cash',
        order_status: 'Cancelled',
        billed_by: 'Rene',
        mobile: '9874563210',
        name: 'Thara',
        total: '435',
        amount: 400,
        tax: 34,
        discount: 0
    },
    {
        id: 3,
        date: '01-02-2021',
        pay_type: 'Cash',
        order_status: 'Settled',
        billed_by: 'Rene',
        mobile: '9874563210',
        name: 'Thara',
        total: '435',
        amount: 400,
        tax: 34,
        discount: 0
    },
    {
        id: 4,
        date: '01-02-2021',
        pay_type: 'Cash',
        order_status: 'Returned',
        billed_by: 'Rene',
        mobile: '9874563210',
        name: 'Thara',
        total: '435',
        amount: 400,
        tax: 34,
        discount: 0
    },
    {
        id: 5,
        date: '01-02-2021',
        pay_type: 'Cash',
        order_status: 'Settled',
        billed_by: 'Rene',
        mobile: '9874563210',
        name: 'Thara',
        total: '435',
        amount: 400,
        tax: 34,
        discount: 0
    }
];

const SalesSummary = () => {
    const { t } = useTranslation();
    const [apiResultArray, setApiResultArray] = useState(tableData);
    const [filterDataList, setFilterDataList] = useState(tableData);
    const [open, setOpen] = useState(false);
    const [singleObj, setSingleObj] = useState({});
    const [sliderTitle, setSliderTitle] = useState('');
    const [print, setPrint] = useState(false);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const breadCrumbs = [
        // { title: t('home'), href: '#/dashboard', current: false, show: 'block' },
        {
            title: t('report'),
            href: '#/reports',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: t('sales_summary'),
            href: '#/sales-summary',
            current: true,
            show: 'block'
        }
    ];
    const [currencySymbol, setCurrencySymbol] = useState(
        localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£'
    );

    const Tablecolumns = [
        {
            id: 1,
            title: t('invoice_no'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },

        {
            id: 2,
            title: t('date'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },

        {
            id: 3,
            title: t('order_status'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },

        {
            id: 4,
            title: `${t('amount')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: `${t('tax')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 6,
            title: `${t('service_charge')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 7,
            title: `${t('Loyolty/Coupon')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 8,
            title: `${t('Manager Discount')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 9,
            title: `${t('miscellaneous')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 10,
            title: `${t('Tip')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 11,
            title: `${t('total')} (${currencySymbol})`,
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 12,
            title: t('actions'),
            className: 'px-6 py-6 text-right text-sm font-poppins font-medium text-gray-400'
        }
    ];

    ChartScript.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        RadialLinearScale,
        Title,
        Tooltip,
        Legend
    );

    const [cashPay, setCashPay] = useState(0);
    const [cashPayCount, setCashPayCount] = useState(0);
    const [cardPay, setCardPay] = useState(0);
    const [cardPayCount, setCardPayCount] = useState(0);
    const [upiPayCount, setUpiPayCount] = useState(0);
    const [upiPay, setUpiPay] = useState(0);
    const [Tax, setTax] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [managerDiscount, setManagerDiscount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [TaxCount, setTaxCount] = useState(0);
    const [ManagerDiscCount, setManagerDiscCount] = useState(0);
    const [discountCount, setDiscountCount] = useState(0);
    const [miscellaneousCount, setMiscellaneousCount] = useState(0);
    const [tipCount, setTipCount] = useState(0);
    const [serviceCount, setServiceCount] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [expensesCount, setExpensesCount] = useState(0);
    const [miscellaneousAmount, setMiscellaneousAmount] = useState(0);
    const [tipAmount, setTipAmount] = useState(0);
    const [total, setTotal] = useState(0);

    const salesData = {
        labels: [
            t('cash_pay'),
            t('card_pay'),
            t('upi'),
            t('tax'),
            t('service_charge'),
            t('Loyolty/Coupon'),
            t('Manager Discount'),
            t('expenses'),
            t('miscellaneous'),
            t('tip')
        ],
        datasets: [
            {
                label: 'Sales',
                data: [
                    cashPay,
                    cardPay,
                    upiPay,
                    Tax,
                    serviceCharge,
                    discount,
                    managerDiscount,
                    expenses,
                    miscellaneousAmount,
                    tipAmount
                ],
                backgroundColor: [
                    COLORS.greenAlpha,
                    COLORS.blueAlpha,
                    COLORS.cyan_600,
                    COLORS.deep_saffron,
                    COLORS.orange_100,
                    COLORS.red_500,
                    COLORS.orange_500,
                    COLORS.indigo_500,
                    COLORS.purple_500,
                    COLORS.deep_violet
                ],
                borderWidth: 1
            }
        ]
    };

    const optionsPolar = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    padding: 10,
                    boxWidth: 15,
                    boxHeight: 15
                }
            },
            title: {
                display: false,
                text: 'Line Chart'
            }
        }
    };
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [showAlert, setShowAlert] = useState(false);
    const [showexportFilter, setshowexportFilter] = useState(false);
    const [start, setStart] = useState(Moment(new Date()).format('YYYY-MM-DD'));

    const [startDate, setStartDate] = useState(
        Moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
    );
    const [endDate, setEndDate] = useState(new Date());
    const sliderOpen = (value, title) => {
        setOpen(value);
        setSliderTitle(title);
    };
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((obj) => obj.id === id);

        setSingleObj(Obj);
        setOpen(value);
        setSliderTitle(title);
    };

    const showConfirmation = (value) => {
        setShowAlert(value);
    };
    const showExportFilter = (value) => {
        setshowexportFilter(value);
    };

    const convertArrayOfObjectsToCSV = (array) => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';

        if (array.length > 0) {
            const keys = Object.keys(array[0]);

            result = '';
            result += 'Sales summary';
            result += lineDelimiter;

            array.forEach((item) => {
                let ctr = 0;
                keys.forEach((key) => {
                    if (ctr > 0) result += columnDelimiter;

                    result += item[key];

                    ctr++;
                });
                result += lineDelimiter;
            });
        }

        return result;
    };

    // const downloadCSV = async () => {
    //     const link = document.createElement('a');
    //     const headers = [
    //         [
    //             'Invoice No',
    //             'Date',
    //             'Payment Type',
    //             'Status',
    //             'Billed By',
    //             'Phone',
    //             'Name',
    //             `Tips (${currencySymbol})`,
    //             `Total (${currencySymbol})`,
    //             `Amount (${currencySymbol})`,
    //             `Tax (${currencySymbol})`,
    //             `service Tax (${currencySymbol})`,
    //             `Loyolty/Coupon (${currencySymbol})`,
    //             `Manager Discount (${currencySymbol})`
    //         ]
    //     ];
    //     const formatValue = (value) => (value == null ? '' : value);
    //     const costData = filterDataList.map((cost) => [
    //         formatValue(cost.id),
    //         formatValue(cost.date),
    //         formatValue(cost.pay_type),
    //         formatValue(cost.status),
    //         formatValue(cost.biller_name),
    //         formatValue(cost.mobile),
    //         formatValue(cost.customer_name),
    //         formatValue(cost.tip_amount),
    //         formatValue(cost.total),
    //         formatValue(cost.amount),
    //         formatValue(cost.tax),
    //         formatValue(cost.service_charge_amt),
    //         formatValue(cost.discount),
    //         formatValue(cost.mgnt_disc_amt)
    //     ]);
    //     const cust = headers.concat(costData);

    //     let csv = await convertArrayOfObjectsToCSV(cust);

    //     if (csv == null) return;

    //     const filename = 'Sales Summary.csv';
    //     csv = '\ufeff' + csv;
    //     var csvFile = new Blob([csv], {
    //         type: 'text/csv'
    //     });
    //     if (!csv.match(/^data:text\/csv/i)) {
    //         csv = `data:text/csv;charset=utf-8,${csv}`;
    //     }

    //     link.setAttribute('href', URL.createObjectURL(csvFile));
    //     link.setAttribute('download', filename);
    //     link.click();
    // };
    const handleDownloadExcel = async () => {
        try {
            // Format startDate to YYYY-MM-DD
            // const formattedStartDate = Moment(startDate).format('YYYY-MM-DD');
            // const formattedEndDate = Moment(endDate).format('YYYY-MM-DD');
            const todayDate = Moment(start).format('YYYY-MM-DD'); // This will return today's date in 'YYYY-MM-DD' format
            const response = await fetch(
                `${SERVERURL}${APILINK.export_invoice_excel}?business_id=${parseInt(
                    businessId
                )}&outlet_id=${parseInt(outletId)}&date=${todayDate}`
            );

            if (!response.ok) {
                throw new Error(
                    `Network response was not ok (${response.status} ${response.statusText})`
                );
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'invoice.xlsx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            alert('Error downloading invoice. No Record Found.');
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    let componentRefone = useRef(null);
    let noPopupSummary = function (target) {
        return new Promise(() => {
            const data = target.contentWindow.document.documentElement.outerHTML;
            const blob = new Blob([data], { type: 'text/html' });
            const url = URL.createObjectURL(blob);

            window.electronAPI.printComponent(url, (responsey) => {});
        });
    };
    let popupHideSummary = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

    let popupShowSummary = popupHideSummary
        ? {
              content: () => componentRefone.current,
              documentTitle: 'Sales Summary Table',
              print: noPopupSummary
          }
        : {
              content: () => componentRefone.current,
              documentTitle: 'Sales Summary Table'
          };

    const handlePrintSummary = useReactToPrint(popupShowSummary);

    //pdf generating function for sales summary
    const salesSummaryPrint = () => {
        handlePrintSummary();
    };

    const { loading, error, data, refetch } = useQuery(GETSALESCOUNTQUERY, {
        variables: {
            start_date: Moment(startDate).format('YYYY-MM-DD'),
            end_date: Moment(endDate).format('YYYY-MM-DD'),
            business_id: businessId,
            outlet_id: outletId
        }
    });

    const {
        loading: loading1,
        error: error1,
        data: data1,
        refetch: refetch1
    } = useQuery(GETINVOICESUMMARYQUERY, {
        variables: {
            start_date: Moment(startDate).format('YYYY-MM-DD'),
            end_date: Moment(endDate).format('YYYY-MM-DD'),
            business_id: businessId,
            outlet_id: outletId
        }
    });
    const noPopup = function (target) {
        return new Promise(() => {
            const data = target.contentWindow.document.documentElement.outerHTML;
            const blob = new Blob([data], { type: 'text/html' });
            const url = URL.createObjectURL(blob);

            window.electronAPI.printComponent(url, (responsey) => {});
        });
    };

    let componentRef = useRef(null);

    let popupHide = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;
    let popupShow = popupHide
        ? {
              content: () => componentRef.current,
              documentTitle: 'Sales Summary Reports',
              print: noPopup
          }
        : {
              content: () => componentRef.current,
              documentTitle: 'Sales Summary Reports'
          };
    const handlePrint = useReactToPrint(popupShow);
    const printFunction = () => {
        setPrint(true);
        handlePrint();
    };

    useEffect(() => {
        if (data) {
            setCardPay(
                data.getSalesCount[0].data.card[0].card_amount
                    ? data.getSalesCount[0].data.card[0].card_amount
                    : 0
            );
            setCardPayCount(
                data.getSalesCount[0].data.card[0].card_count
                    ? data.getSalesCount[0].data.card[0].card_count
                    : 0
            );
            setCashPay(
                data.getSalesCount[0].data.cash[0].cash_amount
                    ? data.getSalesCount[0].data.cash[0].cash_amount
                    : 0
            );
            setCashPayCount(
                data.getSalesCount[0].data.cash[0].cash_count
                    ? data.getSalesCount[0].data.cash[0].cash_count
                    : 0
            );
            setUpiPay(
                data?.getSalesCount?.[0]?.data.upi?.[0]?.upi_amount
                    ? data?.getSalesCount?.[0]?.data.upi?.[0]?.upi_amount
                    : 0
            );
            setUpiPayCount(
                data?.getSalesCount?.[0]?.data?.upi?.[0]?.upi_count
                    ? data?.getSalesCount?.[0]?.data?.upi?.[0]?.upi_count
                    : 0
            );
            setTax(
                data.getSalesCount[0].data.tax[0].tax ? data.getSalesCount[0].data.tax[0].tax : 0
            );
            setServiceCharge(
                data.getSalesCount[0].data.service_charge[0].service_charge
                    ? data.getSalesCount[0].data.service_charge[0].service_charge
                    : 0
            );
            setManagerDiscount(
                data.getSalesCount[0].data.mgnt_discount[0].mgnt_discount
                    ? data.getSalesCount[0].data.mgnt_discount[0].mgnt_discount
                    : 0
            );
            setDiscount(
                data.getSalesCount[0].data.discount[0].discount
                    ? data.getSalesCount[0].data.discount[0].discount
                    : 0
            );
            setTaxCount(
                data.getSalesCount[0].data.tax[0].tax_count
                    ? data.getSalesCount[0].data.tax[0].tax_count
                    : 0
            );
            setManagerDiscCount(
                data.getSalesCount[0].data.mgnt_discount[0].mgnt_discount_count
                    ? data.getSalesCount[0].data.mgnt_discount[0].mgnt_discount_count
                    : 0
            );
            setDiscountCount(
                data.getSalesCount[0].data.discount[0].discount_count
                    ? data.getSalesCount[0].data.discount[0].discount_count
                    : 0
            );
            setMiscellaneousCount(
                data.getSalesCount[0].data.miscellaneous[0].miscellaneous_count
                    ? data.getSalesCount[0].data.miscellaneous[0].miscellaneous_count
                    : 0
            );
            setTipCount(
                data.getSalesCount[0].data.tip[0].tip_count
                    ? data.getSalesCount[0].data.tip[0].tip_count
                    : 0
            );
            setServiceCount(
                data.getSalesCount[0].data.service_charge[0].service_charge_count
                    ? data.getSalesCount[0].data.service_charge[0].service_charge_count
                    : 0
            );
            setExpenses(
                data.getSalesCount[0].data.expenses[0].payout
                    ? data.getSalesCount[0].data.expenses[0].payout
                    : 0
            );
            setExpensesCount(
                data.getSalesCount[0].data.expenses[0].payout_count
                    ? data.getSalesCount[0].data.expenses[0].payout_count
                    : 0
            );
            setMiscellaneousAmount(
                data.getSalesCount[0].data.miscellaneous[0].miscellaneous
                    ? data.getSalesCount[0].data.miscellaneous[0].miscellaneous
                    : 0
            );
            setTipAmount(
                data.getSalesCount[0].data.tip[0].tip ? data.getSalesCount[0].data.tip[0].tip : 0
            );
            let count = parseFloat(
                parseFloat(
                    data?.getSalesCount[0].data.card[0].card_amount
                        ? data?.getSalesCount?.[0]?.data?.card?.[0]?.card_amount
                        : '0.00'
                ) +
                    parseFloat(
                        data?.getSalesCount[0].data.cash[0].cash_amount
                            ? data?.getSalesCount?.[0]?.data?.cash?.[0]?.cash_amount
                            : '0.00'
                    ) +
                    parseFloat(
                        data?.getSalesCount?.[0]?.data.upi?.[0]?.upi_amount
                            ? data?.getSalesCount?.[0]?.data.upi?.[0]?.upi_amount
                            : '0.00'
                    ) +
                    parseFloat(
                        data?.getSalesCount[0].data.tax[0].tax
                            ? data?.getSalesCount[0].data.tax[0].tax
                            : '0.00'
                    ) +
                    parseFloat(
                        data?.getSalesCount[0].data.service_charge[0].service_charge
                            ? data?.getSalesCount[0].data.service_charge[0].service_charge
                            : '0.00'
                    ) +
                    parseFloat(
                        data?.getSalesCount[0].data.expenses[0].payout
                            ? data?.getSalesCount[0].data.expenses[0].payout
                            : '0.00'
                    ) +
                    parseFloat(
                        data?.getSalesCount[0].data.miscellaneous[0].miscellaneous
                            ? data?.getSalesCount[0].data.miscellaneous[0].miscellaneous
                            : '0.00'
                    ) +
                    parseFloat(
                        data?.getSalesCount[0].data.tip[0].tip
                            ? data?.getSalesCount[0].data.tip[0].tip
                            : ' 0.00'
                    )
            ).toFixed(2);
            setTotal(count);
        }
        if (data1) {
            let array = data1?.getInvoiceSummary[0]?.data?.filter((item) => {
                if (item.status != 'kot') {
                    return item;
                }
            });
            setFilterDataList(array);
            setApiResultArray(array);
        }
    }, [data, data1]);
    let sales1 = [
        {
            id: 1,
            name: t('card_payments'),
            stat: cardPayCount,
            icon: CardIcon,
            iconColor: COLORS.blue_600,
            iconBgColor: 'bg-blue-100',
            total: total
        },
        {
            id: 2,
            name: t('cash_payment'),
            stat: cashPayCount,
            icon: CashIcon,
            iconColor: COLORS.green_400,
            iconBgColor: 'bg-green-100',
            total: total
        },
        {
            id: 3,
            name: t('upi_payment'),
            stat: upiPayCount,
            icon: UpiIcon,
            iconColor: COLORS.cyan_600,
            iconBgColor: 'bg-cyan-100',
            total: total
        },

        {
            id: 4,
            name: t('expenses'),
            stat: expensesCount,
            icon: ExpensesAmountIcon,
            iconColor: COLORS.purple_500,
            iconBgColor: 'bg-purple-100',
            total: total
        },

        {
            id: 5,
            name: t('tax'),
            stat: TaxCount,
            icon: TaxPaymentIcon,
            iconColor: COLORS.yellow_400,
            iconBgColor: 'bg-yellow-100',
            total: total
        },
        {
            id: 6,
            name: t('Manager discount'),
            stat: ManagerDiscCount,
            icon: DiscountCountIcon,
            iconColor: COLORS.yellow_400,
            iconBgColor: 'bg-yellow-100',
            total: total
        },
        {
            id: 7,
            name: t('Loyolty/Coupon'),
            stat: discountCount,
            icon: CouponCountIcon,
            iconColor: COLORS.red_500,
            iconBgColor: 'bg-red-100',
            total: total
        },
        {
            id: 8,
            name: t('service_charge'),
            stat: serviceCount,
            icon: ServiceChargeIcon,
            iconColor: COLORS.violet_blue,
            iconBgColor: 'bg-cyan-100',
            total: total
        },
        {
            id: 9,
            name: t('card'),
            stat: `${currencySymbol} ${cardPay}`,
            icon: CardAmountIcon,
            iconColor: COLORS.blue_600,
            iconBgColor: 'bg-blue-100',
            total: total,
            percentage: cardPay !== 0 && total !== 0 ? ((cardPay / total) * 100).toFixed(2) : '0'
        },
        {
            id: 10,
            name: t('cash'),
            stat: `${currencySymbol} ${cashPay}`,
            icon: CashOneIconProps,
            iconColor: COLORS.green_400,
            iconBgColor: 'bg-green-100',
            total: total,
            percentage: cashPay !== 0 && total !== 0 ? ((cashPay / total) * 100).toFixed(2) : '0'
        },
        {
            id: 11,
            name: t('upi'),
            stat: `${currencySymbol} ${upiPay}`,
            icon: UpiPayIcon,
            iconColor: COLORS.cyan_600,
            iconBgColor: 'bg-cyan-100',
            total: total,
            percentage: upiPay !== 0 && total !== 0 ? ((upiPay / total) * 100).toFixed(2) : '0'
        },
        {
            id: 12,
            name: t('expenses'),
            stat: `${currencySymbol} ${expenses}`,
            icon: ExpensesIcon,
            iconColor: COLORS.purple_500,
            iconBgColor: 'bg-purple-100',
            total: total,
            percentage: expenses !== 0 && total !== 0 ? ((expenses / total) * 100).toFixed(2) : '0'
        },
        {
            id: 13,
            name: t('tax'),
            stat: `${currencySymbol} ${Tax}`,
            icon: TaxPayIcon,
            iconColor: COLORS.yellow_400,
            iconBgColor: 'bg-yellow-100',
            total: total,
            percentage: Tax !== 0 && total !== 0 ? ((Tax / total) * 100).toFixed(2) : '0'
        },
        {
            id: 14,
            name: t('Manager discount'),
            stat: `${currencySymbol} ${managerDiscount}`,
            icon: ManagerDiscIcon,
            iconColor: COLORS.orange_500,
            iconBgColor: 'bg-yellow-100',
            total: total,
            percentage:
                managerDiscount !== 0 && total !== 0
                    ? ((managerDiscount / total) * 100).toFixed(2)
                    : '0'
        },
        {
            id: 15,
            name: t('Loyolty/Coupon'),
            stat: `${currencySymbol} ${discount}`,
            icon: CouponIcon,
            iconColor: COLORS.red_500,
            iconBgColor: 'bg-red-100',
            total: total,
            percentage: discount !== 0 && total !== 0 ? ((discount / total) * 100).toFixed(2) : '0'
        },
        {
            id: 16,
            name: t('service_charge'),
            stat: `${currencySymbol} ${serviceCharge ?? 0}`,
            icon: ServiceCountIcon,
            iconColor: COLORS.violet_blue,
            iconBgColor: 'bg-cyan-100',
            total: total,
            percentage:
                serviceCharge !== 0 && total !== 0
                    ? ((serviceCharge / total) * 100).toFixed(2)
                    : '0'
        }
    ];

    const salesTwo = [
        {
            id: 1,
            name: t('miscellaneous'),
            stat: miscellaneousCount,
            icon: MiscellaneousIconNew,
            iconColor: COLORS.white,
            iconBgColor: 'bg-light_blue-500',
            total: total
        },
        {
            id: 2,
            name: t('tip'),
            stat: tipCount,
            icon: TipIconNew,
            iconColor: COLORS.white,
            iconBgColor: 'bg-deep_violet',
            total: total
        },
        {
            id: 3,
            name: t('miscellaneous_amount'),
            stat: `${currencySymbol} ${miscellaneousAmount}`,
            icon: MiscellaneousIconNew,
            iconColor: COLORS.white,
            iconBgColor: 'bg-light_blue-500',
            total: total,
            percentage:
                miscellaneousAmount !== 0 && total !== 0
                    ? ((miscellaneousAmount / total) * 100).toFixed(2)
                    : '0'
        },
        {
            id: 4,
            name: t('tip_amount'),
            stat: `${currencySymbol} ${tipAmount}`,
            icon: TipIconNew,
            iconColor: COLORS.white,
            iconBgColor: 'bg-deep_violet',
            total: total,
            percentage:
                tipAmount !== 0 && total !== 0 ? ((tipAmount / total) * 100).toFixed(2) : '0'
        }
    ];
    // sales summary filteration function
    const searchOrder = (e) => {
        const filterText = e.target.value.toLowerCase(); // Convert filterText to lowercase
        const filterList = apiResultArray?.filter((item) => {
            return item.id && item.id.toLowerCase().includes(filterText.toLowerCase());
        });
        setFilterDataList(filterList);
    };

    return (
        <Layout>
            <PageHeader title={t('report')} breadcrumbs={breadCrumbs} />
            <Parent className="lg:px-12 md:px-6 px-4 pb-5">
                <ContainerSection className=" overflow-hidden py-2">
                    <Parent className="">
                        <Parent className=" flex justify-end ">
                            <Parent className="flex justify-end">
                                <Button
                                    text={t('filter_by')}
                                    className="group inline-flex items-center mr-4 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100  hover:text-white hover:bg-blue-600 focus:outline-none"
                                    onClick={() => showConfirmation(true)}>
                                    <Icon
                                        tag={FilterIcon}
                                        className="w-5 h-5 text-blue-600 group-hover:text-white rounded-md mr-2"
                                    />
                                </Button>
                            </Parent>
                            <Parent className="flex justify-end">
                                <Button
                                    text={t('export_csv')}
                                    className="group inline-flex items-center px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none"
                                    onClick={() => showExportFilter(true)}>
                                    <Icon
                                        tag={CloudDownloadIcon}
                                        className="w-5 h-5 text-blue-600 group-hover:text-white rounded-md mr-2"
                                    />
                                </Button>
                            </Parent>
                        </Parent>
                    </Parent>
                </ContainerSection>

                <Parent className="grid md:grid-cols-12 mt-3 gap-5">
                    <Parent className="bg-white  rounded-lg shadow-custom lg:col-span-4 md:col-span-12  p-5  ">
                        <Parent className="">
                            <Text
                                as="p"
                                className="text-base text-gray-700 font-poppins font-semibold">
                                {t('sales_summary')}
                            </Text>

                            <Parent className="flex justify-center items-center  mx-auto mt-5 max-w-sm">
                                <PolarArea data={salesData} options={optionsPolar} />
                            </Parent>
                        </Parent>
                    </Parent>
                    <Parent className="lg:col-span-8 md:col-span-12 bg-white p-5 shadow-md rounded-lg  ">
                        <Parent className="flex items-center justify-between">
                            <Text
                                as="p"
                                className="text-base text-gray-700 font-poppins font-semibold">
                                {t('sales_summary')}
                            </Text>
                            <Parent
                                title={t('print_sales_summary')}
                                onClick={() => {
                                    printFunction();
                                }}
                                className="cursor-pointer">
                                <Icon
                                    tag={printIcon}
                                    color={COLORS.blue_600}
                                    className="w-5 h-5 text-blue-600 group-hover:text-white rounded-md mr-2 cursor-pointer"
                                />
                            </Parent>
                            <Parent className="hidden">
                                <PrintBill
                                    ref={componentRef}
                                    dataTwo={sales1.slice(8, 16)}
                                    dataOne={sales1.slice(0, 8)}
                                    dataThree={salesTwo.slice(2, 4)}
                                    dataFour={salesTwo.slice(0, 2)}
                                    date={`${Moment(startDate).format('DD MMM YY')} - ${Moment(
                                        endDate
                                    ).format('DD MMM YY')}`}
                                />
                            </Parent>
                        </Parent>

                        <Parent className="grid grid-cols-2 pt-2 pb-4 gap-x-5">
                            <Parent className="flex  justify-between">
                                <Text
                                    as="p"
                                    className=" text-gray-400 font-sm font-medium">{`${Moment(
                                    startDate
                                ).format('DD MMM YY')} - ${Moment(endDate).format(
                                    'DD MMM YY'
                                )}`}</Text>
                                <Text
                                    as="p"
                                    label={t('bills')}
                                    className=" text-gray-400 font-sm font-medium"
                                />
                            </Parent>
                            <Text as="p" className=" text-gray-400 font-sm font-medium text-right">
                                {t('amount')}
                            </Text>
                        </Parent>
                        <Parent className=" grid sm:grid-cols-2 grid-cols-1 gap-x-5">
                            <Parent>
                                {sales1.slice(0, 8).map((item) => {
                                    return <SummaryCard item={item} />;
                                })}
                            </Parent>
                            <Parent>
                                {sales1.slice(8, 16).map((item) => {
                                    return <SummaryCard item={item} />;
                                })}
                            </Parent>
                        </Parent>
                        <Parent className=" grid sm:grid-cols-2 grid-cols-1 gap-x-2">
                            <Parent>
                                {salesTwo.slice(2, 3).map((itemOne) => {
                                    return <SummaryCard itemOne={itemOne} />;
                                })}
                            </Parent>
                            <Parent>
                                {salesTwo.slice(3, 4).map((itemOne) => {
                                    return <SummaryCard itemOne={itemOne} />;
                                })}
                            </Parent>
                        </Parent>
                    </Parent>
                </Parent>

                <ContainerSection className="bg-white overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-5">
                    <Parent className="md:flex md:items-center md:justify-between">
                        <Parent className=" min-w-0">
                            <Text
                                as="span"
                                className=" text-base font-medium font-poppins text-gray-700"
                                label={t('sales_summary')}
                            />
                        </Parent>
                        <Parent className="flex items-center justify-center gap-3">
                            <Parent className=" min-w-0">
                                <Input
                                    type="text"
                                    name="search"
                                    id="search"
                                    className={classNames(
                                        businessType == 'Retail'
                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                            : 'focus:ring-blue-600 focus:border-blue-600',
                                        'shadow-sm  block pl-10 w-full sm:text-xs border-gray-300 text-gray-400 rounded-md font-poppins font-normal py-2 px-4 z-0'
                                    )}
                                    placeHolder={t('Enter your invoice no')}
                                    labelClass="block pt-5 pb-3 text-base text-black-400 font-poppins font-medium "
                                    labelText={t('upload_from_repository')}
                                    iconRequired={true}
                                    iconTag={SearchIcon}
                                    onChange={(e) => searchOrder(e)}
                                />
                            </Parent>
                            <Parent
                                title={t('print_sales_summary')}
                                onClick={() => {
                                    salesSummaryPrint();
                                }}
                                className="cursor-pointer">
                                <Icon
                                    tag={printIcon}
                                    color={COLORS.blue_600}
                                    className="w-5 h-5 text-blue-600 group-hover:text-white rounded-md mr-2 cursor-pointer"
                                />
                            </Parent>
                        </Parent>
                        <Parent className="hidden">
                            <SalesSummaryPrint
                                ref={componentRefone}
                                data={filterDataList}
                                date={`${Moment(startDate).format('DD MMM YY')} - ${Moment(
                                    endDate
                                ).format('DD MMM YY')}`}
                            />
                        </Parent>
                    </Parent>
                    {filterDataList && filterDataList.length > 0 ? (
                        <SalesSummaryTable
                            onClick={sliderOpen}
                            resultData={filterDataList}
                            getSingleObj={getSingleObj}
                            columns={Tablecolumns}
                            total={
                                filterDataList && filterDataList.length > 0
                                    ? filterDataList.length
                                    : 0
                            }
                        />
                    ) : (
                        <DataNotFound
                            textClassName="py-3 text-sm text-gray-400 font-poppins font-normal text-center "
                            textString={t('sales_summary_are_not_available')}
                            iconClassName="h-16 w-16 text-blue-600 m-auto mb-2"
                            iconTag={UserGroupIcon}
                        />
                    )}
                </ContainerSection>
                <ReportFiltter
                    open={showAlert}
                    setOpen={setShowAlert}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                />
                <ExportFilter
                    open={showexportFilter}
                    setOpen={setshowexportFilter}
                    startDate={start}
                    setStartDate={setStart}
                    handleDownloadExcel={handleDownloadExcel}
                />
            </Parent>
        </Layout>
    );
};

export default SalesSummary;
