import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Button, Icon, Input, Parent, Link, Image, Text, Select } from '../../Component/index';
import { Table, Thead, Tbody, Tr, Td } from '../../Component/Table';
import { TrashIcon } from '@heroicons/react/solid';
import { useQuery } from '@apollo/client';
import { ADDONSGETQUERY } from '../../GraphqlQuery/Query/Query';
import TableHeader from '../TableHeaders/TableHeader';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
const Category = [
    {
        label: 'Select ',
        value: '1'
    }
];
const ListAddOns = ({ addOns, add, removeAdd }) => {
    const { t } = useTranslation();
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const columns = [
        {
            id: 1,
            title: t('type'),
            className: 'px-6 pb-2 text-left text-sm font-poppins font-medium text-gray-700'
        },
        {
            id: 2,
            title: t('add_on'),
            className: 'pb-2 px-6 text-left text-sm font-poppins font-medium text-gray-700'
        },
        {
            id: 3,
            title: `${t('price')}(${currencySymbol})`,
            className: ' px-6 pb-2 text-right text-sm font-poppins font-medium text-gray-700'
        },
        {
            id: 4,
            title: '',
            className: 'px-6 pb-2 text-right text-sm font-poppins font-medium text-gray-700'
        }
    ];
    const [apiAddonArray, setApiAddonArray] = useState([]);
    const [addOnId, setaddOnId] = useState(0);
    const [addOnArray, setaddOnArray] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [itemsEmtyArray, setItemsEmtyArray] = useState([]);
    const [addRows, setaddRows] = useState([]);
    const { loading, error, data, refetch } = useQuery(ADDONSGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const addNew = (val) => {
        addOns(addOnArray, itemsEmtyArray, 'add');
    };
    const people = [
        {
            id: '1',
            type: 'Non-Veg',
            img: require('../../assets/media/nonVeg.png')
        },
        {
            id: '2',
            type: 'Veg',
            img: require('../../assets/media/veg.png')
        }
    ];
    const onChangeAddons = (event, from, id) => {
        var _a;
        const oldAddon = [...addRows];
        let oldEmptys = [];
        let val = event.target.value;
        let index = event.nativeEvent.target.selectedIndex;
        let valText = (_a = event.nativeEvent.target[index]) === null || _a === void 0 ? void 0 : _a.text;
        const newAddon = oldAddon.map((item) => {
            if (from == 'addon' && item.id === id) {
                return Object.assign(Object.assign({}, item), { addon: val, addon_name: valText });
            }
            else if (from == 'price' && item.id === id) {
                if (!val) {
                    let emp = {
                        id: item.id
                    };
                    oldEmptys.push(emp);
                    setItemsEmtyArray(oldEmptys);
                    return Object.assign(Object.assign({}, item), { price: val, errorMsg: true });
                }
                return Object.assign(Object.assign({}, item), { price: val, errorMsg: false });
            }
            else if (from == 'type' && item.id === id) {
                return Object.assign(Object.assign({}, item), { type_id: event.target.id, type_name: val });
            }
            return item;
        });
        setaddOnArray(newAddon);
        addOns(newAddon, oldEmptys, 'valueChange');
    };
    useEffect(() => {
        if (data) {
            let oldaddon = [];
            oldaddon.push({
                label: 'Select Addon',
                value: '',
                hidden: true
            });
            data.getAllAddon.map((addon) => {
                let cata = {
                    label: addon.name,
                    value: addon.id
                };
                oldaddon.push(cata);
                setApiAddonArray(oldaddon);
            });
        }
    }, [data]);
    useEffect(() => {
        if (add)
            setaddRows(add);
    }, [add]);
    return (_jsx(Parent, Object.assign({ className: "flex flex-col" }, { children: _jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "py-0 align-middle inline-block min-w-full" }, { children: _jsxs(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200" }, { children: [_jsx(Parent, Object.assign({ className: "justify-end pt-2 flex" }, { children: _jsx(Button, { onClick: () => addNew('add'), text: t('add'), className: classNames(businessType == 'Retail'
                                    ? 'hover:bg-blue-600 '
                                    : 'hover:bg-blue-600 ', 'inline-flex justify-center items-center px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md hover:text-white focus:outline-none text-blue-600 bg-blue-100 ') }, void 0) }), void 0), _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5 " }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400", columns: columns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: addRows.map((addOns, index) => (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-4 py-2.5 whitespace-nowrap " }, { children: businessType == 'Retail' ? ('') : (_jsx(Parent, Object.assign({ className: classNames(addOns.errorMsg ? ' mb-6' : '', 'lg:flex flex-col lg:flex-row items-center ') }, { children: people.map((item) => (_jsxs(Parent, Object.assign({ className: "flex items-center mr-2" }, { children: [_jsx(Input, { name: `people${index}`, type: "radio", className: classNames(businessType == 'Retail'
                                                                    ? 'text-blue-600'
                                                                    : 'text-blue-600', 'focus:ring-transparent mt-0 h-4 w-4 ml-2 border-gray-300'), checked: item.id == addOns.type_id
                                                                    ? true
                                                                    : false, id: item.id, value: item.type, onClick: (e) => onChangeAddons(e, 'type', addOns.id) }, void 0), _jsxs(Parent, Object.assign({ className: "mt-1 ml-2 flex items-center" }, { children: [_jsx(Image, { src: item.img, className: "h-4  w-4 " }, void 0), _jsx(Text, Object.assign({ as: "label", className: "ml-2  block text-sm font-medium text-gray-400 " }, { children: item.type }), void 0)] }), void 0)] }), void 0))) }), void 0)) }), void 0), _jsx(Td, Object.assign({ className: "px-4 py-2.5 whitespace-nowrap " }, { children: _jsx(Parent, Object.assign({ className: classNames(addOns.errorMsg ? 'mb-6' : '', 'flex items-center text-left font-poppins') }, { children: _jsx(Select, { optionItems: apiAddonArray, name: "SalesUnit", id: "SalesUnit", className: classNames(businessType == 'Retail'
                                                            ? 'focus:ring-blue-500 focus:border-blue-500'
                                                            : 'focus:ring-blue-500 focus:border-blue-500', 'sm:py-3 py-4 shadow-sm  block font-poppins sm:w-52 md:w-full font-normal text-gray-600 text-sm leading-none sm:text-xs border-gray-200 rounded-md'), onChange: (e) => onChangeAddons(e, 'addon', addOns.id), value: addOns.addon }, void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-4 py-2.5 whitespace-nowrap " }, { children: _jsxs(Parent, Object.assign({ className: " text-left font-poppins flex flex-col justify-end" }, { children: [_jsx(Input, { type: "number", name: "Price", id: "", className: "py-3 shadow-sm sm:w-52 md:w-full block font-poppins font-normal text-gray-600 text-sm leading-none sm:text-xs border-gray-200 rounded-md", labelClass: "", labelText: " ", value: addOns.price, onChange: (e) => onChangeAddons(e, 'price', addOns.id), onBlur: (e) => onChangeAddons(e, 'price', addOns.id) }, void 0), addOns.errorMsg && (_jsx(Text, { as: "p", className: " leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: 'Price is required' }, void 0))] }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-4 py-2.5 whitespace-nowrap " }, { children: _jsx(Parent, Object.assign({ className: classNames(addOns.errorMsg ? ' mb-6' : '', '  flex justify-end text-left font-poppins') }, { children: _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                            ? 'hover:bg-blue-600'
                                                            : 'hover:bg-blue-600', 'group inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white  focus:outline-none'), title: t('delete'), onClick: () => removeAdd(addOns.id) }, { children: _jsx(Icon, { tag: TrashIcon, className: "h-4 w-4 group-hover:text-white text-red-400" }, void 0) }), void 0) }), void 0) }), void 0)] }, addOns.id))) }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0));
};
export default ListAddOns;
