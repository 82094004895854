import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { classNames } from '../Utilities/Helpers';
import Taxes from './Taxes/Taxes';
import Addons from './Addons/Addons';
import Emptypes from './Emptypes/Emptypes';
import EmpRoels from './EmpRoels/EmpRoels';
import Miscellaneous from './Miscellaneous/Miscellaneous';
import { Button, Icon, Parent, Select, Text } from '../Component/index';
import ContainerSection from './ContainerSection/ContainerSection';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import Tax from '../assets/Icons/Tax';
import Addon from '../assets/Icons/Addons';
import EmploymentType from '../assets/Icons/EmploymentType';
import EmployeeRole from '../assets/Icons/EmployeeRole';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../Utilities/Color';
import MiscellaneousIcon from '../assets/Icons/MiscellaneousIcon';
import CardConfiguration from './CardConfiguaration/CardConfiguration';
import CardConfigIcon from '../assets/Icons/CardConfig';
const Configuration = ({}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [items, setItems] = useState('Tax Master');
    const tabs = [
        {
            id: 1,
            name: t('tax_master'),
            label: t('tax_master'),
            value: t('tax_master'),
            href: '#',
            icon: Tax,
            content: Taxes,
            color: COLORS.blue_600,
            sub_color: COLORS.gray_400
        },
        {
            id: 3,
            name: t('add_on'),
            label: t('add_on'),
            value: t('add_on'),
            href: '#',
            icon: Addon,
            content: Addons,
            color: COLORS.blue_600,
            sub_color: COLORS.gray_400
        },
        {
            id: 6,
            name: t('employment_types'),
            label: t('employment_types'),
            value: t('employment_types'),
            href: '#',
            icon: EmploymentType,
            content: Emptypes,
            color: COLORS.blue_600,
            sub_color: COLORS.gray_400
        },
        {
            id: 7,
            name: t('employee_role'),
            label: t('employee_role'),
            value: t('employee_role'),
            href: '#',
            icon: EmployeeRole,
            content: EmpRoels,
            color: COLORS.blue_600,
            sub_color: COLORS.gray_400
        },
        {
            id: 9,
            name: t('miscellaneous'),
            label: t('miscellaneous'),
            value: t('miscellaneous'),
            href: '#',
            icon: MiscellaneousIcon,
            content: Miscellaneous,
            color: COLORS.blue_600,
            sub_color: COLORS.gray_400
        },
        {
            id: 10,
            name: t('card_configuration'),
            label: t('card_configuration'),
            value: t('card_configuration'),
            href: '#',
            icon: CardConfigIcon,
            content: CardConfiguration,
            color: COLORS.blue_600,
            sub_color: COLORS.gray_400
        }
    ];
    return (_jsx(Parent, Object.assign({ className: "lg:px-12 pb-9 md:px-6 px-4" }, { children: _jsxs(ContainerSection, Object.assign({ className: "bg-white rounded-lg shadow-custom px-2 py-5 mt-3" }, { children: [_jsxs(Parent, Object.assign({ className: "md:flex md:justify-between xl:hidden md:mb-5 md:px-3 flex justify-between px-3 " }, { children: [_jsx(Button, Object.assign({ text: t('back'), className: "group inline-flex items-center lg:mb-4 md:mb-0 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => history.push('/outlet-settings') }, { children: _jsx(Icon, { tag: ArrowLeftIcon, className: "w-4 h-4 text-blue-600 group-hover:text-white rounded-md mr-2" }, void 0) }), void 0), _jsx(Select, { optionItems: tabs, className: ' shadow-sm focus:ring-blue-600 w-44 sm:w-auto lg:h-10 focus:border-blue-600 block font-normal font-poppins text-gray-600 text-sm leading-none t border-gray-200 rounded-md', id: 'item_id', name: 'name', value: items, onChange: (e) => setItems(e.target.value) }, void 0)] }), void 0), _jsx(Parent, Object.assign({ className: "overflow-hidden hidden xl:grid lg:grid-cols-8 md:grid-cols-6 " }, { children: _jsxs(Tab.Group, { children: [_jsxs(Tab.List, Object.assign({ className: "-mb-px pl-3 col-span-2 flex flex-col items-start overflow-y-auto lg:pr-2 md:pr-0 lg:border-r-2  ", "aria-label": "Tabs" }, { children: [_jsx(Button, Object.assign({ text: t('back'), className: "group inline-flex items-center lg:mb-4 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => history.push('/outlet-settings') }, { children: _jsx(Icon, { tag: ArrowLeftIcon, className: "w-4 h-4 text-blue-600 group-hover:text-white rounded-md mr-2" }, void 0) }), void 0), tabs.map((item) => {
                                        return (_jsx(Tab, { children: ({ selected }) => {
                                                return (_jsx(_Fragment, { children: _jsxs(Parent, Object.assign({ className: classNames(selected
                                                            ? 'bg-blue-100 rounded-md  text-blue-600'
                                                            : ' text-gray-500 hover:text-gray-700 ', 'lg:group lg:inline-flex lg:items-center lg:py-3 lg:px-4 lg:font-medium lg:text-sm md:hidden lg:visible') }, { children: [_jsx(Icon, { tag: item.icon, color: selected
                                                                    ? item.color
                                                                    : item.sub_color, className: classNames(selected
                                                                    ? 'text-blue-600'
                                                                    : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5'), "aria-hidden": "true" }, void 0), _jsx(Text, { as: "span", className: "text-left", label: item.name }, void 0)] }), item.id) }, void 0));
                                            } }, item.id));
                                    })] }), void 0), _jsx(Tab.Panels, Object.assign({ className: "col-span-6 pl-2" }, { children: tabs.map((item) => {
                                    const Tag = item.content;
                                    return (_jsx(Tab.Panel, { children: _jsx(Tag, {}, void 0) }, item.id));
                                }) }), void 0)] }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "xl:hidden" }, { children: tabs.map((item) => {
                        const TagName = item.content;
                        return item.name == items && _jsx(TagName, {}, void 0);
                    }) }), void 0)] }), void 0) }), void 0));
};
export default Configuration;
