// /** @format */
// import axios from 'axios';
// import { SERVERURL } from '../app/Utilities/Constants';
// const instance = axios.create({
//     baseURL: SERVERURL
// });
// export default {
//     GetServerCall: (URL: any, methodType: any, token: any, params: any) =>
//         instance({
//             method: methodType,
//             url: URL,
//             params: params,
//             headers: {
//                 'content-type': 'application/json',
//                 Accept: 'application/json',
//                 token: token
//             }
//         }),
//     PostServerCall: (URL: any, methodType: any, token: any, params?: any) =>
//         instance({
//             method: methodType,
//             url: URL,
//             data: params,
//             headers: {
//                 'content-type': 'application/json',
//                 Accept: 'application/json',
//                 token: token
//             }
//         })
// };
/** @format */
import axios from 'axios';
import { SERVERURL } from '../app/Utilities/Constants';
import { toast } from 'react-hot-toast';
const instance = axios.create({
    baseURL: SERVERURL
});
instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    var _a;
    if (error.response) {
        const errorMessage = (_a = error.response.data.message) === null || _a === void 0 ? void 0 : _a.description;
        console.log('error.response.data:', errorMessage);
        if (errorMessage) {
            toast.error(errorMessage);
        }
        else {
            toast.error(` ${error.response.status} - An unexpected error occurred.`);
        }
    }
    else if (error.request) {
        console.error('No response received:', error.request);
        toast.error('No response received: ' + JSON.stringify(error.request));
    }
    else {
        console.error('Request Error:', error.message);
        toast.error('Request Error: ' + error.message);
    }
    return Promise.reject(error);
});
export default {
    GetServerCall: (URL, methodType, token, params) => instance({
        method: methodType,
        url: URL,
        params: params,
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            token: token
        }
    }),
    PostServerCall: (URL, methodType, token, params) => instance({
        method: methodType,
        url: URL,
        data: params,
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            token: token
        }
    })
};
