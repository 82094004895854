/** @format */
import { gql } from '@apollo/client';
/* PLOP_INJECT_IMPORT */
export const UPDATETABLECONFIGAREA = gql `
    mutation updateTableConfigArea($id: ID, $area_name: String, $floor: ID) {
        updateTableConfigArea(id: $id, area_name: $area_name, floor: $floor) {
            successful
            message
        }
    }
`;
export const CREATETABLECONFIGAREA = gql `
    mutation createTableConfigArea(
        $area_name: String
        $floor: ID
        $business_id: ID
        $outlet_id: ID
    ) {
        createTableConfigArea(
            area_name: $area_name
            floor: $floor
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const DELETETABLECONFIGAREA = gql `
    mutation deleteTableConfigArea($id: ID!, $is_active: String!) {
        deleteTableConfigArea(id: $id, is_active: $is_active) {
            successful
            message
        }
    }
`;
export const DELETELOYALTYRULESSETTINGS = gql `
    mutation deleteLoyaltyrulessettings($id: ID!) {
        deleteLoyaltyrulessettings(id: $id) {
            successful
            message
        }
    }
`;
export const UPDATELOYALTYRULESSETTINGS = gql `
    mutation updateLoyaltyrulessettings(
        $id: ID!
        $events: String!
        $points: ID!
        $value: ID!
        $condition: String!
        $outlet_id: ID
        $business_id: ID
    ) {
        updateLoyaltyrulessettings(
            id: $id
            events: $events
            points: $points
            value: $value
            condition: $condition
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const CREATELOYALTYRULESSETTINGS = gql `
    mutation createLoyaltyrulessettings(
        $events: String!
        $points: ID!
        $value: ID!
        $condition: String!
        $outlet_id: ID!
        $business_id: ID!
    ) {
        createLoyaltyrulessettings(
            events: $events
            points: $points
            value: $value
            condition: $condition
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const UPDATELOYALTYSETTINGS = gql `
    mutation updateLoyaltysettings(
        $points: ID!
        $value: ID!
        $redemptions: ID
        $outlet_id: ID
        $business_id: ID
    ) {
        updateLoyaltysettings(
            points: $points
            value: $value
            redemptions: $redemptions
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const COUPONSADDQUERY = gql `
    mutation addCoupon(
        $coupon_name: String!
        $coupon_code: String!
        $valid_from: String!
        $valid_till: String!
        $disc_type_id: ID
        $disc_value: String
        $apply_tax_after_disc: String
        $outlet_id: ID
        $business_id: ID
    ) {
        createCoupons(
            coupon_name: $coupon_name
            coupon_code: $coupon_code
            valid_till: $valid_till
            valid_from: $valid_from
            disc_type_id: $disc_type_id
            disc_value: $disc_value
            apply_tax_after_disc: $apply_tax_after_disc
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const COUPONSUPDATEQUERY = gql `
    mutation updateCoupon(
        $id: ID!
        $coupon_name: String!
        $coupon_code: String!
        $valid_from: String!
        $valid_till: String!
        $disc_type_id: ID
        $disc_value: Float
        $apply_tax_after_disc: String
        $outlet_id: ID
        $business_id: ID
    ) {
        updateCoupons(
            id: $id
            coupon_name: $coupon_name
            coupon_code: $coupon_code
            valid_till: $valid_till
            valid_from: $valid_from
            disc_type_id: $disc_type_id
            disc_value: $disc_value
            apply_tax_after_disc: $apply_tax_after_disc
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
        }
    }
`;
export const COUPONSDELETEQUERY = gql `
    mutation deleteCoupon($id: ID!) {
        deleteCoupons(id: $id) {
            message
            status
        }
    }
`;
export const EMPLOYEEADDQUERY = gql `
    mutation addEmployee(
        $first_name: String!
        $last_name: String!
        $email: String!
        $address: String!
        $dob: String!
        $joining_date: String!
        $leaving_date: String!
        $outlet_id: ID!
        $business_id: ID!
        $id_proof: String!
        $mobile: String
        $profile_img: String
        $role_id: ID!
        $emp_id: String
        $payment_by: String
        $emp_t_id: ID
    ) {
        createEmployee(
            email: $email
            first_name: $first_name
            last_name: $last_name
            mobile: $mobile
            id_proof: $id_proof
            business_id: $business_id
            outlet_id: $outlet_id
            leaving_date: $leaving_date
            joining_date: $joining_date
            dob: $dob
            emp_id: $emp_id
            address: $address
            role_id: $role_id
            payment_by: $payment_by
            emp_t_id: $emp_t_id
            profile_img: $profile_img
        ) {
            successful
            message
            insertId
        }
    }
`;
export const EMPLOYEEUPDATEQUERY = gql `
    mutation updateEmployee(
        $id: ID!
        $first_name: String!
        $last_name: String!
        $email: String!
        $address: String!
        $dob: String!
        $joining_date: String!
        $leaving_date: String!
        $outlet_id: ID!
        $business_id: ID!
        $id_proof: String!
        $mobile: String
        $profile_img: String
        $role_id: ID!
        $payment_by: String
        $emp_id: String
        $emp_t_id: ID
    ) {
        updateEmployee(
            id: $id
            email: $email
            first_name: $first_name
            last_name: $last_name
            mobile: $mobile
            id_proof: $id_proof
            business_id: $business_id
            outlet_id: $outlet_id
            leaving_date: $leaving_date
            joining_date: $joining_date
            emp_id: $emp_id
            dob: $dob
            address: $address
            role_id: $role_id
            payment_by: $payment_by
            emp_t_id: $emp_t_id
            profile_img: $profile_img
        ) {
            successful
            message
        }
    }
`;
export const UPDATESETTINGS = gql `
    mutation updateSettings(
        $id: ID!
        $type: ID!
        $value: ID!
        $passcode: String
        $service_charge: Float
        $currency_code: String
        $website_theme_color: String
        $website_logo: String
        $restaurant_details: String
        $website_font_style: String
        $facebook_link: String
        $instagram_link: String
        $twitter_link: String
        $youtube_link: String
        $aboutus: String
        $name: String
        $contact: String
        $altcontact: String
        $mail: String
    ) {
        updateSettings(
            id: $id
            type: $type
            value: $value
            passcode: $passcode
            service_charge: $service_charge
            currency_code: $currency_code
            website_theme_color: $website_theme_color
            website_font_style: $website_font_style
            website_logo: $website_logo
            restaurant_details: $restaurant_details
            facebook_link: $facebook_link
            instagram_link: $instagram_link
            twitter_link: $twitter_link
            youtube_link: $youtube_link
            aboutus: $aboutus
            name: $name
            contact: $contact
            altcontact: $altcontact
            mail: $mail
        ) {
            successful
            message
        }
    }
`;
export const EMPLOYEEDELETEQUERY = gql `
    mutation deleteEmployee($id: ID!, $is_active: String!) {
        deleteEmployee(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const PAYMENTTYPESADDQUERY = gql `
    mutation addPaymentType($name: String!, $outlet_id: ID!, $business_id: ID!) {
        createPaytype(name: $name, business_id: $business_id, outlet_id: $outlet_id) {
            successful
            message
            insertId
        }
    }
`;
export const PAYMENTTYPESUPDATELISTQUERY = gql `
    mutation updatePayType($id: ID!, $name: String!) {
        updatePaytype(name: $name, id: $id) {
            successful
            message
            insertId
        }
    }
`;
export const PAYTYPESUPDATEQUERY = gql `
    mutation updatePayType($id: ID!, $is_enable: String!) {
        updatePaytypeConfig(id: $id, is_enable: $is_enable) {
            successful
            message
        }
    }
`;
export const UPDATEMISCELLANEOUSCONFIGUPDATEQUERY = gql `
    mutation updateMiscellaneousConfig($id: ID!, $is_enable: String!) {
        updateMiscellaneousConfig(id: $id, is_enable: $is_enable) {
            successful
            message
        }
    }
`;
export const TAXUPDATEQUERY = gql `
    mutation updateTax($id: ID!, $is_enable: String!) {
        updateTaxConfig(id: $id, is_enable: $is_enable) {
            successful
            message
        }
    }
`;
export const PAYMENTTYPESDELETEQUERY = gql `
    mutation deletePaymentType($id: ID!, $is_active: String!) {
        deletePaytype(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const EMP_ROELSADDQUERY = gql `
    mutation addEmpRole(
        $name: String!
        $allow_pos: String!
        $display_as: String
        $description: String
        $business_id: ID
        $outlet_id: ID
    ) {
        createRoles(
            name: $name
            allow_pos: $allow_pos
            display_as: $display_as
            description: $description
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const EMP_ROELSUPDATEQUERY = gql `
    mutation updateEmpRole(
        $id: ID!
        $name: String!
        $allow_pos: String!
        $display_as: String
        $description: String
    ) {
        updateRoles(
            id: $id
            name: $name
            allow_pos: $allow_pos
            display_as: $display_as
            description: $description
        ) {
            successful
            message
        }
    }
`;
export const EMP_ROELSDELETEQUERY = gql `
    mutation deleteEmpRole($id: ID!, $is_active: String!) {
        deleteRoles(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const CATEGORIESADDQUERY = gql `
    mutation addCategories(
        $order_align: ID!
        $cat_name: String!
        $cat_image: String
        $is_pos_on: String!
        $tag: String!
        $color_code: String!
        $outlet_id: ID!
        $business_id: ID!
        $repository_id: ID!
        $parent_id: ID
    ) {
        createCategory(
            order_align: $order_align
            cat_name: $cat_name
            cat_image: $cat_image
            is_pos_on: $is_pos_on
            tag: $tag
            color_code: $color_code
            outlet_id: $outlet_id
            business_id: $business_id
            repository_id: $repository_id
            parent_id: $parent_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const MENUSCHEDULEADDQUERY = gql `
    mutation addMenuSchedule(
        $name: String
        $type: String
        $available_days: String
        $timing: String
        $available_data: String
        $outlet_id: ID!
        $business_id: ID!
    ) {
        createMenuSchedule(
            name: $name
            type: $type
            available_days: $available_days
            timing: $timing
            available_data: $available_data
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const CATEGORIESUPDATEQUERY = gql `
    mutation updateCategories(
        $id: ID!
        $cat_name: String!
        $cat_image: String
        $is_pos_on: String!
        $color_code: String!
        $order_align: ID!
        $outlet_id: ID!
        $business_id: ID!
        $is_active: String!
        $tag: String!
        $parent_id: ID
        $repository_id: ID!
    ) {
        updateCategory(
            id: $id
            cat_name: $cat_name
            cat_image: $cat_image
            is_pos_on: $is_pos_on
            color_code: $color_code
            order_align: $order_align
            outlet_id: $outlet_id
            business_id: $business_id
            is_active: $is_active
            tag: $tag
            parent_id: $parent_id
            repository_id: $repository_id
        ) {
            successful
            message
        }
    }
`;
export const MENUSCHEDULEUPDATEQUERY = gql `
    mutation updateMenuSchedules(
        $id: ID!
        $name: String
        $type: String
        $available_days: String
        $timing: String
        $available_data: String
        $outlet_id: ID!
        $business_id: ID!
    ) {
        updateMenuSchedule(
            id: $id
            name: $name
            type: $type
            available_days: $available_days
            timing: $timing
            available_data: $available_data
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
        }
    }
`;
export const MENUSCHEDULEDELETEQUERY = gql `
    mutation deleteMenuSchedule($id: ID!) {
        deleteMenuSchedule(id: $id) {
            message
            status
        }
    }
`;
export const CATEGORIESDELETEQUERY = gql `
    mutation deleteCategories($id: ID!, $is_active: String!) {
        deleteCategory(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const ROLESADDQUERY = gql `
    mutation addRole(
        $name: String!
        $display_as: String
        $description: String
        $business_id: ID
        $outlet_id: ID
        $allow_pos: String
    ) {
        createRoles(
            name: $name
            display_as: $display_as
            description: $description
            business_id: $business_id
            outlet_id: $outlet_id
            allow_pos: $allow_pos
        ) {
            successful
            message
        }
    }
`;
export const PAYMENTADDQUERY = gql `
    mutation addPayment($name: String!) {
        createPaytype(name: $name) {
            successful
            message
            insertId
        }
    }
`;
export const ROLESUPDATEQUERY = gql `
    mutation updateRoles(
        $id: ID!
        $name: String!
        $display_as: String
        $description: String
        $allow_pos: String
    ) {
        updateRoles(
            id: $id
            name: $name
            display_as: $display_as
            description: $description
            allow_pos: $allow_pos
        ) {
            successful
            message
        }
    }
`;
export const BUSINESSUPDATEQUERY = gql `
    mutation updateBusiness(
        $id: ID!
        $business_name: String!
        $business_owner: String!
        $mobile: String!
        $email: String!
        $b_t_id: ID!
    ) {
        updateBusiness(
            id: $id
            business_name: $business_name
            business_owner: $business_owner
            mobile: $mobile
            email: $email
            b_t_id: $b_t_id
        ) {
            successful
            message
        }
    }
`;
export const BUSINESSIMAGEUPDATEQUERY = gql `
    mutation updateBusinessImage($id: ID!, $business_img: String!) {
        updateBusinessImage(id: $id, business_img: $business_img) {
            successful
            message
        }
    }
`;
export const OUTLETIMAGEUPDATEQUERY = gql `
    mutation updateOutletImage($id: ID!, $outlet_img: String!) {
        updateOutletImage(id: $id, outlet_img: $outlet_img) {
            successful
            message
        }
    }
`;
export const OUTLETUPDATEQUERY = gql `
    mutation updateOutlet(
        $id: ID!
        $business_name: String!
        $display_as: String
        $contact_name: String!
        $mobile: String
        $role: String
        $business_mobile: String
        $email: String
        $address: String
        $referred_by: ID
        $address_one: String
        $zipcode: String
        $city: String
        $business_id: ID!
        $country_id: ID!
        $licence_period: String
        $licence_type: String
        $subscription_no: String
        $start: String
        $end: String
        $feature_options: String
        $website_logo: String
        $website_theme_color: String
        $website_font_style: String
        $facebook_link: String
        $instagram_link: String
        $twitter_link: String
        $youtube_link: String
        $aboutus: String
        $restaurant_details: String
        $publish_website: String
        $custom_domain: String
    ) {
        updateOutlet(
            id: $id
            email: $email
            business_name: $business_name
            display_as: $display_as
            contact_name: $contact_name
            role: $role
            business_mobile: $business_mobile
            address: $address
            address_one: $address_one
            referred_by: $referred_by
            zipcode: $zipcode
            city: $city
            mobile: $mobile
            business_id: $business_id
            country_id: $country_id
            licence_period: $licence_period
            licence_type: $licence_type
            subscription_no: $subscription_no
            start: $start
            end: $end
            feature_options: $feature_options
            website_logo: $website_logo
            website_theme_color: $website_theme_color
            website_font_style: $website_font_style
            facebook_link: $facebook_link
            instagram_link: $instagram_link
            twitter_link: $twitter_link
            youtube_link: $youtube_link
            aboutus: $aboutus
            restaurant_details: $restaurant_details
            publish_website: $publish_website
            custom_domain: $custom_domain
        ) {
            successful
            message
        }
    }
`;
export const ROLESDELETEQUERY = gql `
    mutation deleteRole($id: ID!, $is_active: String!) {
        deleteRoles(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const EMPTYPESADDQUERY = gql `
    mutation addEmptype($emp_type: String!, $business_id: ID, $outlet_id: ID) {
        createEmptype(emp_type: $emp_type, business_id: $business_id, outlet_id: $outlet_id) {
            successful
            message
            insertId
        }
    }
`;
export const EMPTYPESUPDATEQUERY = gql `
    mutation updateEmptype($id: ID!, $emp_type: String!) {
        updateEmptype(id: $id, emp_type: $emp_type) {
            successful
            message
        }
    }
`;
export const EMPTYPESDELETEQUERY = gql `
    mutation deleteEmptype($id: ID!, $is_active: String!) {
        deleteEmptype(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const UNITESADDQUERY = gql `
    mutation addUnit($unit: String!, $description: String, $business_id: ID, $outlet_id: ID) {
        createUnit(
            unit: $unit
            description: $description
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const UNITESUPDATEQUERY = gql `
    mutation updateUnit($id: ID!, $unit: String!, $description: String) {
        updateUnit(id: $id, unit: $unit, description: $description, is_active: "1") {
            successful
            message
        }
    }
`;
export const UNITESDELETEQUERY = gql `
    mutation deleteUnit($id: ID!, $is_active: String!) {
        deleteUnit(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const UOMSADDQUERY = gql `
    mutation addUom($name: String!, $description: String, $business_id: ID, $outlet_id: ID) {
        createUom(
            name: $name
            description: $description
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const UOMSUPDATEQUERY = gql `
    mutation updateUom($id: ID!, $name: String!, $description: String) {
        updateUom(id: $id, name: $name, description: $description, is_active: "1") {
            successful
            message
        }
    }
`;
export const UOMSDELETEQUERY = gql `
    mutation deleteUom($id: ID!, $is_active: String!) {
        deleteUom(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const ADDONSADDQUERY = gql `
    mutation addAddon($name: String!, $description: String, $business_id: ID, $outlet_id: ID) {
        createAddon(
            name: $name
            description: $description
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const ADDONSUPDATEQUERY = gql `
    mutation updateAddon($id: ID!, $name: String!, $description: String) {
        updateAddon(id: $id, name: $name, description: $description) {
            successful
            message
        }
    }
`;
export const ADDONSDELETEQUERY = gql `
    mutation deleteAddon($id: ID!, $is_active: String!) {
        deleteAddon(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const VARIATIONSADDQUERY = gql `
    mutation addVariation(
        $v_name: String!
        $description: String
        $business_id: ID
        $outlet_id: ID
    ) {
        createVariation(
            v_name: $v_name
            description: $description
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const VARIATIONSUPDATEQUERY = gql `
    mutation updateVariation($id: ID!, $v_name: String!, $description: String) {
        updateVariation(id: $id, v_name: $v_name, description: $description, is_active: "1") {
            successful
            message
        }
    }
`;
export const VARIATIONSDELETEQUERY = gql `
    mutation deleteVariation($id: ID!, $is_active: String!) {
        deleteVariation(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const CREATEMISCELLANEOUSADDQUERY = gql `
    mutation createMiscellaneous(
        $name: String!
        $percentage: String!
        $outlet_id: ID
        $business_id: ID
    ) {
        createMiscellaneous(
            name: $name
            percentage: $percentage
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const TAXESADDQUERY = gql `
    mutation addTax(
        $tax_name: String!
        $tax_pct: String!
        $tax_label: String!
        $outlet_id: ID
        $business_id: ID
    ) {
        createTax(
            tax_name: $tax_name
            tax_pct: $tax_pct
            tax_label: $tax_label
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const CREATEMISCELLANEOUSUPDATEQUERY = gql `
    mutation updateMiscellaneous($id: ID!, $name: String!, $percentage: String!) {
        updateMiscellaneous(id: $id, name: $name, percentage: $percentage, is_active: "1") {
            successful
            message
        }
    }
`;
export const TAXESUPDATEQUERY = gql `
    mutation updateTax($id: ID!, $tax_name: String!, $tax_pct: String!, $tax_label: String!) {
        updateTax(
            id: $id
            tax_name: $tax_name
            tax_pct: $tax_pct
            tax_label: $tax_label
            is_active: "1"
        ) {
            successful
            message
        }
    }
`;
export const DELETEMISCELLANEOUSDELETEQUERY = gql `
    mutation deleteMiscellaneous($id: ID!, $is_active: String!) {
        deleteMiscellaneous(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const TAXESDELETEQUERY = gql `
    mutation deleteTax($id: ID!, $is_active: String!) {
        deleteTax(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const USERSPASSWORDUPDATEQUERY = gql `
    mutation ($id: ID!, $email: String!, $password: String, $pin: String) {
        updateUsersPassword(id: $id, email: $email, password: $password, pin: $pin) {
            successful
            message
        }
    }
`;
export const USERSADDQUERY = gql `
    mutation addUser(
        $first_name: String!
        $last_name: String!
        $email: String!
        $role_id: ID!
        $password: String
        $mobile: String
        $outlet_id: ID!
        $business_id: ID!
    ) {
        createUsers(
            email: $email
            first_name: $first_name
            last_name: $last_name
            password: $password
            role_id: $role_id
            mobile: $mobile
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const USERUPDATEQUERY = gql `
    mutation updateUser(
        $id: ID!
        $first_name: String!
        $last_name: String!
        $email: String!
        $address: String
        $profile_img: String
        $role_id: ID!
        $mobile: String
        $outlet_id: ID!
        $business_id: ID!
    ) {
        updateUsers(
            id: $id
            email: $email
            first_name: $first_name
            last_name: $last_name
            profile_img: $profile_img
            address: $address
            role_id: $role_id
            mobile: $mobile
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
        }
    }
`;
export const USERDELETEQUERY = gql `
    mutation deleteUser($id: ID!, $is_active: String!) {
        deleteUsers(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const CUSTOMERSADDQUERY = gql `
    mutation addCustomer(
        $first_name: String!
        $last_name: String!
        $email: String!
        $mobile: String
        $address1: String
        $address2: String
        $anniversaary: String
        $area: String
        $dob: String
        $tax_no: String
        $zipcode: String
        $is_favorite: String
        $do_not_disturb: String
        $profile_img: String
        $outlet_id: ID!
        $business_id: ID!
    ) {
        createCustomers(
            first_name: $first_name
            last_name: $last_name
            email: $email
            mobile: $mobile
            address1: $address1
            address2: $address2
            anniversaary: $anniversaary
            area: $area
            tax_no: $tax_no
            zipcode: $zipcode
            dob: $dob
            is_favorite: $is_favorite
            do_not_disturb: $do_not_disturb
            profile_img: $profile_img
            outlet_id: $outlet_id
            business_id: $business_id
        ) {
            successful
            message
        }
    }
`;
export const CUSTOMERSUPDATEQUERY = gql `
    mutation updateCustomer(
        $id: ID!
        $first_name: String!
        $last_name: String!
        $email: String!
        $mobile: String!
        $address1: String
        $address2: String
        $anniversaary: String
        $dob: String
        $area: String
        $is_favorite: String
        $do_not_disturb: String
        $profile_img: String
        $tax_no: String
        $zipcode: String
    ) {
        updateCustomers(
            id: $id
            first_name: $first_name
            last_name: $last_name
            email: $email
            mobile: $mobile
            address1: $address1
            address2: $address2
            anniversaary: $anniversaary
            dob: $dob
            area: $area
            is_favorite: $is_favorite
            do_not_disturb: $do_not_disturb
            profile_img: $profile_img
            tax_no: $tax_no
            zipcode: $zipcode
        ) {
            successful
            message
        }
    }
`;
export const CUSTOMERSDELETEQUERY = gql `
    mutation deleteCustomer($id: ID!, $is_active: String!) {
        deleteCustomers(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const MENUMAPPINGADDQUERY = gql `
    mutation addMenumapping(
        $is_enabled: String
        $role_id: ID!
        $menu_id: ID!
        $business_id: ID
        $outlet_id: ID
    ) {
        createMenumapping(
            is_enabled: $is_enabled
            role_id: $role_id
            menu_id: $menu_id
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const TICKETSADDQUERY = gql `
    mutation addTickets(
        $title: String
        $description: String
        $screen_name: String
        $assigned_to: ID
        $created_by: ID
        $status: String
        $comments: String
        $priority: String
        $serverity: String
        $profile_img: String
        $file_name: String
        $business_id: ID
        $outlet_id: ID
    ) {
        createTickets(
            title: $title
            description: $description
            file_name: $file_name
            screen_name: $screen_name
            assigned_to: $assigned_to
            created_by: $created_by
            comments: $comments
            status: $status
            priority: $priority
            serverity: $serverity
            profile_img: $profile_img
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const MENUMAPPINGUPDATEQUERY = gql `
    mutation updateMenumapping($is_enabled: String, $role_id: ID!, $menu_id: ID!, $path: String) {
        updateMenumapping(
            is_enabled: $is_enabled
            role_id: $role_id
            menu_id: $menu_id
            path: $path
        ) {
            successful
            message
        }
    }
`;
export const MENUMAPPINGDELETEQUERY = gql `
    mutation deleteMenumapping($id: ID!, $is_active: String!) {
        deleteMenumapping(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const GROUPCATEDELETEQUERY = gql `
    mutation deleteGroupcate($id: ID!, $is_active: String!) {
        deletegroupcategory(id: $id, is_active: $is_active) {
            successful
            message
        }
    }
`;
/* export const GROUPCATEGORYADDQUERY = gql`
  mutation addGroupingcat($group_cat_name: String, $category: String) {
    createGroupCategory(group_cat_name: $group_cat_name, category: $category) {
      successful
      message
      insertId
    }
  }
`; */
export const CREATETABLECONFIGURATION = gql `
    mutation (
        $table_name: String
        $waiter_name: String
        $seating_capacity: ID
        $area: ID
        $business_id: ID
        $outlet_id: ID
    ) {
        createTableConfiguration(
            table_name: $table_name
            waiter_name: $waiter_name
            seating_capacity: $seating_capacity
            area: $area
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
        }
    }
`;
export const GROUPCATEGORYADDQUERY = gql `
    mutation addGroupingcat(
        $group_cat_name: String
        $group_cat_img: String
        $category: String
        $business_id: ID
        $outlet_id: ID
    ) {
        createGroupCategory(
            group_cat_name: $group_cat_name
            group_cat_img: $group_cat_img
            category: $category
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const UPDATETABLECONFIGURATION = gql `
    mutation updateTableConfiguration(
        $table_name: String
        $waiter_name: String
        $seating_capacity: ID
        $id: ID
    ) {
        updateTableConfiguration(
            table_name: $table_name
            waiter_name: $waiter_name
            seating_capacity: $seating_capacity
            id: $id
        ) {
            successful
            message
        }
    }
`;
export const GROUPCATEGORYUPDATEQUERY = gql `
    mutation updateGroupCategory(
        $id: ID
        $group_cat_name: String
        $group_cat_img: String
        $category: String
        $is_active: String
    ) {
        updateGroupCategory(
            id: $id
            group_cat_name: $group_cat_name
            group_cat_img: $group_cat_img
            category: $category
            is_active: $is_active
        ) {
            successful
            message
        }
    }
`;
export const DELETETABLECONFIGURATION = gql `
    mutation deleteTableConfiguration($id: ID!, $is_active: String!) {
        deleteTableConfiguration(id: $id, is_active: $is_active) {
            message
            status
        }
    }
`;
export const COMBODEALSADDQUERY = gql `
    mutation addmealcombo(
        $combo_name: String
        $combo_img: String
        $products: String
        $price: Float
        $business_id: ID
        $outlet_id: ID
    ) {
        createmealcombo(
            combo_name: $combo_name
            combo_img: $combo_img
            products: $products
            price: $price
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
export const COMBODELETEQUERY = gql `
    mutation deleteMealcombo($id: ID!, $is_active: String!) {
        deletemealcombo(id: $id, is_active: $is_active) {
            successful
            message
        }
    }
`;
export const COMBODEALUPDATEQUERY = gql `
    mutation updateMealcombo(
        $id: ID
        $combo_name: String
        $combo_img: String
        $products: String
        $price: Float
        $business_id: ID
        $outlet_id: ID
    ) {
        updatemealcombo(
            id: $id
            combo_name: $combo_name
            combo_img: $combo_img
            products: $products
            price: $price
            business_id: $business_id
            outlet_id: $outlet_id
        ) {
            successful
            message
            insertId
        }
    }
`;
