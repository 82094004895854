var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/ban-types */
/** @format */
import { useState, useEffect } from 'react';
import { EyeIcon } from '@heroicons/react/solid';
import { Parent, Icon, Link, Text } from '../../../Component';
import { Table, Thead, Tbody, Tr, Td } from '../../../Component/Table';
import TableHeader from '../../TableHeaders/TableHeader';
import apiCall from '../../../../API/apiCall';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../Utilities/Helpers';
import { APILINK } from '../../../Utilities/ApiLink';
import EditOrder from '../../../Component/SlideOverlay/EditOrder';
import OnlineOrderView from '../../../Component/SlideOverlay/OnlineOrderView';
import moment from 'moment';
import ServerSidePagination from '../../Pagination/ServerSidePagination';
const OnlineOrdersTable = ({ resultData, total, getOrder, getItem, setCurrentPageNumber, currentPageNumber, offset, setOffset, items, pageLimit, dataLimit, getPaginatedData, setDataLimit, startIndex, endIndex, currentPage, setCurrentPage, strt, setStrt, end, setEnd }) => {
    const { t } = useTranslation();
    const [editOne, setEditOne] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [showInfoEdit, setShowInfoEdit] = useState(false);
    const [editItem, setEditItem] = useState([]);
    const [tableData, setTableData] = useState(resultData);
    const [filterOrder, setFilterOrder] = useState();
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const orderColumns = [
        {
            id: 1,
            title: t('order_no'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('order_type'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('customer'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('items'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 6,
            title: t('date'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 7,
            title: t('status'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 8,
            title: t('actions'),
            className: ' py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const showViewInfo = (value, id) => {
        getSingleOrder(value, id);
    };
    const getSingleOrder = (value, id) => __awaiter(void 0, void 0, void 0, function* () {
        yield apiCall
            .GetServerCall(`${APILINK.order_get}${id}`, 'GET', token, {})
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            yield setFilterOrder((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data);
            setShowInfo(value);
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    useEffect(() => {
        setTableData(resultData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, resultData]);
    useEffect(() => {
        const value = localStorage.getItem('enableOrderEdit');
        setEditOne(value);
    }, []);
    return (_jsxs(Parent, Object.assign({ className: "flex flex-col" }, { children: [_jsx(Parent, Object.assign({ className: "overflow-x-auto" }, { children: _jsx(Parent, Object.assign({ className: "align-middle inline-block min-w-full" }, { children: _jsx(Parent, Object.assign({ className: "overflow-hidden border-b border-gray-200 " }, { children: _jsxs(Table, Object.assign({ className: "min-w-full divide-y divide-gray-200" }, { children: [_jsx(Thead, Object.assign({ className: "mb-5" }, { children: _jsx(TableHeader, { scope: "col", className: "px-6 py-6 text-left text-xs font-poppins font-medium text-gray-400", columns: orderColumns }, void 0) }), void 0), _jsx(Tbody, Object.assign({ className: "bg-white divide-y divide-gray-200" }, { children: tableData.map((person) => person.order_from == 1 ? (_jsxs(Tr, { children: [_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "flex items-center" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.order_no }), void 0) }), void 0) }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "text-sm font-poppins font-normal text-gray-800" }, { children: person.order_from == 1
                                                        ? t('online_order')
                                                        : '' }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsxs(Parent, Object.assign({ className: "flex flex-col " }, { children: [_jsx(Text, Object.assign({ as: "p", className: "text-sm text-left font-poppins font-normal text-gray-800" }, { children: `${person.first_name == null
                                                                ? t('guest')
                                                                : person.first_name} ${person.last_name == null
                                                                ? ''
                                                                : person.last_name}` }), void 0), _jsx(Parent, Object.assign({ className: "text-sm font-poppins mt-1 font-normal text-gray-400" }, { children: `${person.mobile == null
                                                                ? ''
                                                                : person.mobile}` }), void 0)] }), void 0) }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsxs(Parent, Object.assign({ className: "text-sm font-poppins text-center font-normal text-gray-800" }, { children: [person.order_items, " items"] }), void 0) }), void 0), businessType == 'Retail' ? ('') : (_jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: _jsx(Parent, Object.assign({ className: "" }, { children: _jsx(Text, { as: "p", className: "text-sm font-poppins text-left font-normal text-gray-800", label: person.date
                                                            ? moment(person.date).format('DD-MM-YYYY')
                                                            : '-' }, void 0) }), void 0) }), void 0)), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap w-1/7" }, { children: person.order_status == 1 ? (_jsx(Parent, Object.assign({ className: " bg-blue-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-blue-500 text-center" }, { children: t('orderplaced') }), void 0) }), void 0)) : person.order_status == 3 ? (_jsx(Parent, Object.assign({ className: " bg-pink-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-pink-500 text-center" }, { children: t('ready') }), void 0) }), void 0)) : person.order_status == 4 ? (_jsx(Parent, Object.assign({ className: " bg-green-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-green-500 text-center" }, { children: t('delivered') }), void 0) }), void 0)) : person.order_status == 2 ? (_jsx(Parent, Object.assign({ className: " bg-yellow-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-yellow-500 text-center" }, { children: t('inprogress') }), void 0) }), void 0)) : person.order_status == 5 ? (_jsx(Parent, Object.assign({ className: " bg-red-100 rounded-2xl" }, { children: _jsx(Text, Object.assign({ as: "p", className: "text-sm font-poppins font-normal py-1 px-2 text-red-500 text-center" }, { children: t('cancelled') }), void 0) }), void 0)) : ('') }), void 0), _jsx(Td, Object.assign({ className: "px-6 py-2.5 whitespace-nowrap text-center text-sm font-medium w-1/7" }, { children: _jsx(Link, Object.assign({ className: classNames(businessType == 'Retail'
                                                        ? 'hover:bg-blue-600'
                                                        : 'hover:bg-blue-600', 'group  inline-flex items-center px-3 py-3 cursor-pointer  shadow-custom  rounded-md text-gray-400 bg-white hover:text-white focus:outline-none'), title: t('view'), onClick: () => showViewInfo(true, person.id) }, { children: _jsx(Icon, { tag: EyeIcon, className: "h-3.5 w-3.5 group-hover:text-white text-gray-400" }, void 0) }), void 0) }), void 0)] }, person.id)) : ('')) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0), _jsx(ServerSidePagination, { totalData: resultData, items: items, pageLimit: pageLimit, dataLimit: dataLimit, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, total: total, from: 'item', offset: offset, setOffset: setOffset, getItem: getItem, currentPage: currentPage, setCurrentPage: setCurrentPage, strt: strt, setStrt: setStrt, end: end, setEnd: setEnd }, void 0), _jsx(OnlineOrderView, { open: showInfo, setOpen: setShowInfo, filterOrder: filterOrder, title: t('online_order_details') }, void 0), _jsx(EditOrder, { open: showInfoEdit, setOpen: setShowInfoEdit, filterOrder: filterOrder, title: t('edit_online_order'), editItem: editItem, getOrder: getOrder }, void 0)] }), void 0));
};
export default OnlineOrdersTable;
