import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Button, Icon, Parent } from '../../Component/index';
import DatePicker from 'react-datepicker';
import ContainerSection from '../ContainerSection/ContainerSection';
import { ArrowLeftIcon, CalendarIcon, UserGroupIcon } from '@heroicons/react/solid';
import PosSessionTable from './PosSessionTable';
import DataNotFound from '../DataNotFound/DataNotFound';
import { STARTPAGE } from '../../Utilities/Constants';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { useQuery } from '@apollo/client';
import Moment from 'moment';
import { GETALLSESSIONQUERY } from '../../GraphqlQuery/Query/Query';
import { classNames } from '../../Utilities/Helpers';
import { useHistory } from 'react-router-dom';
const PosSession = ({}) => {
    const { t } = useTranslation();
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const { loading, error, data, refetch } = useQuery(GETALLSESSIONQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const [currencySymbol, setCurrencySymbol] = useState(localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£');
    const history = useHistory();
    const Tablecolumns = [
        {
            id: 1,
            title: t('terminal_name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('email'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('opening'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('closing'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: t('status'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 6,
            title: t('orders'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 7,
            title: `${t('amount')}(${currencySymbol})`,
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 8,
            title: t('actions'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const [valid_from, setValid_from] = useState(new Date());
    const [apiResultArray, setApiResultArray] = useState([]);
    const [filterDataList, setFilterDataList] = useState([]);
    const [singleObj, setSingleObj] = useState({});
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const sliderOpen = (value, title) => {
        setOpen(value);
        setSliderTitle(title);
    };
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((obj) => obj.id === id);
        setSingleObj(Obj);
        setOpen(value);
        setSliderTitle(title);
    };
    const filterData = (e, array) => {
        let filterDa;
        filterDa = array.filter((fil) => {
            if (Moment(fil.logged_in, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY') ==
                Moment(e).format('DD-MM-YYYY'))
                return fil;
        });
        setFilterDataList(filterDa);
    };
    useEffect(() => {
        if (data) {
            setApiResultArray(data.getAllSession.data.session);
            filterData(new Date(), data.getAllSession.data.session);
        }
    }, [data]);
    return (_jsx(Parent, Object.assign({ className: "px-4 lg:px-12 pb-9" }, { children: _jsxs(ContainerSection, Object.assign({ className: classNames(filterDataList && filterDataList.length > 0 /* "overflow-hidden" : "", */
                ? 'bg-white  rounded-lg shadow-custom px-5 py-5 mt-3'
                : 'bg-white  rounded-lg shadow-custom px-5 py-5 mt-3') }, { children: [_jsx(Button, Object.assign({ text: t('back'), className: "group inline-flex items-center mr-4 mb-4 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md text-blue-600 bg-blue-100 hover:text-white hover:bg-blue-600 focus:outline-none", onClick: () => history.push('/outlet-settings') }, { children: _jsx(Icon, { tag: ArrowLeftIcon, className: "w-4 h-4 text-blue-600 group-hover:text-white rounded-md mr-2" }, void 0) }), void 0), _jsx(Parent, Object.assign({ className: "md:flex md:items-center md:justify-between" }, { children: _jsxs(Parent, Object.assign({ className: "mt-1 flex relative w-52" }, { children: [_jsx(DatePicker, { closeOnScroll: (e) => e.target === document, selected: valid_from, onChange: (date) => {
                                    filterData(date, apiResultArray);
                                    setValid_from(date);
                                }, maxDate: new Date(), dateFormat: "dd/MM/yyyy", className: "shadow-sm focus:ring-blue-600 z-auto focus:border-blue-600 block w-full sm:text-xs border-gray-200 text-gray-400 rounded-md font-poppins text-sm font-normal py-3" }, void 0), _jsx(Parent, Object.assign({ className: 'right-4 absolute top-1/4 ' }, { children: _jsx(Icon, { tag: CalendarIcon, className: "w-5 h-5 text-gray-400 text-base items-center font-medium font-poppins " }, void 0) }), void 0)] }), void 0) }), void 0), filterDataList && filterDataList.length > 0 ? (_jsx(PosSessionTable, { onClick: sliderOpen, resultData: filterDataList, getSingleObj: getSingleObj, columns: Tablecolumns, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, total: filterDataList && filterDataList.length > 0 ? filterDataList.length : 0 }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: "POS Session are not available.", iconClassName: "h-16 w-16 text-blue-600 m-auto mb-2", iconTag: UserGroupIcon }, void 0))] }), void 0) }), void 0));
};
export default PosSession;
