var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
 * /* Copyright (C) efficientworks.co.uk - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Contact <ravi.raya@gmail.com>, Auto Generated Code October 2021
 *
 * @format
 */
import { useState, useEffect } from 'react';
import { Button, Icon, Input, Link, Parent, Text, Checkbox } from '../../Component/index';
import Moment from 'moment';
import ContainerSection from '../ContainerSection/ContainerSection';
import { SearchIcon, XIcon, ClockIcon, ExclamationCircleIcon } from '@heroicons/react/solid';
import { useMutation, useQuery } from '@apollo/client';
import { CATEGORIESGETQUERY, GETSINGLEMENUSCHEDULES } from '../../GraphqlQuery/Query/Query';
import DataNotFound from '../DataNotFound/DataNotFound';
import { MENUSCHEDULEADDQUERY, MENUSCHEDULEUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import apiCall from '../../../API/apiCall';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import PageHeader from '../../Layouts/Core/PageHeader/PageHeader';
import Layout from '../../Layouts/Layout';
import DatePicker from 'react-datepicker';
import Listed from './Listed';
import Selected from './Selected';
import { useHistory } from 'react-router-dom';
import { APILINK } from '../../Utilities/ApiLink';
import ConfirmationAlert from '../../Component/ConfirmationAlert/ConfirmationAlert';
const AddNew = ({}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const menu_schedule_id = localStorage.getItem('menu_schedule_id');
    const [strt_tym, setStrt_tym] = useState(new Date());
    const [cls_tym, setCls_tym] = useState(new Date());
    const [tym_array, setTym_array] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [filterDataList, setFilterDataList] = useState([]);
    const [filterItem_list, setFilterItem_list] = useState([]);
    const [scheduleName, setScheduleName] = useState('Category');
    const [typeName, setTypeName] = useState('All');
    const [list_X, setList_X] = useState({
        cat: [],
        item: []
    });
    const [product_list, setProduct_list] = useState([]);
    const [fil_inp, setFil_inp] = useState('');
    const [category_list, setCategory_list] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [outletId, setOutletId] = useState(localStorage.getItem('outletId'));
    const [businessId, setBusinessId] = useState(localStorage.getItem('businessId'));
    const [schedule_Name, setSchedule_Name] = useState('');
    const [height, setHeight] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [id_data, setId_data] = useState([]);
    const [id_data_Item, setId_data_Item] = useState([]);
    const { loading, error, data, refetch } = useQuery(CATEGORIESGETQUERY, {
        variables: { business_id: businessId, outlet_id: outletId }
    });
    const { data: data2, loading: loading2, error: error2, refetch: refetch2 } = useQuery(GETSINGLEMENUSCHEDULES, {
        variables: {
            id: menu_schedule_id,
            business_id: businessId,
            outlet_id: outletId
        }
    });
    const [addMenuQuery, { data: data1, loading: loading1, error: error1 }] = useMutation(MENUSCHEDULEADDQUERY);
    const [updateMenuQuery, { data: data3, loading: loading3, error: error3 }] = useMutation(MENUSCHEDULEUPDATEQUERY);
    const breadCrumbs = [
        // {
        //     title: t('dashboard'),
        //     href: '#/dashboard',
        //     current: false,
        //     show: 'block'
        // },
        {
            title: t('products'),
            href: '#/items',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: t('menu_schedule'),
            href: '#/menu-schedule',
            current: false,
            show: 'hidden sm:block'
        },
        {
            title: menu_schedule_id ? t('edit_menu_schedule') : t('add_menu_schedule'),
            href: '#/add-Schedule',
            current: true,
            show: 'block'
        }
    ];
    const Tar_Audience = [
        { id: '1', title: t('category') },
        { id: '2', title: t('item') }
    ];
    const Tar_Audience1 = [
        { id: '1', title: t('all') },
        { id: '2', title: t('veg') },
        { id: '3', title: t('non_veg') }
    ];
    const [s_name, sets_name] = useState(false);
    const [cat, setcat] = useState(false);
    const [timeValidErr, setTimeValidErr] = useState(false);
    const [day, setday] = useState(false);
    const [time, settime] = useState(false);
    const [days, setDays] = useState([
        {
            id: 2,
            week_days: 'Mon'
        },
        {
            id: 3,
            week_days: 'Tue'
        },
        {
            id: 4,
            week_days: 'Wed'
        },
        {
            id: 5,
            week_days: 'Thu'
        },
        {
            id: 6,
            week_days: 'Fri'
        },
        {
            id: 7,
            week_days: 'Sat'
        },
        {
            id: 8,
            week_days: 'Sun'
        }
    ]);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteID, setDeleteID] = useState();
    // Item API call----------
    const getItem = () => __awaiter(void 0, void 0, void 0, function* () {
        var param = { business_id: businessId, outlet_id: outletId };
        yield apiCall
            .GetServerCall(APILINK.product_get, 'GET', token, param)
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            let dataCat = response.data.data.map((item) => {
                return Object.assign(Object.assign({}, item), { checked: false });
            });
            setProduct_list(dataCat);
            setFilterItem_list(dataCat);
            if (menu_schedule_id && data2) {
                yield refetch2();
            }
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    //  individual Days check----------
    const checkIndi = (e, data) => {
        let dataCat = [];
        let count = 0;
        const oldData = [...filterData];
        if (e.target.checked) {
            let date = {
                id: e.target.id,
                week_days: e.target.name
            };
            oldData.push(date);
            setFilterData(oldData);
            dataCat = days.map((item) => {
                if (date.id == item.id)
                    return Object.assign(Object.assign({}, item), { checked: true });
                else
                    return item;
            });
            setDays(dataCat);
            dayError(dataCat);
            setCheckAll(false);
        }
        else {
            const filDate = oldData.filter((data) => {
                return data.id != e.target.id;
            });
            dataCat = days.map((item) => {
                if (e.target.id == item.id)
                    return Object.assign(Object.assign({}, item), { checked: false });
                else
                    return item;
            });
            setDays(dataCat);
            dayError(dataCat);
            setCheckAll(false);
            setFilterData(filDate);
        }
        dataCat.map((item) => {
            if (item.checked == true) {
                count += 1;
            }
            else {
                count -= 1;
            }
        });
        count == dataCat.length ? setCheckAll(true) : setCheckAll(false);
    };
    //  all days check---------
    const selectAll = (data) => {
        const oldData = [];
        if (data.checked) {
            setCheckAll(true);
            const dataCat = days.map((item) => {
                let date = {
                    id: item.id,
                    week_days: item.week_days
                };
                oldData.push(date);
                setFilterData(oldData);
                return Object.assign(Object.assign({}, item), { checked: true });
            });
            setDays(dataCat);
            dayError(dataCat);
        }
        else {
            setCheckAll(false);
            const dataCat = days.map((item) => {
                setFilterData([]);
                return Object.assign(Object.assign({}, item), { checked: false });
            });
            setDays(dataCat);
            dayError(dataCat);
        }
    };
    //create schedule timing-----------
    const addTime = (start_time, close_time) => {
        if (start_time && close_time) {
            let oldCata = [...tym_array];
            if (tym_array.length == 0) {
                oldCata.push({
                    id: oldCata.length + 1,
                    strt: Moment(start_time).format('hh:mm A'),
                    cls: Moment(close_time).format('hh:mm A')
                });
            }
            else {
                let find = false;
                oldCata.map((item) => {
                    if (item.strt == Moment(start_time).format('hh:mm A') &&
                        item.cls == Moment(close_time).format('hh:mm A')) {
                        find = true;
                        setTimeValidErr(true);
                    }
                });
                if (!find) {
                    setTimeValidErr(false);
                    oldCata.push({
                        id: oldCata.length + 1,
                        strt: Moment(start_time).format('hh:mm A'),
                        cls: Moment(close_time).format('hh:mm A')
                    });
                }
            }
            setTym_array(oldCata);
            setStrt_tym(new Date());
            setCls_tym(new Date());
            timeError(oldCata);
        }
    };
    // select type cat/item----------
    const radio_select = (data) => {
        setScheduleName(data.id);
        changeheight();
    };
    // select type for veg/nv
    const radio_select2 = (data) => {
        setTypeName(data.id);
        changeFilter(fil_inp, data.id);
    };
    const cancel = () => {
        window.location.href = '#/menu-schedule';
    };
    const reset = () => {
        sets_name(false);
        setday(false);
        setcat(false);
        settime(false);
        setScheduleName('Category');
        setTimeValidErr(false);
        setTypeName('All');
        setList_X({ cat: [], item: [] });
        setCheckAll(false);
        setTym_array([]);
        setSchedule_Name('');
        setFil_inp('');
        let filOrd = filterDataList.filter((cat) => {
            return cat.cat_name.toLowerCase();
        });
        setCategory_list(filOrd);
        let dataCatt = data.getAllCategory.map((item) => {
            return Object.assign(Object.assign({}, item), { checked: false });
        });
        setCategory_list(dataCatt);
        let b = filterItem_list.map((item) => {
            return Object.assign(Object.assign({}, item), { checked: false });
        });
        setProduct_list(b);
        let dataCat = days.map((item) => {
            return Object.assign(Object.assign({}, item), { checked: false });
        });
        setDays(dataCat);
    };
    // timing card delete call ---------
    const showConfirmation = (value, id) => {
        setShowAlert(value);
        setDeleteID(id);
    };
    const tym_del = () => {
        let x = tym_array.filter((item) => item.id != deleteID);
        setTym_array(x);
        timeError(x);
        setShowAlert(false);
    };
    // category/item select and unselect
    const catSelection = (data, img, img2) => {
        changeheight();
        if (data.checked) {
            let oldCata = scheduleName === 'Category' ? [...list_X.cat] : [...list_X.item];
            oldCata.push({
                id: data.id,
                name: img.cat_name ? img.cat_name : img2.prod_name,
                img: img.cat_image ? img.cat_image : img2.product_img,
                checked: true
            });
            let a;
            let b;
            if (scheduleName === 'Category') {
                a = category_list.map((item) => {
                    if (item.id == img.id) {
                        return Object.assign(Object.assign({}, item), { checked: true });
                    }
                    else {
                        return item;
                    }
                });
            }
            else {
                a = product_list.map((item) => {
                    if (item.id == img2.id) {
                        return Object.assign(Object.assign({}, item), { checked: true });
                    }
                    else {
                        return item;
                    }
                });
                b = filterItem_list.map((item) => {
                    if (item.id == img2.id) {
                        return Object.assign(Object.assign({}, item), { checked: true });
                    }
                    else {
                        return item;
                    }
                });
            }
            scheduleName === 'Category'
                ? setList_X(Object.assign(Object.assign({}, list_X), { cat: oldCata }))
                : setList_X(Object.assign(Object.assign({}, list_X), { item: oldCata }));
            scheduleName === 'Category'
                ? catItemError(oldCata, list_X.item)
                : catItemError(list_X.cat, oldCata);
            if (scheduleName === 'Category') {
                setCategory_list(a);
            }
            else {
                setProduct_list(a);
                setFilterItem_list(b);
            }
            let z = scheduleName === 'Category' ? [...id_data] : [...id_data_Item];
            z.push(data.id);
            if (scheduleName === 'Category') {
                setId_data(z);
            }
            else {
                setId_data_Item(z);
            }
        }
        else {
            let z = scheduleName === 'Category' ? [...id_data] : [...id_data_Item];
            z = z.filter((list) => list != data.id);
            if (scheduleName === 'Category') {
                setId_data(z);
            }
            else {
                setId_data_Item(z);
            }
            let a;
            let b;
            if (scheduleName === 'Category') {
                a = category_list.map((item) => {
                    if (item.id == img.id) {
                        return Object.assign(Object.assign({}, item), { checked: false });
                    }
                    else {
                        return item;
                    }
                });
            }
            else {
                a = product_list.map((item) => {
                    if (item.id == img2.id) {
                        return Object.assign(Object.assign({}, item), { checked: false });
                    }
                    else {
                        return item;
                    }
                });
                b = filterItem_list.map((item) => {
                    if (item.id == img2.id) {
                        return Object.assign(Object.assign({}, item), { checked: false });
                    }
                    else {
                        return item;
                    }
                });
            }
            let x = [];
            scheduleName === 'Category'
                ? (x = list_X.cat.filter((item) => item.id != img.id))
                : (x = list_X.item.filter((item) => item.id != img2.id));
            scheduleName === 'Category'
                ? setList_X(Object.assign(Object.assign({}, list_X), { cat: x }))
                : setList_X(Object.assign(Object.assign({}, list_X), { item: x }));
            scheduleName === 'Category'
                ? catItemError(x, list_X.item)
                : catItemError(list_X.cat, x);
            if (scheduleName === 'Category') {
                setCategory_list(a);
            }
            else {
                setProduct_list(a);
                setFilterItem_list(b);
            }
        }
    };
    // filter call-----------
    const changeFilter = (data, type) => {
        setFil_inp(data);
        const filterText = data;
        if (scheduleName === 'Category') {
            let filOrd = filterDataList.filter((cat) => {
                return cat.cat_name.toLowerCase().includes(filterText.toLowerCase());
            });
            setCategory_list(filOrd);
        }
        else {
            let filOrd = filterItem_list.filter((cat) => {
                if (data && type != 'All') {
                    if (cat.type == 'Veg' &&
                        cat.prod_name.toLowerCase().includes(filterText.toLowerCase())) {
                        return cat;
                    }
                    else if (cat.type == 'Non-Veg' &&
                        cat.prod_name.toLowerCase().includes(filterText.toLowerCase())) {
                        return cat;
                    }
                }
                else if (type == 'Veg') {
                    if (cat.type == 'Veg') {
                        return cat;
                    }
                }
                else if (type == 'Non-veg') {
                    if (cat.type == 'Non-Veg') {
                        return cat;
                    }
                }
                else {
                    return cat.prod_name.toLowerCase().includes(filterText.toLowerCase());
                }
            });
            setProduct_list(filOrd);
        }
    };
    // add & update API call -----------
    const saveData = () => __awaiter(void 0, void 0, void 0, function* () {
        yield setBtnLoading(true);
        let parems = {
            id: menu_schedule_id,
            name: schedule_Name,
            type: scheduleName,
            available_days: JSON.stringify(days),
            timing: JSON.stringify(tym_array),
            outlet_id: outletId,
            business_id: businessId,
            available_data: scheduleName == 'Category' ? id_data.toString() : id_data_Item.toString()
        };
        if (menu_schedule_id) {
            updateMenuQuery({
                variables: parems
            }).then((result) => {
                setBtnLoading(false);
                reset();
                sets_name(false);
                setday(false);
                setcat(false);
                let x = [];
                if (scheduleName == 'Category') {
                    setList_X(Object.assign(Object.assign({}, list_X), { item: x }));
                }
                else {
                    setList_X(Object.assign(Object.assign({}, list_X), { cat: x }));
                }
                settime(false);
                localStorage.setItem('loadData', 'true');
                history.push('/menu-schedule');
            });
        }
        else {
            addMenuQuery({
                variables: parems
            }).then((result) => {
                setBtnLoading(false);
                let x = [];
                if (scheduleName == 'Category') {
                    setList_X(Object.assign(Object.assign({}, list_X), { item: x }));
                }
                else {
                    setList_X(Object.assign(Object.assign({}, list_X), { cat: x }));
                }
                reset();
                localStorage.setItem('loadData', 'true');
                history.push('/menu-schedule');
            });
        }
    });
    // load values from singleget API
    const getSingleData = (val) => {
        if (menu_schedule_id) {
            val === null || val === void 0 ? void 0 : val.map((item) => {
                setScheduleName(item.type);
                setSchedule_Name(item.name);
                let days = JSON.parse(item.available_days).filter((day) => day.checked);
                days.length == 7 && setCheckAll(true);
                setDays(JSON.parse(item.available_days));
                setTym_array(JSON.parse(item.timing));
                item.type == 'Category'
                    ? (setId_data(item.available_data.split(',')), setId_data_Item([]))
                    : (setId_data_Item(item.available_data.split(',')), setId_data([]));
                let itemArray = item.type == 'Category' ? category_list : product_list;
                let x = item.scheduled_menus.map((item1) => {
                    let a = itemArray.map((items) => {
                        if (item1.id == items.id) {
                            return Object.assign(Object.assign({}, items), { checked: true });
                        }
                        else {
                            return items;
                        }
                    });
                    itemArray = a;
                    if (item.type == 'Category') {
                        setFilterDataList(itemArray);
                        setCategory_list(itemArray);
                    }
                    else {
                        setProduct_list(itemArray);
                        setFilterItem_list(itemArray);
                    }
                    return Object.assign(Object.assign({}, item1), { checked: true });
                });
                if (item.type == 'Category') {
                    setList_X(Object.assign(Object.assign({}, list_X), { cat: x, item: [] }));
                }
                else {
                    setList_X(Object.assign(Object.assign({}, list_X), { cat: [], item: x }));
                }
                scheduleName === 'Category'
                    ? catItemError(x, list_X.item)
                    : catItemError(list_X.cat, x);
            });
        }
    };
    const checkScheduleName = (val) => {
        if (val) {
            setSchedule_Name(val);
            sets_name(false);
            return false;
        }
        else {
            setSchedule_Name(val);
            sets_name(true);
            return true;
        }
    };
    const checkErrors = () => {
        let name = checkScheduleName(schedule_Name);
        let cat = catItemError(list_X.cat, list_X.item);
        let day = dayError(days);
        let time = timeError(tym_array);
        if (!name && !day && !cat && !time) {
            saveData();
        }
    };
    const catItemError = (cat, item) => {
        if (scheduleName == 'Category' ? cat.length > 0 : item.length > 0) {
            setcat(false);
            return false;
        }
        else {
            setcat(true);
            return true;
        }
    };
    const dayError = (arr) => {
        let a = 0;
        arr.map((day) => {
            if (day.checked) {
                a += 1;
            }
        });
        if (a == 0) {
            setday(true);
            return true;
        }
        else {
            setday(false);
            return false;
        }
    };
    const timeError = (arr) => {
        if (arr.length > 0) {
            settime(false);
            return false;
        }
        else {
            settime(true);
            setTimeValidErr(false);
            return true;
        }
    };
    // listed height seeting function
    const changeheight = () => __awaiter(void 0, void 0, void 0, function* () {
        if (scheduleName == 'Category') {
            if (list_X.cat.length > 0) {
                setHeight(true);
            }
            else {
                setHeight(false);
            }
        }
        else {
            if (list_X.item.length > 0) {
                setHeight(true);
            }
            else {
                setHeight(false);
            }
        }
    });
    //menuschedule getbyid query call
    useEffect(() => {
        var _a;
        if (menu_schedule_id && data2) {
            getSingleData((_a = data2 === null || data2 === void 0 ? void 0 : data2.getAllMenuSchedules) === null || _a === void 0 ? void 0 : _a.data);
        }
    }, [data2, menu_schedule_id]);
    // category getall query call
    useEffect(() => {
        let dataCat = [];
        if (data) {
            dataCat = data.getAllCategory.map((item) => {
                return Object.assign(Object.assign({}, item), { checked: false });
            });
            setCategory_list(dataCat);
            setFilterDataList(dataCat);
            refetch2();
        }
    }, [data]);
    useEffect(() => {
        getItem();
    }, []);
    // height useEffct
    useEffect(() => {
        changeheight();
    }, [scheduleName, list_X.cat, list_X.item]);
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('menu_schedule'), breadcrumbs: breadCrumbs }, void 0), _jsxs(Parent, Object.assign({ className: "lg:px-12 md:px-6 pb-9 px-4" }, { children: [_jsx(ContainerSection, Object.assign({ className: "bg-white relative schedule_h_content overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-3" }, { children: _jsxs(Parent, Object.assign({ className: "w-full h-full flex flex-col lg:flex-row overflow-y-auto" }, { children: [_jsxs(Parent, Object.assign({ className: "lg:w-2/4 lg:pr-5 lg:border-r lg:border-gray-200 mr-2" }, { children: [_jsxs(Parent, Object.assign({ className: "grid sm:grid-cols-2 grid-cols-1 sm:gap-4" }, { children: [_jsxs(Parent, { children: [_jsx(Input, { name: "Schedule Name", className: classNames(businessType == 'Retail'
                                                                ? 'focus:ring-blue-600 focus:border-blue-600'
                                                                : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm focus:outline-none sm:w-10/12 w-full block px-8 pl-4 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "Schedule Name", type: "text", value: schedule_Name, labelText: t('schedule_name'), labelClass: "block text-sm font-poppins font-medium text-gray-700 pb-1", onChange: (e) => checkScheduleName(e.target.value), onBlur: (e) => checkScheduleName(e.target.value), labelRequired: true }, void 0), s_name && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: t('schedule_name_is_required') }, void 0))] }, void 0), _jsx(Parent, Object.assign({ className: "sm:my-5 flex items-center justify-between" }, { children: Tar_Audience.map((tar) => {
                                                        return (_jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Input, { name: 'tar.title', type: "radio", className: classNames(businessType == 'Retail'
                                                                        ? 'text-blue-600'
                                                                        : 'text-blue-600', 'focus:ring-transparent mt-0 h-4 w-4  border-gray-300'), id: tar.title, onChange: (e) => radio_select(e.target), value: scheduleName, checked: scheduleName == 'Category'
                                                                        ? tar.id == '1'
                                                                        : tar.id == '2' }, void 0), _jsx(Text, { as: "label", className: "ml-3 block text-sm font-poppins pr-10 mt-4 font-medium pb-3 text-gray-700", label: tar.title }, void 0)] }), void 0));
                                                    }) }), void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: "grid sm:grid-cols-2 grid-cols-1 sm:gap-4 " }, { children: [_jsx(Parent, { children: _jsx(Input, { name: "search", className: classNames(businessType == 'Retail'
                                                            ? 'focus:ring-blue-600 focus:border-blue-600'
                                                            : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm focus:outline-none block px-2 sm:w-10/12 w-full pl-10 py-2.5 text-sm border-gray-200 text-gray-400 placeholder:text-gray-300 rounded-md font-poppins font-medium'), id: "search", type: "text", value: fil_inp, labelText: '', labelClass: "block text-sm font-poppins font-medium text-gray-700 pb-1", onChange: (e) => changeFilter(e.target.value, typeName), iconRequired: true, labelRequired: true, iconTag: SearchIcon, placeHolder: `${t('search')} ${scheduleName}` }, void 0) }, void 0), scheduleName === 'Item' && (_jsx(Parent, { children: _jsx(Parent, Object.assign({ className: "my-4 flex justify-between pr-2 items-center" }, { children: Tar_Audience1.map((tar) => {
                                                            return (_jsxs(Parent, Object.assign({ className: "flex items-center" }, { children: [_jsx(Input, { name: "typeName", type: "radio", className: classNames(businessType == 'Retail'
                                                                            ? 'text-blue-600'
                                                                            : 'text-blue-600', 'focus:ring-transparent mt-0 h-4 w-4  border-gray-300'), id: tar.title, onChange: (e) => radio_select2(e.target), defaultChecked: tar.id == '1' }, void 0), _jsx(Text, { as: "label", className: "ml-3 block text-sm font-poppins font-medium text-gray-700", label: tar.title }, void 0)] }), void 0));
                                                        }) }), void 0) }, void 0))] }), void 0), _jsxs(Parent, Object.assign({ className: "" }, { children: [_jsxs(Parent, Object.assign({ className: "py-5" }, { children: [_jsx(Text, Object.assign({ className: "block text-sm  font-medium  font-poppins text-gray-700  ", as: "p" }, { children: `${t('selected')} ${scheduleName}` }), void 0), cat && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: t('selected_category_item_is_required') }, void 0))] }), void 0), _jsx(Selected, { scheduleName: scheduleName, list: list_X.cat, list2: list_X.item, businessType: businessType }, void 0)] }), void 0), category_list.length > 0 || product_list.length > 0 ? (_jsx(Listed, { scheduleName: scheduleName, list: category_list, list2: product_list, businessType: businessType, catSelection: catSelection, is_Array: height }, void 0)) : (_jsx(Parent, Object.assign({ className: " flex justify-center items-center" }, { children: _jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: "Items / Category not available", iconClassName: classNames(businessType == 'Retail'
                                                    ? 'text-blue-600'
                                                    : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: ExclamationCircleIcon }, void 0) }), void 0))] }), void 0), _jsxs(Parent, Object.assign({ className: "lg:w-2/4 lg:pl-5" }, { children: [_jsxs(Parent, Object.assign({ className: "border-b  border-gray-200" }, { children: [_jsxs(Parent, Object.assign({ className: "lg:mt-0 mt-5" }, { children: [_jsx(Text, Object.assign({ className: "block text-sm font-medium  font-poppins text-gray-700  ", as: "p" }, { children: t('select_days') }), void 0), day && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: t('schedule_day_is_required') }, void 0))] }), void 0), _jsxs(Parent, Object.assign({ className: "grid sm:grid-cols-4 grid-cols-2 mt-5" }, { children: [_jsxs(Parent, Object.assign({ className: "flex gap-x-5 mb-5 items-center " }, { children: [_jsx(Checkbox, { type: "checkbox", name: 'day.week_days', id: 'day.id', checked: checkAll, onChange: (e) => selectAll(e.target), className: classNames(businessType == 'Retail'
                                                                        ? 'text-blue-500'
                                                                        : 'text-blue-500', 'focus:ring-transparent h-4 ml-2 w-4 items-center justfy-center border-gray-300 rounded') }, void 0), _jsx(Parent, Object.assign({ className: "flex items-center " }, { children: _jsx(Text, Object.assign({ className: "font-poppins text-sm font-medium text-gray-700 ", as: "h2" }, { children: t('all_days') }), void 0) }), void 0)] }), void 0), days.map((day) => {
                                                            return (_jsxs(Parent, Object.assign({ className: "flex gap-x-5 mb-5 items-center " }, { children: [_jsx(Checkbox, { type: "checkbox", name: day.week_days, id: day.id, checked: day.checked, onChange: (e) => checkIndi(e, day), className: classNames(businessType == 'Retail'
                                                                            ? 'text-blue-500'
                                                                            : 'text-blue-500', 'focus:ring-transparent h-4 ml-2 w-4 items-center justfy-center border-gray-300 rounded') }, void 0), _jsx(Parent, Object.assign({ className: "flex items-center " }, { children: _jsx(Text, Object.assign({ className: "font-poppins text-sm font-medium text-gray-700 ", as: "h2" }, { children: day.week_days }), void 0) }), void 0)] }), void 0));
                                                        })] }), void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: "pt-5 " }, { children: [_jsx(Text, Object.assign({ className: "block text-sm  font-medium  font-poppins text-gray-700  ", as: "p" }, { children: t('schedule_timimg') }), void 0), time && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: t('schedule_time_is_required') }, void 0)), timeValidErr && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: t('slot_has_been_already_exist') }, void 0))] }), void 0), _jsxs(Parent, Object.assign({ className: "  grid sm:grid-cols-5 grid-cols-1 sm:gap-5 mr-2" }, { children: [_jsxs(Parent, Object.assign({ className: "mt-1 flex flex-col col-span-2 relative" }, { children: [_jsx(Text, Object.assign({ as: "label", className: "block pb-4 text-sm font-poppins font-medium text-gray-700 py-3" }, { children: t('start_time') }), void 0), _jsx(DatePicker, { closeOnScroll: (e) => e.target === document, selected: strt_tym, onChange: (date) => setStrt_tym(date), showTimeSelect: true, showTimeSelectOnly: true, timeIntervals: 60, timeCaption: "time", dateFormat: "h:mm aa", className: classNames(businessType == 'Retail'
                                                                ? 'focus:ring-blue-600 focus:border-blue-600'
                                                                : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm block w-full sm:text-xs border-gray-200 text-gray-400 rounded-md font-poppins text-sm font-normal pr-8 py-3') }, void 0), _jsx(Parent, Object.assign({ className: "absolute top-14 right-3" }, { children: _jsx(Icon, { tag: ClockIcon, className: "w-6 h-6 pt-1 text-gray-400 text-base items-center font-medium font-poppins " }, void 0) }), void 0)] }), void 0), _jsxs(Parent, Object.assign({ className: "mt-1 flex flex-col col-span-2 relative" }, { children: [_jsx(Text, Object.assign({ as: "label", className: "block pb-4 text-sm font-poppins font-medium text-gray-700 py-3" }, { children: t('close_time') }), void 0), _jsx(DatePicker, { closeOnScroll: (e) => e.target === document, selected: cls_tym, onChange: (date) => setCls_tym(date), showTimeSelect: true, showTimeSelectOnly: true, timeIntervals: 60, timeCaption: "time", dateFormat: "h:mm aa", className: classNames(businessType == 'Retail'
                                                                ? 'focus:ring-blue-600 focus:border-blue-600'
                                                                : 'focus:ring-blue-600 focus:border-blue-600', 'shadow-sm block w-full sm:text-xs border-gray-200 text-gray-400 rounded-md font-poppins text-sm font-normal py-3 pr-8') }, void 0), _jsx(Parent, Object.assign({ className: "absolute top-14 right-3" }, { children: _jsx(Icon, { tag: ClockIcon, className: "w-6 h-6 pt-1 text-gray-400 text-base items-center font-medium font-poppins " }, void 0) }), void 0)] }), void 0), _jsx(Parent, Object.assign({ className: "mt-1 flex flex-col col-span-1 items-end py-3 sm:py-0 justify-end relative" }, { children: _jsx(Button, { text: t('add'), disabled: tym_array.length == 3, onClick: () => addTime(strt_tym, cls_tym), className: classNames(tym_array.length == 3
                                                            ? 'text-blue-600 cursor-not-allowed opacity-50 bg-blue-100 hover:bg-blue-600'
                                                            : 'text-blue-600 bg-blue-100 hover:bg-blue-600', 'group inline-flex justify-center items-center w-full sm:mt-12 px-4 py-2 border border-transparent shadow-custom text-base font-poppins font-medium rounded-md  hover:text-white  focus:outline-none') }, void 0) }), void 0)] }), void 0), _jsx(Parent, Object.assign({ className: "overflow-y-auto h_timing h_timing_one" }, { children: tym_array.length > 0 ? (_jsx(Parent, Object.assign({ className: "grid sm:grid-cols-2 grid-cols-1 gap-4 my-2 py-3 px-3 border-2 border-gray-100" }, { children: strt_tym &&
                                                    tym_array.map((item, index) => {
                                                        return (_jsxs(Parent, Object.assign({ className: "border border-gray-200 h-18 rounded-md p-2 relative" }, { children: [_jsx(Text, Object.assign({ as: "p", className: "font-medium text-sm text-gray-700 font-poppins" }, { children: `Timing Part ${index + 1}` }), void 0), _jsx(Parent, Object.assign({ className: " absolute -top-2 -right-1.5 border-2 border-white bg-gray-50 rounded-full  p-1  " }, { children: _jsx(Link, Object.assign({ className: " cursor-pointer  ", onClick: () => showConfirmation(true, item.id), title: t('delete') }, { children: _jsx(Icon, { tag: XIcon, className: classNames(businessType == 'Retail'
                                                                                ? 'text-blue-600'
                                                                                : 'text-blue-600', 'w-3 h-3') }, void 0) }), void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "flex items-center pt-4" }, { children: [_jsx(Icon, { tag: ClockIcon, className: "w-5 h-5 text-gray-200 text-base items-center font-medium font-poppins " }, void 0), _jsx(Text, Object.assign({ as: "p", className: "font-normal text-xs text-gray-400 pl-3 font-poppins" }, { children: `${item.strt} - ${item.cls}` }), void 0)] }), void 0)] }), item.id));
                                                    }) }), void 0)) : ('') }), void 0), _jsx(Parent, Object.assign({ className: "lg:absolute lg:bottom-6 lg:right-6" }, { children: _jsxs(Parent, Object.assign({ className: "flex  justify-end " }, { children: [_jsx(Button, { text: menu_schedule_id ? t('cancel') : t('reset'), onClick: () => (menu_schedule_id ? cancel() : reset()), className: "inline-flex justify-center items-center px-4 py-2 text-base font-poppins font-medium rounded-md bg-blue-100 text-blue-600 " }, void 0), _jsx(Button, Object.assign({ text: menu_schedule_id ? t('update') : t('done'), onClick: () => checkErrors(), className: "ml-4 inline-flex justify-center items-center px-4 py-2 text-base font-poppins font-medium rounded-md bg-blue-600 text-white" }, { children: btnLoading && (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3" }, { children: _jsx(Parent, { className: "animate-spin rounded-full h-5 w-5 border-b-2 border-white-900" }, void 0) }), void 0)) }), void 0)] }), void 0) }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx(ConfirmationAlert, { id: 'menu', open: showAlert, setOpen: setShowAlert, deleteRecord: tym_del, title: t('are_you_sure'), description: `${t('to_remove_this_time_slot')}` }, void 0)] }), void 0)] }, void 0));
};
export default AddNew;
