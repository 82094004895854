import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PageHeader from '../Layouts/Core/PageHeader/PageHeader';
import Layout from '../Layouts/Layout';
import { useTranslation } from 'react-i18next';
import '../../translations/i18n';
import LoyaltyTab from '../Containers/Loyalty/LoyaltyTab';
const Loyalty = () => {
    const { t } = useTranslation();
    const breadCrumbs = [
        // { title: t("home"), href: "#/dashboard", current: false, show: "block" },
        {
            title: t('customer'),
            href: '#/customers',
            current: false,
            show: 'hidden sm:block'
        },
        { title: t('loyalty'), href: '#/loyalty', current: true, show: 'block' }
    ];
    return (_jsxs(Layout, { children: [_jsx(PageHeader, { title: t('loyalty'), breadcrumbs: breadCrumbs }, void 0), _jsx(LoyaltyTab, {}, void 0)] }, void 0));
};
export default Loyalty;
