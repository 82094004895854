import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Text, Image, Input, Button } from '..';
import { useMutation } from '@apollo/client';
import { BUSINESSIMAGEUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { t } from 'i18next';
import { classNames, imageHandler } from '../../Utilities/Helpers';
const no_image = require('../../assets/media/no-image.png');
const EditBussiness = ({ className, reloadData, businesses, setOpen, onClick, setSingleBusiness, title, businessId }) => {
    const [id, setId] = useState('');
    const [updateBusinessImage, { data, loading, error }] = useMutation(BUSINESSIMAGEUPDATEQUERY);
    const [uploadImg, setUploadImg] = useState('');
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    const [editBusinessImgErr, setEditBusinessImgErr] = useState(false);
    const saveBusiness = () => {
        const params = {
            id: id,
            business_img: uploadImg
        };
        updateBusinessImage({
            variables: params
        }).then((result) => {
            reloadData(result, 'update');
            setOpen(false);
        });
    };
    useEffect(() => {
        if (setSingleBusiness)
            setUploadImg(setSingleBusiness.business_img);
        if (businessId) {
            setId(businessId);
        }
    }, [businessId]);
    return (_jsx(Parent, { children: _jsx(Parent, { children: _jsxs(Parent, { children: [_jsxs(Parent, Object.assign({ className: " w-full pb-10" }, { children: [_jsx(Parent, Object.assign({ className: "  pb-4" }, { children: _jsx(Text, Object.assign({ className: "block text-sm font-medium font-poppins text-gray-800  ", as: "label" }, { children: t('business_image') }), void 0) }), void 0), _jsxs(Parent, Object.assign({ className: "flex w-full" }, { children: [_jsxs(Parent, Object.assign({ className: "w-52 h-48 " }, { children: [_jsx(Image, { className: " w-full h-48 object-cover rounded-lg ", src: uploadImg ? uploadImg : no_image, alt: "Home img" }, void 0), editBusinessImgErr && (_jsx(Text, { as: "p", className: "mt-1 leading-6 font-normal text-left text-xs text-red-600 max-w-xs", label: t('file_size') }, void 0))] }), void 0), _jsx(Parent, Object.assign({ className: "w-2/4 flex flex-col-reverse justify-center" }, { children: _jsx(Parent, Object.assign({ className: "flex flex-col text-sm text-center text-gray-600" }, { children: _jsxs(Text, Object.assign({ htmlFor: "file-upload", className: classNames(businessType == 'Retail'
                                                    ? 'bg-blue-600 '
                                                    : 'bg-blue-600 ', 'w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2  text-base font-medium text-white focus:outline-none  ml-3 sm:w-auto '), as: "label" }, { children: [_jsx(Text, Object.assign({ as: "span", className: "items-center" }, { children: t('browse') }), void 0), _jsx(Input, { id: "file-upload", name: "file-upload", type: "file", className: "sr-only", onChange: (e) => imageHandler(e, setEditBusinessImgErr, setUploadImg) }, void 0)] }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0), _jsx(Parent, { children: _jsxs(Parent, Object.assign({ className: "flex justify-end" }, { children: [_jsx(Parent, { children: _jsx(Button, { onClick: () => saveBusiness(), disabled: editBusinessImgErr || !uploadImg, text: t('update'), className: classNames(editBusinessImgErr || !uploadImg
                                            ? 'opacity-50 cursor-not-allowed bg-blue-100 text-blue-600'
                                            : 'text-white bg-blue-600 hover:text-white hover:bg-blue-700', 'w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2  text-base font-medium  focus:outline-none  sm:ml-3 sm:w-auto ') }, void 0) }, void 0), _jsx(Parent, { children: _jsx(Button, { text: t('cancel'), className: classNames(businessType == 'Retail'
                                            ? 'bg-blue-100  text-blue-600'
                                            : 'bg-blue-100  text-blue-600', 'w-full inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-4 py-2 text-base font-medium ml-3 sm:w-auto '), onClick: () => setOpen(false) }, void 0) }, void 0)] }), void 0) }, void 0)] }, void 0) }, void 0) }, void 0));
};
export default EditBussiness;
