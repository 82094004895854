import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @format */
import { useState } from 'react';
import { Parent, Text } from '../../Component/index';
import ContainerSection from '../ContainerSection/ContainerSection';
import { UserGroupIcon } from '@heroicons/react/solid';
import SlideOverlay from '../../Component/SlideOverlay/SlideOverlay';
import { CUSTOMERSADDQUERY, CUSTOMERSUPDATEQUERY } from '../../GraphqlQuery/Mutation/Mutation';
import { STARTPAGE } from '../../Utilities/Constants';
import DataNotFound from '../DataNotFound/DataNotFound';
import { useTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { classNames } from '../../Utilities/Helpers';
import SubscriptionTable from './SubscriptionTable';
const Subscriptions = ({ subscriptionGet, setSubscriptionGet, token, subsNumber, startDate, endDate, dueDate }) => {
    const { t } = useTranslation();
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const [singleObj, setSingleObj] = useState({});
    const [showErr, setShowErr] = useState(false);
    const [businessType, setBusinessType] = useState(localStorage.getItem('business_type'));
    //columns header for subscription table
    const tableHead = [
        {
            id: 1,
            title: t('subscription'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('status'),
            className: 'px-6 py-6 text-center text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 3,
            title: t('start_date'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 4,
            title: t('end_date'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 5,
            title: t('features'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 6,
            title: t('total'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 7,
            title: t('actions'),
            className: 'px-4 pl-10 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        }
    ];
    return (_jsx(Parent, Object.assign({ className: "lg:px-12 md:px-6 px-4 lg:pb-9 md:pb-5 " }, { children: loading ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames(businessType == 'Retail' ? 'border-blue-900' : 'border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames(businessType == 'Retail' ? 'text-blue-900' : 'text-blue-900', ' font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsxs(ContainerSection, Object.assign({ className: "bg-white overflow-hidden rounded-lg shadow-custom px-5 py-5 mt-3" }, { children: [_jsx(Parent, Object.assign({ className: "flex items-center justify-between" }, { children: _jsx(Parent, Object.assign({ className: "flex-1 min-w-0" }, { children: _jsx(Text, { as: "p", label: t('subscription'), className: " font-poppins font-semibold text-lg mt-2" }, void 0) }), void 0) }), void 0), subscriptionGet && subscriptionGet.length > 0 ? (_jsx(SubscriptionTable, { resultData: subscriptionGet, columns: tableHead, setCurrentPageNumber: setCurrentPageNumber, currentPageNumber: currentPageNumber, total: subscriptionGet && subscriptionGet.length > 0
                        ? subscriptionGet.length
                        : 0, setSubscriptionGet: setSubscriptionGet, token: token, subsNumber: subsNumber, startDate: startDate, endDate: endDate, dueDate: dueDate }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('subscription_data_are_not_available'), iconClassName: classNames(businessType == 'Retail' ? 'text-blue-600' : 'text-blue-600', 'h-16 w-16  m-auto mb-2'), iconTag: UserGroupIcon }, void 0)), _jsx(SlideOverlay, { overLayWidth: "max-w-2xl", open: open, setOpen: setOpen, title: sliderTitle, from: "customers", singleObj: singleObj, addQuery: CUSTOMERSADDQUERY, updateQuery: CUSTOMERSUPDATEQUERY, showErr: showErr, setShowErr: setShowErr }, void 0)] }), void 0)) }), void 0));
};
export default Subscriptions;
