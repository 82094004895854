var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Button, Parent, Text } from '../../Component';
import { UploadImageCustom } from './UploadImage';
import ProductComp from '../../Component/Products/Products';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AboutUsSideBanner from '../../assets/Icons/AboutUsSideBanner';
import apiCall from '../../../API/apiCall';
import { APILINK } from '../../Utilities/ApiLink';
import { SERVERURL } from '../../Utilities/Constants';
import { fromUrlToBase64Data } from '../../Utilities/Helpers';
import SuccessPopUp from '../../Component/SuccessPopUp/SuccessPopUp';
import ResetPopup from '../Orders/OnlineOrder/ResetPopup';
const OrderNow = ({ getValidationWebsite }) => {
    const [updatedId, setUpdatedId] = useState();
    const [isOpen, setisOpen] = useState(false);
    const [resetOrderOpen, setResetOrderOpen] = useState(false);
    const ordernowSchema = Yup.object().shape({
        order_now_section_one_heading: Yup.string()
            .required('Heading is required')
            .max(50, 'Heading should contain 40 to 50 characters')
    });
    const saveData = (values, resetForm) => __awaiter(void 0, void 0, void 0, function* () {
        const params = {
            id: values.id,
            business_id: localStorage.getItem('businessId'),
            outlet_id: localStorage.getItem('outletId'),
            order_now_section_one_banner_image: ordernowSingleBanner
            // order_now_section_one_heading: values.order_now_section_one_heading
        };
        const token = localStorage.getItem('token');
        if (isOrderNowNoRecord) {
            yield apiCall
                .PostServerCall(APILINK.website_setup_order_now, 'POST', token, params)
                .then((response) => {
                if (response.data.message['code'] === '0001') {
                    setShowpop(true);
                    getOrderDetails();
                    getValidationWebsite();
                }
            })
                .catch((err) => {
                console.log(err, 'err');
            });
        }
        else {
            yield apiCall
                .PostServerCall(`${APILINK.website_setup_order_now}/${ordernowDetails.id}`, 'PUT', token, params)
                .then((response) => {
                if (response.data.message['code'] === '0001') {
                    setShowpop(true);
                    getOrderDetails();
                }
            })
                .catch((err) => {
                console.log(err, 'err');
            });
        }
    });
    const [ordernowSingleBannerErr, setordernowSingleBannerErr] = useState(false);
    const [ordernowSingleBanner, setordernowSingleBanner] = useState('');
    const [isOrderNowNoRecord, setOrderNowNoRecord] = useState();
    const [showpop, setShowpop] = useState(false);
    const orderNowInitialValues = {
        id: '',
        order_now_section_one_banner_image: ''
    };
    const [ordernowDetails, setOrdernowDetails] = useState(orderNowInitialValues);
    const getOrderDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        const params = {
            business_id: localStorage.getItem('businessId'),
            outlet_id: localStorage.getItem('outletId')
        };
        yield apiCall
            .GetServerCall(`${APILINK.website_setup_order_now}`, 'GET', localStorage.getItem('token'), params)
            .then((response) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (response.data.message['code'] === '0001') {
                const data = response.data.data;
                if (Array.isArray(data)) {
                    if (data.length == 0) {
                        setOrderNowNoRecord(true);
                    }
                    else {
                        setOrderNowNoRecord(false);
                    }
                }
                else {
                    setOrderNowNoRecord(false);
                    setUpdatedId((_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id);
                    setOrdernowDetails(data);
                    const ORDER_NOW_SINGLE_BANNER_IMG_URL = `${SERVERURL}/${data === null || data === void 0 ? void 0 : data.order_now_section_one_banner_image}`;
                    fromUrlToBase64Data(ORDER_NOW_SINGLE_BANNER_IMG_URL, (myBase64) => {
                        setordernowSingleBanner(myBase64);
                    });
                }
            }
        }))
            .catch((err) => {
            console.log(err);
        });
    });
    useEffect(() => {
        getOrderDetails();
    }, []);
    useEffect(() => {
        setTimeout(() => {
            setShowpop(false);
        }, 3000);
    }, [showpop]);
    function resetOrderNow(resetForm) {
        setordernowSingleBanner('');
        // setOrdernowDetails(orderNowInitialValues);
        resetForm();
    }
    return (_jsxs(_Fragment, { children: [_jsx(Parent, Object.assign({ className: " right-5 left-5 sm:left-0 sm:right-12 top-28 mt-2 absolute flex justify-end " }, { children: isOrderNowNoRecord
                    ? showpop && (_jsx(SuccessPopUp, { setShowSuccess: setShowpop, title: 'Order Now Saved', description: 'Order Now Saved Successfully' }, void 0))
                    : showpop && (_jsx(SuccessPopUp, { setShowSuccess: setShowpop, title: 'Order Now Updated', description: 'Order Now Updated Successfully' }, void 0)) }), void 0), _jsx(Formik, Object.assign({ enableReinitialize: true, initialValues: ordernowDetails, onSubmit: (values, { resetForm }) => saveData(values, resetForm) }, { children: ({ handleChange, handleBlur, handleSubmit, values, touched, isSubmitting, isValid, errors, resetForm }) => (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "mt-5 orderNow flex flex-row w-full justify-between items-center" }, { children: [_jsxs("div", { children: [_jsx(Text, { as: "p", className: " text-lg font-poppins font-medium text-gray-700", label: "Order Now" }, void 0), _jsx(Text, { as: "p", className: "mt-2 text-sm font-poppins font-normal text-gray-500", label: "Highlight all your Menu items, It will be displayed in your order details page" }, void 0)] }, void 0), _jsxs("div", { children: [_jsx(Button, { text: "Reset", className: "ml-3 mt-3 sm:mt-0  inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-8 py-2 bg-blue-100 text-base font-medium text-blue-600 hover:text-white hover:bg-blue-600  w-auto ", onClick: () => setResetOrderOpen(true) }, void 0), _jsx(Button, { text: `${isOrderNowNoRecord === true ? 'Save' : 'Update'}`, disabled: ordernowSingleBanner === undefined ||
                                                ordernowSingleBanner === '', className: "disabled:opacity-50 mt-3  inline-flex justify-center rounded-md border border-gray-200 shadow-sm px-6 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 ml-3 w-auto ", onClick: handleSubmit }, void 0)] }, void 0)] }), void 0), _jsxs("div", Object.assign({ className: "max-h-screen overflow-y-auto" }, { children: [_jsxs(Parent, { children: [_jsx(Text, { as: "p", className: "mt-5 text-lg leading-6 font-medium font-poppins  text-gray-800 ", label: 'Section 1 (Banner)' }, void 0), _jsx(Text, { as: "p", className: "mt-2 text-sm font-poppins font-normal text-gray-500", label: 'Caption to be displayed in this page' }, void 0)] }, void 0), _jsxs(Parent, Object.assign({ className: "mt-8" }, { children: [_jsx(Text, { as: "p", className: " mb-3 text-base leading-6 font-medium font-poppins  text-gray-800 ", label: 'Single Banner' }, void 0), _jsx("div", Object.assign({ className: "border-2 border-b-gray-200 hover:border-blue-500 p-3 my-5 flex justify-center w-64 rounded-lg" }, { children: _jsx(AboutUsSideBanner, { className: '', color: '' }, void 0) }), void 0)] }), void 0), _jsx(Parent, Object.assign({ className: "my-4" }, { children: _jsx(UploadImageCustom, { imageValue: ordernowSingleBanner, setImageValue: setordernowSingleBanner, imageErr: ordernowSingleBannerErr, setImageErr: setordernowSingleBannerErr, imageRequiredText: "Single Banner Image is Required", page: "OrderNow" }, void 0) }), void 0), _jsx("div", { className: "border-b border-b-gray-200" }, void 0), _jsx(Parent, { children: _jsx(Parent, Object.assign({ className: "mt-4 " }, { children: _jsx(ProductComp, {}, void 0) }), void 0) }, void 0), _jsx(ResetPopup, { open: resetOrderOpen, setOpen: setResetOrderOpen, resetData: resetOrderNow, resetForm: resetForm }, void 0)] }), void 0)] }, void 0)) }), void 0)] }, void 0));
};
export default OrderNow;
