var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/** @format */
import { useState, useEffect } from 'react';
import { Parent, Checkbox, Text } from '../../../../Component/index';
import DataNotFound from '../../../DataNotFound/DataNotFound';
import { DATALIMIT, PAGELIMIT, STARTPAGE } from '../../../../Utilities/Constants';
import SelectProductsTable from './SelectProductsTable';
import { useTranslation } from 'react-i18next';
import '../../../../../translations/i18n';
import { APILINK } from '../../../../Utilities/ApiLink';
import useRestApiHooks from '../../../../Containers/hooks/RestApiHooks';
import { classNames } from '../../../../Utilities/Helpers';
const SelectProducts = ({ title, setApiItemArray, apiItemArray, setProDeteils }) => {
    const { t } = useTranslation();
    const [currentPageNumber, setCurrentPageNumber] = useState(STARTPAGE);
    const [productCheck, setProductCheck] = useState(false);
    const [open, setOpen] = useState(false);
    const [sliderTitle, setSliderTitle] = useState('');
    const [targetProd, setTargetProd] = useState([]);
    const [apiResultArray, setApiResultArray] = useState([]);
    const [is_all_products, setIs_all_products] = useState(0);
    const sliderOpen = (value, title) => {
        setOpen(value);
        setSliderTitle(title);
    };
    const getSingleObj = (id, value, title) => {
        const Obj = apiResultArray.find((obj) => obj.id === id);
        setOpen(value);
        setSliderTitle(title);
    };
    const Tablecolumns = [
        {
            id: 1,
            title: (_jsxs(Parent, Object.assign({ className: "flex" }, { children: [_jsx(Checkbox, { type: "checkbox", name: "products", id: "", checked: productCheck, className: "focus:ring-transparent h-4 w-4 mr-2 text-blue-500 items-center justfy-center border-gray-300 rounded", onChange: (e) => changeTarCust(e) }, void 0), t('image')] }), void 0)),
            className: 'px-2 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        },
        {
            id: 2,
            title: t('product_name'),
            className: 'px-6 py-6 text-left text-sm font-poppins font-medium text-gray-400'
        }
    ];
    const changeTarCust = (e) => {
        if (e.target.checked === true)
            e.target.value = '1';
        if (e.target.value == '1') {
            setIs_all_products(1);
            setProductCheck(true);
            let oldCust = [];
            apiResultArray.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: true });
                oldCust.push(cust);
                setApiResultArray(oldCust);
                setApiItemArray(oldCust);
            });
        }
        else {
            setIs_all_products(0);
            setProductCheck(false);
            let oldCust = [];
            apiResultArray.map((item) => {
                let cust = Object.assign(Object.assign({}, item), { checked: false });
                oldCust.push(cust);
                setApiResultArray(oldCust);
                setApiItemArray(oldCust);
            });
        }
    };
    const checks = (e) => {
        const oldpro = [...targetProd];
        setProductCheck(false);
        if (e.target.checked) {
            let date = parseInt(e.target.id);
            oldpro.push(date);
            setTargetProd(oldpro);
            let oldItem = [];
            apiItemArray.map((item) => {
                if (item.id == e.target.id) {
                    let cust = Object.assign(Object.assign({}, item), { checked: true });
                    oldItem.push(cust);
                }
                else {
                    let cust = Object.assign({}, item);
                    oldItem.push(cust);
                }
                setApiItemArray(oldItem);
                setApiResultArray(oldItem);
            });
        }
        else {
            let oldItem = [];
            apiItemArray.map((item) => {
                if (item.id == e.target.id) {
                    let cust = Object.assign(Object.assign({}, item), { checked: false });
                    oldItem.push(cust);
                }
                else {
                    let cust = Object.assign({}, item);
                    oldItem.push(cust);
                }
                setApiItemArray(oldItem);
                setApiResultArray(oldItem);
            });
            const filDate = oldpro.filter((data) => {
                return data != e.target.id;
            });
            setTargetProd(filDate);
        }
    };
    const proCheck = () => {
        let proItem = [];
        apiItemArray.map((audience, item) => {
            if (audience.checked) {
                let pro = Object.assign(Object.assign({}, item), { checked: true });
                proItem.push(pro);
            }
        });
        if (apiItemArray.length > 0) {
            if (proItem.length == apiItemArray.length) {
                setProductCheck(true);
            }
            else {
                setProductCheck(false);
            }
        }
    };
    const { isLoading: getLoad, error, sendRequest: fetchTasks } = useRestApiHooks();
    const [filterData, setFilterData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataLimit, setDataLimit] = useState(DATALIMIT);
    const [pageLimit, setPageLimit] = useState(PAGELIMIT);
    const [items, setItems] = useState(filterData);
    const [startIndex, setStartIndex] = useState(1);
    const [endIndex, setEndIndex] = useState(dataLimit);
    const [strt, setStrt] = useState(0);
    const [end, setEnd] = useState(5);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const businessId = localStorage.getItem('businessId');
    const outletId = localStorage.getItem('outletId');
    useEffect(() => {
        getItem();
    }, [offset, dataLimit]);
    useEffect(() => {
        setItems(filterData);
        getPaginatedData(currentPageNumber);
    }, [currentPageNumber, dataLimit, filterData]);
    const getPaginatedData = (pageNumber) => {
        const startIndex = pageNumber * dataLimit - dataLimit;
        const endIndex = currentPage * dataLimit;
        const list = filterData.slice(startIndex, endIndex);
        setEndIndex(endIndex);
        setStartIndex(startIndex + 1);
        setOffset(currentPage === 1 ? 0 : (currentPage - 1) * dataLimit);
    };
    useEffect(() => {
        setApiResultArray(apiItemArray);
    }, [title]);
    useEffect(() => {
        proCheck();
    }, []);
    useEffect(() => {
        setProDeteils('pro', is_all_products, targetProd);
    }, [is_all_products, targetProd.length]);
    const [productTotalLength, setproductTotalLength] = useState(0);
    const datass = (response) => __awaiter(void 0, void 0, void 0, function* () {
        let oldItem = [];
        response.data.data.map((item) => {
            let it = Object.assign(Object.assign({}, item), { name: item.prod_name, image: item.product_img });
            oldItem.push(it);
        });
        yield setApiResultArray(oldItem);
        setproductTotalLength(Number(response.data.product_count));
        setItems(filterData);
        getPaginatedData(currentPageNumber);
    });
    const getItem = () => __awaiter(void 0, void 0, void 0, function* () {
        const param = {
            business_id: businessId,
            outlet_id: outletId,
            offset: offset,
            limit: dataLimit
        };
        fetchTasks({
            url: APILINK.product_get,
            method: 'GET',
            token,
            param
        }, datass);
    });
    return (_jsx(Parent, { children: getLoad ? (_jsx(Parent, Object.assign({ className: "flex justify-center items-center mr-3 h-screen" }, { children: _jsxs(Parent, Object.assign({ className: "text-center" }, { children: [_jsx(Parent, { className: classNames('border-blue-900', 'animate-spin rounded-full mx-auto  h-10 w-10 border-b-2 ') }, void 0), _jsx(Text, { as: "p", label: t('loading'), className: classNames('text-blue-900, font-poppins font-medium text-lg mt-2') }, void 0)] }), void 0) }), void 0)) : (_jsx(_Fragment, { children: _jsx(Parent, Object.assign({ className: "" }, { children: apiResultArray && apiResultArray.length > 0 ? (_jsx(SelectProductsTable, { onClick: sliderOpen, resultData: apiResultArray, getSingleObj: getSingleObj, columns: Tablecolumns, currentPageNumber: currentPageNumber, setCurrentPageNumber: setCurrentPageNumber, checks: checks, total: productTotalLength, offset: offset, setOffset: setOffset, items: items, pageLimit: pageLimit, dataLimit: dataLimit, getPaginatedData: getPaginatedData, setDataLimit: setDataLimit, startIndex: startIndex, endIndex: endIndex, currentPage: currentPage, setItems: setItems, setCurrentPage: setCurrentPage, strt: strt, setStrt: setStrt, end: end, setEnd: setEnd }, void 0)) : (_jsx(DataNotFound, { textClassName: "py-3 text-sm text-gray-400 font-poppins font-normal text-center ", textString: t('product_details_are_not_available_click_select_products_or_select_categories'), iconTag: 'symbol', iconClassName: '' }, void 0)) }), void 0) }, void 0)) }, void 0));
};
export default SelectProducts;
